import { useRecoilCallback } from "recoil";

import type { TrackSupplierClickArgs } from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  getNumberSupplierHits,
} from "../components/ContractSearch/utils";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSearchAnalyticsParamsState,
  contractSearchParamsState,
  contractSearchTrackingCountsState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  searchResultTypeState,
  singleAwardOnlyFilterState,
  supplierSearchResponseDataState,
} from "../recoil/search";
import { searchTypes } from "../utils/enums";
import { trackContractClick } from "../utils/tracking";

export default function useTrackSupplierClick() {
  const _trackSupplierClick = useRecoilCallback<TrackSupplierClickArgs, void>(
    ({ snapshot }) =>
      async (data) => {
        const [
          supplierResponseData,
          searchParams,
          analyticsParams,
          trackingCounts,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          contractSourceFilter,
          expirationFilter,
          contractDocumentsFilter,
          searchResultType,
        ] = await Promise.all([
          snapshot.getPromise(supplierSearchResponseDataState),
          snapshot.getPromise(contractSearchParamsState),
          snapshot.getPromise(contractSearchAnalyticsParamsState),
          snapshot.getPromise(contractSearchTrackingCountsState),
          snapshot.getPromise(diversityCertificationsFilterState),
          snapshot.getPromise(approvedSourcesOnlyFilterState),
          snapshot.getPromise(singleAwardOnlyFilterState),
          snapshot.getPromise(contractSourceFilterState),
          snapshot.getPromise(expirationFilterState),
          snapshot.getPromise(contractDocumentsFilterState),
          snapshot.getPromise(searchResultTypeState),
        ]);
        const params = formatSearchPageParams(searchParams);
        const { searchSource, requestID } = analyticsParams;

        // For supplier results, absolute depth equals depth, because we list supplier results first
        trackContractClick({
          ...trackingCounts,
          matchTier: null,
          semanticScore: null,
          filterScore: null,
          ...data,
          relativeDepth: data.absoluteDepth,
          contractId: null,
          buyerLeadAgencyId: null,
          buyerLeadAgency: null,
          cooperativeAffiliation: null,
          firstPageSupplierCount: getNumberSupplierHits(supplierResponseData),
          searchQuery: params.query,
          queryZip: params.zip,
          searchType:
            searchResultType === "supplier"
              ? searchTypes.SUPPLIER
              : searchTypes.CONTRACT,
          userSelectedFromDefault: null,
          searchSource,
          requestID,
          expirationFilter,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          contractSourceFilter,
          contractDocumentsFilter,
        });
      },
    []
  );
  return _trackSupplierClick;
}
