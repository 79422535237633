import clsx from "clsx";
import { Suspense, lazy, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import { PageSection, Typography } from "../../library";
import { SuccessPopup } from "../../popups/AnimatedPopup";
import {
  profileTypeState,
  userState as recoilUserState,
  supplierExpiredAgreementsState,
  userInitializedState,
} from "../../recoil/user";
import Tag, { TagVariants } from "../../shared/Tag";
import { getParam, setParamNoHistory } from "../../utils";
import { ProfileType, SupplierApprovalStatus } from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import { isFeatureEnabled } from "../../utils/split";
import BuyerProfilePage from "./BuyerProfilePage/BuyerProfilePage";
import ProSupplierWelcomePage from "./ProSupplierWelcomePage";
import OldProSupplierWelcomePage from "./ProSupplierWelcomePage/OldProSupplierWelcomePage";
import SupplierWelcomePage from "./SupplierWelcomePage";
import { bannerClass, bannerHeightClass } from "./constants";
import type { SupplierAgreement } from "./types";

// Lazily load components to avoid inflating the main bundle.
const WelcomeBanner = lazy(() => import("./WelcomeBanner"));

interface WelcomePageProps {
  numAgencyContracts: Maybe<number>;
  numExpiringAgencyContracts: Maybe<number>;
  numSupplierActiveShareableContracts: Maybe<number>;
  expiredAgreements: Maybe<SupplierAgreement[]>;
  emailVerified: boolean;
  supplierApprovalStatus: SupplierApprovalStatus;
  supplierLogoUrl: Maybe<string>;
}

function WelcomePage({
  numAgencyContracts,
  numExpiringAgencyContracts,
  numSupplierActiveShareableContracts,
  expiredAgreements,
  emailVerified,
  supplierApprovalStatus,
  supplierLogoUrl,
}: WelcomePageProps) {
  const { firstName, buyerProfile, userState, supplier } =
    useRecoilValue(recoilUserState);
  const isInitialized = useRecoilValue(userInitializedState);
  const showSignupSurvey = useShowSignupSurvey();
  const setExpiredAgreements = useSetRecoilState(
    supplierExpiredAgreementsState
  );
  const profileType = useRecoilValue(profileTypeState);
  const isSupplier = profileType === ProfileType.SUPPLIER;
  const textColor = isSupplier
    ? "neutral.inverse.primary.enabled"
    : "brand.bold";

  useEffect(() => {
    if (expiredAgreements) setExpiredAgreements(expiredAgreements);
  }, [expiredAgreements, setExpiredAgreements]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once after initialization.
  useEffect(() => {
    if (
      !isInitialized ||
      !isSupplier ||
      userState.hasSeenSupplierSourceChannelSurvey
    )
      return;
    showSignupSurvey({});
  }, [isInitialized]);

  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(
    getParam("emailConfirmed") === "true"
  );

  const showProSupplierWelcomePage =
    emailVerified &&
    supplierApprovalStatus === SupplierApprovalStatus.APPROVED &&
    supplierHasFeature(supplier?.activeAgreements, "basicProfileAccess");

  function getSupplierPage() {
    if (showProSupplierWelcomePage) {
      return <OldProSupplierWelcomePage />;
    }
  }

  // TODO: Show new pro supplier welcome page behind a feature flag.
  if (isSupplier) {
    if (!showProSupplierWelcomePage) {
      return (
        <SupplierWelcomePage
          emailVerified={emailVerified}
          supplierApprovalStatus={supplierApprovalStatus}
          numActiveShareableContracts={numSupplierActiveShareableContracts || 0}
        />
      );
    }
    if (isFeatureEnabled("supplierDashboardUnification")) {
      return (
        <ProSupplierWelcomePage
          numActiveShareableContracts={numSupplierActiveShareableContracts || 0}
          supplierLogoUrl={supplierLogoUrl || ""}
        />
      );
    }
  }

  return (
    <>
      <SuccessPopup
        show={hasConfirmedEmail}
        setShow={(show) => {
          setHasConfirmedEmail(show);
          setParamNoHistory("emailConfirmed", null);
        }}
        onClose={() => setHasConfirmedEmail(false)}
      >
        Success! Your email is now verified.
      </SuccessPopup>

      <div className="analytics-welcome-page min-h-[calc(100vh-23rem)] flex flex-col">
        {isSupplier && (
          <PageSection>
            <Suspense fallback={<div className={bannerClass(isSupplier)} />}>
              <WelcomeBanner
                coverBackground
                agencyType={
                  isSupplier
                    ? "SUPPLIER"
                    : buyerProfile?.governmentAgency?.agencyType
                }
              />
            </Suspense>
            <div
              className={clsx(
                "pt-10 flex flex-col gap-2",
                bannerHeightClass({ isSupplier: true })
              )}
            >
              {showProSupplierWelcomePage && (
                <Tag variant={TagVariants.PLACEHOLDER} showIcon={false}>
                  Pro account
                </Tag>
              )}
              <Typography variant="display" color={textColor}>
                Welcome{firstName ? `, ${firstName}` : ""}
              </Typography>
              <Typography variant="headline" size="sm" color={textColor}>
                {!!userState?.role && `${userState.role} at `}
                {isSupplier
                  ? supplier?.displayName
                  : buyerProfile?.governmentAffiliationDisplayName}
              </Typography>
            </div>
          </PageSection>
        )}
        {isSupplier ? (
          getSupplierPage()
        ) : (
          <BuyerProfilePage
            numAgencyContracts={numAgencyContracts || 0}
            numExpiringAgencyContracts={numExpiringAgencyContracts || 0}
          />
        )}
      </div>
    </>
  );
}

export default WelcomePage;
