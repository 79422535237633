import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import clsx from "clsx";
import { isSameDay, parseISO } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useRecoilState, useRecoilValue } from "recoil";

import useSelectTabByHash from "../../../hooks/useSelectTabByHash";
import { Badge, PageSection } from "../../../library";
import Avatar, { AvatarSizes } from "../../../library/Avatar";
import Button, { ButtonThemes } from "../../../library/Button";
import Link from "../../../library/Link";
import Logo, { LogoSizes } from "../../../library/Logo";
import Typography from "../../../library/Typography";
import type { SubmitFn } from "../../../library/form/types";
import {
  supplierState,
  userInitializedState,
  userState,
} from "../../../recoil/user";
import AccountVerificationBanner from "../../../shared/AccountVerificationBanner";
import UploadContractsButton from "../../../shared/UploadContractsButton";
import { handleError, patchSupplierProfile } from "../../../utils/api";
import { formatDate } from "../../../utils/date";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { formatAddressString, getSupplierUrlPath } from "../../../utils/format";
import { isFeatureEnabled } from "../../../utils/split";
import {
  getUser,
  trackSupplierEditFormStatus,
  trackSupplierEditRequest,
} from "../../../utils/tracking";

import _parseInt from "lodash/parseInt";
import {
  ApiService,
  type SupplierEditRequestSummary,
  SupplierEditRequestTypeEnum,
} from "../../../generated";
import { SupplierApprovalStatus } from "../../../utils/enums";
import { handleError as handleGeneratedError } from "../../../utils/generatedApi";
import SupplierEditFormWrapper from "./SupplierEditFormWrapper";
import AdditionalLocations from "./SupplierEditSection/AdditionalLocations";
import SupplierAliasSection from "./SupplierEditSection/SupplierAliasSection";
import SupplierContactSection from "./SupplierEditSection/SupplierContactSection";
import SupplierLogoSection from "./SupplierEditSection/SupplierLogoSection";
import SupplierContracts from "./SupplierEditSection/SupplierManageContracts/SupplierContracts";
import SupplierManageContracts from "./SupplierEditSection/SupplierManageContracts/SupplierManageContracts";
import SupplierProductsSection from "./SupplierEditSection/SupplierProductsSection";
import SupplierPublicAgencies from "./SupplierEditSection/SupplierPublicAgencies";
import SupplierServiceAreaForm from "./SupplierEditSection/SupplierServiceArea";
import SupplierTasks from "./SupplierTasks";
import {
  SUPPLIER_ABOUT_FIELDS,
  SUPPLIER_ADDRESS_FIELDS,
  SUPPLIER_BUSINESS_CATEGORY_FIELDS,
  SUPPLIER_COMPETITOR_FIELDS,
  SUPPLIER_EIN_FIELDS,
  SUPPLIER_NAME_FIELDS,
  SUPPLIER_SERVICE_AREA_FIELDS,
  SUPPLIER_WEBSITE_FIELDS,
} from "./constants";
import type {
  Supplier,
  SupplierAboutValues,
  SupplierAddressValues,
  SupplierBusinessCategoryValues,
  SupplierCompetitorValues,
  SupplierDefaultContactValue,
  SupplierEinValues,
  SupplierNameValues,
  SupplierProductListValues,
  SupplierServiceAreaValues,
  SupplierValues,
  SupplierWebsiteValues,
} from "./types";

interface SupplierEditFormProps {
  supplier: Supplier;
}

interface PanelProps extends Supplier {
  handleSubmit: SubmitFn<SupplierValues>;
  currentSupplier: Supplier;
  setCurrentSupplier: (supplier: Supplier) => void;
  setUpdateDate: (date: Date | undefined) => void;
  savedFields: string[];
  editRequests: SupplierEditRequestSummary;
}

const panelClassNames =
  "mt-12 mb-8 flex gap-6 justify-between lg:grid lg:gap-6 lg:grid-cols-12";
const formSectionClassNames =
  "flex flex-col grow-[3] gap-10 lg:grid lg:col-start-1 lg:col-end-8";
const sidebarClassNames =
  "empty:hidden hidden md:block flex flex-col grow-[1] lg:grid gap-8 lg:col-start-9 lg:col-end-13";

const TABS = (
  lockFields: boolean,
  activeAgreements: string[],
  proContractManagementEnabled: boolean,
  isVerifiedUser: boolean,
  isAdmin: boolean,
  savedFields: string[],
  isInitialized: boolean
) => {
  const isProSelfServeOnboarding =
    isFeatureEnabled("proSelfServeOnboarding") &&
    supplierHasFeature(activeAgreements, "selfServeOnboarding");
  const tabList = [
    {
      hash: "details",
      title: "Business details",
      Panel: ({
        displayName,
        handle,
        website,
        about,
        addressLine1,
        addressLine2,
        addressCity,
        addressStateCode,
        addressZip,
        addressCountryCode,
        einNumber,
        businessTypes,
        handleSubmit,
        setUpdateDate,
        currentSupplier,
        setCurrentSupplier,
        editRequests,
      }: PanelProps) => (
        <div className={panelClassNames}>
          <div className={formSectionClassNames}>
            <SupplierEditFormWrapper
              fields={SUPPLIER_NAME_FIELDS}
              onSubmit={handleSubmit as SubmitFn<SupplierNameValues>}
              initialValues={{ displayName }}
              savedFields={savedFields}
            />
            <SupplierAliasSection
              currentSupplier={currentSupplier}
              setCurrentSupplier={setCurrentSupplier}
              handle={handle}
              setUpdateDate={setUpdateDate}
              disabled={lockFields}
            />
            <SupplierEditFormWrapper
              fields={SUPPLIER_EIN_FIELDS}
              onSubmit={handleSubmit as SubmitFn<SupplierEinValues>}
              initialValues={{ einNumber }}
              savedFields={savedFields}
            />
            <SupplierEditFormWrapper
              fields={SUPPLIER_ADDRESS_FIELDS}
              onSubmit={handleSubmit as SubmitFn<SupplierAddressValues>}
              initialValues={{
                address: {
                  addressLine1: addressLine1 || "",
                  addressLine2: addressLine2 || "",
                  addressCity: addressCity || "",
                  addressStateCode: addressStateCode || "",
                  addressZip: addressZip || "",
                  addressCountryCode: addressCountryCode || "",
                },
              }}
              disabled={lockFields}
              savedFields={savedFields}
            />
            {supplierHasFeature(activeAgreements, "additionalLocations") && (
              <AdditionalLocations
                disabled={lockFields}
                currentSupplier={currentSupplier}
                setCurrentSupplier={setCurrentSupplier}
                handle={handle}
                setUpdateDate={setUpdateDate}
              />
            )}
            <SupplierEditFormWrapper
              fields={SUPPLIER_WEBSITE_FIELDS}
              onSubmit={handleSubmit as SubmitFn<SupplierWebsiteValues>}
              initialValues={{ website }}
              disabled={lockFields}
              savedFields={savedFields}
            />
            {supplierHasFeature(activeAgreements, "customLogo") && (
              <SupplierLogoSection
                currentSupplier={currentSupplier}
                setCurrentSupplier={setCurrentSupplier}
                handle={handle}
              />
            )}
            {!isProSelfServeOnboarding && (
              <SupplierEditFormWrapper
                fields={SUPPLIER_BUSINESS_CATEGORY_FIELDS}
                onSubmit={
                  handleSubmit as SubmitFn<SupplierBusinessCategoryValues>
                }
                initialValues={{ businessTypes }}
                savedFields={savedFields}
              />
            )}
            <SupplierEditFormWrapper
              fields={SUPPLIER_ABOUT_FIELDS}
              onSubmit={handleSubmit as SubmitFn<SupplierAboutValues>}
              initialValues={{ about }}
              savedFields={savedFields}
            />
            {isProSelfServeOnboarding && (
              <SupplierEditFormWrapper
                disabled={lockFields}
                fields={SUPPLIER_COMPETITOR_FIELDS}
                onSubmit={handleSubmit as SubmitFn<SupplierCompetitorValues>}
                initialValues={{
                  competitors: editRequests.competitors,
                }}
                savedFields={savedFields}
                callouts={[
                  {
                    title: "What value does sharing competitors add?",
                    description:
                      "This will help increase your visibility when a buyer searches for your competitor.",
                  },
                ]}
                status
              />
            )}
          </div>
          <div className={sidebarClassNames}>
            {isProSelfServeOnboarding && !lockFields && (
              <SupplierTasks
                tasks={[
                  {
                    label: "Add business competitors",
                    completed: !!editRequests.competitors,
                    buttons: [
                      {
                        label: "Add competitors",
                        href: "#competitors",
                        onClick: () => {
                          window.location.hash = "competitors";
                        },
                      },
                    ],
                  },
                  {
                    label: "Add business description",
                    completed: !!about,
                    buttons: [
                      {
                        label: "Add description",
                        href: "#about",
                        onClick: () => {
                          window.location.hash = "about";
                        },
                      },
                    ],
                  },
                ]}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      hash: "contracts",
      title: "Contracts",
      Panel: ({ currentSupplier }: PanelProps) => {
        if (!isInitialized) {
          return null;
        }
        return (
          <div className={clsx(panelClassNames, "flex-col gap-2")}>
            <div className="lg:grid lg:col-span-12">
              {isAdmin || (proContractManagementEnabled && isVerifiedUser) ? (
                <SupplierManageContracts
                  id={Number.parseInt(currentSupplier.id || "")}
                  handle={currentSupplier.handle}
                />
              ) : (
                <SupplierContracts supplier={currentSupplier} />
              )}
            </div>
          </div>
        );
      },
    },
    {
      hash: "service-area",
      title: "Service area",
      Panel: ({
        manualServiceAreaNational,
        manualServiceAreaState,
        handleSubmit,
      }: PanelProps) => (
        <div className={clsx(panelClassNames, "mb-16")}>
          <div className={formSectionClassNames}>
            <SupplierServiceAreaForm
              fields={SUPPLIER_SERVICE_AREA_FIELDS}
              onSubmit={handleSubmit as SubmitFn<SupplierServiceAreaValues>}
              initialValues={{
                serviceArea: {
                  manualServiceAreaNational: !!manualServiceAreaNational,
                  manualServiceAreaState: manualServiceAreaState || [],
                },
              }}
            />
          </div>
        </div>
      ),
    },
    {
      hash: "contacts",
      title: "Sales representatives",
      Panel: ({
        handle,
        supplierEmail,
        setUpdateDate,
        currentSupplier,
        setCurrentSupplier,
        handleSubmit,
      }: PanelProps) => {
        return (
          <div className={panelClassNames}>
            <div className={formSectionClassNames}>
              <SupplierContactSection
                handle={handle}
                updateSupplier={
                  handleSubmit as SubmitFn<SupplierDefaultContactValue>
                }
                supplierEmail={supplierEmail || ""}
                disabled={lockFields}
                setUpdateDate={setUpdateDate}
                currentSupplier={currentSupplier}
                setCurrentSupplier={setCurrentSupplier}
              />
            </div>
          </div>
        );
      },
    },
  ];

  if (isProSelfServeOnboarding) {
    tabList.splice(1, 0, {
      hash: "products-and-services",
      title: "Products and services",
      Panel: ({
        id,
        businessTypes,
        handleSubmit,
        editRequests,
      }: PanelProps) => {
        if (!isInitialized) {
          return null;
        }
        const completed = !!editRequests.productLists.length;
        return (
          <div className={panelClassNames}>
            <div className={formSectionClassNames}>
              <SupplierEditFormWrapper
                fields={SUPPLIER_BUSINESS_CATEGORY_FIELDS}
                onSubmit={
                  handleSubmit as SubmitFn<SupplierBusinessCategoryValues>
                }
                initialValues={{ businessTypes }}
                savedFields={savedFields}
              />
              <SupplierProductsSection
                businessTypes={businessTypes}
                disabled={lockFields}
                completed={completed}
                supplierId={id}
                uploadedProductLists={editRequests.productLists}
                onProductListsUpdated={
                  handleSubmit as SubmitFn<SupplierProductListValues>
                }
              />
            </div>
            <div className={sidebarClassNames}>
              {isProSelfServeOnboarding && !lockFields && (
                <SupplierTasks
                  tasks={[
                    {
                      label: "Add your product list",
                      completed: completed,
                    },
                  ]}
                />
              )}
            </div>
          </div>
        );
      },
    });
  }

  if (supplierHasFeature(activeAgreements, "selfServeOnboarding")) {
    tabList.push({
      hash: "public-entities",
      title: "Customer validation",
      Panel: ({ currentSupplier }: PanelProps) => {
        return (
          <div className={panelClassNames}>
            <div className={formSectionClassNames}>
              <SupplierPublicAgencies
                handle={currentSupplier.handle}
                disabled={lockFields}
                publicEntitiesServed={
                  currentSupplier.publicEntitiesServed || []
                }
              />
            </div>
          </div>
        );
      },
    });
  }

  return tabList;
};
export default function SupplierEditForm({ supplier }: SupplierEditFormProps) {
  const {
    id,
    handle,
    displayName,
    updatedAt,
    hasVerifiedSupplierUser,
    activeAgreements,
    isApproved,
  } = supplier;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [currentSupplier, setCurrentSupplier] = useState(supplier);
  const [savedFields, setSavedFields] = useState<string[]>([]);
  const { isAdmin, emailVerified } = useRecoilValue(userState);
  const isInitialized = useRecoilValue(userInitializedState);
  const proContractManagementEnabled = supplierHasFeature(
    activeAgreements,
    "contractManagement"
  );

  const [updateDate, setUpdateDate] = useState(
    updatedAt ? parseISO(updatedAt) : undefined
  );

  const [supplierRecoilState, setSupplierRecoilState] =
    useRecoilState(supplierState);

  const [editRequests, setEditRequests] = useState<SupplierEditRequestSummary>({
    competitors: null,
    productLists: [],
  });

  const isProSelfServeOnboarding =
    isFeatureEnabled("proSelfServeOnboarding") &&
    supplierHasFeature(activeAgreements, "selfServeOnboarding");

  useEffect(() => {
    (async () => {
      if (!isProSelfServeOnboarding) return;

      const supplierId = _parseInt(id || "");
      const response =
        await ApiService.apiV1SuppliersEditRequestSummaryRetrieve(supplierId);
      setEditRequests(response);
    })();
  }, [isProSelfServeOnboarding, id]);

  const tabs = useMemo(
    () =>
      TABS(
        !isApproved && !hasVerifiedSupplierUser,
        activeAgreements,
        !!proContractManagementEnabled,
        !!(
          emailVerified &&
          supplierRecoilState.approvalStatus === SupplierApprovalStatus.APPROVED
        ),
        !!isAdmin,
        savedFields,
        isInitialized
      ),
    [
      isApproved,
      activeAgreements,
      hasVerifiedSupplierUser,
      savedFields,
      proContractManagementEnabled,
      isAdmin,
      emailVerified,
      supplierRecoilState,
      isInitialized,
    ]
  );
  useSelectTabByHash(tabs, setSelectedTabIndex);

  const aliasString = currentSupplier?.aliases
    ?.filter((alias) => !!alias.displayAlias)
    .slice(0, 5)
    .map((alias) => alias.displayAlias)
    .join(", ");

  const saveSupplierData: SubmitFn<SupplierValues> = async (
    values,
    formikHelpers
  ) => {
    // Special handling for supplier edit requests
    let supplierEditRequestParams = undefined;
    if ("competitors" in values) {
      supplierEditRequestParams = {
        type: SupplierEditRequestTypeEnum.COMPETITORS_UPDATE,
        data: [values.competitors || ""],
      };
    } else if ("productListNames" in values) {
      supplierEditRequestParams = {
        type: values.isUpload
          ? SupplierEditRequestTypeEnum.PRODUCT_LIST_UPLOAD
          : SupplierEditRequestTypeEnum.PRODUCT_LIST_DELETE,
        data: values.productListNames || [],
      };
    }
    if (isProSelfServeOnboarding && supplierEditRequestParams && supplier.id) {
      const supplierId = _parseInt(supplier.id);
      trackSupplierEditRequest(
        supplierId,
        supplierEditRequestParams.type,
        supplierEditRequestParams.data
      );
      try {
        const response = await ApiService.apiV1SuppliersEditRequestCreate(
          supplierId,
          {
            supplierEditRequestType: supplierEditRequestParams.type,
            data: supplierEditRequestParams.data,
          }
        );
        setEditRequests(response);
        setSavedFields(Object.keys(values));
        return true;
      } catch (error) {
        handleGeneratedError(error);
        return false;
      }
    }
    const response = await patchSupplierProfile(handle, values);
    if (handleError(response)) {
      const failedFields = Object.keys(values);
      failedFields.forEach((field) => {
        formikHelpers?.setFieldError(
          field,
          "Invalid request. Please try again."
        );
      });
      return false;
    }

    const data = await response.json();
    setCurrentSupplier((prevSupplier) => Object.assign(prevSupplier, data));
    setSupplierRecoilState((prevState) => ({
      ...prevState,
      about: data?.about,
      displayName: data?.displayName,
      website: data?.website,
      manualServiceAreaNational: data?.manualServiceAreaNational,
      manualServiceAreaState: data?.manualServiceAreaState,
      addressString: formatAddressString(data || {}),
    }));
    setSavedFields(Object.keys(values));
    trackSupplierEditFormStatus(getUser() || "", data);
    if (!updatedAt || !isSameDay(parseISO(updatedAt), new Date())) {
      setUpdateDate(new Date());
    }

    return true;
  };

  const buttonLinkProps = {
    underline: false,
    href: getSupplierUrlPath(handle).toString(),
  };

  return (
    <PageSection className="mt-12">
      <div className="flex flex-col gap-8">
        {!isApproved && (
          <div className="mb-12">
            <AccountVerificationBanner
              subtitle={
                hasVerifiedSupplierUser
                  ? "Hang tight! We are in the process of verifying your profile. This typically takes 2-3 days. Once verification is complete, we will let you know so you can make edits to your business profile."
                  : "We are in the process of verifying your profile. Edits to your profile won't be live until Pavilion verifies your account."
              }
            />
          </div>
        )}
        <div className="flex flex-col gap-6 lg:flex-row justify-between">
          <div className="flex flex-row gap-x-5 items-center">
            {currentSupplier.logoUrl && (
              <Logo imageSrc={currentSupplier.logoUrl} size={LogoSizes.XL} />
            )}
            {!currentSupplier.logoUrl && displayName && (
              <Avatar
                className="min-w-[64px]"
                monogram={displayName[0]}
                size={AvatarSizes.XL}
                bgColor="success.bold.primary.hovered"
              />
            )}
            <div className="grid gap-y-2 items-center">
              <Typography variant="headline" size="lg" color="brand.bold">
                {displayName}
              </Typography>
              {aliasString && (
                <Typography
                  variant="meta"
                  color="neutral.default.secondary.enabled"
                >
                  Also known as {aliasString}
                </Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 lg:flex-row">
              <Button
                theme={ButtonThemes.TERTIARY_DARK}
                as={Link}
                size={Button.sizes.LARGE}
                linkProps={buttonLinkProps}
                className="text-center text-nowrap analytics-supplier-edit-view-live-page"
              >
                View live page
              </Button>
              <UploadContractsButton supplierHandle={supplier?.handle} />
            </div>
            <Typography
              variant="meta"
              color="neutral.default.tertiary.enabled"
              className="lg:text-right font-normal"
            >
              All changes saved
              {updateDate &&
                `. Last update: ${formatDate(updateDate, "M/d/yyyy")}`}
            </Typography>
          </div>
        </div>
        {!isApproved && hasVerifiedSupplierUser && (
          <div className="flex justify-center rounded-xl p-6 bg-cp-persimmon-50">
            <Badge
              className="mr-2 text-cp-persimmon-700"
              Icon={WarningAmberRoundedIcon}
            >
              <Typography color="warning.default">
                Editing your business profile requires a verified account. If
                you have questions, please reach out to{" "}
                <Link
                  href="mailto:support@withpavilion.com"
                  underline={false}
                  newWindow={false}
                  emphasis={false}
                >
                  support@withpavilion.com
                </Link>
                .
              </Typography>
            </Badge>
          </div>
        )}
        <Tabs
          className="w-full mt-0 text-cp-midnight-300"
          forceRenderTabPanel
          selectedIndex={selectedTabIndex}
          onSelect={(index) => {
            setSelectedTabIndex(index);
            window.location.hash = tabs[index].hash;
            setSavedFields([]);
          }}
        >
          <TabList className="flex border-solid border-cp-neutral-20 border-b overflow-auto">
            {tabs.map(({ title, hash }, ix) => (
              <Tab
                key={`${hash}-tab`}
                className={`analytics-${hash}-tab cursor-pointer mr-6 inline-block group text-nowrap`}
              >
                <Typography
                  variant="headline"
                  size="sm"
                  className="flex items-center pb-2"
                  color={
                    selectedTabIndex === ix
                      ? "brand.default.secondary.enabled"
                      : "neutral.default.tertiary.enabled"
                  }
                >
                  {title}
                </Typography>
                <div
                  className={clsx(
                    "bg-cp-lapis-500 h-1 transition-all ease-in",
                    {
                      "w-full": selectedTabIndex === ix,
                      "group-hover:w-3 w-0": selectedTabIndex !== ix,
                    }
                  )}
                />
              </Tab>
            ))}
          </TabList>

          {tabs.map(({ hash, Panel }, ix) => (
            <TabPanel
              key={`${hash}-panel`}
              className={clsx({
                hidden: selectedTabIndex !== ix,
              })}
            >
              <div
                className={clsx({
                  "pointer-events-none opacity-50":
                    hasVerifiedSupplierUser &&
                    !isApproved &&
                    hash !== "contracts",
                })}
              >
                <Panel
                  {...currentSupplier}
                  savedFields={savedFields}
                  handleSubmit={saveSupplierData}
                  currentSupplier={currentSupplier}
                  setCurrentSupplier={setCurrentSupplier}
                  setUpdateDate={setUpdateDate}
                  editRequests={editRequests}
                />
              </div>
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </PageSection>
  );
}
