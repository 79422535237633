import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import clsx from "clsx";
import type { ReactNode } from "react";

import { Badge } from "../../library";
import { textColorClass } from "../../utils/colors";

export default function ConfirmedBadge({
  inline = true,
  children,
}: { inline?: boolean; children: ReactNode }) {
  return (
    <Badge
      className={clsx(
        { "ml-4 h-fit": inline },
        textColorClass.feedback.bold.success
      )}
      size="sm-md"
      Icon={CheckCircleRoundedIcon}
      inline={inline}
    >
      {children}
    </Badge>
  );
}
