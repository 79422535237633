import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import { isFeatureEnabled } from "../../utils/split";
import BuyerOptInModal from "./BuyerOptinModal";
import type { SharedBuyerOptInModalProps } from "./types";

interface BuyerOptInContractSourceModalProps
  extends SharedBuyerOptInModalProps {}

export type ShowBuyerOptInContractSourceModal = (
  args: Omit<BuyerOptInContractSourceModalProps, "hideModal">
) => void;

export default function BuyerOptInContractSourceModal({
  ...props
}: BuyerOptInContractSourceModalProps) {
  return (
    <BuyerOptInModal
      {...props}
      title="You’re leaving Pavilion, but before you go..."
      subtitle={
        isFeatureEnabled("quoteRequestOptIn")
          ? "Can we connect you with suppliers for quotes via email?"
          : "Can we connect you with this supplier to get pricing and contract details in your inbox?"
      }
      ctaText="Go to contract source"
      ctaBadgeProps={{
        Icon: LaunchRoundedIcon,
        reverse: true,
      }}
      analyticsClassName="analytics-buyer-contract-source-optin-contact-modal"
    />
  );
}
