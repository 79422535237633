import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ApiService, type ProQualifications } from "../generated";
import { isAuthenticatedState, profileTypeState } from "../recoil/user";
import { ProfileType, modals } from "../utils/enums";
import { handleError } from "../utils/generatedApi";
import useShowModal from "./useShowModal";

export default function useShowProQualificationEffect({
  proQualifications,
  supplierName,
  supplierId,
  solicitationId,
}: {
  proQualifications: Maybe<ProQualifications>;
  supplierId: number;
  supplierName: string;
  solicitationId?: string;
}) {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const profileType = useRecoilValue(profileTypeState);
  const showProQualification = useShowModal(modals.PRO_QUALIFICATION);
  const [shouldFetch, setShouldFetch] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Check only once on first render.
  useEffect(() => {
    if (!isAuthenticated) {
      setShouldFetch(true);
      return;
    }
    if (
      !(
        proQualifications?.minOrderSize ||
        proQualifications?.qualifiedProducts?.length
      ) ||
      profileType !== ProfileType.BUYER
    )
      return;

    showProQualification({
      proQualifications,
      supplierName,
      supplierId,
      solicitationId,
    });
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when the user authenticates after the page loads.
  useEffect(() => {
    if (!isAuthenticated || !shouldFetch || profileType !== ProfileType.BUYER)
      return;

    (async () => {
      try {
        const response =
          await ApiService.apiV1SuppliersProQualificationsRetrieve(supplierId);

        if (!(response.minOrderSize || response.qualifiedProducts?.length)) {
          setShouldFetch(false);
          return;
        }

        showProQualification({
          proQualifications: response,
          supplierName,
          supplierId,
          solicitationId,
        });
      } catch (err) {
        // If we fail, do not show the modal and exit gracefully.
        handleError(err);
        setShouldFetch(false);
        return;
      }
    })();
  }, [isAuthenticated, shouldFetch, profileType]);
}
