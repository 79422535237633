import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import { useRecoilValue } from "recoil";

import useShowModal from "../../../hooks/useShowModal";
import { Button } from "../../../library";
import { contractSearchParamsState } from "../../../recoil/search";
import { isAuthenticatedState } from "../../../recoil/user";
import { SharedLinkType, modals } from "../../../utils/enums";

export default function ShareSearchButton() {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const show = useShowModal(modals.SHARE_LINK);
  const searchParams = useRecoilValue(contractSearchParamsState);

  const onClick = () => {
    const urlParams = new URLSearchParams();
    Object.entries(searchParams)
      .filter(([, value]) => !!value)
      .forEach(([key, value]) => urlParams.append(key, value));
    show({
      shareType: SharedLinkType.SEARCH,
      sharedUrl: `/contract-search?${urlParams.toString()}`,
    });
  };
  if (!isAuthenticated) return null;

  return (
    <Button
      theme={Button.themes.SECONDARY_DARK}
      size={Button.sizes.SMALL}
      onClick={onClick}
      className="analytics-share-search h-fit whitespace-nowrap"
    >
      <LinkRoundedIcon className="h-5 w-5 mr-2" />
      Share this search
    </Button>
  );
}
