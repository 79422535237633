import unusableContractImage from "../../../img/icons/contracts-unusable.svg";
import { Banner, Link, Typography } from "../../library";
import { UNSUPPORTED_TAG_TYPES } from "../../shared/ContractBase/getTags";
import {
  type ContractTag,
  ContractTagTypes,
} from "../../shared/ContractBase/types";
import { getSupplierUrlPath } from "../../utils/format";

interface UnusableContractBannerProps {
  contractTags: ContractTag[];
  supplierHandle: string;
}

export default function UnusableContractBanner({
  contractTags,
  supplierHandle,
}: UnusableContractBannerProps) {
  if (
    !contractTags.find((tag) => UNSUPPORTED_TAG_TYPES.includes(tag.variant))
  ) {
    return null;
  }

  return (
    <Banner
      className="flex items-center"
      color="accent.default.quaternary.enabled"
    >
      <div className="flex flex-col gap-x-2 flex-1">
        <Typography color="neutral.bolder.enabled">
          {contractTags.find(
            (tag) => tag.variant === ContractTagTypes.UNSUPPORTED_BY_SUPPLIER
          ) ? (
            <>
              The supplier does not support this contract.{" "}
              <Link href={getSupplierUrlPath(supplierHandle).toString()}>
                See other usable contracts
              </Link>{" "}
              from this supplier.
            </>
          ) : (
            "The lead agency has cancelled or removed this contract."
          )}
        </Typography>
      </div>
      <div>
        <img src={unusableContractImage} className="h-20" />
      </div>
    </Banner>
  );
}
