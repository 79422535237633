import {
  ArrowForward,
  AutoAwesomeOutlined,
  CheckCircle,
  CircleOutlined,
} from "@mui/icons-material";
import type { ReactNode } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import useSupplierEditUrl from "../../../hooks/useSupplierEditUrl";
import { Badge, Button, ButtonSizes, Link, Typography } from "../../../library";
import AccordionGroup, {
  type AccordionItem,
} from "../../../library/AccordionGroup";
import {
  type ISupplierState,
  supplierState,
  userState,
} from "../../../recoil/user";
import Tag, { TagVariants } from "../../../shared/Tag";
import { handleError, patchSupplierProfile } from "../../../utils/api";
import { MANAGE_CONTRACTS_FILTER_OPTIONS } from "../../../utils/constants";
import type { SupplierValues } from "../../supplier/SupplierEditForm/types";

interface Task {
  title: string;
  subtitle: string;
  isDone: boolean;
  actions: ReactNode[];
}

interface TaskCard {
  title: string;
  key: string;
  tasks: Task[];
}

function getProSupplierTaskCards(
  supplier: ISupplierState,
  saveSupplierData: (data: Partial<SupplierValues>, handle: string) => void,
  supplierEditUrl: string
): TaskCard[] {
  return [
    {
      title: "Get in front of more high-intent buyers",
      key: "contract-review",
      tasks: [
        {
          title: "Add missing contracts",
          subtitle:
            "Make sure that your cooperative and piggybackable contracts are added to make them discoverable by buyers.",
          isDone: supplier?.confirmedAllContracts,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              className="analytics-supplier-welcome-page-confirm-all-contracts-cta"
              key="action-contract-confirmation"
              onClick={(e) => {
                e?.stopPropagation();
                saveSupplierData(
                  { confirmedAllContracts: true },
                  supplier?.handle || ""
                );
              }}
            >
              All contracts are on Pavilion
            </Button>,
            <Button
              theme={Button.themes.SECONDARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              size={ButtonSizes.SMALL}
              className="!text-cp-midnight-300 analytics-supplier-welcome-page-review-contracts-cta"
              key="action-review-contracts"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#contracts`,
                underline: false,
                newWindow: false,
              }}
            >
              Review contracts
            </Button>,
          ],
        },
        {
          title: "Review expired contracts",
          subtitle:
            "Expired contracts don't automatically show up in search. Add renewal documentation to get these contracts in front of buyers.",
          isDone: !supplier?.contractsNeedExpirationReview,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              size={ButtonSizes.SMALL}
              key="action-review-expired-contracts"
              className="analytics-supplier-welcome-page-review-expired-contracts-cta"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}?filterBy=${MANAGE_CONTRACTS_FILTER_OPTIONS.EXPIRED}#contracts`,
                underline: false,
                newWindow: false,
              }}
            >
              Review expired contracts
            </Button>,
          ],
        },
      ],
    },
    {
      title: "Show you're the right choice",
      key: "contract-document-review",
      tasks: [
        {
          title: "Upload contract documents",
          subtitle:
            "Give buyers key documents (signed contract documents, RFP / bid information, pricing, etc) to assess if your contract fits their needs.",
          isDone: !supplier?.contractsNeedDocReview,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              key="action-review-contracts-without-docs"
              className="analytics-supplier-welcome-page-upload-documents-cta"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}?filterBy=${MANAGE_CONTRACTS_FILTER_OPTIONS.MISSING_DOCS}#contracts`,
                underline: false,
                newWindow: false,
              }}
            >
              View contracts without documents
            </Button>,
          ],
        },
      ],
    },
    {
      title: "Sell to the right entities",
      key: "service-area-review",
      tasks: [
        {
          title: "Confirm business service area",
          subtitle:
            "Increase your visibility among buyers in the locations you service.",
          isDone: !!(
            supplier?.manualServiceAreaNational ||
            (supplier?.manualServiceAreaState &&
              supplier.manualServiceAreaState?.length > 0)
          ),
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              key="action-nationwide-service-confirmation"
              className="analytics-supplier-welcome-page-confirm-nationwide-coverage-cta"
              onClick={(e) => {
                e?.stopPropagation();
                saveSupplierData(
                  {
                    serviceArea: {
                      manualServiceAreaNational: true,
                      manualServiceAreaState: [],
                    },
                  },
                  supplier?.handle || ""
                );
              }}
            >
              Confirm nationwide coverage
            </Button>,
            <Button
              theme={Button.themes.SECONDARY_LIGHT}
              badgeProps={{ Icon: ArrowForward, reverse: true }}
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#service-area`,
                underline: false,
                newWindow: false,
              }}
              size={ButtonSizes.SMALL}
              className="!text-cp-midnight-300 analytics-supplier-welcome-page-review-service-coverage-cta"
              key="action-review-service-area"
            >
              Review service area
            </Button>,
          ],
        },
        {
          title: "Add entities to Exclusion List",
          subtitle:
            "Avoid charges for entities on this list, but you also won't get boosted exposure when buyers from those entities search.",
          isDone: supplier?.confirmedNoExclusions || supplier?.hasExclusions,
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              key="action-no-exclusions-confirmation"
              className="analytics-supplier-welcome-page-no-exclusions-confirmation-cta"
              onClick={(e) => {
                e?.stopPropagation();
                saveSupplierData(
                  {
                    confirmedNoExclusions: true,
                  },
                  supplier?.handle || ""
                );
              }}
            >
              No entities to add
            </Button>,
            <Button
              theme={Button.themes.SECONDARY_LIGHT}
              badgeProps={{
                Icon: ArrowForward,
                reverse: true,
              }}
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: "/profile#billing-preferences",
                underline: false,
                newWindow: false,
              }}
              size={ButtonSizes.SMALL}
              className="!text-cp-midnight-300 analytics-supplier-welcome-page-add-exclusions-cta"
              key="action-add-exclusions"
            >
              Review Exclusion List
            </Button>,
          ],
        },
      ].filter((v) => !!v) as Task[],
    },
    {
      title: "Make it easy for buyers to reach you",
      key: "contacts-review",
      tasks: [
        {
          title: "Add sales contacts",
          subtitle:
            "Adding the right contact information allows buyers to email or call the right point of contact, and gives you exclusive visual tags to stand out in searches",
          isDone: !!supplier?.manualContacts.find(
            (contact) => contact.phoneNumber?.isVerified
          ),
          actions: [
            <Button
              theme={Button.themes.PRIMARY_LIGHT}
              size={ButtonSizes.SMALL}
              className="analytics-supplier-welcome-page-add-sales-representatives-cta"
              key="action-add-sales-reps"
              as={Link}
              onClick={(e) => {
                e?.stopPropagation();
              }}
              linkProps={{
                href: `${supplierEditUrl}#contacts`,
                underline: false,
                newWindow: false,
              }}
            >
              Add sales representatives
            </Button>,
          ],
        },
      ],
    },
  ];
}

function sortProSupplierTaskCards(taskCards: TaskCard[]) {
  return taskCards.sort((cardData1, cardData2) => {
    const numTasksLeft1 = cardData1.tasks.filter((task) => !task.isDone).length;

    const numTasksLeft2 = cardData2.tasks.filter((task) => !task.isDone).length;

    return numTasksLeft2 - numTasksLeft1;
  });
}

const mapTaskCardToAccordionItem = (taskCard: TaskCard) => {
  {
    const numTasks = taskCard.tasks.length;
    const numTasksCompleted = taskCard.tasks.filter(
      (task) => task.isDone
    ).length;
    const allTasksCompleted = numTasksCompleted === numTasks;

    return {
      key: taskCard.key,
      header: (
        <div className="flex flex-row gap-4">
          <Typography
            variant="headline"
            color={
              allTasksCompleted
                ? "feedback.bold.success"
                : "brand.boldest.enabled"
            }
          >
            {taskCard.title}
          </Typography>
          {allTasksCompleted && (
            <Tag
              variant={TagVariants.VERIFIED}
              className="h-fit"
              Icon={AutoAwesomeOutlined}
            >
              Complete
            </Tag>
          )}
        </div>
      ),
      subheader: allTasksCompleted ? null : (
        <Typography
          variant="meta"
          color={
            numTasksCompleted ? "feedback.bold.success" : "neutral.bold.enabled"
          }
        >
          {numTasksCompleted} of {numTasks} completed
        </Typography>
      ),
      children: (
        <div className="flex flex-col gap-y-6">
          {taskCard.tasks.map((task) => (
            <div className="flex flex-row gap-x-3" key={task.title}>
              <Badge
                Icon={task.isDone ? CheckCircle : CircleOutlined}
                className={task.isDone ? "text-cp-leaf-400" : ""}
                inline
              />
              <div>
                <Typography
                  variant="headline"
                  size="xs"
                  emphasis
                  color={
                    task.isDone
                      ? "feedback.bold.success"
                      : "neutral.boldest.enabled"
                  }
                >
                  {task.title}
                </Typography>
                {!task.isDone && (
                  <>
                    <Typography color="neutral.bolder.enabled">
                      {task.subtitle}
                    </Typography>
                    <div className="mt-3 flex flex-row gap-3">
                      {task.actions}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ),
    };
  }
};

export default function ProSupplierWelcomeTasks({
  className,
}: { className?: string }) {
  const { supplier } = useRecoilValue(userState);
  const [supplierRecoilState, setSupplierRecoilState] =
    useRecoilState(supplierState);
  const supplierEditUrl = useSupplierEditUrl();

  const saveSupplierData = async (
    values: Partial<SupplierValues>,
    handle: string
  ) => {
    const response = await patchSupplierProfile(handle, values);
    if (handleError(response)) {
      return;
    }
    const data = await response.json();
    setSupplierRecoilState({
      ...supplierRecoilState,
      manualServiceAreaNational: data?.manualServiceAreaNational,
      confirmedAllContracts: data?.confirmedAllContracts,
      confirmedNoExclusions: data?.confirmedNoExclusions,
    });

    return;
  };
  const taskCards = supplier
    ? getProSupplierTaskCards(supplier, saveSupplierData, supplierEditUrl || "")
    : [];
  const sortedTaskCards = sortProSupplierTaskCards(taskCards);
  const accordionTasks: AccordionItem[] = sortedTaskCards.map(
    mapTaskCardToAccordionItem
  );

  return (
    <AccordionGroup
      cards={accordionTasks}
      initialKey="contract-review"
      className={className}
    />
  );
}
