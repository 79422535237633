import useIsDebug from "../../../hooks/useIsDebug";
import useRequestID from "../../../hooks/useRequestID";
import { expirationText, getTags } from "../../../shared/ContractBase";
import type {
  Contract,
  ContractWithTags,
} from "../../../shared/ContractBase/types";
import { SingleDocumentIcon } from "../../../shared/CustomIcons/SingleDocumentIcon";
import ViewMoreButton from "../../../shared/ViewMoreButton";
import { getParam, goToURL } from "../../../utils";
import { isDateInPast } from "../../../utils/date";
import {
  LoginWallTriggers,
  pageNavigationSourceTypes,
} from "../../../utils/enums";
import { trackViewContractFromSupplierProfile } from "../../../utils/tracking";
import ContractCard from "../../ContractSearch/ContractCard";

interface SupplierContractsProps {
  id: number;
  handle: string;
  contracts: ContractWithTags[];
  fetchMore: () => void;
  hasNext: boolean;
  isLoading: boolean;
}

export default function SupplierContractList({
  id,
  handle,
  contracts,
  fetchMore,
  hasNext,
  isLoading,
}: SupplierContractsProps) {
  const requestID = useRequestID();

  function contractOnClick(
    { url, docid, numDocs }: Contract,
    rank: number,
    query: Maybe<string>
  ) {
    goToURL(url, {
      pageNavigationSource: pageNavigationSourceTypes.SUPPLIER_PAGE,
      query,
      requestID,
    });
    trackViewContractFromSupplierProfile({
      rank,
      contractId: docid,
      supplierId: id,
      supplierHandle: handle,
      numDocs: numDocs || 0,
      requestID,
    });
  }

  const isDebug = useIsDebug();

  return (
    <div className="flex flex-col gap-6 w-full">
      {contracts.map((contract, ix) => {
        const {
          docid,
          title,
          buyerLeadAgency,
          expirationDate,
          expirationTimestamp,
          contractNumber,
          cooperativeLanguage,
          cooperativeAffiliation,
        } = contract.contract;
        const { contractTagElements: contractTags } = getTags({
          isCooperative: cooperativeLanguage,
          expiration_ts: expirationTimestamp,
          expiration_date: expirationDate,
          isDebug,
          contractTagData: contract.contractTags,
        });

        const isExpired = isDateInPast(expirationDate);
        const query = getParam("query");

        return (
          <div key={docid} className="flex flex-col gap-x-6 gap-y-2">
            <ContractCard
              className="w-full"
              onClick={() => contractOnClick(contract.contract, ix + 1, query)}
              title={title}
              contractTags={contractTags}
              contractNumber={contractNumber || "No contract #"}
              expiration={expirationText(expirationDate, {
                short: true,
              })}
              isExpired={isExpired}
              IconComponent={SingleDocumentIcon}
              loginWallTrigger={LoginWallTriggers.SUPPLIER_PAGE_CONTRACT_CLICK}
              loginWallTriggerId={docid}
              buyerLeadAgency={buyerLeadAgency}
              cooperative={cooperativeAffiliation}
            />
          </div>
        );
      })}
      {hasNext && (
        <ViewMoreButton
          onClick={fetchMore}
          isLoading={isLoading}
          className="mx-auto"
        />
      )}
    </div>
  );
}
