import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

function NIGP() {
  const { zip, entity } = getParams();
  function getCustomizedUrl(url) {
    url.searchParams.set("utm_campaign", "biz-council");
    if (entity) {
      url.searchParams.set("governmentAffiliation", entity);
    }
    url.searchParams.set("utm_source", "NIGP");
    if (zip) {
      url.searchParams.set("zip", zip);
    }

    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title={
          <span>
            Find shareable, cooperative government contracts for anything.
            <br />
            All in one place.{" "}
            <span className="text-cp-lapis-500 underline">For free.</span>
          </span>
        }
      />
      <BaseWidgetSearchBar
        searchSource={agencySearchSource[agencies.NIGP]}
        getCustomizedUrl={getCustomizedUrl}
      />
      <WidgetFooterWrapper />
    </div>
  );
}

const container = getDOMAnchorById("iframe-nigp");
if (container) {
  const root = createRoot(container);
  root.render(
    <RecoilRoot>
      <NIGP />
    </RecoilRoot>
  );
}
