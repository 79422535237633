import { useRecoilValue, useSetRecoilState } from "recoil";

import { modalState } from "../../recoil/page";
import { userDetailsState, userTypeSignupState } from "../../recoil/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { getParams } from "../../utils";
import {
  SignupOrigin,
  accountModals,
  modalTriggerURLParams,
} from "../../utils/enums";
import { trackAccountAccessToggle } from "../../utils/tracking";

import type { ExistingUser } from "../../generated";
import InitialSignupForm from "./InitialSignupForm";
import { userTypeTabData } from "./constants";
import { WindowType } from "./types";

interface InitialSignupModalProps {
  hideModal: () => void;
  onComplete: () => void;
  showGoBackToSearch?: boolean;
  isBlocking?: boolean;
  title?: string;
  subtitle?: string;
  supplierName?: string;
  handle?: string;
}

export default function InitialSignupModal({
  hideModal,
  onComplete,
  isBlocking = false,
  title,
  subtitle,
  supplierName,
  handle,
}: InitialSignupModalProps) {
  // set initial user type via url params
  const urlParams = getParams();
  const userType = useRecoilValue(userTypeSignupState);
  const { email } = useRecoilValue(userDetailsState);
  const setCurrentModal = useSetRecoilState(modalState);

  const invite = urlParams[modalTriggerURLParams.INVITE];
  if (!title) title = userTypeTabData[userType].title;
  if (!subtitle) subtitle = userTypeTabData[userType].subtitle;
  const isSupplier = userType === userTypeTabData.supplier.type;

  function goToInitialSignupModal() {
    setCurrentModal({
      name: accountModals.INITIAL_SIGN_UP,
      onComplete,
      isBlocking,
      title,
      subtitle,
    });
  }

  function goToLoginModal() {
    setCurrentModal({
      name: accountModals.SIGNUP_STEPS_LOGIN,
      onComplete,
      isBlocking,
      title,
      subtitle,
    });
    trackAccountAccessToggle({
      toggle: "log in",
      loginExperience: WindowType.Modal,
    });
  }

  const checkUserAndSetNextModal = async (user: ExistingUser) => {
    const goBack = () => goToInitialSignupModal();
    if (user.existingUser) {
      setCurrentModal({
        name: accountModals.PUSH_TO_LOGIN,
        onComplete,
        isBlocking,
        goBack,
        socialLoginSource: user.socialAccountProvider || email,
      });
    } else if (isSupplier) {
      setCurrentModal({
        name: accountModals.SUPPLIER_SIGN_IN,
        hideModal,
        onComplete,
        isBlocking,
        goBack,
        supplierName,
        handle,
      });
    } else {
      // TODO @KwasiAdu 9/30/2023: Assuming sign in pages become permanent,
      //  deprecate the usage of url params to get inferredBLA in account modals.
      //  shortcut: https://app.shortcut.com/coprocure/story/15800/deprecate-invite-and-inferred-bla-params-in-initial-sign-up-modal-in-09-2023
      // Prefill BLA information if it's in the URL params
      // Note: data.inferredBLA will have precedence over the fields from urlParams
      const inferredBLA = user.inferredBla;
      if (invite && urlParams.blaId && urlParams.blaName) {
        inferredBLA.id = inferredBLA.id || (urlParams.blaId as string);
        inferredBLA.displayName =
          inferredBLA.displayName || (urlParams.blaName as string);
      }

      setCurrentModal({
        name: accountModals.SIGNUP_STEPS_SIGNUP,
        onComplete,
        isBlocking,
        goBack,
        inferredBLA,
      });
    }
  };

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      title={title}
      subtitle={subtitle}
      className="analytics-email-signup-modal inline-flex items-center"
      contentClassName="no-scrollbar"
      modalSize={modalSizes.SMALL}
      showLogoHeader
    >
      <InitialSignupForm
        onComplete={onComplete}
        checkUserAndSetNextModalOrPage={checkUserAndSetNextModal}
        goToLoginPageOrModal={goToLoginModal}
        parentWindow={WindowType.Modal}
        origin={SignupOrigin.LOGIN_WALL}
      />
    </Modal>
  );
}
