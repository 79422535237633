import clsx from "clsx";
import type { NIGPCategory } from "../../generated";
import { BoldedText } from "../../library";
import { SearchBarThemes, colorsByTheme } from "./types";

interface SearchAutocompleteCategoryOptionProps {
  category: NIGPCategory;
  index: number;
  analyticsClass: string;
  totalOptions: string[];
  activeIndex: number;
  handleSubmit: (result: string, index: number, searchType: string) => void;
  searchQuery: string;
  analyticsType: string;
  nestingLevel?: number;
  theme?: SearchBarThemes;
}

export default function SearchAutocompleteCategoryOption({
  category,
  index,
  analyticsClass,
  totalOptions,
  activeIndex,
  handleSubmit,
  searchQuery,
  analyticsType,
  nestingLevel = 0,
  theme = SearchBarThemes.LIGHT,
}: SearchAutocompleteCategoryOptionProps) {
  return (
    <div
      key={index}
      className={clsx(
        `px-${6 * (nestingLevel + 1)} pr-6 py-1.5 hover:bg-cp-violet-100 cursor-pointer`,
        analyticsClass,
        {
          "bg-cp-violet-100":
            totalOptions[activeIndex] === category.description,
        }
      )}
      onClick={() => {
        handleSubmit(category.description, index, analyticsType);
      }}
    >
      <BoldedText
        size="sm"
        text={category.description}
        highlight={searchQuery}
        highlightColor={colorsByTheme[theme].autocompleteHighlightColor}
      />
    </div>
  );
}
