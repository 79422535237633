import clsx from "clsx";
import { Suspense, lazy } from "react";
import { useRecoilValue } from "recoil";

import { Button, ButtonSizes, ButtonThemes, Link } from "../../../library";
import { profileTypeState } from "../../../recoil/user";
import { ProfileType } from "../../../utils/enums";
import SectionHeader from "../SectionHeader";

const NoSearchResults = lazy(() => import("../../NoSearchResults"));

interface NoSearchResultsBannerProps {
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
  viewSource: () => void;
  sourceUrl?: string;
  onRequestDocuments: () => void;
  isLoading: boolean;
  numDocuments: number;
  onClickMessageContractAdmin: () => void;
}
export default function NoContractFilesBanner({
  cooperativeAffiliation,
  buyerLeadAgency,
  viewSource,
  sourceUrl,
  onRequestDocuments,
  isLoading,
  numDocuments,
  onClickMessageContractAdmin,
}: NoSearchResultsBannerProps) {
  const profileType = useRecoilValue(profileTypeState);
  const isSupplier = profileType === ProfileType.SUPPLIER;
  const ctas = [];
  if (!isSupplier) {
    ctas.push({
      analyticsClassName: "analytics-contact-buyer-lead-agency",
      element: (
        <Button
          size={Button.sizes.SMALL}
          theme={Button.themes.SECONDARY_DARK}
          dataTestId="message-contract-admin"
          onClick={onClickMessageContractAdmin}
        >
          Email contract admin
        </Button>
      ),
    });
  }

  return (
    <div className="flex flex-col gap-6">
      <SectionHeader
        title={`Contract Documents (${numDocuments})`}
        ctas={ctas}
      />
      <div
        className="flex flex-col md:flex-row justify-between items-center gap-6
          border border-solid border-cp-neutral-20 rounded-lg px-12 py-6"
      >
        <div className="flex flex-col gap-6">
          <GetCtas
            viewSource={viewSource}
            sourceUrl={sourceUrl}
            cooperativeAffiliation={cooperativeAffiliation}
            buyerLeadAgency={buyerLeadAgency}
            onRequestDocuments={onRequestDocuments}
            isLoading={isLoading}
            isSupplier={isSupplier}
          />
        </div>
        <Suspense fallback={<div className="h-[152px]" />}>
          <NoSearchResults className="hidden md:flex shrink-0 items-center justify-center h-[152px] w-[200px]" />
        </Suspense>
      </div>
    </div>
  );
}

interface GetCtasProps {
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
  viewSource: () => void;
  sourceUrl?: string;
  onRequestDocuments: () => void;
  isLoading: boolean;
  isSupplier: boolean;
}
function GetCtas({
  cooperativeAffiliation,
  buyerLeadAgency,
  viewSource,
  sourceUrl,
  onRequestDocuments,
  isLoading,
  isSupplier,
}: GetCtasProps) {
  const requestDocsButton = (
    <Button
      key="request-documents"
      onClick={onRequestDocuments}
      className={clsx("analytics-open-request-missing-docs", {
        "pointer-events-none": isLoading,
      })}
      disabled={isLoading}
      size={ButtonSizes.SMALL}
    >
      Request documents
    </Button>
  );

  const viewSourceButton = (
    <Button
      key="view-source"
      className="analytics-view-source contract-source"
      onClick={viewSource}
      size={ButtonSizes.SMALL}
      theme={ButtonThemes.SECONDARY_DARK}
    >
      View Contract Source
    </Button>
  );

  return (
    <>
      <span>
        Documents for this contract may only be available for{" "}
        {sourceUrl ? (
          <Link emphasis={false} underline={false} onClick={viewSource}>
            {cooperativeAffiliation || buyerLeadAgency}
          </Link>
        ) : (
          cooperativeAffiliation || buyerLeadAgency
        )}{" "}
        members.
        {!isSupplier && " Contact us to request documents from this source."}
      </span>
      {!isSupplier && (
        <div className="flex gap-3">
          {requestDocsButton} {sourceUrl && viewSourceButton}
        </div>
      )}
    </>
  );
}
