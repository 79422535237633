import type { FormikValues } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { Typography } from "../library";
import { ChipInputField, FormWrapper } from "../library/form";
import Modal from "../shared/Modal/Modal";
import { getRequestID } from "../utils";
import { createSharedLink, getErrorMessage, handleError } from "../utils/api";
import {
  trackShareContractCopyLink,
  trackShareContractSubmit,
} from "../utils/tracking";

import { SharedLinkType } from "../utils/enums";
import CopyLinkInput from "./ProjectModals/CopyLinkInput";
import { useSubmitAndSave } from "./constants";

const FIELDS = [
  {
    name: "emails",
    label: "Email addresses",
    component: ChipInputField,
    message: "Enter email addresses separated by commas",
    validate: yup
      .array()
      .min(
        1,
        "Enter at least one email address to share this contract with your colleagues."
      ),
  },
];
interface ShareContractModalProps {
  hideModal: () => void;
  contractUrl: string;
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  contractNumber: string;
  contractTitle: string;
  showContractSharedPopup: () => void;
}

export default function ShareContractModal({
  hideModal,
  contractUrl,
  contractId,
  supplierId,
  supplierHandle,
  contractNumber,
  contractTitle,
  showContractSharedPopup,
}: ShareContractModalProps) {
  const [errorMessage, setErrorMessage] = useState("");

  const [handleSubmit, loading] = useSubmitAndSave(
    hideModal,
    async (values: FormikValues) => {
      const response = await createSharedLink({
        emails: values.emails,
        message: "",
        shareType: SharedLinkType.CONTRACT,
        sharedUrl: `/contracts/${contractId}`,
        payload: { contractTitle },
      });
      if (
        handleError(response, {
          logToSentry: true,
          log400ErrorsToSentry: false,
        })
      ) {
        if (response.status === 400) {
          getErrorMessage(response).then((message) => setErrorMessage(message));
        }
        return;
      }

      trackShareContractSubmit({
        requestID: getRequestID(),
        contractId,
        supplierId,
        supplierHandle,
        contractNumber,
        ccEmails: values.emails,
        numCcEmails: values.emails.length,
      });

      showContractSharedPopup();
    }
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Share this contract with a colleague"
      subtitle="Email this contract to a colleague or copy the link to share."
      contentClassName="flex flex-col gap-8"
    >
      <FormWrapper
        fields={FIELDS}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={{ emails: [] }}
        submitClassName="analytics-share-contract w-fit h-fit"
        submitCta="Send"
        disabled={loading}
        inline
      />
      {contractUrl && (
        <CopyLinkInput
          link={contractUrl}
          trackCopyLink={() =>
            trackShareContractCopyLink({
              contractId,
              supplierId,
              supplierHandle,
              contractNumber,
            })
          }
        />
      )}
      {errorMessage && (
        <Typography variant="meta" color="destructive.default.primary.enabled">
          {errorMessage}
        </Typography>
      )}
    </Modal>
  );
}
