import UploadDocumentsForm from "../../components/UploadContractsPage/UploadDocumentsForm";
import { FILE_UPLOADS_FIELDS } from "../../components/UploadContractsPage/constants";
import { Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { CONTRACT_FILE_TYPE } from "../../utils/constants";

export interface UploadContractDetails {
  docid: string;
  title: string;
  contractNumber: string;
  buyerLeadAgency?: string;
  cooperativeAffiliation?: string;
  expirationDate?: string;
}

interface UploadDocumentsModalProps {
  supplierId: number;
  title: string;
  subtitle: string;
  contractDetails: UploadContractDetails;
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  documentType?: string;
}

export default function UploadDocumentsModal({
  supplierId,
  contractDetails,
  hideModal,
  onComplete,
  documentType,
  isBlocking = false,
}: UploadDocumentsModalProps) {
  return (
    <Modal
      hideModal={hideModal}
      className="analytics-upload-documents-modal"
      contentClassName="no-scrollbar"
      formID="analytics-upload-documents-modal-submit"
      title={
        documentType === CONTRACT_FILE_TYPE.RENEWAL
          ? "Upload renewal document"
          : "Upload additional contract documents"
      }
      modalSize={modalSizes.SMALL}
      isBlocking={isBlocking}
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Typography color="neutral.bolder.enabled">
            {documentType === CONTRACT_FILE_TYPE.RENEWAL
              ? "Upload all renewal documents associated with the following contract:"
              : `Upload all additional documents [signed contract document
            (required), RFP, pricing documents and amendments] associated with
            the following contract:`}
          </Typography>

          <div className="flex flex-col gap-1 border-l border-solid border-cp-lapis-100 pl-2 ml-2">
            {contractDetails.title && (
              <Typography
                variant="headline"
                size="xs"
                color="neutral.boldest.enabled"
                emphasis
              >
                {contractDetails.title}
              </Typography>
            )}
            {contractDetails.contractNumber && (
              <Typography color="neutral.bolder.enabled" size="sm">
                Contract #{contractDetails.contractNumber}
              </Typography>
            )}
            {contractDetails.buyerLeadAgency && (
              <Typography color="neutral.bolder.enabled" size="sm">
                Lead agency: {contractDetails.buyerLeadAgency}
              </Typography>
            )}
            {contractDetails.cooperativeAffiliation && (
              <Typography color="neutral.bolder.enabled" size="sm">
                Cooperative: {contractDetails.cooperativeAffiliation}
              </Typography>
            )}
            {contractDetails.expirationDate && (
              <Typography color="neutral.bolder.enabled" size="sm">
                Expiration date: {contractDetails.expirationDate}
              </Typography>
            )}
          </div>
        </div>
        <UploadDocumentsForm
          fileUploadFields={FILE_UPLOADS_FIELDS}
          supplierId={supplierId}
          docid={contractDetails.docid}
          onComplete={onComplete}
          documentType={documentType}
        />
      </div>
    </Modal>
  );
}
