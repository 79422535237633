import { Fragment, useMemo } from "react";
import { useRecoilValue } from "recoil";
import type { ContractSupplierHit, RelevantContract } from "../../generated";
import { useContractMatchesFilters } from "../../hooks/search/useContractMatchesFilters";
import { useMatchingSource } from "../../hooks/search/useMatchingSource";
import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import useIsDebug from "../../hooks/useIsDebug";
import useLoginWall from "../../hooks/useLoginWall";
import { useScopeMatchElements } from "../../hooks/useScopeMatchElements";
import useTrackContractClick from "../../hooks/useTrackContractClick";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import useTrackSupplierClick from "../../hooks/useTrackSupplierClick";
import {
  Avatar,
  AvatarSizes,
  Button,
  ButtonSizes,
  ButtonThemes,
  ClampedText,
  GenericCard,
  Link,
  LogoSizes,
  Tooltip,
  Typography,
} from "../../library";
import {
  contractSourceFilterState,
  expirationFilterState,
  requestIDState,
  useAddRecentSuccessfulSearch,
} from "../../recoil/search";
import { buyerProfileState, userZipState } from "../../recoil/user";
import { getContractUrl, getTags } from "../../shared/ContractBase";
import ContractCTASection from "../../shared/ContractBase/ContractCTASection";
import Tag, { TagVariants } from "../../shared/Tag";
import { goToURL } from "../../utils";
import { textColorClass } from "../../utils/colors";
import { parseDate } from "../../utils/date";
import {
  LoginWallTriggers,
  type pageNavigationSourceTypes,
  resultTypes,
} from "../../utils/enums";
import { getMessageSupplierUrl, getSupplierUrlPath } from "../../utils/format";
import { isFeatureEnabled } from "../../utils/split";
import {
  trackContactSupplierOnSupplierSERP,
  trackSerpClick,
} from "../../utils/tracking";
import { MessageSupplierSource } from "../MessageSupplierPage/types";
import ProductSection from "./ProductSection";
import { matchTiers, matchesForKey, styleSearchResponseText } from "./utils";

type TrackingFnData = Pick<
  RelevantContract,
  | "docid"
  | "RankingInfo"
  | "matchTier"
  | "buyerLeadAgencyId"
  | "buyerLeadAgency"
  | "cooperativeAffiliation"
> & { tagCopyList: string[]; tagVariantList: string[] };

function ContractResult({
  result,
  query,
  pageNavigationSource,
  trackContractClick,
  trackSerpClick,
  primary,
}: {
  result: RelevantContract;
  query: string;
  pageNavigationSource: Maybe<keyof typeof pageNavigationSourceTypes>;
  trackContractClick: (data: TrackingFnData) => void;
  trackSerpClick: (data: TrackingFnData) => void;
  primary: boolean;
}) {
  const buyerProfile = useRecoilValue(buyerProfileState);
  const userZip = useRecoilValue(userZipState);
  const isDebug = useIsDebug();
  const expirationDate = parseDate(result.expirationTimestamp);
  const scopeMatchText =
    useScopeMatchElements(result, 3, "neutral.bold.enabled") || [];
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const expirationFilter = useRecoilValue(expirationFilterState);
  const sourcesFilter = useRecoilValue(contractSourceFilterState);
  const requireLogin = useLoginWall();
  const contractMatchesFilters = useContractMatchesFilters();
  const matchingSource = useMatchingSource();
  const requestID = useRecoilValue(requestIDState);
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");

  const { contractTagElements, tagVariantList, tagCopyList } = getTags({
    contractTagData: result.contractTags,
    expiration_ts: result.expirationTimestamp,
    expiration_date: expirationDate,
    isCooperative: result.cooperativeLanguage ?? false,
    matchTier: result.matchTier,
    blaState: result.buyerLeadAgencyState,
    blaType: result.buyerLeadAgencyType,
    blaRank: result.buyerLeadAgencyRank,
    filterScore: result.RankingInfo.filters,
    semanticScore: result.RankingInfo.semanticScore,
    proBoost: result.RankingInfo.proBoost,
    geoBoost: result.RankingInfo.geoBoost,
    scaledBoost: result.RankingInfo.scaledBoost,
    productBoost: result.RankingInfo.productBoost,
    contractQualityBoost: result.RankingInfo.contractQualityBoost,
    bm25Score: result.RankingInfo.bm25Score,
    isOwnBla:
      !!buyerProfile.governmentAgency &&
      result.buyerLeadAgencyId === buyerProfile.governmentAgency.id,
    isDebug,
    transparent: true,
    size: hasRedesignedSerpCards ? "md" : "sm",
  });

  const data: TrackingFnData = {
    ...result,
    tagVariantList,
    tagCopyList,
  };

  function handleClick() {
    const offeringMatches = matchesForKey(
      result.HighlightResult,
      "contractOfferings"
    );
    const autoExtractedOfferingsMatches = matchesForKey(
      result.HighlightResult,
      "autoExtractedOfferingsList"
    );

    addRecentSuccessfulSearch(query);
    trackContractClick(data);
    goToURL(
      getContractUrl(
        result.solicitationId,
        result.docid,
        query,
        userZip,
        autoExtractedOfferingsMatches.length > 0,
        offeringMatches.length > 0,
        expirationFilter,
        sourcesFilter,
        pageNavigationSource as string,
        requestID
      )
    );
  }

  // Wrap our onClick behavior in the login wall if we have the relevant props/state.
  const onCardClick = () => {
    requireLogin({
      triggerId: result.docid,
      onComplete: handleClick,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK,
    });
  };

  if (primary && !contractMatchesFilters(result)) return null;

  const source = matchingSource(result);
  let title = (
    <Typography
      size="sm"
      color="neutral.boldest.enabled"
      emphasis
      className="line-clamp-1"
      title={result.title}
    >
      {result.title}
    </Typography>
  );

  if (isDebug && result.RankingInfo.semanticContext) {
    title = (
      <Tooltip className="max-w-88" info={result.RankingInfo.semanticContext}>
        {title}
      </Tooltip>
    );
  }

  return (
    <GenericCard
      loginWallTriggerId={result.docid}
      loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
      trackSerpClick={() => trackSerpClick(data)}
      onClick={onCardClick}
      className="!p-3"
    >
      <div className="flex flex-row">
        <div className="grid gap-3 grow">
          <div className="grid gap-1">
            {title}
            <Typography
              component="div"
              size="sm"
              color="neutral.boldest.enabled"
            >
              {source}
            </Typography>
          </div>
          {!!scopeMatchText.length && (
            <Typography
              variant="meta"
              color="neutral.bold.enabled"
              className="line-clamp-1"
            >
              Confirmed in contract:{" "}
              {scopeMatchText.map((e, ix) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: Index is fine here as these are static.
                <Fragment key={ix}>
                  {e}
                  {ix < scopeMatchText.length - 1 ? " · " : ""}
                </Fragment>
              ))}
            </Typography>
          )}
          <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
            {contractTagElements}
          </div>
        </div>
        <ContractCTASection
          loginWallTriggerId={result.docid}
          loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
          ctas={[
            {
              styling: isFeatureEnabled("messageSupplierSerpCard")
                ? "tertiary"
                : "secondary",
              text: "View contract",
              ctaAnalyticsClass: "analytics-serp-view-contract",
              trackSerpClick: () => trackSerpClick(data),
              onClick: handleClick,
            },
          ]}
        />
      </div>
    </GenericCard>
  );
}

export default function SearchCardSupplierResult({
  hit,
  diversityPreferences = [],
  query,
  pageNavigationSource,
  visibleRank,
  primary,
  requestID,
}: {
  hit: ContractSupplierHit;
  diversityPreferences?: Maybe<string>[];
  query: string;
  pageNavigationSource: Maybe<keyof typeof pageNavigationSourceTypes>;
  visibleRank: number;
  primary: boolean;
  requestID: Maybe<string>;
}) {
  const trackContractClick = useTrackContractClick();
  const trackSupplierClick = useTrackSupplierClick();
  const trackSerpContractClick = useTrackSerpClick();
  const userZip = useRecoilValue(userZipState);
  const requireLogin = useLoginWall();
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const supplierHasDiversityOverlap =
    hit.supplierDiversityCertificationIds?.some((diversityId) =>
      diversityPreferences.includes(diversityId)
    );
  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId: hit.supplierId,
    supplierName: hit.supplierDisplayName,
  });

  const { supplierTagElements, tagVariantList, tagCopyList } = getTags({
    supplierTagData: hit.supplierTags || [],
    matchesDiversity: supplierHasDiversityOverlap,
    transparent: true,
    size: hasRedesignedSerpCards ? "md" : "sm",
  });

  const getTrackingData = (data: TrackingFnData) => ({
    contractId: data.docid,
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    semanticScore: data.RankingInfo.semanticScore,
    absoluteDepth: hit.rank,
    relativeDepth: visibleRank,
    matchTier: data.matchTier,
    buyerLeadAgencyId: data.buyerLeadAgencyId,
    buyerLeadAgency: data.buyerLeadAgency,
    cooperativeAffiliation: data.cooperativeAffiliation,
    filterScore: data.RankingInfo.filters,
    groupingType: hit.groupingType,
    requestID,
    searchQuery: query,
    displayTag: data.tagVariantList,
    displayTagCopy: data.tagCopyList,
  });

  const onSupplierClick = () => {
    const data = {
      ...getTrackingData({
        ...hit.relevantSuppliers[0],
        tagVariantList,
        tagCopyList,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      matchTier: "",
      resultType: resultTypes.SUPPLIER_RESULT,
      hasSimilarEntities: false,
      numEntitiesInState: 0,
      numEntitiesInCategory: 0,
    };
    trackSerpClick(data);
    requireLogin({
      triggerId: hit.supplierHandle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_SUPPLIER_CLICK,
      onComplete: () => {
        trackSupplierClick(data);
        goToURL(
          getSupplierUrlPath(
            hit.supplierHandle,
            query,
            userZip,
            pageNavigationSource,
            requestID
          )
        );
      },
    });
  };

  const onContactSupplierClick = (handle: string) => {
    const data = {
      ...getTrackingData({
        ...hit.relevantSuppliers[0],
        tagVariantList,
        tagCopyList,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      resultType: resultTypes.SUPPLIER_RESULT,
    };
    trackSerpClick(data);
    requireLogin({
      triggerId: handle,
      triggerType:
        LoginWallTriggers.CONTRACT_SEARCH_MESSAGE_SUPPLIER_ON_SUPPLIER_CLICK,
      onComplete: () => {
        trackContactSupplierOnSupplierSERP(data);
        goToURL(
          getMessageSupplierUrl({
            handle,
            query,
            zip: userZip,
            requestID: requestID || "",
            messageSupplierSource: MessageSupplierSource.SUPPLIER_SERP,
          })
        );
      },
    });
  };

  const keywords = useMemo(() => {
    if (!hit.relevantSuppliers[0].HighlightResult?.supplierKeywords)
      return (hit.supplierKeywords || []).map((value) => ({ value }));
    // Sort full keyword matches to the start of the list.
    return [
      ...(hit.relevantSuppliers[0].HighlightResult?.supplierKeywords || []),
    ].sort((a, b) => {
      return matchTiers[b.matchLevel] - matchTiers[a.matchLevel];
    });
  }, [hit]);

  return (
    <div className="grid gap-5 max-w-[49.5rem]">
      <div className="grid gap-4">
        <div className="flex w-full justify-between">
          <div className="grid gap-3">
            <div className="flex flex-row gap-4">
              {hit.supplierLogoUrl && (
                <Avatar
                  size={AvatarSizes.LARGE}
                  logoImageSrc={hit.supplierLogoUrl}
                  logoSize={LogoSizes.XS}
                />
              )}
              <div className="grid gap-1">
                <div className="flex flex-wrap gap-x-2 items-center">
                  <Typography
                    variant="headline"
                    size="sm"
                    emphasis
                    color="brand.boldest.enabled"
                  >
                    {hit.supplierDisplayName}
                  </Typography>
                  {exactSupplierMatchAlias && (
                    <Typography size="sm" color="subtler">
                      Also known as {exactSupplierMatchAlias}
                    </Typography>
                  )}
                </div>
                {!hasRedesignedSerpCards && (
                  <div className="flex flex-row flex-wrap gap-y-4 gap-x-4 empty:hidden">
                    {supplierTagElements}
                  </div>
                )}
              </div>
            </div>
            {hasRedesignedSerpCards && (
              <>
                <div className="flex flex-wrap gap-2 max-h-[26px] overflow-hidden empty:hidden">
                  {keywords.slice(0, 5).map((k) => (
                    <Tag key={k.value} variant={TagVariants.SUBTLE} size="md">
                      <p>
                        {styleSearchResponseText(
                          k.value,
                          "em",
                          `${textColorClass.neutral.bolder.enabled} font-normal`,
                          `${textColorClass.neutral.bolder.enabled} font-semibold bg-cp-persimmon-100`
                        )}
                      </p>
                    </Tag>
                  ))}
                </div>
                <div className="flex flex-row flex-wrap gap-y-4 gap-x-4 empty:hidden">
                  {supplierTagElements}
                </div>
              </>
            )}
            {!hasRedesignedSerpCards && hit.supplierAbout && (
              <ClampedText
                size="sm"
                color="neutral.boldest.enabled"
                inline
                underline={false}
              >
                {hit.supplierAbout}
              </ClampedText>
            )}
          </div>
          <div className="flex flex-none gap-2">
            {hasRedesignedSerpCards && (
              <Button
                className="h-fit"
                theme={ButtonThemes.TERTIARY_DARK}
                size={ButtonSizes.SMALL}
                onClick={onSupplierClick}
              >
                View profile
              </Button>
            )}
            {isFeatureEnabled("messageSupplierSupplierCard") && (
              <Button
                className="h-fit analytics-serp-contact-supplier"
                theme={ButtonThemes.SECONDARY_DARK}
                size={ButtonSizes.SMALL}
                onClick={() => {
                  onContactSupplierClick(hit.supplierHandle);
                }}
              >
                Request quote
              </Button>
            )}
          </div>
        </div>
        {!hasRedesignedSerpCards && (
          <div className="flex flex-wrap gap-2 max-h-[23px] overflow-hidden empty:hidden">
            {keywords.slice(0, 5).map((k, ix) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: This list is static so index is ok.
              <Tag key={ix} variant={TagVariants.SUBTLE}>
                <p>
                  {styleSearchResponseText(
                    k.value,
                    "em",
                    `${textColorClass.neutral.bolder.enabled} font-normal`,
                    `${textColorClass.neutral.bolder.enabled} font-semibold bg-cp-persimmon-100`
                  )}
                </p>
              </Tag>
            ))}
          </div>
        )}
        <ProductSection products={hit.productHits || []} />
      </div>
      <div className="grid gap-3">
        {hit.relevantSuppliers.map((result) => (
          <ContractResult
            key={result.docid}
            result={result}
            query={query}
            trackSerpClick={(data) =>
              trackSerpContractClick(getTrackingData(data))
            }
            trackContractClick={(data) =>
              trackContractClick(getTrackingData(data))
            }
            pageNavigationSource={pageNavigationSource}
            primary={primary}
          />
        ))}
        <Link
          emphasis={false}
          color="neutral.bolder.enabled"
          size="sm"
          onClick={onSupplierClick}
          className={
            hasRedesignedSerpCards
              ? "analytics-serp-view-supplier-all-contracts text-end"
              : "analytics-serp-view-supplier"
          }
        >
          View all{" "}
          {hasRedesignedSerpCards
            ? `${hit.activeShareableContractCount} active`
            : hit.supplierDisplayName}{" "}
          contracts
        </Link>
      </div>
    </div>
  );
}
