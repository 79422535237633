import type { FormEventHandler } from "react";
import { useRecoilValue } from "recoil";

import {
  Button,
  LabeledInput,
  LabeledInputVariants,
  Typography,
} from "../../library";
import {
  userSocialAccountProviderState,
  userTypeSignupState,
} from "../../recoil/user";
import { getCSRFToken } from "../../utils";
import { LoginType } from "../../utils/enums";
import { trackForgotPasswordSend } from "../../utils/tracking";

import SocialAccountError from "./SocialAccountError";

interface PasswordResetEmailFormProps {
  emailError: string;
}
export default function PasswordResetEmailForm({
  emailError,
}: PasswordResetEmailFormProps) {
  const socialAccountProvider = useRecoilValue(userSocialAccountProviderState);
  const userType = useRecoilValue(userTypeSignupState);
  if (socialAccountProvider) {
    return <SocialAccountError />;
  }
  const trackEvent: FormEventHandler<HTMLFormElement> = (e) => {
    trackForgotPasswordSend({
      emailEntered: e.currentTarget.email.value,
      accountType: userType,
      loginType: (socialAccountProvider
        ? socialAccountProvider
        : LoginType.PAVILION) as LoginType,
    });
  };

  return (
    <div className="cp-page-container mt-20 mb-10">
      <form method="post" onSubmit={trackEvent}>
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={getCSRFToken()}
        />
        <Typography className="mb-6" variant="headline" size="lg">
          Forgot your password?
        </Typography>
        <Typography className="mb-10">
          That&apos;s ok, it happens! Enter your email address and we&apos;ll
          send you a link to reset your password.
        </Typography>
        <LabeledInput
          name="email"
          type="email"
          size="md"
          label="Email"
          initialVariant={
            emailError
              ? LabeledInputVariants.ERROR
              : LabeledInputVariants.DEFAULT
          }
          message={emailError}
          className="max-w-[45rem]"
        />
        <Button
          className="analytics-password-reset-email-cta mt-10"
          type="submit"
        >
          Send link to email
        </Button>
      </form>
    </div>
  );
}
