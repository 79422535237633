import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";
import { type KeyboardEvent, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import loaderGifIcon from "../../../img/loaders/loader-neutral-60.gif";
import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import useSearchContracts from "../../hooks/useSearchContracts";
import { Checkbox, DropdownPicker, Typography } from "../../library";
import { getParam } from "../../utils";
import {
  ContractDocumentsFilterOptions,
  ExpirationStatuses,
  SearchActions,
  SearchBarIntentVariants,
  SearchIntent,
  SearchSource,
  modals,
  researchModeDefaultFilters,
  searchIntentTabData,
} from "../../utils/enums";
import type { TrackAutocompleteOptions } from "../../utils/tracking";

import type { SearchOptions } from "../../components/ContractSearch/types";
import { ApiService, type VagueQuerySuggestion } from "../../generated";
import useResetAndGetFilters from "../../hooks/useResetAndGetFilters";
import useSearchIntentSurvey from "../../hooks/useSearchIntentSurvey";
import useShowModal from "../../hooks/useShowModal";
import { DropdownPickerVariants } from "../../library/Dropdown/DropdownPicker";
import ToggleTabs from "../../library/ToggleTabs";
import {
  contractDocumentsFilterState,
  contractSearchParamsState,
  elementIdToFocusState,
  entityContractsParamsState,
  expirationFilterState,
} from "../../recoil/search";
import { handleError } from "../../utils/generatedApi";
import { isFeatureEnabled } from "../../utils/split";
import SearchAutocomplete from "./SearchAutocomplete";
import { SearchBarThemes, colorsByTheme } from "./types";

export enum SearchBarCtaTypes {
  ICON = "ICON",
  TEXT = "TEXT",
}

export enum SearchBarSizes {
  FULL = "FULL",
  RESPONSIVE = "RESPONSIVE",
  COMPACT = "COMPACT",
}

const FOCUS_WITHIN_STYLES =
  "focus-within:outline focus-within:outline-1 focus-within:outline-offset-2 focus-within:outline-cp-lapis-500 focus-within:rounded-3";

// Before JS runs on the client, the search bar is not interactive.
// These styles are used by server-side rendering to make this non-interactive
// loading state apparent to users.
const loadingAnimationStyles =
  "bg-neutral-220 bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine";

const searchIntentOptions = Object.entries(searchIntentTabData).map(
  ([type, val]) => ({
    key: type as SearchIntent,
    value: type as SearchIntent,
    label: val.label,
    tooltipText: val.tooltipText,
  })
);

interface SearchBarProps {
  className?: string;
  defaultQuery?: string;
  customPlaceholder?: string;
  searchSource?: string;
  cbOnEmptyQuery?: () => void;
  cbOnSearchRedirect?: (newQuery: string) => void;
  isLocationRelevant?: boolean;
  theme?: SearchBarThemes;
  isSSR?: boolean;
  submitCta?: SearchBarCtaTypes;
  disableAutocomplete?: boolean;
  size?: SearchBarSizes;
  searchInNewTab?: boolean;
  searchUrl?: string;
  buyerLeadAgencyId?: string;
  showExactKeywordsFilter?: boolean;
  onSearch?: (args: SearchOptions) => void;
  intentVariant?: SearchBarIntentVariants;
  disambiguationModalEnabled?: boolean;
  // If true, changes to search intent within the searchbar will immediately be reflected in
  // search params stored in Recoil, which sync to the params shown in the SERP URL.
  syncIntent?: boolean;
}

function SearchBar({
  className,
  defaultQuery = "",
  customPlaceholder,
  searchSource = SearchSource.DEFAULT,
  cbOnEmptyQuery,
  cbOnSearchRedirect,
  isLocationRelevant = true,
  theme = SearchBarThemes.LIGHT,
  isSSR = false,
  submitCta = SearchBarCtaTypes.ICON,
  disableAutocomplete = false,
  size = SearchBarSizes.FULL,
  searchInNewTab = false,
  searchUrl,
  buyerLeadAgencyId,
  showExactKeywordsFilter = false,
  onSearch,
  intentVariant = SearchBarIntentVariants.NONE,
  disambiguationModalEnabled = false,
  syncIntent = false,
}: SearchBarProps) {
  const searchIntentToggleEnabled = isFeatureEnabled("searchIntentToggle");
  const nigpCategoriesTypeaheadEnabled = isFeatureEnabled(
    "nigpCategoriesTypeahead"
  );

  const isEntityContractsSearch =
    searchSource === SearchSource.ENTITY_CONTRACTS_PAGE;
  const searchParams = useRecoilValue(
    isEntityContractsSearch
      ? entityContractsParamsState
      : contractSearchParamsState
  );
  const setSearchParams = useSetRecoilState(
    isEntityContractsSearch
      ? entityContractsParamsState
      : contractSearchParamsState
  );
  const [searchQuery, setSearchQuery] = useState(
    searchParams?.query || getParam("query") || ""
  );
  const [selectedSearchIntent, setSelectedSearchIntent] = useState(
    (getParam("intent") as SearchIntent) ||
      (searchParams?.intent as SearchIntent) ||
      SearchIntent.BUYING
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to update the search query when the search params change.
  useEffect(() => {
    if (searchParams?.query && searchParams?.query !== searchQuery) {
      setSearchQuery(searchParams.query);
    }
    if (searchParams?.intent && searchParams.intent !== selectedSearchIntent) {
      setSelectedSearchIntent(searchParams.intent as SearchIntent);
    }
    // Set search query when intent changes so that we update searchbar upon use of search modal
  }, [searchParams?.query, searchParams?.intent]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Sync search intent between multiple instances of the searchbar on the page.
  useEffect(() => {
    if (syncIntent) {
      setSearchParams({
        ...searchParams,
        intent: selectedSearchIntent,
      });
    }
  }, [syncIntent, selectedSearchIntent]);

  const resetAndGetUserDefaultFilters = useResetAndGetFilters();
  const resetFilters = useResetAndGetFilters(true);
  const setExpirationFilter = useSetRecoilState(expirationFilterState);
  const setContractDocsFilter = useSetRecoilState(contractDocumentsFilterState);

  const [elementIdToFocus, setElementIdToFocus] = useRecoilState(
    elementIdToFocusState
  );
  const searchBar = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showTypeahead, setShowTypeahead] = useState(false);
  const searchContractWithParams = onSearch || useSearchContractWithParams();
  const [exactKeywordsOnly, setExactKeywordsOnly] = useState(
    searchQuery.startsWith('"') && searchQuery.endsWith('"')
  );

  // State variables for rendering
  const [autocompleteTrackingInfo, setAutocompleteTrackingInfo] =
    useState<TrackAutocompleteOptions>();
  const [loading, setLoading] = useState(false);
  const showDisambiguationModal = useShowModal(modals.SEARCH_DISAMBIGUATION);
  const searchIntentSurvey = useSearchIntentSurvey();
  const searchContracts = useSearchContracts({
    searchContractWithParams,
    cbOnSearchRedirect,
    searchInNewTab,
    searchUrl,
    buyerLeadAgencyId,
    searchSource,
    autocompleteTrackingInfo,
    isEntityContractsSearch:
      searchSource === SearchSource.ENTITY_CONTRACTS_PAGE,
    setShowTypeahead,
    searchIntentSurvey,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Selectively update the typeahead click handler.
  useEffect(() => {
    const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
      if (
        showTypeahead &&
        searchBar.current &&
        !searchBar.current.contains(event.target as Node)
      ) {
        setShowTypeahead(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTypeahead, searchBar]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to search when typing.
  useEffect(() => {
    if (!showExactKeywordsFilter) {
      return;
    }
    let newQuery = searchQuery;
    if (exactKeywordsOnly) {
      if (!searchQuery.startsWith('"') && !searchQuery.endsWith('"')) {
        newQuery = `"${searchQuery}"`;
      }
    } else {
      if (searchQuery.startsWith('"') && searchQuery.endsWith('"')) {
        newQuery = searchQuery.slice(1, -1);
      }
    }
    setSearchQuery(newQuery);
  }, [showExactKeywordsFilter, exactKeywordsOnly]);

  useEffect(() => {
    if (elementIdToFocus === `search-bar-${searchSource}`) {
      inputRef.current?.scrollIntoView({ behavior: "smooth" });
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [elementIdToFocus, searchSource]);

  function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  async function isAmbiguousQuery(
    query: string
  ): Promise<[boolean, VagueQuerySuggestion[]]> {
    try {
      const response =
        await ApiService.apiV1VagueQuerySuggestionsRetrieve(query);
      return [response.isVague, response.suggestions];
    } catch (error) {
      handleError(error);
      return [false, []];
    }
  }

  async function handleSubmit(
    clickedQuerySuggestion?: string,
    clickedQuerySuggestionTrackingValue?: TrackAutocompleteOptions
  ) {
    const query = clickedQuerySuggestion || searchQuery || defaultQuery;
    if (cbOnEmptyQuery && !query) {
      cbOnEmptyQuery();
      return;
    }
    if (disambiguationModalEnabled) {
      const [isAmbiguous, suggestions] = await isAmbiguousQuery(query);
      if (isAmbiguous) {
        showDisambiguationModal({
          originalQuery: query,
          searchOptions: suggestions,
          searchIntent: selectedSearchIntent,
          isLocationRelevant,
          searchInNewTab,
          isEntityContractsSearch,
          onSearchContracts: searchContracts,
        });
        return;
      }
    }
    await searchContracts({
      query,
      isLocationRelevant,
      selectedSearchIntent,
      clickedQuerySuggestionTrackingValue,
    });
  }

  const isFocused =
    inputRef.current && inputRef.current === document.activeElement;

  let placeholder = "Search products, services, brands, and suppliers.";
  if (isSSR) {
    placeholder = "";
  } else if (customPlaceholder) {
    placeholder = customPlaceholder;
  } else if (size === SearchBarSizes.COMPACT) {
    placeholder = "Search";
  } else if (selectedSearchIntent === SearchIntent.RESEARCHING) {
    placeholder = "Search for scope of work examples";
  }

  function onToggleSearchIntent(index?: number) {
    for (const [type, val] of Object.entries(searchIntentTabData)) {
      if (index === val.index) {
        setSelectedSearchIntent(type as SearchIntent);
        return;
      }
    }
  }

  function onSelectSearchIntentDropdown(value: SearchIntent) {
    if (value !== selectedSearchIntent) {
      setSelectedSearchIntent(value);
      if (value === SearchIntent.RESEARCHING) {
        resetFilters();
        setExpirationFilter(ExpirationStatuses.ACTIVE_AND_EXPIRED);
        setContractDocsFilter(ContractDocumentsFilterOptions.ONLY_WITH_DOCS);
      }
      searchContractWithParams({
        newParams: {
          intent: value,
          collapseBySupplier: value === SearchIntent.BUYING,
          filters:
            value === SearchIntent.RESEARCHING
              ? researchModeDefaultFilters
              : resetAndGetUserDefaultFilters(),
          originalAmbiguousQuery: searchQuery,
        },
        action: SearchActions.SAVE_INTENT,
      });
    }
  }

  return (
    <div
      className={clsx("main-search-bar flex-col space-y-2", className)}
      ref={searchBar}
    >
      {searchIntentToggleEnabled &&
        intentVariant === SearchBarIntentVariants.TOGGLE && (
          <ToggleTabs
            defaultTabIndex={searchIntentTabData[selectedSearchIntent].index}
            onSelectTab={onToggleSearchIntent}
            className="w-120 analytics-search-intent-toggle"
          >
            <div label={searchIntentTabData.BUYING.label} />
            <div label={searchIntentTabData.RESEARCHING.label} />
          </ToggleTabs>
        )}
      <div className="relative">
        <div className="flex">
          {searchIntentToggleEnabled &&
            intentVariant === SearchBarIntentVariants.DROPDOWN && (
              <div className="w-fit-content whitespace-nowrap">
                <DropdownPicker
                  variant={DropdownPickerVariants.SEARCHBAR}
                  initialValue={selectedSearchIntent}
                  refreshWithInitialValue
                  onChange={onSelectSearchIntentDropdown}
                  options={searchIntentOptions}
                  buttonClassName={clsx(
                    "rounded-l-2xl rounded-r-0 py-4 border border-solid",
                    {
                      [FOCUS_WITHIN_STYLES]:
                        elementIdToFocus === `search-bar-${searchSource}`,
                    },
                    `!${colorsByTheme[theme].borderColor}`
                  )}
                  className="analytics-search-intent-dropdown text-cp-body-sm"
                  allowOverflow // allow tooltip to overflow dropdown container
                  dataTestId="search-intent-dropdown"
                />
              </div>
            )}
          <div
            className={clsx(
              "bg-white relative border border-solid flex flex-row flex-1 items-center px-2 py-1 justify-between",
              searchIntentToggleEnabled &&
                intentVariant === SearchBarIntentVariants.DROPDOWN
                ? "border-l-0"
                : "rounded-l-2xl",
              {
                [FOCUS_WITHIN_STYLES]:
                  elementIdToFocus === `search-bar-${searchSource}`,
              },
              {
                "w-104": size === SearchBarSizes.FULL,
                // set absolute values for max-width and percentage value for width
                "lg:max-w-104 md:max-w-92 w-full":
                  size === SearchBarSizes.RESPONSIVE,
                "w-68": size === SearchBarSizes.COMPACT,
              },
              colorsByTheme[theme].borderColor
            )}
          >
            <Typography
              size="sm"
              component="div"
              emphasis
              className="px-2 relative w-full"
            >
              <input
                type="hidden"
                name="analytics-search-source"
                value={searchSource}
              />
              <input
                id={`search-bar-${searchSource}`}
                value={searchQuery || defaultQuery}
                name="query"
                placeholder={placeholder}
                onChange={(e) => setSearchQuery(e.target.value)}
                onBlur={() => setElementIdToFocus(null)}
                required
                disabled={isSSR}
                className={clsx(
                  `analytics-${searchSource}-searchBox text-ellipsis relative font-semibold placeholder:font-light px-0 py-1.5 w-full`,
                  {
                    [loadingAnimationStyles]: isSSR,
                  }
                )}
                onKeyUp={handleKeyUp}
                onFocus={() => setShowTypeahead(true)}
                autoComplete="off"
                ref={inputRef}
              />
              {loading && (
                <div className="absolute right-2 top-1/2 -translate-y-1/2">
                  <img src={loaderGifIcon} className="w-6" alt="Loading icon" />
                </div>
              )}
            </Typography>
            {!nigpCategoriesTypeaheadEnabled &&
              !disableAutocomplete &&
              isFocused && (
                <div className="absolute left-0 top-full mt-2 w-full z-1">
                  <SearchAutocomplete
                    handleSubmit={handleSubmit}
                    searchQuery={searchQuery}
                    setAutocompleteTrackingInfo={setAutocompleteTrackingInfo}
                    setLoading={setLoading}
                    setSearchQuery={setSearchQuery}
                    showResults={showTypeahead}
                  />
                </div>
              )}
          </div>
          <button
            tabIndex={0}
            className={clsx(
              // set a fixed width for button element so that the preceding search bar
              // input element can shrink to fit within the parent element
              `analytics-default-search-button analytics-${searchSource}-search-button rounded-tr-2xl rounded-br-2xl py-3 px-5 shrink-0 flex items-center justify-center w-23`,
              searchIntentToggleEnabled &&
                intentVariant === SearchBarIntentVariants.DROPDOWN
                ? "border border-l-0 border-solid border-cp-lapis-100"
                : "",
              "focus-visible:outline focus-visible:outline-1 focus-visible:outline-cp-lapis-500",
              colorsByTheme[theme].bgColor,
              colorsByTheme[theme].ctaTextColor,
              { "w-16": submitCta === SearchBarCtaTypes.ICON }
            )}
            onClick={() => handleSubmit()}
          >
            {submitCta === SearchBarCtaTypes.ICON ? (
              <SearchRoundedIcon className="text-md" />
            ) : (
              <div>Search</div>
            )}
          </button>
          {showExactKeywordsFilter && (
            <div className="mx-6 flex flex-col justify-center">
              <Checkbox
                name="exact-keywords-only"
                checked={exactKeywordsOnly}
                label="Search exact keywords"
                onChange={(e) => {
                  setExactKeywordsOnly(e.target.checked);
                }}
              />
            </div>
          )}
        </div>
        {nigpCategoriesTypeaheadEnabled &&
          !disableAutocomplete &&
          isFocused && (
            <div className="absolute top-full mt-2 w-200 z-1">
              <SearchAutocomplete
                handleSubmit={handleSubmit}
                searchQuery={searchQuery}
                setAutocompleteTrackingInfo={setAutocompleteTrackingInfo}
                setLoading={setLoading}
                setSearchQuery={setSearchQuery}
                showResults={showTypeahead}
                theme={theme}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default SearchBar;
