import clsx from "clsx";

import imageIcon from "../../img/icons/image.svg";

import type { ProductServiceVariant } from "../generated";
import Modal from "../shared/Modal/Modal";

import { Typography } from "../library";
import ImageWithFallback from "../library/ImageWithFallback";

export default function ProductDetailModal({
  hideModal,
  itemName,
  variants,
}: ProductDetailModalProps) {
  const mainVariant = variants[0];
  const mainVariantUrl = variants?.[0]?.photos[0]?.url;

  const otherVariantsHaveName = variants
    .slice(1)
    .some(({ variantName }) => !!variantName);

  return (
    <Modal
      hideModal={hideModal}
      className="analytics-role-collection-modal"
      formID="analytics-role-collection-modal-submit"
    >
      <Typography
        color="brand.default.secondary.enabled"
        variant="headline"
        size="sm"
        emphasis
        className="text-center line-clamp-2 mb-6"
      >
        {itemName}
      </Typography>
      <div className="flex gap-6">
        <ImageWithFallback
          className={clsx(
            "shrink-0 w-60 h-60 border rounded-xl",
            !mainVariantUrl && "p-6"
          )}
          src={mainVariantUrl ? mainVariantUrl : imageIcon}
          alt={`${mainVariant.variantName}-image`}
          srcFallback={imageIcon}
          fallbackClassName="p-6"
          fit="object-scale-down"
        />
        <Typography italic={!mainVariant.notes}>
          {mainVariant.notes || "No description available."}
        </Typography>
      </div>
      {variants.length > 1 && otherVariantsHaveName && (
        <div className="flex flex-col gap-2 mt-6">
          <Typography
            color="brand.default.secondary.enabled"
            variant="headline"
            size="xs"
            emphasis
          >
            Other options
          </Typography>
          <ul>
            {variants.slice(1).map(({ variantName, photos }) => {
              const imageUrl = photos.at(0)?.url;
              if (!variantName) return <></>;
              return (
                <li key={variantName} className="flex gap-2 py-2 border-b">
                  <ImageWithFallback
                    className={clsx("shrink-0 w-10 h-10 object-scale-down", {
                      "p-1.5": !imageUrl,
                    })}
                    src={imageUrl ? imageUrl : imageIcon}
                    alt={`${variantName}-image`}
                    srcFallback={imageIcon}
                    fallbackClassName="p-1.5"
                  />
                  <Typography>{variantName}</Typography>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Modal>
  );
}

interface ProductDetailModalProps {
  hideModal: () => void;
  itemName: string;
  variants: ProductServiceVariant[];
}
