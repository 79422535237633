import { useMemo, useState } from "react";
import type { TeamMember } from "../../components/Profile/TeamManagement/types";
import { ApiService, PermissionRoleEnum } from "../../generated";
import { Button, ButtonThemes, DropdownPicker } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { handleError } from "../../utils/generatedApi";

export default function ChangeOnlyAdminModal({
  hideModal,
  onComplete,
  teamList,
  userEmail,
}: ChangeOnlyAdminModalProps) {
  const pickerOptions = useMemo(() => {
    return teamList
      .filter(({ email, isInvited }) => email !== userEmail && !isInvited)
      .map(({ name, email }) => {
        return {
          key: name,
          value: email,
          label: name,
        };
      });
  }, [teamList, userEmail]);

  const [selectedUserEmail, setSelectedUserEmail] = useState(
    pickerOptions[0].value
  );

  function selectAdmin() {
    try {
      ApiService.apiV1RoleManagementChangeRoleCreate({
        affectedUserEmail: selectedUserEmail,
        permissionRole: PermissionRoleEnum.ADMIN,
      });
    } catch (e) {
      handleError(e);
    }
  }

  const ctas = (
    <div className="flex gap-4 justify-center">
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        onClick={hideModal}
        dataTestId="cancel-cta"
      >
        Cancel
      </Button>
      <Button
        theme={ButtonThemes.PRIMARY_DARK}
        onClick={() => {
          selectAdmin();
          onComplete?.();
        }}
        dataTestId="set-as-admin"
      >
        Set as entity admin
      </Button>
    </div>
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Before removing yourself as an admin, set someone else as an admin for your entity."
      className="analytics-confirm-change-admin-modal"
      modalSize={modalSizes.SMALL}
      ctaSection={ctas}
      contentClassName="flex justify-center py-4 overflow-visible"
    >
      <div className="w-3/4">
        <DropdownPicker
          className="w-full"
          initialValue={pickerOptions[0].value}
          onChange={(value) => {
            setSelectedUserEmail(value);
          }}
          options={pickerOptions}
        />
      </div>
    </Modal>
  );
}

interface ChangeOnlyAdminModalProps {
  hideModal: () => void;
  onComplete?: () => void;
  teamList: TeamMember[];
  userEmail: string;
}
