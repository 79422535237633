import { useState } from "react";
import { useRecoilValue } from "recoil";
import type { SupplierCardType } from "../../../generated";
import { useHideSupplierSearch } from "../../../hooks/useHideSupplierSearch";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import {
  contractSearchParamsState,
  contractSearchResponseDataState,
  requestIDState,
  searchQueryState,
  searchResultTypeState,
  showSupplierRedirectState,
  supplierSearchResponseDataState,
} from "../../../recoil/search";
import { SearchActions } from "../../../utils/enums";
import { trackHeapEvent } from "../../../utils/tracking";
import { headerText } from "../utils";
import ResultCountText from "./ResultCountText";

export default function ContractResultsHeaderText() {
  const contractSearchResponseData = useRecoilValue(
    contractSearchResponseDataState
  );
  const query = useRecoilValue(searchQueryState);
  const searchResultType = useRecoilValue(searchResultTypeState);
  const supplierResults = searchResultType === "supplier";

  return (
    <div className="grid gap-2 analytics-search-results-header-text">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        {supplierResults
          ? `Results for "${query}"`
          : headerText(query, contractSearchResponseData?.agencyData?.agency)}
      </Typography>
      {!supplierResults && <ResultCountText />}
    </div>
  );
}

export function SupplierCategoriesHeaderText() {
  const search = useSearchContractWithParams();
  const requestID = useRecoilValue(requestIDState);
  const supplierResponseData = useRecoilValue(supplierSearchResponseDataState);

  const suggestedSearches =
    supplierResponseData?.supplierData?.suggestedSearches?.slice(0, 3) || [];

  const [selectedQuery, setSelectedQuery] = useState<string>(
    suggestedSearches[0] || ""
  );

  return (
    <div className="grid gap-2 analytics-search-results-header-text">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        Contracts from similar suppliers
      </Typography>
      <Typography color="neutral.boldest.enabled" className="mb-6">
        Showing active contracts from suppliers that have similar offerings to{" "}
        {supplierResponseData?.supplierData?.suppliers[0].supplier.displayName}.
      </Typography>
      <div className="flex flex-row gap-2 items-center">
        <Typography size="sm">Filter by supplier offerings:</Typography>

        {suggestedSearches.map((suggestedSearch) => (
          <Button
            key={suggestedSearch}
            theme={
              suggestedSearch === selectedQuery
                ? ButtonThemes.OUTLINE_SELECTED
                : ButtonThemes.OUTLINE
            }
            size={ButtonSizes.SMALL}
            rounded="circle"
            onClick={() => {
              trackHeapEvent("supplier-redirect-click-category", {
                suggestedSearch,
                requestID,
              });
              setSelectedQuery(suggestedSearch);
              search({
                newParams: { query: suggestedSearch },
                action: SearchActions.SEARCH_CONTRACTS_ONLY,
              });
            }}
          >
            {suggestedSearch}
          </Button>
        ))}
      </div>
    </div>
  );
}

export function SupplierResultsHeaderText({
  suppliers,
}: { suppliers: SupplierCardType[] }) {
  const showSupplierRedirect = useRecoilValue(showSupplierRedirectState);
  const { query } = useRecoilValue(contractSearchParamsState);
  const search = useSearchContractWithParams();
  const requestID = useRecoilValue(requestIDState);
  const hideSupplierSearch = useHideSupplierSearch();

  return (
    <div className="grid gap-2 analytics-search-results-header-text mr-2">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        Top supplier match
      </Typography>
      {showSupplierRedirect && !!suppliers.length && (
        <Typography size="sm">
          Showing supplier match for &quot;
          {suppliers[0].supplier.displayName}
          &quot;. Search instead for{"  "}
          <Link
            size="sm"
            underline={false}
            emphasis={false}
            onClick={() => {
              trackHeapEvent("supplier-redirect-show-contracts", {
                query,
                requestID,
              });
              hideSupplierSearch(query, false);
              search({
                newParams: { query },
                action: SearchActions.SEARCH_CONTRACTS_ONLY,
              });
            }}
          >
            {query} contracts.
          </Link>
        </Typography>
      )}
    </div>
  );
}
