import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import type {
  ProjectCreationSource,
  ProjectProps,
} from "../../components/Project/types";
import { ApiService, type ItemTypeEnum } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import useShowModal from "../../hooks/useShowModal";
import {
  Badge,
  Button,
  type ButtonSizes,
  type ButtonThemes,
  DropdownMenu,
  Link,
  Typography,
} from "../../library";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import type { TypographyColor } from "../../library/Typography/types";
import { modalState, popupState } from "../../recoil/page";
import { isAuthenticatedState } from "../../recoil/user";
import { PopupType, modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import {
  trackOpenSaveToProjectDropdown,
  trackSaveToProject,
} from "../../utils/tracking";
import { BUTTON_PROPS } from "../saveToProjectUtils";

export type ShowModalCallbackType = ({
  onClickCta,
}: { onClickCta: () => void }) => void;

interface SaveToProjectButtonProps {
  initialSavedProjectId: Maybe<string>;
  itemId: string;
  itemType: ItemTypeEnum;
  additionalItems?: MenuItemProps[];
  buttonSize?: ButtonSizes;
  buttonTheme?: ButtonThemes;
  source: ProjectCreationSource;
  className?: string;
  buttonClassName?: string;
  dataTestId?: string;
  showOutreachModal?: ShowModalCallbackType;
}

export default function SaveToProjectButton({
  initialSavedProjectId,
  itemId,
  itemType,
  additionalItems = [],
  buttonSize = Button.sizes.SMALL,
  buttonTheme = Button.themes.SECONDARY_DARK,
  buttonClassName,
  source,
  className,
  dataTestId = "save-to-project",
  showOutreachModal,
}: SaveToProjectButtonProps) {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const currentModal = useRecoilValue(modalState);
  const [savedProjectId, setSavedProjectId] = useState(initialSavedProjectId);
  const setPopupState = useSetRecoilState(popupState);
  const [userProjects, setUserProjects] = useState<ProjectProps[]>([]);
  const showModal = useShowModal(modals.NEW_PROJECT_MODAL);
  const requireLogin = useLoginWall();

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is intentional so that we re-fetch projects when the current modal changes
  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList();

        // Only show the not archived projects
        const activeProjects = projects.filter(
          (project) => !project.archivedAt
        );
        setUserProjects(activeProjects);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [isAuthenticated, currentModal]);

  async function saveProjectItem(projectId: string) {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId,
        itemType,
      });
      setSavedProjectId(projectId);
      setPopupState({
        analyticsClassName: `analytics-new-project-success-${source}`,
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: (
          <Badge Icon={CheckRoundedIcon} iconClass="!mr-4">
            <Typography color="inverse">
              {BUTTON_PROPS[itemType].viewSavedCtaCopy}{" "}
              <Link
                color="inverse"
                emphasis={false}
                href={`/projects/${projectId}`}
              >
                here
              </Link>
              .
            </Typography>
          </Badge>
        ),
        show: true,
      });
      trackSaveToProject({ itemId, itemType });
    } catch (e) {
      handleError(e);
    }
  }

  const onClick = useCallback(async () => {
    if (savedProjectId) {
      try {
        await ApiService.apiV1ProjectsItemsDestroy(itemId, savedProjectId);
        setSavedProjectId("");
      } catch (e) {
        handleError(e);
      }
    }
    trackOpenSaveToProjectDropdown({ source });
    requireLogin({
      onComplete: () => {},
      subtitle: BUTTON_PROPS[itemType].loginWallTitle,
      triggerType: BUTTON_PROPS[itemType].triggerType,
      triggerId: itemId,
    });
  }, [savedProjectId, itemId, itemType, requireLogin, source]);

  const items: MenuItemProps[] = userProjects.map(({ id, name }) => {
    return {
      label: name,
      className: "analytics-save-project-dropdown-project-item",
      onClick: () => {
        if (showOutreachModal) {
          showOutreachModal({
            onClickCta: () => saveProjectItem(id),
          });
        } else {
          saveProjectItem(id);
        }
      },
    };
  });

  items.push({
    label: "+ New project",
    className:
      "analytics-save-project-dropdown-add-project font-semibold sm:font-semibold",
    onClick: () => {
      showModal({
        source,
        saveProjectItem,
        itemId,
        itemType,
        showOutreachModal,
      });
    },
    color: "brand.bold.enabled" as TypographyColor,
  });

  items.push(...additionalItems);

  return (
    <div
      className={clsx("sm:relative w-fit", className)}
      data-testid={dataTestId}
    >
      <DropdownMenu
        items={items}
        label="SAVE TO PROJECT"
        responsive
        buttonProps={{
          theme: buttonTheme,
          size: buttonSize,
          className: clsx(
            "gap-2 h-auto analytics-save-contract",
            buttonClassName
          ),
          onClick,
        }}
      >
        <Badge
          Icon={savedProjectId ? BookmarkIcon : BookmarkBorderOutlinedIcon}
        >
          {savedProjectId ? (
            BUTTON_PROPS[itemType].savedCopy
          ) : (
            <>
              {BUTTON_PROPS[itemType].saveCtaCopy}
              <ExpandMoreRoundedIcon className="w-5 h-5 ml-1" />
            </>
          )}
        </Badge>
      </DropdownMenu>
    </div>
  );
}
