import * as yup from "yup";

import { BuyerProfileTypes } from "../../shared/types";

import type { FormFieldProps } from "./types";

import DropdownField from "./DropdownField";

export const buyerRoleOptions = [
  {
    key: BuyerProfileTypes.BUYER,
    value: BuyerProfileTypes.BUYER,
    label: "I'm a procurement professional",
    sublabel: "e.g. buyer, contract specialist",
  },
  {
    key: BuyerProfileTypes.LEADERSHIP,
    value: BuyerProfileTypes.LEADERSHIP,
    label: "I lead a purchasing team",
    sublabel: "e.g. purchasing manager, CPO",
  },
  {
    key: BuyerProfileTypes.END_USER,
    value: BuyerProfileTypes.END_USER,
    label: "I have purchasing needs",
    sublabel: "e.g. staff from a department besides procurement",
  },
  {
    key: BuyerProfileTypes.OTHER,
    value: BuyerProfileTypes.OTHER,
    label: "Other",
  },
];

interface RoleFieldProps extends FormFieldProps {
  placeholder: NonNullable<FormFieldProps["placeholder"]>;
  options: NonNullable<FormFieldProps["options"]>;
}

export const BUYER_ROLE_FIELDS: RoleFieldProps[] = [
  {
    name: "buyerRole",
    size: "md",
    options: buyerRoleOptions,
    label: "What is your role at your entity?",
    placeholder: "Select your role",
    component: DropdownField,
    dataTestId: "buyer-role",
    validate: yup.string().required("This field is required."),
    className: "w-full !max-h-60",
  },
];
