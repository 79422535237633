import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  ApiService,
  type ApprovedSource,
  type BuyerLeadAgency,
} from "../../../generated";
import { Typography } from "../../../library";
import { buyerProfileState } from "../../../recoil/user";
import SupportEmailLink from "../../../shared/SupportEmailLink";
import { CONTRACT_EMAIL_ADDRESS } from "../../../utils/constants";
import { handleError } from "../../../utils/generatedApi";
import { trackRequestNewApprovedSource } from "../../../utils/tracking";
import BLAAutocompleteInput from "../../BLAAutocompleteInput";
import ContactBox from "../ContactBox";
import ApprovedSourcesTable from "./ApprovedSourcesTable";

export default function NonAdminApprovedSources() {
  const buyerProfile = useRecoilValue(buyerProfileState);
  const [pendingApprovedSources, setPendingApprovedSources] = useState<
    ApprovedSource[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await ApiService.apiV1ApprovedSourcesList();
        setPendingApprovedSources(response);
      } catch (err) {
        handleError(err);
        // setError("Unable to add public entity. Please try again.");
      }
    })();
  }, []);

  const onAddSource = async (
    _name: string | null,
    agency: BuyerLeadAgency | null,
    { clear }: { clear: () => void }
  ) => {
    if (!agency) return;
    try {
      const response = await ApiService.apiV1ApprovedSourcesCreate({
        sourceAgencyId: agency.id,
        sourceAgencyGroup: null,
      });

      const { sourceName, sourceTypeLabel, sourceId } = response;
      clear();

      setPendingApprovedSources([...pendingApprovedSources, response]);
      trackRequestNewApprovedSource({
        sourceName,
        sourceTypeLabel,
        sourceId,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const agencyName = buyerProfile?.governmentAffiliationDisplayName;

  return (
    <div className="flex flex-col gap-10 md:gap-16 md:grid md:grid-cols-9 md:gap-x-6">
      <div className="flex flex-col md:flex-row md:grid md:grid-cols-9 md:col-span-9 md:gap-x-6">
        <div className="grid gap-8 mb-4 md:mb-0 md:col-span-6">
          <div className="grid gap-2">
            <Typography
              variant="headline"
              size="sm"
              color="brand.boldest.enabled"
              emphasis
            >
              Request approved sources
            </Typography>
            <Typography color="neutral.bolder.enabled">
              Select contract sources you'd like to see at the top of search
              results for your entity. We'll send this request to your admin.
            </Typography>
          </div>
          <div className="relative">
            <BLAAutocompleteInput
              analyticsClass="analytics-approved-sources-input"
              label="Add a cooperative, state, or local agency"
              sublabel="Start typing to see contract sources"
              onChange={onAddSource}
              errorMessage={""}
              useAddComponent
              freeSolo={false}
              excludeCooperatives={false}
            />
          </div>
        </div>
        <div className="md:col-span-3">
          <ContactBox
            headline="Can't find the contract source you're looking for?"
            body={
              <>
                Let us know by emailing{" "}
                <SupportEmailLink
                  className="analytics-click-pro-supplier-bulk-contract-email"
                  underline
                  email={CONTRACT_EMAIL_ADDRESS}
                />
                .
              </>
            }
          />
        </div>
      </div>
      <div className="grid gap-8 md:col-span-9">
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
        >
          Approved sources for {agencyName}
        </Typography>
        <ApprovedSourcesTable sources={pendingApprovedSources} />
      </div>
    </div>
  );
}
