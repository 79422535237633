import TuneRoundedIcon from "@mui/icons-material/TuneRounded";

import type { SearchOptions } from "../../../components/ContractSearch/types";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import useShowModal from "../../../hooks/useShowModal";
import useUserStateCode from "../../../hooks/useUserStateCode";
import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
} from "../../../library";

import { Suspense, lazy } from "react";
import { useRecoilState } from "recoil";
import { useCanShowDebug } from "../../../hooks/useCanShowDebug";
import { debugState } from "../../../recoil/search";
import { SearchActions, SearchSource, modals } from "../../../utils/enums";
import { ContractDocumentsFilterPopover } from "./ContractDocumentsFilter";
import { ExpirationFilterPopover } from "./ExpirationFilter";
import FilterPills from "./FilterPills";
import OtherFiltersPopover from "./OtherFilters";
import { SourcesFilterPopover } from "./SourcesFilter";
import { SupplierLocationFilterPopover } from "./SupplierLocationFilter";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

const CompactSearchResultTypeToggle = lazy(
  () => import("./CompactSearchResultTypeToggle")
);

export enum SearchFilter {
  SOURCES = "sources",
  EXPIRATION = "expiration",
  CONTRACT_DOCUMENTS = "contractDocuments",
  SUPPLIER_LOCATION = "supplierLocation",
  OTHER = "other",
}

export default function SearchFilterHeader({
  onFilterChange,
  filters,
  setFilters,
  filtersToShow,
  searchSource,
  onSearch,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: string[];
  setFilters: (f: string[]) => void;
  filtersToShow?: SearchFilter[];
  searchSource: SearchSource;
  onSearch?: (options: SearchOptions) => void;
}) {
  const [debug, setDebug] = useRecoilState(debugState);
  const isLandingPage =
    searchSource === SearchSource.LANDING_PAGE_ENTITY_SEARCH;

  const performSearch = onSearch || useSearchContractWithParams();

  const userStateCode = useUserStateCode();
  const canShowDebug = useCanShowDebug();
  const showFiltersModal = useShowModal(modals.FILTERS);

  const showAllFilters = !filtersToShow;

  const showSourcesFilter = shouldShowFilter(SearchFilter.SOURCES);
  const showExpirationFilter = shouldShowFilter(SearchFilter.EXPIRATION);
  const showContractDocumentsFilter = shouldShowFilter(
    SearchFilter.CONTRACT_DOCUMENTS
  );
  const showSupplierLocationFilter = shouldShowFilter(
    SearchFilter.SUPPLIER_LOCATION
  );

  const showOtherFilters = shouldShowFilter(SearchFilter.OTHER);

  function shouldShowFilter(filter: SearchFilter) {
    return showAllFilters || filtersToShow?.includes(filter);
  }

  const onToggleFilter: OnToggleFilterFn = (append, filter) => {
    let update: string[];
    if (append) update = [...filters, filter];
    else update = [...filters.filter((value) => value !== filter)];

    setFilters(update);
    onFilterChange({ type: filter, value: append ? "on" : "off" });

    performSearch({
      newParams: { filters: update },
      action: SearchActions.SAVE_FILTERS,
    });
  };

  return (
    <>
      <div className="hidden sm:block">
        <div className="flex flex-wrap sm:flex-nowrap gap-3">
          <Suspense fallback={null}>
            <CompactSearchResultTypeToggle />
          </Suspense>
          <div className="flex flex-wrap gap-3">
            {showSourcesFilter && (
              <SourcesFilterPopover onChange={onFilterChange} />
            )}
            {showSupplierLocationFilter && userStateCode && (
              <SupplierLocationFilterPopover
                stateCode={userStateCode}
                onToggleFilter={onToggleFilter}
                onChange={onFilterChange}
                filters={filters}
              />
            )}
            {showExpirationFilter && (
              <ExpirationFilterPopover
                isLandingPage={isLandingPage}
                onChange={onFilterChange}
                filters={filters}
                onToggleFilter={onToggleFilter}
              />
            )}
            {showContractDocumentsFilter && (
              <ContractDocumentsFilterPopover
                onChange={onFilterChange}
                filters={filters}
                onToggleFilter={onToggleFilter}
              />
            )}
            {showOtherFilters && (
              <OtherFiltersPopover
                onChange={onFilterChange}
                onToggleFilter={onToggleFilter}
                filters={filters}
              />
            )}
            {canShowDebug && (
              <Button
                size={ButtonSizes.SMALL}
                theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
                onClick={() => {
                  setDebug(!debug);
                }}
              >
                Toggle Debug
              </Button>
            )}
          </div>
        </div>
        <FilterPills
          filters={showAllFilters ? filters : filtersToShow}
          userStateCode={userStateCode}
          onToggleFilter={onToggleFilter}
          onSearch={onSearch}
        />
      </div>
      <div className="sm:hidden max-w-fit">
        <div className="flex gap-3">
          <Suspense fallback={null}>
            <CompactSearchResultTypeToggle />
          </Suspense>
          <Badge
            Icon={TuneRoundedIcon}
            as={Link}
            onClick={() =>
              showFiltersModal({
                filters,
                onToggleFilter,
                onFilterChange,
                isLandingPage,
              })
            }
            linkProps={{ underline: false, emphasis: false }}
            className="rounded-none"
          >
            Filter
          </Badge>
        </div>
      </div>
    </>
  );
}
