/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `PAVILION_REPORTED` - Pavilion Reported
 * * `SUPPLIER_REPORTED` - Supplier Reported
 */
export enum DiscoverySourceEnum {
    PAVILION_REPORTED = 'PAVILION_REPORTED',
    SUPPLIER_REPORTED = 'SUPPLIER_REPORTED',
}
