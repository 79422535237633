import type { ElementType, ReactNode } from "react";

import {
  BlockRounded,
  BoltRounded,
  BookmarkRounded,
  Diversity1Rounded,
  GppGood,
  HourglassEmptyRounded,
  LocationOffRounded,
  ReportRounded,
  RequestQuoteRounded,
  StorefrontRounded,
} from "@mui/icons-material";
import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import AutoAwesomeOutlined from "@mui/icons-material/AutoAwesomeOutlined";
import EqualizerOutlined from "@mui/icons-material/EqualizerOutlined";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import WorkspacePremiumOutlined from "@mui/icons-material/WorkspacePremiumOutlined";
import { Badge, Typography } from "../../library";
import { textColorClass } from "../../utils/colors";
import { TagVariants } from "../Tag";

const VARIANTS: Partial<
  Record<TagVariants, { Icon: ElementType; iconColor: string }>
> = {
  [TagVariants.DIVERSITY]: {
    Icon: Diversity1Rounded,
    iconColor: "text-cp-lapis-200",
  },
  [TagVariants.OUT_OF_AREA]: {
    Icon: LocationOffRounded,
    iconColor: "text-cp-red-palette-400",
  },
  [TagVariants.LOCATION]: {
    Icon: StorefrontRounded,
    iconColor: "text-cp-lapis-300",
  },
  [TagVariants.EXPIRED]: {
    Icon: HourglassEmptyRounded,
    iconColor: "text-cp-red-palette-400",
  },
  [TagVariants.WARNING]: {
    Icon: WarningAmberRounded,
    iconColor: "text-cp-persimmon-300",
  },
  [TagVariants.UNSUPPORTED]: {
    Icon: ReportRounded,
    iconColor: "text-cp-red-palette-400",
  },
  [TagVariants.NON_COOPERATIVE]: {
    Icon: BlockRounded,
    iconColor: "text-cp-persimmon-300",
  },
  [TagVariants.VERIFIED]: { Icon: GppGood, iconColor: "text-cp-leaf-300" },
  [TagVariants.PRICING_DOC]: {
    Icon: RequestQuoteRounded,
    iconColor: "text-cp-lapis-400",
  },
  [TagVariants.PRO_SUPPLIER]: {
    Icon: BoltRounded,
    iconColor: "text-cp-limeade-700",
  },
  [TagVariants.YOUR_CONTRACT]: {
    Icon: BookmarkRounded,
    iconColor: "text-cp-leaf-200",
  },
  [TagVariants.DEBUG_MATCH]: {
    Icon: WorkspacePremiumOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.DEBUG_BLA]: {
    Icon: AccountBalanceOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.DEBUG_PRO]: {
    Icon: AutoAwesomeOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
  [TagVariants.DEBUG_INFO]: {
    Icon: EqualizerOutlined,
    iconColor: textColorClass.neutral.bold.enabled,
  },
};

export default function TransparentTag({
  variant,
  size,
  children,
}: { variant: TagVariants; size: "sm" | "md"; children: ReactNode }) {
  const icon = VARIANTS[variant];
  return (
    <Badge
      key={variant}
      as={Typography}
      size={size === "sm" ? "sm-md" : size}
      linkProps={{
        variant: "meta",
        size,
        color: "neutral.bolder.enabled",
      }}
      Icon={icon?.Icon}
      iconClass={icon?.iconColor}
    >
      {children}
    </Badge>
  );
}
