import pluralize from "pluralize";
import { useRecoilValue } from "recoil";

import { Typography } from "../../../library";
import {
  allSearchesLoadedState,
  matchedSearchResultCountState,
  numFiltersAppliedState,
} from "../../../recoil/search";
import { MAX_RESULTS } from "../../../utils/constants";
import { LoadingCards } from "../../Loading/LoadingCards";

export default function ResultCountText() {
  const numActiveFilters = useRecoilValue(numFiltersAppliedState);
  const isLoading = !useRecoilValue(allSearchesLoadedState);
  const matchedSearchResultCount = useRecoilValue(
    matchedSearchResultCountState
  );

  const numResults =
    matchedSearchResultCount < MAX_RESULTS
      ? matchedSearchResultCount
      : `${MAX_RESULTS}+`;

  if (isLoading) return <LoadingCards />;

  if (!numActiveFilters) {
    return (
      <Typography size="sm">{`${numResults} contracts available`}</Typography>
    );
  }

  const filteredResultsText = `${numResults} ${pluralize(
    " contract",
    matchedSearchResultCount
  )} available based on your ${pluralize("filter", numActiveFilters)}`;
  return <Typography size="sm">{filteredResultsText}</Typography>;
}
