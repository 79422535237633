import { useMemo } from "react";
import type { Maybe } from "yup";
import type {
  ContractHit,
  ContractResult,
  ContractSearchAgency,
  ContractSearchPrioritizedAgencyMatchData,
  RelevantContract,
  SupplierCardType,
} from "../../generated";

import { contractMatchTypes, resultTypes } from "../../utils/enums";

export const getSupplierTrackingData = (supplier: SupplierCardType) => ({
  absoluteDepth: supplier.rank || 0,
  supplierId: supplier.supplier.id,
  supplierHandle: supplier.supplier.handle,
  resultType: resultTypes.TOP_SUPPLIER,
  hasSimilarEntities: !!supplier.compliance.publicEntitiesServed?.length,
  numEntitiesInState:
    supplier.compliance.publicEntitiesServed?.filter(
      ({ sameState }) => sameState
    ).length || 0,
  numEntitiesInCategory:
    supplier.compliance.publicEntitiesServed?.filter(
      ({ sameCategory }) => sameCategory
    ).length || 0,
});

export function useGetSearchMethod({
  results,
  prioritizedEntityData,
}: {
  results: ContractResult[];
  prioritizedEntityData: Maybe<ContractSearchPrioritizedAgencyMatchData>;
}) {
  let searchMethodType = "Keyword";
  useMemo(() => {
    const isSemanticSearch = results.some((result) =>
      resultHasMatch(result, (r) => r.matchTier === contractMatchTypes.SEMANTIC)
    );

    if (prioritizedEntityData) searchMethodType = "Prioritized Entity";
    if (isSemanticSearch) {
      searchMethodType = "Semantic";
    }
  }, [results, prioritizedEntityData, searchMethodType]);

  return searchMethodType;
}

// A helper to conditionally run the provided function either on the main contract hit
// if it's a contract result OR the sub-contracts if it's a supplier result. Returns true
// if the provided matcher matches any result.
export function resultHasMatch(
  c: ContractResult,
  fn: (r: ContractHit | RelevantContract) => boolean
) {
  return isContractHit(c) ? fn(c) : c.relevantSuppliers.some((r) => fn(r));
}

export function getCompetitorKeywords(results: ContractResult[]) {
  const competitorResult = results.find((result) =>
    resultHasMatch(
      result,
      (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
    )
  );
  return competitorResult?.supplierKeywords?.slice(0, 3) || [];
}

export function isCompetitorMatch(c: ContractResult) {
  return resultHasMatch(
    c,
    (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
  );
}

export function headerText(query: string, agency?: ContractSearchAgency) {
  if (agency) {
    return `Contracts from ${agency.name}`;
  }
  return `Contracts for "${query || ""}"`;
}

export function isContractHit(h: ContractResult): h is ContractHit {
  return (h as ContractHit).contractTags !== undefined;
}
