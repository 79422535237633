import type { ContractSolicitationDetails } from "../../pages/Contract";
import { expirationText } from "../../shared/ContractBase";
import SemanticRecommendationCarousel from "../../shared/RecommendationCarousel/SemanticRecommendationCarousel";
import { getParams } from "../../utils";
import { trackViewSolicitationRecommendations } from "../../utils/tracking";
import type {
  SimilarContract,
  TrackContractClickWithinSolicitationCarouselFn,
} from "./types";

interface ContractRecommendationsProps {
  contractId: string;
  contractDetails: ContractSolicitationDetails;
  query: string;
  title: string;
  solicitationId: Maybe<string>;
  numSuppliersOnSolicitation: number;
  buyerLeadAgency: string;
  cooperativeAffiliation: string;
  sourceKey: Maybe<string>;
  trackContractClickWithinSolicitation: TrackContractClickWithinSolicitationCarouselFn;
  pageType: Maybe<string>;
}

export default function ContractRecommendations({
  pageType,
  contractId,
  query,
  title,
  contractDetails,
  solicitationId,
  numSuppliersOnSolicitation,
  buyerLeadAgency,
  cooperativeAffiliation,
  sourceKey,
  trackContractClickWithinSolicitation,
}: ContractRecommendationsProps) {
  const params = getParams();
  const searchSource = (params.searchSource || "") as string;
  const searchType = (params.searchType || "") as string;
  if (!pageType) return null;

  return (
    <div className="w-full">
      <SemanticRecommendationCarousel
        id={contractId}
        query={query}
        onRender={(solicitations: SimilarContract[]) => {
          trackViewSolicitationRecommendations({
            contractTitle: title,
            supplierId: contractDetails.supplier.id,
            supplierName: contractDetails.supplier.displayName,
            supplierHandle: contractDetails.supplier.handle,
            contractId,
            solicitationId,
            numSuppliersOnSolicitation,
            numDocs: contractDetails.file_information?.length,
            verifiedContacts: !!contractDetails.supplier_contact?.full_name,
            hasContractOfferings: contractDetails.offerings?.length > 0,
            expirationDate: expirationText(contractDetails.expiration_date, {
              showDateOnly: true,
              showUnknown: true,
            }),
            searchQuery: query,
            queryZip: params.queryZip as string | undefined, // Not using useUserZip since it is async and could potentially be empty + params should have the accurate zip code
            contractType: pageType,
            buyerLeadAgency,
            cooperativeAffiliation,
            sourceKey,
            searchType,
            searchSource,
            requestID: params.requestID?.toString(),
            contractRecommendations: solicitations
              .map((s) => s.docid)
              .join(","),
            proSupplierContractRecommendations: solicitations
              .filter((s) => s.pro_treatment)
              .map((s) => s.docid)
              .join(","),
            numRecommendations: solicitations.length,
          });
        }}
        trackContractClickWithinSolicitation={
          trackContractClickWithinSolicitation
        }
      />
    </div>
  );
}
