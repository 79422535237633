import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";

import type { ElementType } from "react";
import type { NIGPCategory } from "../../generated";
import { Badge, BoldedText } from "../../library";
import { isFeatureEnabled } from "../../utils/split";
import SearchAutocompleteCategoryOption from "./SearchAutocompleteCategoryOption";
import { SearchBarThemes } from "./types";

interface SearchAutocompleteOptionsProps {
  title: string;
  Icon?: ElementType;
  results?: string[];
  nigpCategoryResults?: NIGPCategory[];
  analyticsClass: string;
  totalOptions: string[];
  activeIndex: number;
  handleSubmit: (result: string, index: number, searchType: string) => void;
  searchQuery: string;
  analyticsType: string;
  theme?: SearchBarThemes;
}

export default function SearchAutocompleteOptions({
  title,
  Icon,
  results = [],
  nigpCategoryResults = [],
  analyticsClass,
  totalOptions,
  activeIndex,
  handleSubmit,
  searchQuery,
  analyticsType,
  theme = SearchBarThemes.LIGHT,
}: SearchAutocompleteOptionsProps) {
  const nigpCategoriesTypeaheadEnabled = isFeatureEnabled(
    "nigpCategoriesTypeahead"
  );

  if (!nigpCategoriesTypeaheadEnabled && results.length === 0) {
    return null;
  }

  return (
    <div className={nigpCategoriesTypeaheadEnabled ? "w-full" : ""}>
      <div
        className={clsx("text-cp-meta-sm mb-1.5 px-6", {
          "font-extralight tracking-widest flex-row align-bottom text-cp-neutral-palette-800 mt-2":
            nigpCategoriesTypeaheadEnabled,
          "font-semibold text-cp-midnight-300 mt-4":
            !nigpCategoriesTypeaheadEnabled,
        })}
      >
        {Icon && <Icon className="mr-2 w-6 h-6 text-cp-neutral-palette-800" />}
        {title}
      </div>
      {nigpCategoriesTypeaheadEnabled ? (
        <>
          {nigpCategoryResults.length === 0 ? (
            <div
              className={clsx(
                "px-6 py-1.5 italic text-cp-body-sm text-cp-neutral-palette-500 font-normal",
                analyticsClass
              )}
            >
              No matches found
            </div>
          ) : (
            nigpCategoryResults.map((category, i) => (
              <>
                <SearchAutocompleteCategoryOption
                  key={category.description}
                  category={category}
                  index={i}
                  analyticsClass={analyticsClass}
                  totalOptions={totalOptions}
                  activeIndex={activeIndex}
                  handleSubmit={handleSubmit}
                  searchQuery={searchQuery}
                  analyticsType={analyticsType}
                  theme={theme}
                />
                {category.subcategories?.map((subcategory: NIGPCategory, j) => {
                  return (
                    <SearchAutocompleteCategoryOption
                      key={subcategory.description}
                      category={subcategory}
                      index={i + j + 1}
                      analyticsClass={analyticsClass}
                      totalOptions={totalOptions}
                      activeIndex={activeIndex}
                      handleSubmit={handleSubmit}
                      searchQuery={searchQuery}
                      analyticsType={analyticsType}
                      nestingLevel={1}
                      theme={theme}
                    />
                  );
                })}
              </>
            ))
          )}
        </>
      ) : (
        <>
          {results.map((result, i) => (
            <div
              key={result}
              className={clsx(
                "py-1.5 hover:bg-cp-violet-100 cursor-pointer",
                analyticsClass,
                {
                  "bg-cp-violet-100": totalOptions[activeIndex] === result,
                }
              )}
              onClick={() => {
                handleSubmit(result, i, analyticsType);
              }}
            >
              <Badge
                className="px-6"
                Icon={SearchRoundedIcon}
                iconClass="text-cp-black-100"
              >
                <BoldedText text={result} highlight={searchQuery} />
              </Badge>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
