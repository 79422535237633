import _groupBy from "lodash/groupBy";
import { useMemo } from "react";

import type {
  DedupedSupplierContact,
  SupplierContact,
} from "../../../../shared/types";

export function getContactDisplayName(
  contact: SupplierContact,
  supplierName: string,
  index?: number
) {
  const isPerson = contact.firstName || contact.lastName;
  if (isPerson)
    return [contact.firstName, contact.lastName].filter((s) => s).join(" ");
  if (contact.title) return contact.title;
  if (index) return `Phone Contact ${index + 1}`;
  return supplierName;
}

/**
 * Combine contacts with the same name into a single contact with
 * a list of phone numbers in `duplicatePhoneIds`.
 */
export function useDedupedSupplierContacts(
  supplierName: string,
  contacts?: SupplierContact[]
): DedupedSupplierContact[] {
  return useMemo(() => {
    if (!contacts) return [];

    const dedupedContacts: DedupedSupplierContact[] = [];
    const groupedContacts: Record<string, SupplierContact[]> = contacts.reduce(
      (acc: Record<string, SupplierContact[]>, contact, index) => {
        contact.lastName = contact.lastName || "";
        contact.firstName = contact.firstName || "";
        contact.title = contact.title || "";

        const key = getContactDisplayName(
          contact,
          supplierName,
          index
        ).toLowerCase();
        acc[key] = acc[key] || [];
        acc[key].push(contact);
        return acc;
      },
      {}
    );
    for (const [_, contacts] of Object.entries(groupedContacts)) {
      dedupedContacts.push({
        ...contacts[0],
        duplicatePhoneIds: contacts.map(
          (contact: SupplierContact) => contact.id
        ),
      });
    }

    return dedupedContacts;
  }, [contacts, supplierName]);
}

export function groupContactsByTitle(
  contacts: DedupedSupplierContact[]
): Record<string, DedupedSupplierContact[]> {
  return _groupBy(
    contacts.filter(({ title }) => title),
    "title"
  );
}
