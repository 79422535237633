import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import type { TrackClickWithinSupplierCarouselFn } from "../../components/SolicitationPage/types";
import { pageIdState, pageTypeState } from "../../recoil/page";
import { userInitializedState, userZipState } from "../../recoil/user";
import { formatLocal } from "../../utils/format";

import {
  ApiService,
  type RecommendSupplierToSupplierRequest,
  type SupplierCardType,
} from "../../generated";
import { handleError } from "../../utils/generatedApi";
import SupplierCarousel from "./SupplierCarousel";

export enum RecommendationTypes {
  supplierToSupplier = "supplier_to_supplier",
}
interface RecommendationCarouselProps {
  type: RecommendationTypes;
  id: string;
  onRender: (suppliers: SupplierCardType[]) => void;
  onClick: TrackClickWithinSupplierCarouselFn;
  className?: string;
}

export default function RecommendationCarousel({
  type,
  id,
  onRender,
  onClick,
  className,
}: RecommendationCarouselProps): JSX.Element | null {
  const userZip = useRecoilValue(userZipState);
  const pageType = useRecoilValue(pageTypeState);
  const pageId = useRecoilValue(pageIdState);
  const isInitialized = useRecoilValue(userInitializedState);
  const [recommendations, setRecommendations] = useState<SupplierCardType[]>(
    []
  );
  const [geo, setGeo] = useState<Maybe<string>>(null);

  const recommendedSuppliersTitle = geo
    ? `More suppliers for ${geo}`
    : "More suppliers";

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once after the user has been initialized.
  useEffect(() => {
    if (!isInitialized) return;
    const body: RecommendSupplierToSupplierRequest = { id, zip: null };
    if (userZip) body.zip = userZip;

    (async () => {
      try {
        const response =
          await ApiService.apiV1RecommendationsSupplierCreate(body);
        if (response.queryLocation) {
          const { state, city, zip } = response.queryLocation;
          if (state || city) {
            setGeo(formatLocal({ state, city, zip }));
          }
        }
        setRecommendations(response.suppliers);
      } catch (err) {
        handleError(err);
      }
    })();
  }, [isInitialized]);
  if (!recommendations.length) {
    return null;
  }
  switch (type) {
    case RecommendationTypes.supplierToSupplier:
      return (
        <div className={className}>
          <SupplierCarousel
            suppliers={recommendations}
            title={recommendedSuppliersTitle}
            sourceData={{ pageType, pageId }}
            onRender={onRender}
            onClick={onClick}
          />
        </div>
      );
  }
}
