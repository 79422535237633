import clsx from "clsx";
import type { MouseEvent } from "react";

import useLoginWall from "../../hooks/useLoginWall";
import { Badge, Button, Checkbox, Typography } from "../../library";
import { type ButtonProps, TextButton } from "../../library/Button";
import { textColorClass } from "../../utils/colors";
import type { LoginWallTriggers } from "../../utils/enums";

import { type CTA, CTAType } from "./types";

export const STYLINGS: Record<
  string,
  { className: string; props: ButtonProps }
> = {
  primary: {
    className: "",
    props: { size: Button.sizes.SMALL },
  },
  secondary: {
    className: "",
    props: {
      size: Button.sizes.SMALL,
      theme: Button.themes.SECONDARY_DARK,
    },
  },
  tertiary: {
    className: "",
    props: {
      size: Button.sizes.SMALL,
      theme: Button.themes.TERTIARY_DARK,
    },
  },
  success: {
    className: textColorClass.success.default.primary.enabled,
    props: {},
  },
  warning: {
    className: textColorClass.warning.default,
    props: {},
  },
  destructive: {
    className: textColorClass.destructive.default.primary.enabled,
    props: {},
  },
  defaultIcon: {
    className: "text-cp-lapis-500 hover:text-cp-midnight-100",
    props: {},
  },
};

export default function ContractCTASection({
  loginWallTrigger,
  loginWallTriggerId,
  ctas = [],
}: ContractCTASectionProps) {
  const requireLogin = useLoginWall();

  // Trigger the login wall if necessary/desirable.
  const onCTAClick = (
    onClick: (e: unknown) => void,
    e: unknown,
    trackSerpClick?: () => void
  ) => {
    // Prevent events bubbling up to the containing card.
    (e as Event).stopPropagation();
    trackSerpClick?.();

    if (!loginWallTrigger || !loginWallTriggerId) {
      onClick(e);
      return;
    }
    void requireLogin({
      triggerId: loginWallTriggerId,
      onComplete: onClick,
      triggerType: loginWallTrigger,
      title: undefined,
      subtitle: undefined,
    });
  };

  return (
    <div className="justify-center sm:justify-end flex flex-row gap-x-4 items-start mx-auto">
      {ctas.map((cta: CTA, ix) => {
        if (cta.type === CTAType.CHECKBOX) {
          return (
            <Checkbox
              // biome-ignore lint/suspicious/noArrayIndexKey: Allow indexes for static ctas
              key={ix}
              checked={cta.selected || false}
              onChange={cta.onClick}
              name={cta.name || ""}
            />
          );
        }
        if (!cta.Icon) {
          return (
            <TextButton
              // biome-ignore lint/suspicious/noArrayIndexKey: Allow indexes for static ctas
              key={ix}
              textProps={{
                emphasis: true,
                variant: "cta",
                size: "sm",
                color: "inherit",
              }}
              className={clsx(
                "whitespace-nowrap w-full sm:w-fit",
                cta.ctaAnalyticsClass,
                STYLINGS[cta.styling].className
              )}
              {...STYLINGS[cta.styling].props}
              onClick={(e?: MouseEvent) =>
                onCTAClick(cta.onClick, e, cta.trackSerpClick)
              }
            >
              {cta.text}
            </TextButton>
          );
        }
        return (
          <Badge
            className={clsx(
              "w-fit my-auto",
              cta.ctaAnalyticsClass,
              STYLINGS[cta.styling].className
            )}
            // biome-ignore lint/suspicious/noArrayIndexKey: Allow indexes for static ctas
            key={ix}
            size="lg"
            Icon={cta.Icon}
            iconClass={STYLINGS[cta.styling].className}
            onClick={(e) => onCTAClick(cta.onClick, e, cta.trackSerpClick)}
          >
            {cta.text ? (
              <Typography
                variant="cta"
                emphasis
                color="brand.default.primary.enabled"
                size="sm"
              >
                {cta.text}
              </Typography>
            ) : null}
          </Badge>
        );
      })}
    </div>
  );
}

interface ContractCTASectionProps {
  loginWallTriggerId?: string;
  loginWallTrigger?: LoginWallTriggers;
  ctas?: CTA[];
}
