import type { SupplierPublicEntityServed } from "../../generated";
import { Typography } from "../../library";

const MAX_ENTITIES = 2;

export default function SimilarPublicEntities({
  entities,
  className,
}: {
  entities: SupplierPublicEntityServed[];
  className?: string;
}) {
  const text = entities
    .slice(0, MAX_ENTITIES)
    .map(({ name }) => name)
    .join("; ");
  const extra =
    entities.length > MAX_ENTITIES
      ? ` and ${entities.length - MAX_ENTITIES} more`
      : "";

  return (
    <Typography color="neutral.bolder.enabled" className={className}>
      Similar public entities served:{" "}
      <Typography component="span" emphasis>
        {text}
      </Typography>
      {extra}
    </Typography>
  );
}
