import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/utils";
import clsx from "clsx";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { tooltipState, useRemoveActiveTooltip } from "../../recoil/page";
import {
  type IUserStateState,
  userInitializedState,
  userStateState,
} from "../../recoil/user";
import { patchUserState } from "../../utils/api";
import {
  trackDismissFeatureTooltip,
  trackShowFeatureTooltip,
} from "../../utils/tracking";
import { ArrowPosition, FloatingTooltip } from "./FloatingTooltip";

// IMPORTANT: When removing a FeatureHighlightTooltip instance,
// delete the corresponding key from visibilityOrder
export enum VisibilityPriority {
  otherSuppliers = 0,
  contactSuppliers = 1,
  supplierHasVerifiedContact = 2,
}

const visibilityOrder: (keyof typeof VisibilityPriority)[] = Object.values(
  VisibilityPriority
)
  // Object.values will return all of the enum key and values,
  // but we only want the string keys
  .filter((key) => typeof key === "string")
  // casting is necessary since Object.values returns string[]
  .map((key) => key as keyof typeof VisibilityPriority);

export interface FeatureHighlightTooltipProps {
  children: ReactNode;
  tooltipKey: keyof typeof VisibilityPriority;
  userStateKey?: keyof IUserStateState;
  title: string;
  body: string;
  className?: string;
  inline?: boolean;
  tagText: string;
  arrowPosition?: ArrowPosition;
  placement?: Placement;
  onClose?: () => void;
}

export default function FeatureHighlightTooltip({
  children,
  tooltipKey,
  userStateKey,
  title,
  body,
  className,
  inline = false,
  tagText,
  arrowPosition = ArrowPosition.CENTER,
  placement = "bottom-end",
  onClose,
}: FeatureHighlightTooltipProps) {
  // Determine visibility based on:
  // - visibilityOrder, which ensures only one tooltip is shown at a time
  // - userState, which stores whether a user has dismissed a tooltip
  const isInitialized = useRecoilValue(userInitializedState);
  const activeTooltips = useRecoilValue(tooltipState);
  const [showTooltip, setShowTooltip] = useState(false);
  const [userState, setUserState] = useRecoilState(userStateState);
  const removeActiveTooltip = useRemoveActiveTooltip();

  if (visibilityOrder.indexOf(tooltipKey) === -1) {
    console.error(
      "Expected `tooltipKey` prop to be in `visibilityOrder` in js/library/FeatureHighlight/index.tsx."
    );
    return <></>;
  }

  useEffect(() => {
    if (!isInitialized) return;

    const visibleTooltipIndices = visibilityOrder.map(
      (key) =>
        activeTooltips.includes(key) &&
        (!userStateKey ||
          userState[userStateKey as keyof IUserStateState] === false)
    );

    // Returns -1 when there are no tooltips to show, otherwise
    // returns index of the first undismissed tooltip in `visibilityOrder`.
    const firstVisibleTooltipIndex = visibleTooltipIndices.findIndex(
      (i) => !!i
    );
    const tooltipIndex = VisibilityPriority[tooltipKey];
    if (tooltipIndex === firstVisibleTooltipIndex) {
      if (!showTooltip) {
        // Only track show if the tooltip is not already shown
        trackShowFeatureTooltip({ tooltipKey });
      }
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [
    tooltipKey,
    userStateKey,
    activeTooltips,
    isInitialized,
    userState,
    showTooltip,
  ]);

  const arrowRef = useRef<SVGSVGElement>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(20),
      shift({
        mainAxis: false,
      }),
      flip(),
      arrow({ element: arrowRef }),
    ],
  });

  return (
    <div
      className={clsx({
        "inline-flex": inline,
        flex: !inline,
      })}
    >
      <div className="relative flex-1">
        <div className="flex" ref={refs.setReference}>
          {children}
        </div>
        {showTooltip && (
          <FloatingTooltip
            ref={refs.setFloating}
            arrowRef={arrowRef}
            style={floatingStyles}
            context={context}
            title={title}
            body={body}
            className={className}
            tagText={tagText}
            onClose={() => {
              if (userStateKey) {
                patchUserState({ [userStateKey]: true });
                setUserState((prev) => ({
                  ...prev,
                  [userStateKey]: true,
                }));
              }
              trackDismissFeatureTooltip({ tooltipKey });
              removeActiveTooltip(tooltipKey);
              onClose?.();
            }}
            arrowPosition={arrowPosition}
          />
        )}
      </div>
    </div>
  );
}
