import { useSetRecoilState } from "recoil";
import type { VagueQuerySuggestion } from "../generated";
import type { SearchContractsParams } from "../hooks/useSearchContracts";
import { Button, ButtonSizes, ButtonThemes } from "../library";
import { originalAmbiguousQueryState } from "../recoil/search";
import Modal, { modalSizes } from "../shared/Modal/Modal";
import { getSentenceCase } from "../utils";
import type { SearchIntent } from "../utils/enums";
import { trackDisambiguationModalClick } from "../utils/tracking";
export default function SearchDisambiguationModal({
  hideModal,
  originalQuery,
  searchOptions,
  searchIntent,
  isLocationRelevant,
  onSearchContracts,
}: {
  hideModal: () => void;
  originalQuery: string;
  searchOptions: VagueQuerySuggestion[];
  searchIntent: SearchIntent;
  isLocationRelevant?: boolean;
  searchInNewTab?: boolean;
  isEntityContractsSearch?: boolean;
  onSearchContracts: (params: SearchContractsParams) => Promise<void>;
}) {
  const setOriginalAmbiguousQueryState = useSetRecoilState(
    originalAmbiguousQueryState
  );

  async function setAndSubmitSearch(newQuery: string, selectedOption: string) {
    trackDisambiguationModalClick({
      originalQuery,
      selectedOption,
      newQuery: newQuery,
    });

    setOriginalAmbiguousQueryState(originalQuery);
    await onSearchContracts({
      query: newQuery,
      selectedSearchIntent: searchIntent,
      isLocationRelevant,
      originalAmbiguousQuery: originalQuery,
    });
    hideModal();
  }

  function sendOriginalSearchAndClose() {
    setAndSubmitSearch(originalQuery, originalQuery);
    hideModal();
  }

  return (
    <Modal
      hideModal={sendOriginalSearchAndClose}
      className="analytics-disambiguation-modal"
      title={`What kind of ${originalQuery} are you looking for?`}
      titleSize="sm"
      modalSize={modalSizes.SMALL}
      paddingBottom="5"
    >
      <div className="w-full space-y-3">
        {searchOptions.map((option) => {
          return (
            <Button
              key={option.query}
              className="analytics-disambiguation-modal-option w-full text-center"
              theme={ButtonThemes.TERTIARY_DARK}
              size={ButtonSizes.SMALL}
              onClick={() =>
                setAndSubmitSearch(
                  option.redirectQuery || option.query,
                  option.query
                )
              }
            >
              {getSentenceCase(option.query)}
            </Button>
          );
        })}
      </div>
      <div className="w-full h-px my-3 bg-neutral-200" />
      <Button
        className="analytics-disambiguation-modal-continue w-full"
        theme={ButtonThemes.TERTIARY_DARK}
        size={ButtonSizes.SMALL}
        onClick={() => setAndSubmitSearch(originalQuery, originalQuery)}
      >
        Continue to search for "{originalQuery}"
      </Button>
    </Modal>
  );
}
