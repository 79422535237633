/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `CITY` - City
 * * `COUNTY` - County
 * * `CITY_AND_COUNTY` - City and County
 * * `SCHOOL_OR_SCHOOL_DISTRICT` - School or School District
 * * `UNIVERSITY` - University
 * * `COMMUNITY_COLLEGE` - Community College
 * * `CHARTER_SCHOOL` - Charter School
 * * `HOSPITAL_OR_PUBLIC_HEALTH` - Hospital or Public Health
 * * `AIRPORT` - Airport
 * * `WATER_DISTRICT` - Water District
 * * `FIRE_DISTRICT` - Fire District
 * * `UTILITY` - Utility
 * * `TRANSIT_AGENCY` - Transit Agency
 * * `NON_PROFIT` - Non-profit
 * * `PORT` - Port
 * * `COURT` - Court
 * * `SPECIAL_DISTRICT` - Special District
 * * `REGIONAL_ENTITY` - Regional Entity
 * * `REGIONAL_EDUCATIONAL_ENTITY` - Regional Educational Entity
 * * `STATE` - State
 * * `REGIONAL_COOPERATIVE` - Regional Cooperative
 * * `NATIONAL_COOPERATIVE` - National Cooperative
 * * `FEDERAL_AGENCY` - Federal Agency
 * * `OTHER` - Other
 */
export enum AgencyTypeEnum {
    CITY = 'CITY',
    COUNTY = 'COUNTY',
    CITY_AND_COUNTY = 'CITY_AND_COUNTY',
    SCHOOL_OR_SCHOOL_DISTRICT = 'SCHOOL_OR_SCHOOL_DISTRICT',
    UNIVERSITY = 'UNIVERSITY',
    COMMUNITY_COLLEGE = 'COMMUNITY_COLLEGE',
    CHARTER_SCHOOL = 'CHARTER_SCHOOL',
    HOSPITAL_OR_PUBLIC_HEALTH = 'HOSPITAL_OR_PUBLIC_HEALTH',
    AIRPORT = 'AIRPORT',
    WATER_DISTRICT = 'WATER_DISTRICT',
    FIRE_DISTRICT = 'FIRE_DISTRICT',
    UTILITY = 'UTILITY',
    TRANSIT_AGENCY = 'TRANSIT_AGENCY',
    NON_PROFIT = 'NON_PROFIT',
    PORT = 'PORT',
    COURT = 'COURT',
    SPECIAL_DISTRICT = 'SPECIAL_DISTRICT',
    REGIONAL_ENTITY = 'REGIONAL_ENTITY',
    REGIONAL_EDUCATIONAL_ENTITY = 'REGIONAL_EDUCATIONAL_ENTITY',
    STATE = 'STATE',
    REGIONAL_COOPERATIVE = 'REGIONAL_COOPERATIVE',
    NATIONAL_COOPERATIVE = 'NATIONAL_COOPERATIVE',
    FEDERAL_AGENCY = 'FEDERAL_AGENCY',
    OTHER = 'OTHER',
}
