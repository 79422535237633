import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

import useRequestID from "../../../hooks/useRequestID";
import {
  Badge,
  Button,
  ButtonThemes,
  ClampedText,
  Link,
  Typography,
} from "../../../library";
import { getParam } from "../../../utils";
import {
  LoginWallTriggers,
  pageNavigationSourceTypes,
} from "../../../utils/enums";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { getSupplierUrlPath } from "../../../utils/format";
import {
  ClickSource,
  trackViewSupplierCatalog,
  trackViewSupplierFromSolicitation,
  trackViewSupplierWebsite,
} from "../../../utils/tracking";

import { AboutSupplierSizes } from ".";
import useLoginWall from "../../../hooks/useLoginWall";

export default function SupplierAboutSummary({
  id,
  about,
  aliases,
  website,
  handle,
  addressCity,
  addressStateCode,
  catalog,
  activeAgreements,
  size = AboutSupplierSizes.LARGE,
  contractId,
}: {
  id: number;
  about: Maybe<string>;
  aliases?: string[];
  website: Maybe<string>;
  handle: Maybe<string>;
  addressCity: Maybe<string>;
  addressStateCode: Maybe<string>;
  catalog: Maybe<string>;
  activeAgreements: string[];
  size?: AboutSupplierSizes;
  contractId: Maybe<string>;
}) {
  const requestID = useRequestID();
  const requireLogin = useLoginWall();

  // Rendering logic for supplier links
  // COMPACT: show supplier profile
  // LARGE: if enrolled and catalog exists, show supplier catalog, else show website
  const isCompact = size === AboutSupplierSizes.COMPACT;
  const showSupplierProfile = handle && isCompact;
  const showCatalog =
    !isCompact &&
    supplierHasFeature(activeAgreements, "supplierCatalog") &&
    catalog;
  const showWebsite = !isCompact && !showCatalog && website;
  const onSupplierWebsiteClick = () => {
    trackViewSupplierWebsite({
      destinationUrl: website,
      supplierId: id,
      supplierHandle: handle,
      requestId: requestID,
    });
  };
  const onSupplierCatalogClick = () => {
    trackViewSupplierCatalog({
      destinationUrl: catalog,
      supplierId: id,
      supplierHandle: handle,
      requestId: requestID,
    });
  };
  if (
    !about &&
    !aliases?.length &&
    !addressCity &&
    !addressStateCode &&
    !showWebsite &&
    !showCatalog &&
    !showSupplierProfile
  ) {
    return null;
  }

  function getAliasText(aliases: string[]) {
    if (!aliases?.length) {
      return null;
    }
    if (aliases.length === 1) {
      return `Also known as ${aliases[0]}`;
    }
    if (aliases.length === 2) {
      return `Also known as ${aliases[0]} and ${aliases[1]}`;
    }
    if (aliases.length === 3) {
      return `Also known as ${aliases[0]}, ${aliases[1]}, and ${aliases[2]}`;
    }
    return `Also known as ${aliases[0]}, ${aliases[1]}, ${aliases[2]} and ${
      aliases.length - 3
    } more`;
  }

  function handleSupplierClick() {
    void requireLogin({
      triggerId: contractId || "",
      triggerType: LoginWallTriggers.SOLICITATION_PAGE_VIEW_SUPPLIER_CLICK,
      onComplete: () => {
        const path = getSupplierUrlPath(
          handle || "",
          getParam("query", undefined),
          null,
          pageNavigationSourceTypes.SOLICITATION_PAGE,
          requestID
        ).toString();
        window.open(path, "_blank");
        // This should only be called when the link is clicked from a
        // solicitation (i.e. when the size is COMPACT).
        trackViewSupplierFromSolicitation({
          contractId,
          supplierId: id,
          supplierHandle: handle || "",
          clickSource: ClickSource.ABOUT_SUPPLIER,
        });
      },
    });
  }

  return (
    <div className="grid gap-4">
      {(addressCity || addressStateCode) && (
        <Badge
          Icon={PlaceOutlinedIcon}
          size="md"
          iconClass="text-cp-black-100"
          as={Typography}
          linkProps={{ color: "neutral.default.primary.enabled" }}
        >
          {[addressCity, addressStateCode].filter(Boolean).join(", ")}
        </Badge>
      )}
      {about && (
        <div className="grid gap-4">
          {isCompact ? (
            <ClampedText className="whitespace-pre-line">{about}</ClampedText>
          ) : (
            <Typography
              color="neutral.default.primary.enabled"
              className="whitespace-pre-line"
            >
              {about}
            </Typography>
          )}
        </div>
      )}
      {!!aliases?.length && (
        <Typography color="neutral.default.primary.enabled">
          {getAliasText(aliases || [])}
        </Typography>
      )}
      {showSupplierProfile && (
        <Button
          as={Badge}
          theme={ButtonThemes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={handleSupplierClick}
          className="w-fit analytics-other-contracts-from-supplier"
        >
          View other contracts from supplier
        </Button>
      )}
      {showCatalog && (
        <Badge
          as={Link}
          linkProps={{ href: catalog, underline: false }}
          Icon={LaunchRoundedIcon}
          onClick={onSupplierCatalogClick}
          className="w-fit"
        >
          Visit supplier catalog
        </Badge>
      )}
      {showWebsite && (
        <Badge
          as={Link}
          linkProps={{ href: website, underline: false }}
          onClick={onSupplierWebsiteClick}
          Icon={LaunchRoundedIcon}
          className="w-fit"
        >
          Visit website
        </Badge>
      )}
    </div>
  );
}
