import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import useCookie from "../../hooks/useCookie";
import InitialSignupForm from "../../modals/SignupSteps/InitialSignupForm";
import { userTypeTabData } from "../../modals/SignupSteps/constants";
import {
  type AccountModalKeys,
  WindowType,
} from "../../modals/SignupSteps/types";
import AccountPage from "../../pages/Account";
import { userDetailsState, userTypeSignupState } from "../../recoil/user";
import { getParams } from "../../utils";
import {
  COMPANY_NAME,
  SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
} from "../../utils/constants";
import { accountModals, modalTriggerURLParams } from "../../utils/enums";
import type { SocialProvider } from "../../utils/social";
import {
  trackAccountAccessToggle,
  trackSignupLoginExit,
} from "../../utils/tracking";

import type { ExistingUser } from "../../generated";
import { educationalData } from "./constants";
import { goToHomePage, goToLoginPage, redirectOnComplete } from "./helpers";

export default function InitialSignupPage() {
  const urlParams = getParams();
  const [userType, setUserType] = useRecoilState(userTypeSignupState);
  const { email } = useRecoilValue(userDetailsState);
  const [, setSocialLoginSource] = useCookie<SocialProvider | string>(
    SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
    ""
  );

  const invite = urlParams[modalTriggerURLParams.INVITE];
  const inviter = urlParams.inviter;
  const title =
    invite && inviter
      ? `Join ${inviter} on ${COMPANY_NAME}`
      : educationalData[userType].title;
  const educationalInfo = educationalData[userType].educationalInfo;
  const isSupplier = userType === educationalData.supplier.type;

  // set initial user type via url params
  const supplierSignUpParam = urlParams.supplierSignup;
  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once on mount.
  useEffect(() => {
    if (supplierSignUpParam === "true") {
      setUserType(userTypeTabData.supplier.type);
    }
  }, []);

  const checkUserAndGoToNextPage = async (data: ExistingUser) => {
    if (data.existingUser) {
      setSocialLoginSource(data.socialAccountProvider || email || "");
      window.open("/accounts/login", "_parent");
    } else if (isSupplier) {
      window.open("/accounts/signup-supplier", "_parent");
    } else {
      // Pass on BLA params
      let url = "/accounts/signup";
      if (invite && urlParams.blaId && urlParams.blaName) {
        url += `?invite=true&blaId=${urlParams.blaId}&blaName=${urlParams.blaName}`;
      }
      window.open(url, "_parent");
    }
  };

  function closePage() {
    trackSignupLoginExit({
      accountType: userType,
      emailEntered: email as string,
      loginStage: accountModals.INITIAL_SIGN_UP as AccountModalKeys,
      loginExperience: WindowType.Page,
    });
    goToHomePage();
  }

  function redirectToLoginPage() {
    trackAccountAccessToggle({
      toggle: "log in",
      loginExperience: WindowType.Page,
    });
    goToLoginPage();
  }

  return (
    <AccountPage title={title} info={educationalInfo} closePage={closePage}>
      <InitialSignupForm
        goToLoginPageOrModal={redirectToLoginPage}
        checkUserAndSetNextModalOrPage={checkUserAndGoToNextPage}
        onComplete={redirectOnComplete}
        parentWindow={WindowType.Page}
      />
    </AccountPage>
  );
}
