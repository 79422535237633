import clsx from "clsx";

import { Bullets, Typography } from "../../../library";
import type { OCRFile } from "../../../shared/types";
import { borderColorClass } from "../../../utils/colors";
import { isFeatureEnabled } from "../../../utils/split";
import { FileItem, type HandleClickBookmarkOptions } from "./FileItem";
import { HiddenFileItem } from "./HiddenFileItem";

interface FileGroupProps {
  analyticsClassName?: string;
  contractNumber?: string;
  currentFileIndex: number;
  files: OCRFile[];
  header: string;
  onClick: (file: OCRFile) => void;
  onCopyLink: (file: OCRFile) => void;
  onDownload: (file: OCRFile) => void;
  handleClickBookmark: ({ file, bookmark }: HandleClickBookmarkOptions) => void;
  sectionFiles: OCRFile[];
  supplierName: string;
}

export default function FileGroup({
  analyticsClassName,
  contractNumber,
  currentFileIndex,
  files,
  handleClickBookmark,
  header,
  onClick,
  onCopyLink,
  onDownload,
  sectionFiles,
  supplierName,
}: FileGroupProps) {
  if (!sectionFiles.length) return null;

  const hasFeature = isFeatureEnabled("simplifiedContractPage");
  const itemClassName = hasFeature ? "justify-between" : undefined;

  const itemsList = sectionFiles.map((file: OCRFile) =>
    file.buyer_verification_gated ? (
      <HiddenFileItem
        key={file.id}
        file={file}
        contractNumber={contractNumber}
        supplierName={supplierName}
        className={itemClassName}
      />
    ) : (
      <>
        <FileItem
          key={file.id}
          file={file}
          contractNumber={contractNumber}
          selected={file === files[currentFileIndex]}
          handleClickBookmark={handleClickBookmark}
          onDownload={onDownload}
          onCopyLink={onCopyLink}
          onClick={onClick}
          className={itemClassName}
        />
      </>
    )
  );

  return (
    <div className={clsx("grid gap-1", analyticsClassName)}>
      <Typography
        variant="headline"
        size="xs"
        emphasis={!hasFeature}
        color="neutral.bolder.enabled"
      >
        {header}
      </Typography>
      {hasFeature && <hr className={borderColorClass.neutral.subtle.enabled} />}
      {hasFeature ? (
        itemsList
      ) : (
        <Bullets
          columns={1}
          showAllItems
          itemClassName="flex flex-col"
          itemsList={itemsList}
          capitalize={false}
        />
      )}
    </div>
  );
}
