import { Suspense, lazy } from "react";
import { useRecoilValue } from "recoil";

import { Typography } from "../../library";
import {
  allSearchesLoadedState,
  contractSearchResponseDataState,
} from "../../recoil/search";
import { isAuthenticatedState, profileTypeState } from "../../recoil/user";
import { ProfileType } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/split";
import SuggestedSearches from "../SuggestedSearches";
import { getCompetitorKeywords } from "./utils";

const QuotingServiceSidebar = lazy(() => import("./QuotingServiceSidebar"));

export default function Sidebar() {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const searchesLoaded = useRecoilValue(allSearchesLoadedState);
  const profileType = useRecoilValue(profileTypeState);
  const showQuotesSidebar =
    isFeatureEnabled("quotingService") &&
    !isFeatureEnabled("quotingServiceSerpBanner");
  isAuthenticated && profileType !== ProfileType.SUPPLIER;
  const showQuotesLoggedOutSidebar =
    isFeatureEnabled("quotingServiceAnon") && !isAuthenticated;

  const contractResponseData = useRecoilValue(contractSearchResponseDataState);
  const searchResults = contractResponseData?.contractData?.results || [];
  const competitorKeywords = getCompetitorKeywords(searchResults);
  if (!searchesLoaded) return null;

  return (
    <div className="sticky top-24 flex flex-col gap-6">
      {!!competitorKeywords.length && (
        <SuggestedSearches
          searchSource="suggested-supplier-keywords"
          searchTerms={competitorKeywords}
          header={
            <Typography
              variant="headline"
              size="xs"
              emphasis
              color="neutral.bolder.enabled"
            >
              Other people searched for
            </Typography>
          }
        />
      )}
      {(showQuotesSidebar || showQuotesLoggedOutSidebar) && (
        <div className="grid gap-4 min-w-[21rem]">
          <Suspense fallback={null}>
            <QuotingServiceSidebar />
          </Suspense>
        </div>
      )}
    </div>
  );
}
