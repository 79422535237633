import clsx from "clsx";
import _get from "lodash/get";
import type { FC } from "react";

import { type BgColor, bgColorClass } from "../../utils/colors";
import { shapeClass } from "../../utils/designTokens";
import Logo, { type LogoSizes } from "../Logo";
import Typography from "../Typography";
import type {
  TypographyColor,
  TypographySize,
  TypographyVariant,
} from "../Typography/types";

const BASE_STYLE = "justify-center items-center flex flex-none";
export enum AvatarSizes {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  MED_LG = "MED_LG",
  LARGE = "LARGE",
  XL = "XL",
}

export enum AvatarVariant {
  DEFAULT = "DEFAULT",
  CIRCLE = "CIRCLE",
  PROFILE = "PROFILE",
  BORDERED = "BORDERED",
}

const stylesBySize = {
  [AvatarSizes.SMALL]: "w-6 h-6",
  [AvatarSizes.MEDIUM]: "w-8 h-8",
  [AvatarSizes.MED_LG]: "w-10 h-10",
  [AvatarSizes.LARGE]: "w-12 h-12",
  [AvatarSizes.XL]: "w-16 h-16",
};

const stylesByVariant = {
  [AvatarVariant.CIRCLE]: shapeClass.shapeFull,
  [AvatarVariant.DEFAULT]: shapeClass.shape3,
  [AvatarVariant.PROFILE]: clsx(
    "w-9 h-9 bg-cp-lapis-100 font-semibold uppercase text-cp-meta-sm inline-flex",
    shapeClass.shapeFull
  ),
  [AvatarVariant.BORDERED]: "border border-solid rounded-4",
};

const typographyVariantBySize: Record<
  AvatarSizes,
  { typographyVariant: TypographyVariant; typographySize: TypographySize }
> = {
  [AvatarSizes.SMALL]: { typographyVariant: "meta", typographySize: "sm" },
  [AvatarSizes.MEDIUM]: { typographyVariant: "meta", typographySize: "sm" },
  [AvatarSizes.MED_LG]: {
    typographyVariant: "monogram",
    typographySize: "sm",
  },
  [AvatarSizes.LARGE]: {
    typographyVariant: "monogram",
    typographySize: "md",
  },
  [AvatarSizes.XL]: { typographyVariant: "display", typographySize: "sm" },
};

type AvatarSourceProps =
  | {
      monogram: string;
    }
  | {
      IconComponent: FC<{ size: AvatarSizes; className?: string }>;
    }
  | {
      logoImageSrc: string;
      logoSize: LogoSizes;
    };

export type AvatarProps = {
  size: AvatarSizes;
  variant?: AvatarVariant;
  className?: string;
  bgColor?: BgColor;
  textColor?: TypographyColor;
  typographyClassName?: string;
  typographyVariantProp?: TypographyVariant;
} & AvatarSourceProps;

export default function Avatar({
  size,
  className,
  bgColor,
  textColor = "brand.inverse.primary.enabled",
  variant = AvatarVariant.DEFAULT,
  typographyClassName,
  typographyVariantProp,
  ...props
}: AvatarProps) {
  const emphasis =
    size === AvatarSizes.SMALL ||
    size === AvatarSizes.MEDIUM ||
    typographyVariantProp === "meta";
  const { typographyVariant, typographySize } = typographyVariantBySize[size];
  // Swap to another font size and style if there are multiple characters

  const bgClass = _get(bgColorClass, bgColor as BgColor);

  return (
    <div
      className={clsx(
        BASE_STYLE,
        stylesBySize[size],
        stylesByVariant[variant],
        bgClass,
        "overflow-hidden",
        className
      )}
    >
      {"monogram" in props && (
        <Typography
          variant={typographyVariantProp || typographyVariant}
          emphasis={emphasis}
          color={textColor}
          size={typographySize}
          /* Typography doesn't support the size of text we need for the large avatar,
           so override */
          className={clsx(
            {
              "text-xl": size === AvatarSizes.LARGE,
            },
            typographyClassName
          )}
        >
          {props.monogram}
        </Typography>
      )}
      {"IconComponent" in props && <props.IconComponent size={size} />}
      {"logoImageSrc" in props && (
        <Logo imageSrc={props.logoImageSrc} size={props.logoSize} />
      )}
    </div>
  );
}
