import { type FC, type ReactNode, useEffect } from "react";
import { RecoilRoot } from "recoil";

import useShowPiggybackLaunchModal from "../../components/EntityContractsPage/PiggybackedContracts/useShowPiggybackLaunchModal";
import useInitializeUser from "../../hooks/useInitializeUser";
import { pageIdState, pageTypeState } from "../../recoil/page";
import { initializeSearch } from "../../recoil/search";
import {
  isAuthenticatedState,
  profileTypeState,
  supplierActiveAgreementsState,
} from "../../recoil/user";
import Header from "../../shared/Header";
import type { PageType, ProfileTypes } from "../../utils/enums";

import PurchasingGuideBanner from "../../shared/PurchasingGuideBanner";
import ModalConductor from "./ModalConductor";
import PopupConductor from "./PopupConductor";

interface PageProps {
  children?: ReactNode;
  isSSR?: boolean;
  searchSource?: string;
  showHeader?: boolean;
}

function Page({
  children,
  isSSR = false,
  searchSource,
  showHeader = true,
}: PageProps) {
  const initializeUser = useInitializeUser();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once on mount.
  useEffect(() => {
    initializeUser();
  }, []);

  useShowPiggybackLaunchModal();

  return (
    <>
      {showHeader && <Header searchSource={searchSource} isSSR={isSSR} />}
      <PurchasingGuideBanner />
      <PopupConductor />
      {children}
      <ModalConductor />
    </>
  );
}

export type AuthPageProps = {
  is_authenticated?: boolean;
  profile_type?: ProfileTypes;
  active_agreements?: Maybe<string>;
};

interface RecoilRootWrapperProps extends PageProps {
  pageType?: PageType;
  pageId?: string;
  isAuthenticated?: boolean;
  profileType: Maybe<ProfileTypes>;
  activeAgreements: Maybe<string>;
  children?: ReactNode;
}

// Smaller HOC to help migrate some page variables onto recoil.
function withRecoilRoot(
  WrappedComponent: FC<PageProps>
): FC<RecoilRootWrapperProps> {
  function RecoilRootWrapper({
    pageType,
    pageId,
    isAuthenticated,
    profileType,
    activeAgreements,
    ...props
  }: RecoilRootWrapperProps): JSX.Element {
    return (
      <RecoilRoot
        initializeState={({ set }) => {
          // TODO: initializeUserCallback still lives as a useEffect.
          //  These two patterns should be reconciled.
          set(pageTypeState, pageType);
          set(pageIdState, pageId);

          if (isAuthenticated) set(isAuthenticatedState, true);
          if (profileType) set(profileTypeState, profileType);
          if (activeAgreements)
            set(supplierActiveAgreementsState, JSON.parse(activeAgreements));
          initializeSearch({ set });
        }}
      >
        <WrappedComponent {...props} />
      </RecoilRoot>
    );
  }
  RecoilRootWrapper.displayName = `withRecoilRoot(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return RecoilRootWrapper;
}

export default withRecoilRoot(Page);
