import { redirectOnComplete } from "../../components/Account/helpers";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import Modal, { modalSizes } from "../../shared/Modal/Modal";

import CompleteSupplierAccountForm from "./CompleteSupplierAccountForm";
import { WindowType } from "./types";

interface SupplierSignupModalProps {
  hideModal: () => void;
  goBack?: () => void;
  supplierName?: string;
  handle?: string;
  isBlocking?: boolean;
}

export default function SupplierSignupModal({
  hideModal,
  supplierName = "",
  handle = "",
  isBlocking,
  goBack,
}: SupplierSignupModalProps) {
  const showSignupSurvey = useShowSignupSurvey();
  function onComplete(redirectUrl: string) {
    showSignupSurvey({ onComplete: () => redirectOnComplete(redirectUrl) });
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      goBack={goBack}
      className="analytics-signup-modal inline-flex items-center"
      // Allow scrolling on smaller screens so user can select dropdown items
      contentClassName="no-scrollbar"
      title="Welcome to Pavilion!"
      subtitle="We just need a little bit more information before we can create your account. This allows us to give you a tailored experience on Pavilion."
      modalSize={modalSizes.SMALL}
      showLogoHeader={false}
    >
      <CompleteSupplierAccountForm
        onComplete={onComplete}
        supplierName={supplierName}
        handle={handle}
        hideModal={hideModal}
        isBlocking={isBlocking}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
