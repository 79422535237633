import listify from "listify";
import { singular } from "pluralize";
import { useMemo, useRef, useState } from "react";
import type { BaseSupplier, ProductService } from "../../generated";
import { Button, Typography } from "../../library";
import BookmarkLink from "../../library/BookmarkLink";
import { ChipList } from "../../library/ChipList/ChipList";
import { matchesSupplier } from "../../recoil/products";
import type { Bookmark, OCRFile } from "../../shared/types";
import { trackScopeSummaryBookmarkClick } from "../../utils/tracking";
import { SlimProductSection } from "../ContractSearch/ProductSection";
import ConfirmedBadge from "./ConfirmedBadge";

function OfferingsSummary({
  offerings,
  summary,
  query,
}: { offerings: string[]; summary: Maybe<string>; query?: string }) {
  const ref = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const getText = () => {
    if (summary) return summary;
    if (!offerings.length) return null;

    let offeringsText = listify(offerings.slice(0, 3), {
      finalWord: offerings.length <= 3 ? "and" : "",
    });

    if (offerings.length > 3) offeringsText += ", and more";

    return `This contract offers ${offeringsText}.`;
  };

  const text = getText();
  if (!text) return null;

  return (
    <div ref={ref} className="grid gap-2 justify-items-start">
      <Typography color="neutral.boldest.enabled">{text}</Typography>
      {showDetails && (
        <ChipList parentRef={ref} keywords={offerings} query={query} />
      )}
      {!!offerings.length && (
        <Button
          className="analytis-scope-summary-offerings-toggle"
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "See less" : "See all contract offerings"}
        </Button>
      )}
    </div>
  );
}

function KeywordsSummary({
  keywords,
  supplierName,
  query,
}: { keywords: string[]; supplierName: string; query?: string }) {
  const ref = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const getText = () => {
    if (!keywords.length) return null;

    let keywordsText = listify(keywords.slice(0, 3), {
      finalWord: keywords.length <= 3 ? "and" : "",
    });

    if (keywords.length > 3) keywordsText += ", and more";

    return `${supplierName} offers ${keywordsText}.`;
  };

  const text = getText();
  if (!text) return null;

  return (
    <div ref={ref} className="grid gap-2 justify-items-start">
      <Typography color="neutral.boldest.enabled">{text}</Typography>
      {showDetails && (
        <ChipList parentRef={ref} keywords={keywords} query={query} />
      )}
      {!!keywords.length && (
        <Button
          className="analytis-scope-summary-keywords-toggle"
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "See less" : "See all supplier offerings"}
        </Button>
      )}
    </div>
  );
}

export interface FileBookmark {
  fileWithBookmark: OCRFile;
  bookmark: Bookmark;
}

interface SimplifiedScopeSummaryProps {
  scrollToViewer: () => void;
  handleProductListClick: () => void;
  handleDocumentsClick: () => void;
  handleBookmarkClick: (fileBookmark: FileBookmark) => void;
  fileBookmark: FileBookmark | null;
  showProductList?: boolean;
  supplier: BaseSupplier;
  offerings: string[];
  summary: Maybe<string>;
  products: ProductService[];
  keywords: string[];
  query?: string;
}

export default function SimplifiedScopeSummary({
  showProductList,
  handleProductListClick,
  handleDocumentsClick,
  handleBookmarkClick,
  fileBookmark,
  supplier,
  offerings,
  summary,
  products,
  keywords,
  query,
}: SimplifiedScopeSummaryProps) {
  const bookmarkType =
    fileBookmark?.bookmark.bookmark_type || "contract documents";
  const showProductSummary =
    showProductList &&
    query &&
    !matchesSupplier(query, supplier.handle, supplier.displayName);

  const hasConfirmedMatch = useMemo(() => {
    if (!query || query.length <= 1) return false;
    return [
      ...products.map(({ itemName }) => itemName),
      ...keywords,
      ...offerings,
    ].some((item) => item.toLowerCase().includes(singular(query)));
  }, [query, keywords, offerings, products]);

  return (
    <div className="grid gap-4 h-fit">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="brand.boldest.enabled"
          >
            Scope summary
          </Typography>
          {hasConfirmedMatch && <ConfirmedBadge>Confirmed</ConfirmedBadge>}
        </div>
        <div className="flex flex-row gap-4">
          {showProductList && (
            <BookmarkLink
              onClick={() => {
                trackScopeSummaryBookmarkClick({
                  bookmarkType: "supplier products",
                });
                handleProductListClick();
              }}
              label="Jump to products"
              background
            />
          )}
          <BookmarkLink
            onClick={() => {
              trackScopeSummaryBookmarkClick({
                bookmarkType,
              });
              if (fileBookmark) handleBookmarkClick(fileBookmark);
              else handleDocumentsClick();
            }}
            label={`Jump to ${fileBookmark ? bookmarkType.toLowerCase() : "docs"}`}
            background
          />
        </div>
      </div>
      <OfferingsSummary offerings={offerings} summary={summary} query={query} />
      <KeywordsSummary
        keywords={keywords}
        supplierName={supplier.displayName}
        query={query}
      />
      {showProductSummary && <SlimProductSection products={products} />}
    </div>
  );
}
