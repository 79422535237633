import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useRecoilValue } from "recoil";

import { Badge, Link, Tooltip } from "../../../library";
import { intermediatedEmailState } from "../../../recoil/messageSupplier";
import { buyerProfileState, userInitializedState } from "../../../recoil/user";
import { getParam, getRequestID } from "../../../utils";
import { textColorClass } from "../../../utils/colors";
import { isFeatureEnabled } from "../../../utils/split";
import { trackIntermediatedEmailClick } from "../../../utils/tracking";
import { getPrefillText } from "../../MessageSupplierPage/helpers";

export default function SupplierContactEmail({
  supplierName,
  supplierHandle,
  contractId,
  solicitationId,
}: {
  supplierName: string;
  supplierHandle: string;
  contractId?: string;
  solicitationId?: Maybe<string>;
}) {
  const buyerProfile = useRecoilValue(buyerProfileState);
  const isInitialized = useRecoilValue(userInitializedState);
  const { email, loading } = useRecoilValue(intermediatedEmailState);
  const hasFeture = isFeatureEnabled("supplierIntermediatedEmail");
  if (!hasFeture) return null;

  if (loading || !isInitialized) {
    return (
      <div className="rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-5 w-full" />
    );
  }

  const subject = encodeURIComponent(
    `Time-sensitive purchase request from ${buyerProfile.governmentAffiliationDisplayName}`
  );
  const body = encodeURIComponent(
    `${getPrefillText({ isRecommendationPage: false })}\n`
  );
  const href = `mailto:${supplierName}<${email}>?subject=${subject}&body=${body}`;
  const [local, domain] = email.split("@");
  return (
    <Tooltip
      info={`This email is for the government sales team at ${supplierName}`}
    >
      <div className="flex flex-row gap-1">
        <Badge
          as={Link}
          Icon={ErrorOutlineRoundedIcon}
          reverse
          size="sm-md"
          className="whitespace-nowrap analytics-supplier-email-alias hover:underline"
          iconClass={textColorClass.neutral.bold.enabled}
          linkProps={{
            size: "sm",
            underline: false,
            emphasis: false,
            href,
          }}
          onClick={() =>
            trackIntermediatedEmailClick({
              contractId,
              solicitationId,
              supplierHandle,
              requestID: getRequestID(),
              searchQuery: getParam("query"),
            })
          }
        >
          {local}
          <wbr />@{domain}
        </Badge>
      </div>
    </Tooltip>
  );
}
