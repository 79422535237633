import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

const DEFAULT_GOV_AFFILIATION =
  "Strategic Alliance for Volume Expenditures (S.A.V.E.)";
const DEFAULT_SOURCE_ENTITY_ID = "9de0db3b-4198-413d-bf11-153f7e41db1c";
const DEFAULT_GOV_ZIP = "85007";

function SAVEEmbed() {
  // all param fields are optional
  const { entity, zip } = getParams();

  function getCustomizedUrl(url) {
    url.searchParams.set(
      "governmentAffiliation",
      entity || DEFAULT_GOV_AFFILIATION
    );

    url.searchParams.set("zip", zip || DEFAULT_GOV_ZIP);

    url.searchParams.set("embedSourceEntityId", DEFAULT_SOURCE_ENTITY_ID);

    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Search for Shareable Contracts"
        subtitle="Search through S.A.V.E. contracts and tens of thousands of competitively solicited shareable contracts available to use now."
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          searchSource={agencySearchSource[agencies.SAVE_AZ]}
          getCustomizedUrl={getCustomizedUrl}
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-save-az");
if (container) {
  const root = createRoot(container);
  root.render(
    <RecoilRoot>
      <SAVEEmbed />
    </RecoilRoot>
  );
}
