import type { ReactNode } from "react";

import _get from "lodash/get";
import { Typography } from "../../../library";

import clsx from "clsx";
import { useRecoilValue } from "recoil";
import { searchResultTypeState } from "../../../recoil/search";
import { bgColorClass } from "../../../utils/colors";

interface CompetitorContainerProps {
  children: ReactNode | ReactNode[];
}

export default function CompetitorContainer({
  children,
}: CompetitorContainerProps) {
  const searchResultType = useRecoilValue(searchResultTypeState);
  return (
    <div
      className={clsx(
        _get(bgColorClass, "brand.subtlest.enabled"),
        "p-6 space-y-6 rounded-3xl"
      )}
    >
      <div className="space-y-2">
        <Typography
          variant="headline"
          color="brand.boldest.enabled"
          size="xs"
          emphasis
        >
          {searchResultType === "supplier"
            ? "Results from similar suppliers"
            : "Contracts from similar suppliers"}
        </Typography>
        <Typography color="neutral.bolder.enabled" size="sm">
          These suppliers have similar offerings, verified contact information,
          and updated contract documents ready for you to review.
        </Typography>
      </div>
      {children}
    </div>
  );
}
