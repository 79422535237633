import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useSetRecoilState } from "recoil";

import { Typography } from "../../../../../library";
import type { TypographyColor } from "../../../../../library/Typography/types";
import { modalState } from "../../../../../recoil/page";
import { modals } from "../../../../../utils/enums";
import { formatAddressString } from "../../../../../utils/format";
import { AvatarCard, type AvatarMenuItem } from "../../../AvatarCard";
import type { SupplierLocation } from "../../types";

interface SupplierLocationContainerProps {
  location: SupplierLocation;
  onDeleteLocation: (id: number) => void;
  handle: string;
  onLocationUpsert: (c: SupplierLocation) => void;
}

export default function SupplierLocationContainer({
  location,
  onDeleteLocation,
  handle,
  onLocationUpsert,
}: SupplierLocationContainerProps) {
  const setCurrentModal = useSetRecoilState(modalState);

  const showEditModal = () => {
    setCurrentModal({
      name: modals.ADD_LOCATION,
      initialLocation: location,
      handle,
      id: location?.id,
      onUpsert: onLocationUpsert,
    });
  };

  // To be updated in follow-up ticket
  const deleteLocation = () => {
    if (location?.id) {
      onDeleteLocation(location.id);
    }
  };

  const menuItems: AvatarMenuItem[] = [
    {
      onClick: showEditModal,
      text: "Edit location",
    },
    {
      onClick: deleteLocation,
      color: "destructive.default.primary.enabled" as TypographyColor,
      text: "Delete location",
    },
  ];
  const addressString = location.address
    ? formatAddressString(location.address)
    : "";
  return (
    <AvatarCard
      name={location.locationName || "—"}
      Icon={PlaceOutlinedIcon}
      avatarBgColor="palettes.leaf.10"
      avatarTextColor="accent.default.secondary.enabled"
      menuItems={menuItems}
    >
      <div className="mt-1">
        <Typography color="neutral.bolder.enabled" variant="body">
          {addressString}
        </Typography>
      </div>
    </AvatarCard>
  );
}
