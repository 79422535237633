/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `COMPETITORS_UPDATE` - Competitors Update
 * * `PRODUCT_LIST_UPLOAD` - Product List Upload
 * * `PRODUCT_LIST_DELETE` - Product List Delete
 */
export enum SupplierEditRequestTypeEnum {
    COMPETITORS_UPDATE = 'COMPETITORS_UPDATE',
    PRODUCT_LIST_UPLOAD = 'PRODUCT_LIST_UPLOAD',
    PRODUCT_LIST_DELETE = 'PRODUCT_LIST_DELETE',
}
