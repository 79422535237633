import cloudFiles from "../../../img/icons/contracts-cloud.svg";
import { Bullets, Typography } from "../../library";
import SupportEmailLink from "../../shared/SupportEmailLink";
import type { GovernmentAgency } from "../../shared/types";
import { CONTRACT_EMAIL_ADDRESS } from "../../utils/constants";

interface EntityContractsUpdatePageProps {
  governmentAgency: GovernmentAgency;
}
export default function EntityContractsUpdatePage({
  governmentAgency,
}: EntityContractsUpdatePageProps) {
  return (
    <div className="flex flex-col md:flex-row justify-between py-6 md:py-12">
      <div className="flex flex-col gap-5">
        <Typography variant="headline" size="lg" color="brand.boldest.enabled">
          Update contracts for {entityDisplayName(governmentAgency)}
        </Typography>
        <img
          src={cloudFiles}
          className="md:hidden block w-[192px] h-[112px] self-center"
          alt="Uploading contracts"
        />
        <Bullets
          capitalize={false}
          columns={1}
          itemsList={[
            "Add missing contracts from your entity",
            "Add contracts you’re piggybacking on",
            "Update your entity’s contracts",
          ]}
        />

        <Typography variant="body" size="md" color="brand.boldest.enabled">
          Please email{" "}
          <SupportEmailLink
            underline
            className="analytics-entity-contracts-support"
            email={CONTRACT_EMAIL_ADDRESS}
          />{" "}
          to add contracts. Stay tuned for easier ways to upload and manage your
          contracts.
        </Typography>
      </div>
      <img
        src={cloudFiles}
        className="hidden md:block w-[192px] h-[112px]"
        alt="Uploading contracts"
      />
    </div>
  );
}

function entityDisplayName(governmentAgency: GovernmentAgency) {
  return [
    governmentAgency.name,
    governmentAgency.appendStateForDisplay ? governmentAgency.stateCode : null,
  ]
    .filter(Boolean)
    .join(", ");
}
