import { useRef } from "react";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import type { SearchOptions } from "../components/ContractSearch/types";
import {
  contractDocumentsFilterState,
  expirationFilterState,
} from "../recoil/search";
import { userZipState } from "../recoil/user";
import { getParams } from "../utils";
import {
  ContractDocumentsFilterOptions,
  ExpirationStatuses,
  SearchIntent,
  SearchSource,
  conversionLabels,
  researchModeDefaultFilters,
} from "../utils/enums";
import { getSearchBasePath, getWindowLocationHref } from "../utils/format";
import {
  type TrackAutocompleteOptions,
  trackGTMEvent,
  trackUserSelectedFromAutocomplete,
} from "../utils/tracking";
import useResetAndGetFilters from "./useResetAndGetFilters";
import type { OnCompleteOptions } from "./useSearchIntentSurvey";

export type SearchContractsParams = {
  query: string;
  isLocationRelevant?: boolean;
  selectedSearchIntent: SearchIntent;
  clickedQuerySuggestionTrackingValue?: TrackAutocompleteOptions;
  originalAmbiguousQuery?: string;
};

export default function useSearchContracts({
  searchContractWithParams,
  cbOnSearchRedirect,
  searchInNewTab,
  searchUrl,
  buyerLeadAgencyId,
  searchSource,
  autocompleteTrackingInfo,
  isEntityContractsSearch,
  setShowTypeahead,
  searchIntentSurvey,
}: {
  searchContractWithParams: (args: SearchOptions) => void;
  cbOnSearchRedirect?: (query: string) => void;
  searchInNewTab: boolean;
  searchUrl?: string;
  buyerLeadAgencyId?: string;
  searchSource: string;
  autocompleteTrackingInfo?: TrackAutocompleteOptions;
  isEntityContractsSearch: boolean;
  setShowTypeahead: (show: boolean) => void;
  searchIntentSurvey: () => Promise<OnCompleteOptions>;
}) {
  const conversionTracked = useRef(false);
  const searchEndpoint = searchUrl || getSearchBasePath();
  const url = new URL(searchEndpoint, getWindowLocationHref());
  const params = getParams();
  const isLandingPage = searchSource === SearchSource.LANDING_PAGE_SEARCH;
  const userZip = useRecoilValue(userZipState);
  const resetFilters = useResetAndGetFilters(true);
  const setExpirationFilter = useSetRecoilState(expirationFilterState);
  const setContractDocsFilter = useSetRecoilState(contractDocumentsFilterState);
  const resetAndGetUserDefaultFilters = useResetAndGetFilters();

  return useRecoilCallback(
    () =>
      async ({
        query,
        isLocationRelevant,
        selectedSearchIntent,
        clickedQuerySuggestionTrackingValue,
      }: SearchContractsParams) => {
        cbOnSearchRedirect?.(query);

        // Record Google Ads (Search on Landing Page) conversions
        function trackSearchOnLandingPageConversion() {
          if (conversionTracked.current) {
            return;
          }
          // only track searches in the landing page searchbar
          if (!isLandingPage) {
            return;
          }

          trackGTMEvent(conversionLabels.searchOnLandingPage);

          conversionTracked.current = true;
        }

        if (searchEndpoint === window.location.pathname) {
          if (params.filters) {
            url.searchParams.set("filters", params.filters.toString());
          }
          if (params["widget-search-source"]) {
            url.searchParams.set(
              "widget-search-source",
              params["widget-search-source"]?.toString() || ""
            );
          }
          if (params.embedSourceEntityId && !buyerLeadAgencyId) {
            url.searchParams.set(
              "embedSourceEntityId",
              params.embedSourceEntityId.toString()
            );
          }
          if (params.feature) {
            url.searchParams.set("feature", params.feature.toString());
          }
          if (params.debug) {
            url.searchParams.set("debug", params.debug.toString());
          }
          if (params.benchmarkingSessionId) {
            url.searchParams.set(
              "benchmarkingSessionId",
              params.benchmarkingSessionId.toString()
            );
          }
          if (
            searchSource === SearchSource.LANDING_PAGE_ENTITY_SEARCH &&
            params.landingPageSlug
          ) {
            url.searchParams.set(
              "landingPageSlug",
              params.landingPageSlug.toString()
            );
          }
        }

        if (buyerLeadAgencyId) {
          url.searchParams.set("embedSourceEntityId", buyerLeadAgencyId);
        }

        // Make Heap tracking call for typeahead data
        if (autocompleteTrackingInfo || clickedQuerySuggestionTrackingValue) {
          // If selected from default, change search source
          trackUserSelectedFromAutocomplete(
            autocompleteTrackingInfo ||
              (clickedQuerySuggestionTrackingValue as TrackAutocompleteOptions)
          );
        }

        const { filters } =
          isLandingPage || isEntityContractsSearch
            ? { filters: [] }
            : await searchIntentSurvey();
        if (filters?.length) url.searchParams.set("filters", filters.join(";"));

        let newFilters = params.filters?.toString().split(";") || [];

        // When intent changes, reset filters to the defaults for the intent
        if (selectedSearchIntent !== params.intent) {
          // If switching to buying mode, use user-level default filters
          if (selectedSearchIntent === SearchIntent.BUYING) {
            newFilters = resetAndGetUserDefaultFilters();
          } else {
            resetFilters();
            setExpirationFilter(ExpirationStatuses.ACTIVE_AND_EXPIRED);
            setContractDocsFilter(
              ContractDocumentsFilterOptions.ONLY_WITH_DOCS
            );
            newFilters = researchModeDefaultFilters;
          }
          url.searchParams.set("filters", newFilters.join(";"));
        }

        url.searchParams.set("intent", selectedSearchIntent);

        if (query || isEntityContractsSearch) {
          url.searchParams.set("query", query);
          if (isLocationRelevant) {
            url.searchParams.set("zip", userZip);
          } else {
            url.searchParams.append("filters", "IS_NOT_LOCATION_RELEVANT");
          }

          // Add param to track searches from default list
          const userSelectedFromDefault =
            autocompleteTrackingInfo?.selectedDefaultQuery ||
            clickedQuerySuggestionTrackingValue?.selectedDefaultQuery;

          if (userSelectedFromDefault) {
            url.searchParams.set(
              "user-selected-from-default",
              userSelectedFromDefault.toString()
            );
          }

          url.searchParams.set("analytics-search-source", `${searchSource}`);

          // Record Google Ads (Search on Landing Page) conversions
          trackSearchOnLandingPageConversion();

          if (searchInNewTab) {
            window.open(url.href, "_blank");
          } else if (
            searchSource === SearchSource.CONTRACTS_SERP_SEARCH ||
            searchSource === SearchSource.LANDING_PAGE_ENTITY_SEARCH
          ) {
            setShowTypeahead(false);
            searchContractWithParams({
              newParams: {
                query,
                zip: userZip,
                embedSourceEntityId: params.embedSourceEntityId?.toString(),
                searchSource,
                filters: newFilters,
                userSelectedFromDefault,
                landingPageSlug: params.landingPageSlug?.toString(),
                intent: selectedSearchIntent,
              },
            });
            window.scrollTo(0, 0);
          } else {
            window.location.href = url.href;
          }
        }
      }
  );
}
