import _isEqual from "lodash/isEqual";
import { type RecoilState, useRecoilCallback } from "recoil";
import { v4 as uuidv4 } from "uuid";

import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
} from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import {
  contractSearchAnalyticsParamsState,
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
} from "../recoil/search";
import { MAX_RESULTS } from "../utils/constants";
import { SearchActions, SearchSource } from "../utils/enums";
import { handleError } from "../utils/generatedApi";

import { captureException } from "@sentry/browser";
import { ApiService } from "../generated";
import {
  getProBoost,
  shouldUpdateRequestID,
} from "./useSearchContractWithParams";
import useTrackContractSearch from "./useTrackContractSearch";

const searchContracts = async (
  requestID: string,
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  landingPageSlug: string,
  query?: string,
  filters?: string[],
  zip?: string
) => {
  set(contractSearchIsLoadingState, true);
  set(contractSearchResponseDataState, null);
  try {
    const response = await ApiService.apiV1LandingPageSearchCreate({
      query: query || "",
      filters,
      zip,
      // When filtering clientside, retrieve results that includes all
      // strong matches in one go to offer the largest bucket of filterable
      // results.
      numResultsPerPage: MAX_RESULTS,
      page: 0,
      requestID,
      // TODO: This is experimental as part of
      //  https://app.shortcut.com/coprocure/story/17661/milestone-2-1-add-feature-gated-ranking-boost-for-pro-suppliers-in-serp
      proBoost: getProBoost(),
      landingPageSlug,
    });
    set(contractSearchResponseDataState, response);
    set(contractSearchIsLoadingState, false);
    return response;
  } catch (err) {
    handleError(err);
    set(contractSearchResponseDataState, {
      contractData: {
        numAllResults: 0,
        numShowingResults: 0,
        numStrongResults: 0,
        results: [],
      },
      metadata: null,
      params: null,
      agencyData: null,
      prioritizedEntityMatchData: null,
    });
    set(contractSearchIsLoadingState, false);
    return null;
  }
};

export default function useLandingPageSearchContractWithParams(
  landingPageSlug: string
) {
  const trackContractSearch = useTrackContractSearch();

  const search = useRecoilCallback<[SearchOptions], void>(
    ({ snapshot, set }) =>
      async ({ newParams = null }) => {
        const [searchParams, analyticsParams] = await Promise.all([
          snapshot.getPromise(contractSearchParamsState),
          snapshot.getPromise(contractSearchAnalyticsParamsState),
        ]);
        const params = formatSearchPageParams(searchParams);
        const combinedParams = {
          ...params,
          ...newParams,
          landingPageSlug,
        } as ContractSearchParams;

        const validation = validateSearchParams(combinedParams);
        if (!validation.valid) {
          set(contractSearchResponseDataState, {
            contractData: {
              numAllResults: 0,
              numShowingResults: 0,
              numStrongResults: 0,
              results: [],
            },
            metadata: null,
            params: null,
            agencyData: null,
            prioritizedEntityMatchData: null,
            errorMessage: validation.errorMessage || "",
          });
          return;
        }
        const { query, zip, page, filters } = combinedParams;

        // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
        // PLEASE COORDINATE IF THESE CHANGE.
        const updatedParams: SearchParams = {
          query: query || "",
          zip: zip || "",
          page: page?.toString() || "",
          filters: filters?.join(";") || "",
        };

        // Update search params local state, which binds to URL param changes.
        if (!_isEqual(searchParams, updatedParams)) {
          set(contractSearchParamsState, updatedParams);
        }

        const requestID = shouldUpdateRequestID(searchParams, updatedParams)
          ? uuidv4()
          : analyticsParams.requestID || uuidv4();
        // If we change the request id, track this as a new search.
        if (requestID !== analyticsParams.requestID) {
          set(contractSearchAnalyticsParamsState, {
            requestID,
            searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
          });
        }

        const contractResponse = await searchContracts(
          requestID,
          set,
          landingPageSlug || "",
          query,
          filters,
          zip
        );
        if (contractResponse) {
          let params: SearchParams;
          params = {
            query: contractResponse.params?.query || "",
            zip: contractResponse.params?.zip || "",
            page: contractResponse.params?.page?.toString() || "",
            filters: contractResponse.params?.filters?.join(";") || "",
          };
          set(contractSearchParamsState, params);

          if (!contractResponse.params?.requestId) {
            // Track if the contract search response does not have a request ID - this is a problem
            captureException(
              new Error("Contract search response does not have a request ID"),
              {
                extra: { contractResponse },
              }
            );
          }

          set(contractSearchAnalyticsParamsState, {
            requestID: contractResponse.params?.requestId || requestID,
            searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
          });
        }
        if (contractResponse) {
          trackContractSearch(
            contractResponse,
            SearchActions.SEARCH,
            0,
            null,
            false,
            params.intent
          );
        }
        return null;
      },
    []
  );

  return search;
}
