import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { modalState } from "../recoil/page";
import { userTypeSignupState } from "../recoil/user";
import { accountModals, modals } from "../utils/enums";
import { isFeatureEnabled } from "../utils/split";

export default function useShowSignupSurvey() {
  const userType = useRecoilValue(userTypeSignupState);
  const setCurrentModal = useSetRecoilState(modalState);

  const show = useCallback(
    ({ onComplete }: { onComplete?: () => void }) => {
      if (isFeatureEnabled("signupSurvey")) {
        setCurrentModal({
          name:
            userType === "buyer"
              ? accountModals.SIGNUP_STEPS_SURVEY
              : modals.SUPPLIER_SURVEY,
          onComplete,
          isBlocking: true,
        });
      } else {
        onComplete?.();
      }
    },
    [userType, setCurrentModal]
  );

  return show;
}
