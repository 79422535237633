import { useRecoilValue } from "recoil";

import { useBenchmarkingCtas } from "../../../hooks/useBenchmarkingCtas";
import useIsDebug from "../../../hooks/useIsDebug";
import {
  contractSourceFilterState,
  expirationFilterState,
  useAddRecentSuccessfulSearch,
} from "../../../recoil/search";
import { userZipState } from "../../../recoil/user";
import { expirationText, getContractUrl } from "../../../shared/ContractBase";
import getTags from "../../../shared/ContractBase/getTags";
import { MultipleDocumentsIcon } from "../../../shared/CustomIcons/MultipleDocumentsIcon";
import { SingleDocumentIcon } from "../../../shared/CustomIcons/SingleDocumentIcon";
import { goToURL } from "../../../utils";
import { isDateInPast, parseDate } from "../../../utils/date";
import { LoginWallTriggers } from "../../../utils/enums";
import ContractCard from "../ContractCard";
import type { TrackContractCardClickFn } from "../types";
import { matchesForKey } from "../utils";

import type { ContractHit } from "../../../generated";
import { MatchesFound } from "./MatchesFound";

export interface SerpCardContainerProps {
  hit: ContractHit;
  cardAnalyticsClass: string;
  query: string;
  trackContractClick: TrackContractCardClickFn;
  // Unused when component is rendered on category and landing pages.
  trackSerpClick?: TrackContractCardClickFn;
  diversityPreferences?: (string | null)[];
  pageNavigationSource?: string;
  requestID: Maybe<string>;
}

function SerpCardContainer({
  hit,
  cardAnalyticsClass,
  query,
  trackContractClick,
  trackSerpClick,
  diversityPreferences = [],
  pageNavigationSource,
  requestID,
}: SerpCardContainerProps) {
  const expirationFilter = useRecoilValue(expirationFilterState);
  const sourcesFilter = useRecoilValue(contractSourceFilterState);
  const userZip = useRecoilValue(userZipState);
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const {
    solicitationId,
    docid,
    title,
    rank,
    cooperativeAffiliation,
    cooperativeLanguage,
    buyerLeadAgency,
    buyerLeadAgencyRank,
    buyerLeadAgencyState,
    buyerLeadAgencyType,
    relevantSuppliers,
    contractTags,
    expirationTimestamp,
    matchTier,
    HighlightResult,
    contractNumber,
    numSuppliers,
    RankingInfo,
  } = hit;

  const expirationDate = parseDate(expirationTimestamp);
  const isExpired = isDateInPast(expirationDate?.getTime() || 0);
  const isDebug = useIsDebug();

  //  used for manual benchmarking when debug=true&benchmarkingSessionId=something
  const benchmarkingCtas = useBenchmarkingCtas({
    solicitationId: solicitationId,
    rank,
  });
  const offeringMatches = matchesForKey(HighlightResult, "contractOfferings");
  const autoExtractedOfferingsMatches = matchesForKey(
    HighlightResult,
    "autoExtractedOfferingsList"
  );

  // When a user clicks the card, generate url for contract click and navigate to it.
  const handleClick: TrackContractCardClickFn = (data) => {
    addRecentSuccessfulSearch(query);
    trackContractClick(data);
    goToURL(
      getContractUrl(
        solicitationId,
        data.docid,
        query,
        userZip,
        autoExtractedOfferingsMatches.length > 0,
        offeringMatches.length > 0,
        expirationFilter,
        sourcesFilter,
        pageNavigationSource,
        requestID
      )
    );
  };

  const hasDiversityOverlap =
    relevantSuppliers[0].supplierDiversityCertificationIds?.some(
      (diversityId) => diversityPreferences.includes(diversityId)
    );

  // generate tags for contract
  const { tags, tagVariantList, tagCopyList } = getTags({
    contractTagData: contractTags,
    supplierTagData: relevantSuppliers[0]?.supplierTags || [],
    matchesDiversity: hasDiversityOverlap,
    expiration_ts: expirationTimestamp,
    expiration_date: expirationDate,
    matchTier: matchTier,
    blaState: buyerLeadAgencyState,
    blaType: buyerLeadAgencyType,
    blaRank: buyerLeadAgencyRank,
    filterScore: RankingInfo.filters,
    semanticScore: RankingInfo.semanticScore,
    proBoost: RankingInfo.proBoost,
    geoBoost: RankingInfo.geoBoost,
    scaledBoost: RankingInfo.scaledBoost,
    contractQualityBoost: RankingInfo.contractQualityBoost,
    bm25Score: RankingInfo.bm25Score,
    isCooperative: cooperativeLanguage,
    isDebug,
  });

  const suppliers = relevantSuppliers?.map(
    (supplier) => supplier.supplierDisplayName
  );

  const data = {
    docid,
    semanticScore: hit.RankingInfo.semanticScore || 0,
    supplierHandle: hit.supplierHandle,
    supplierId: hit.supplierId,
    displayTag: tagVariantList,
    displayTagCopy: tagCopyList,
  };

  return (
    <ContractCard
      className={cardAnalyticsClass}
      loginWallTrigger={LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK}
      loginWallTriggerId={docid}
      onClick={() => handleClick(data)}
      trackSerpClick={() => trackSerpClick?.(data)}
      ctas={benchmarkingCtas}
      title={title}
      supplierTags={tags}
      contractNumber={
        contractNumber ? `Contract #${contractNumber}` : "No contract #"
      }
      expiration={expirationText(expirationDate, { short: true })}
      semanticContext={isDebug && RankingInfo.semanticContext}
      isExpired={isExpired}
      numSuppliers={numSuppliers}
      awardedSuppliers={suppliers}
      buyerLeadAgency={buyerLeadAgency}
      cooperative={cooperativeAffiliation || ""}
      IconComponent={
        suppliers.length > 1 ? MultipleDocumentsIcon : SingleDocumentIcon
      }
      searchMatches={<MatchesFound hit={hit} />}
    />
  );
}

export default SerpCardContainer;
