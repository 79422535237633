import _partition from "lodash/partition";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { Typography } from "../../library";
import { modalState } from "../../recoil/page";
import { LoadingCards } from "../../shared/Loading/LoadingCards";
import { NO_MODAL } from "../../utils";

import clsx from "clsx";
import { ApiService, type Project } from "../../generated";
import { handleError } from "../../utils/generatedApi";
import CreateProjectButton from "./CreateProjectButton";
import NoProjectSection from "./NoProjectSection";
import ProjectCard from "./ProjectCard";
import ProjectDetailBanner, { BannerType } from "./ProjectDetailBanner";
import { ProjectCreationSource, type Search } from "./types";

const CONTAINER_STYLE = "grid gap-4 grid-cols-1 md:grid-cols-3";

interface ProjectsPageProps {
  searches: Search[];
}

function ProjectSectionTitle({
  title,
  showCta,
  className,
}: {
  title: string;
  showCta: boolean;
  className?: string;
}) {
  return (
    <div className={clsx("flex justify-between items-center", className)}>
      <Typography
        variant="headline"
        emphasis
        color="brand.boldest.enabled"
        component="h1"
      >
        {title}
      </Typography>
      {showCta && (
        <CreateProjectButton source={ProjectCreationSource.PROJECT_PAGE} />
      )}
    </div>
  );
}

function ProjectSection({
  projects,
  title,
  showCta,
  className,
}: {
  title: string;
  showCta: boolean;
  projects: Project[];
  className?: string;
}) {
  if (projects.length === 0) return null;

  return (
    <>
      <ProjectSectionTitle
        title={title}
        showCta={showCta}
        className={className}
      />
      <div className={CONTAINER_STYLE}>
        {projects
          .sort((a, b) => {
            return (
              new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime()
            );
          })
          .map((project) => (
            <ProjectCard key={project.id} {...project} />
          ))}
      </div>
    </>
  );
}

export default function ProjectsPage({ searches }: ProjectsPageProps) {
  const currentModal = useRecoilValue(modalState);
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentModal !== NO_MODAL) return;
    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList();
        setUserProjects(projects);
        setIsLoading(false);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [currentModal]);

  const [archivedProjects, activeProjects] = _partition(
    userProjects,
    (proj) => {
      return !!proj.archivedAt;
    }
  );

  return (
    <div className="flex flex-row cp-page-container m-auto py-12 min-h-[28rem]">
      <div className="flex flex-col w-full gap-8">
        {userProjects.length > 0 ? (
          <>
            <ProjectSection
              projects={activeProjects}
              title="Projects"
              showCta
            />
            <ProjectSection
              className={clsx({ "mt-3": activeProjects.length > 0 })}
              projects={archivedProjects}
              title="Archived projects"
              showCta={activeProjects.length === 0}
            />
          </>
        ) : isLoading ? (
          <LoadingCards className={CONTAINER_STYLE} />
        ) : (
          <>
            <ProjectSectionTitle title="Projects" showCta />
            <ProjectDetailBanner type={BannerType.BLOG_POST} compressed />
            <NoProjectSection searches={searches} />
          </>
        )}
      </div>
    </div>
  );
}
