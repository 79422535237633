import { type SetStateAction, useState } from "react";

import { SUPPLIER_ALIAS_FIELDS } from "../../../components/supplier/SupplierEditForm/constants";
import {
  ApiService,
  type SupplierAlias,
  type SupplierAliasRequest,
} from "../../../generated";
import Typography from "../../../library/Typography";
import { FormWrapper } from "../../../library/form";
import Modal from "../../../shared/Modal/Modal";
import { handleError as handleGeneratedError } from "../../../utils/generatedApi";
import { getUser, trackSupplierEditFormStatus } from "../../../utils/tracking";

interface EditAliasModalProps {
  handle: string;
  alias?: SupplierAlias;
  title?: string;
  onAliasUpsert: (newAlias: SupplierAlias) => void;
  hideModal: () => void;
  setUpdateDate: (value: SetStateAction<Date | undefined>) => void;
}

export default function EditAliasModal({
  handle,
  onAliasUpsert,
  hideModal,
  alias,
  title = "Edit name",
  setUpdateDate,
}: EditAliasModalProps) {
  const initialAliasValues: SupplierAliasRequest = {
    displayAlias: alias?.displayAlias || "",
  };

  const [invalidRequest, setInvalidRequest] = useState("");

  const saveAlias = async (aliasData: SupplierAliasRequest) => {
    const trimmedDisplayAlias = aliasData.displayAlias.trim();
    if (!trimmedDisplayAlias) {
      setInvalidRequest("Please enter a name");
      return;
    }

    let data: SupplierAlias;
    if (alias?.id) {
      try {
        data = await ApiService.apiV1SupplierEditAliasesPartialUpdate(
          alias.id,
          handle,
          aliasData
        );
      } catch (err) {
        handleGeneratedError(err);
        setInvalidRequest("Invalid name - please try a different name");
        return;
      }
    } else {
      try {
        data = await ApiService.apiV1SupplierEditAliasesCreate(
          handle,
          aliasData
        );
      } catch (err) {
        handleGeneratedError(err);
        setInvalidRequest("Invalid name - please try a different name");
        return;
      }
    }

    trackSupplierEditFormStatus(getUser() || "", data.displayAlias);
    onAliasUpsert(data);

    hideModal();
    setUpdateDate(new Date());
    return null;
  };

  return (
    <Modal
      title={title}
      hideModal={hideModal}
      ctaClass="analytics-add-supplier-alias"
    >
      <div className="mb-5">
        <FormWrapper
          fields={SUPPLIER_ALIAS_FIELDS}
          initialValues={initialAliasValues}
          onSubmit={saveAlias}
          submitClassName="analytics-supplier-alias-edit-cta"
          submitCta="Save name"
          disabled={false}
        />
        {invalidRequest && (
          <Typography
            className="mt-8"
            color="destructive.default.primary.enabled"
          >
            {invalidRequest}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
