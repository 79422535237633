/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `VIEW` - View
 * * `CLICK_RESULT` - Click Result
 * * `SERP_CLICK` - Serp Click
 * * `VIEW_SOURCE` - View Source
 * * `COPY_CONTRACT_NUMBER` - Copy Contract Number
 * * `COPY_CONTRACT_TITLE` - Copy Contract Title
 * * `COPY_CONTRACT_LINK` - Copy Contract Link
 * * `CLICK_MESSAGE_SUPPLIER` - Click Message Supplier
 * * `CLICK_MESSAGE_SUPPLIER_FROM_SUPPLIER_SERP` - Click Message Supplier From Supplier Serp
 * * `CLICK_MESSAGE_SUPPLIER_FROM_CONTRACT_SERP` - Click Message Supplier From Contract Serp
 * * `MESSAGE_SUPPLIER` - Message Supplier
 * * `INTERMEDIATED_MESSAGE_SUPPLIER` - Intermediated Message Supplier
 * * `VISIT_SUPPLIER_WEBSITE` - Visit Supplier Website
 * * `DOWNLOAD_FILES` - Download Files
 * * `SAVE_CONTRACT` - Save Contract
 * * `SAVE_SUPPLIER` - Save Supplier
 * * `SHARE_CONTRACT` - Share Contract
 * * `MESSAGE_BLA` - Message Bla
 * * `OPENED_FILE` - Opened File
 * * `VIEW_PHONE_NUMBER` - View Phone Number
 * * `PRINT_FILE` - Print File
 * * `PHONE_CALL_CONNECTED` - Phone Call Connected
 * * `SEARCH_PRODUCT_LIST` - Search Product List
 * * `CLICK_MESSAGE_SUPPLIER_FROM_PRODUCT` - Click Message Supplier From Product
 * * `OPEN_PRODUCT_DETAIL` - Open Product Detail
 * * `OUTREACH_OPT_IN_MODAL_VIEW_SOURCE` - Outreach Opt In Modal View Source
 * * `OUTREACH_OPT_IN_MODAL_DOWNLOAD_FILES` - Outreach Opt In Modal Download Files
 * * `OUTREACH_OPT_IN_MODAL_SAVE_CONTRACT` - Outreach Opt In Modal Save Contract
 * * `CLICK_UPLOAD_CONTRACTS` - Click Upload Contracts
 * * `CLICK_QUOTE_REQUEST_SIDEBAR` - Click Quote Request Sidebar
 * * `QUOTE_REQUEST` - Quote Request
 * * `QUOTE_REQUEST_OPT_IN_VIEW_SOURCE` - Quote Request Opt In View Source
 * * `QUOTE_REQUEST_OPT_IN_MODAL_DOWNLOAD_FILES` - Quote Request Opt In Modal Download Files
 * * `QUOTE_REQUEST_OPT_IN_MODAL_SAVE_CONTRACT` - Quote Request Opt In Modal Save Contract
 */
export enum InteractionTypeEnum {
    VIEW = 'VIEW',
    CLICK_RESULT = 'CLICK_RESULT',
    SERP_CLICK = 'SERP_CLICK',
    VIEW_SOURCE = 'VIEW_SOURCE',
    COPY_CONTRACT_NUMBER = 'COPY_CONTRACT_NUMBER',
    COPY_CONTRACT_TITLE = 'COPY_CONTRACT_TITLE',
    COPY_CONTRACT_LINK = 'COPY_CONTRACT_LINK',
    CLICK_MESSAGE_SUPPLIER = 'CLICK_MESSAGE_SUPPLIER',
    CLICK_MESSAGE_SUPPLIER_FROM_SUPPLIER_SERP = 'CLICK_MESSAGE_SUPPLIER_FROM_SUPPLIER_SERP',
    CLICK_MESSAGE_SUPPLIER_FROM_CONTRACT_SERP = 'CLICK_MESSAGE_SUPPLIER_FROM_CONTRACT_SERP',
    MESSAGE_SUPPLIER = 'MESSAGE_SUPPLIER',
    INTERMEDIATED_MESSAGE_SUPPLIER = 'INTERMEDIATED_MESSAGE_SUPPLIER',
    VISIT_SUPPLIER_WEBSITE = 'VISIT_SUPPLIER_WEBSITE',
    DOWNLOAD_FILES = 'DOWNLOAD_FILES',
    SAVE_CONTRACT = 'SAVE_CONTRACT',
    SAVE_SUPPLIER = 'SAVE_SUPPLIER',
    SHARE_CONTRACT = 'SHARE_CONTRACT',
    MESSAGE_BLA = 'MESSAGE_BLA',
    OPENED_FILE = 'OPENED_FILE',
    VIEW_PHONE_NUMBER = 'VIEW_PHONE_NUMBER',
    PRINT_FILE = 'PRINT_FILE',
    PHONE_CALL_CONNECTED = 'PHONE_CALL_CONNECTED',
    SEARCH_PRODUCT_LIST = 'SEARCH_PRODUCT_LIST',
    CLICK_MESSAGE_SUPPLIER_FROM_PRODUCT = 'CLICK_MESSAGE_SUPPLIER_FROM_PRODUCT',
    OPEN_PRODUCT_DETAIL = 'OPEN_PRODUCT_DETAIL',
    OUTREACH_OPT_IN_MODAL_VIEW_SOURCE = 'OUTREACH_OPT_IN_MODAL_VIEW_SOURCE',
    OUTREACH_OPT_IN_MODAL_DOWNLOAD_FILES = 'OUTREACH_OPT_IN_MODAL_DOWNLOAD_FILES',
    OUTREACH_OPT_IN_MODAL_SAVE_CONTRACT = 'OUTREACH_OPT_IN_MODAL_SAVE_CONTRACT',
    CLICK_UPLOAD_CONTRACTS = 'CLICK_UPLOAD_CONTRACTS',
    CLICK_QUOTE_REQUEST_SIDEBAR = 'CLICK_QUOTE_REQUEST_SIDEBAR',
    QUOTE_REQUEST = 'QUOTE_REQUEST',
    QUOTE_REQUEST_OPT_IN_VIEW_SOURCE = 'QUOTE_REQUEST_OPT_IN_VIEW_SOURCE',
    QUOTE_REQUEST_OPT_IN_MODAL_DOWNLOAD_FILES = 'QUOTE_REQUEST_OPT_IN_MODAL_DOWNLOAD_FILES',
    QUOTE_REQUEST_OPT_IN_MODAL_SAVE_CONTRACT = 'QUOTE_REQUEST_OPT_IN_MODAL_SAVE_CONTRACT',
}
