import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { type KeyboardEvent, useEffect, useState } from "react";
import { Typography } from "../../library";

interface ProductSearchBarProps {
  queryPlaceholder: string;
  search: (q: string) => void;
  query: string;
}

export default function ProductSearchBar({
  queryPlaceholder,
  query,
  search,
}: ProductSearchBarProps) {
  const [text, setText] = useState(query);
  function handleQueryKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      search(text);
    }
  }

  useEffect(() => {
    setText(query);
  }, [query]);

  return (
    <>
      <div className="flex justify-center">
        <div className="flex bg-white border border-solid border-r-0 w-full border-cp-violet-300 rounded-l-3 items-center">
          <Typography
            size="sm"
            component="div"
            className="relative items-center justify-between py-2 px-4 w-64"
          >
            <div className="flex items-center">
              <input
                autoComplete="off"
                name="query"
                placeholder={queryPlaceholder}
                required
                className="analytics-widget-query p-0 w-full rounded-none placeholder:font-light"
                onKeyUp={handleQueryKeyUp}
                onChange={(e) => setText(e.target.value)}
                value={text}
                aria-label="Search Proucts"
              />
            </div>
          </Typography>
        </div>
        <button
          aria-label="Search"
          className="analytics-widget-search mr-[4px] py-2 px-5 flex items-center justify-center bg-cp-violet-300 text-cp-black-400 rounded-r-3"
          onClick={() => search(text)}
        >
          <SearchRoundedIcon className="text-cp-midnight-300" />
        </button>
      </div>
    </>
  );
}
