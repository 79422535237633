/**
 * Small file to keep interlinked component types in sync.
 */
import type { ElementType } from "react";

import type {
  RENEWAL_INFO_RESPONSE,
  SUPPLIER_TAG_TYPES,
} from "../../utils/constants";
import type { TagVariants } from "../Tag";

import type { VariantEnum } from "../../generated";
import type { STYLINGS } from "./ContractCTASection";

// New Types
export enum CTAType {
  SAVE = "save",
  CHECKBOX = "checkbox",
  NONE = "none",
}
export interface CTA {
  type?: CTAType;
  name?: string;
  styling: keyof typeof STYLINGS;
  text?: string;
  Icon?: ElementType;
  classNames?: string;
  ctaAnalyticsClass?: string;
  onClick: (e?: unknown) => void;
  trackSerpClick?: () => void;
  selected?: boolean;
}

export interface Solicitation {
  id: string;
  title: string;
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
}

export interface ContractDocumentData {
  hasContractDoc: boolean;
  hasPricingDoc: boolean;
  hasRfpBidDoc: boolean;
}

export interface ContractEditRequestData {
  hasPendingRenewalDoc: boolean;
  renewalInfoConfirmation: keyof typeof RENEWAL_INFO_RESPONSE;
  numPendingDocs: number;
  needsExpirationReview: boolean;
  needsDocReview: boolean;
}

export interface Contract {
  title: string;
  docid: string;
  cooperativeAffiliation: string;
  cooperativeLanguage: boolean;
  buyerLeadAgency: string;
  url: string;
  expirationDate: string;
  expirationTimestamp?: number;
  isAutorenewing?: boolean;
  suppliers: string[];
  numDocs?: number;
  contractNumber?: string;
  contractDocumentData?: ContractDocumentData;
  contractEditRequestData?: ContractEditRequestData;
}

export interface ContractWithTags {
  contract: Contract;
  supplierTags: SupplierTagset;
  contractTags: ContractTag[];
}
export enum ContractTagTypes {
  NOT_AVAILABLE_LOCATION = "NOT_AVAILABLE_LOCATION",
  PRICING_DOC = "PRICING_DOC",
  UNSUPPORTED_BY_SUPPLIER = "UNSUPPORTED_BY_SUPPLIER",
  UNSUPPORTED_BY_BLA = "UNSUPPORTED_BY_BLA",
  UNSUPPORTED_BY_SOURCE = "UNSUPPORTED_BY_SOURCE",
}

export interface ContractTag {
  copy: string;
  variant: ContractTagTypes;
}

export interface SupplierTag {
  copy: string;
  variant: keyof typeof SUPPLIER_TAG_TYPES | VariantEnum;
}

export interface SupplierTagset {
  tags: SupplierTag[];
}
export interface TagType {
  text: string;
  variant: TagVariants;
  tooltip?: string;
}
