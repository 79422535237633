import clsx from "clsx";
import { borderColorClass } from "../../utils/colors";
import { ComplicanceChecklistSection } from "./ComplianceChecklistSection";
import type { ComplianceChecklistSectionData } from "./utils";

export default function ComplianceChecklist({
  contractId,
  sectionData,
}: {
  contractId: string;
  sectionData: ComplianceChecklistSectionData[];
}) {
  if (!sectionData.length) return <></>;
  return (
    <div
      className={clsx(
        borderColorClass.brand.subtle.enabled,
        "border border-1 rounded-4 p-4 flex flex-col"
      )}
    >
      {sectionData.map((section, i) => (
        <ComplicanceChecklistSection
          key={section.sectionKey}
          contractId={contractId}
          defaultOpen={i === 0}
          className="border-b border-cp-neutral-200 pb-3 mb-3 last:pb-0 last:mb-0 last:border-b-0"
          {...section}
        />
      ))}
    </div>
  );
}
