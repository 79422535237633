import clsx from "clsx";
import { useRecoilValue } from "recoil";

import { Bullets, Button, ButtonThemes, Typography } from "../library";
import { supplierActiveAgreementsState } from "../recoil/user";
import Modal, { modalSizes } from "../shared/Modal/Modal";
import { borderColorClass, textColorClass } from "../utils/colors";
import { formatDate } from "../utils/date";
import { PartnershipType } from "../utils/enums";

interface ExclusionRemovalModalProps {
  hideModal: () => void;
  onConfirm: () => void;
  entity: string;
  activeAt: Date;
}

export default function ExclusionRemovalModal({
  hideModal,
  onConfirm,
  entity,
  activeAt,
}: ExclusionRemovalModalProps) {
  const activeAgreements = useRecoilValue(supplierActiveAgreementsState);
  const isViewsLimited = activeAgreements.find(
    (v) => v === PartnershipType.VIEWS_LIMITED
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Confirm entity removal"
      modalSize={modalSizes.DEFAULT}
      contentClassName="grid gap-8"
    >
      <div className="flex flex-col gap-2">
        <Typography>Are you sure you want to remove:</Typography>
        <div
          className={clsx(
            "ml-2 pl-2 border-l border-solid",
            borderColorClass.brand.subtle.enabled
          )}
        >
          <Typography
            variant="headline"
            size="xs"
            color="neutral.bolder.enabled"
            emphasis
          >
            {entity}
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            Added on: {formatDate(activeAt, "MMMM d, yyyy")}
          </Typography>
        </div>
        <div>
          <Typography color="neutral.bolder.enabled">
            By removing this entity, the following will happen:
          </Typography>
          <Bullets
            itemsList={[
              "You will receive increased exposure with this entity's buyers and end users.",
              isViewsLimited
                ? "Any views from this entity going forward will count towards unique entity views."
                : "Going forward, sales generated from this entity after they view your pages on Pavilion will be subject to the referral fee.",
              "You can always add an entity back to your exclusion list in the future.",
            ]}
            className={clsx("mr-4", textColorClass.neutral.bolder.enabled)}
            columns={1}
            capitalize={false}
          />
        </div>
      </div>
      <div className="flex flex-row justify-center gap-4">
        <Button
          theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
          onClick={() => {
            onConfirm();
            hideModal();
          }}
        >
          Yes, remove
        </Button>
        <Button theme={ButtonThemes.SECONDARY_DARK} onClick={hideModal}>
          No, keep entity
        </Button>
      </div>
    </Modal>
  );
}
