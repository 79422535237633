import clsx from "clsx";
import { useRecoilValue } from "recoil";
import { searchResultTypeState } from "../../recoil/search";
import LoadingHit from "../SearchPage/SearchHit/LoadingHit";
import SupplierResultLoadingHit from "../SearchPage/SearchHit/SupplierResultLoadingHit";

interface LoadingContractCardsProps {
  className?: string;
}

export function LoadingCards({ className }: LoadingContractCardsProps) {
  const searchResultType = useRecoilValue(searchResultTypeState);

  let Component = LoadingHit;
  const hasSupplierResults = searchResultType === "supplier";
  if (hasSupplierResults) {
    Component = SupplierResultLoadingHit;
  }

  return (
    <div
      className={clsx(
        "flex flex-col",
        { "gap-6": !hasSupplierResults, "gap-12": hasSupplierResults },
        className
      )}
    >
      <Component />
      <Component />
      <Component />
    </div>
  );
}
