import type { FormikValues } from "formik";
import { InlineFormWrapper } from "../../library/form";
import { REQUIREMENT_PAGE_QUESTIONS } from "./constants";

export function QuoteRequestForm({
  handleSubmit,
  isLoading,
}: {
  handleSubmit: (values: FormikValues) => void;
  isLoading: boolean;
}) {
  return (
    <InlineFormWrapper
      className="!gap-12"
      fields={REQUIREMENT_PAGE_QUESTIONS}
      initialValues={{
        description: "",
        preferredSuppliers: "",
        additionalRequirements: [],
        additionalDetails: "",
        requestedCc: [],
        isOpenToComparableProducts: true,
      }}
      onSubmit={handleSubmit}
      submitCta="Submit"
      submitClassName="w-fit"
      disabled={isLoading}
    />
  );
}
