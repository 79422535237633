import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import clsx from "clsx";
import type { ElementType, ReactElement, ReactNode } from "react";

import {
  bgColorClass,
  borderColorClass,
  textColorClass,
} from "../../utils/colors";
import Typography from "../Typography";
import type { TypographyVariant } from "../Typography/types";

interface PopoverMenuProps {
  text: string;
  Icon?: ElementType;
  variant?: "default" | "primary";
  enabled?: boolean;
  border?: boolean;
  reverse?: boolean;
  noWrap?: boolean;
  typographyVariant?: TypographyVariant;
  children: ReactNode | ((props: { close: () => void }) => ReactElement);
}

const buttonClass = (
  border: boolean,
  enabled: boolean,
  reverse: boolean,
  noWrap: boolean
) =>
  clsx(
    "focus-visible:outline focus-visible:outline-1 focus-visible:outline-cp-lapis-500 analytics-search-filters-menu max-w-full flex px-3 py-3 cursor-pointer rounded-xl hover:bg-cp-neutral-30",
    {
      [borderColorClass.neutral.default]: !enabled,
      [borderColorClass.interactive.default.primary.enabled]: enabled,
    },
    {
      [bgColorClass.neutral.default.primary.enabled]: !enabled,
      [bgColorClass.brand.default.secondary.enabled]: enabled,
    },
    {
      "border border-solid": border,
    },
    {
      "flex-row-reverse justify-end": reverse,
    },
    {
      "whitespace-nowrap": noWrap,
    }
  );
const panelClass = `absolute rounded-xl shadow-popup py-4 px-6 z-1 ${bgColorClass.neutral.default.primary.enabled}`;

export default function PopoverMenu({
  variant = "default",
  border = false,
  reverse = true,
  enabled = false,
  noWrap = false,
  typographyVariant = "body",
  Icon = KeyboardArrowDownRoundedIcon,
  children,
  text,
}: PopoverMenuProps) {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), flip(), shift()],
  });

  return (
    <div className="flex">
      <Popover className="relative">
        <PopoverButton
          as="button"
          ref={refs.setReference}
          className={buttonClass(border, enabled, reverse, noWrap)}
        >
          <Icon
            className={clsx("w-5 h-5", {
              [textColorClass.default]: variant === "default" && !enabled,
              [textColorClass.interactive.default.primary.enabled]:
                variant !== "default" || enabled,
              "ml-1": reverse,
              "mr-1": !reverse,
            })}
          />
          <Typography
            variant={typographyVariant}
            size="sm"
            color={
              variant === "default" && !enabled
                ? "default"
                : "interactive.default.primary.enabled"
            }
          >
            {text}
          </Typography>
        </PopoverButton>
        <PopoverPanel
          className={panelClass}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          {children}
        </PopoverPanel>
      </Popover>
    </div>
  );
}
