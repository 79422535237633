import clsx from "clsx";
import type { ElementType, MouseEventHandler, ReactNode } from "react";

import type { LinkProps } from "../Link";
import type { TypographySize } from "../Typography/types";

const SIZE_TO_FONT_SIZE = {
  xs: "cp-body-sm",
  sm: "cp-body-md",
  "sm-md": "cp-body-sm-md",
  md: "cp-body-lg",
  lg: "cp-body-xxl",
  xl: "cp-body-xxl",
  none: "cp-body-md",
};

export interface BadgeProps {
  as?: string | ElementType;
  className?: string;
  Icon?: ElementType;
  label?: ReactNode;
  children?: ReactNode;
  onClick?: MouseEventHandler;
  onClickIcon?: MouseEventHandler;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  size?: TypographySize;
  reverse?: boolean;
  inline?: boolean;
  iconClass?: string;
  linkProps?: LinkProps;
  includeMargin?: boolean;
  disabled?: boolean;
  dataTestId?: string;
}

export default function Badge({
  as = "div",
  Icon,
  label,
  className,
  onClick,
  onClickIcon,
  onMouseOut,
  size = "md",
  reverse = false,
  inline = false,
  iconClass,
  children,
  linkProps,
  includeMargin = true,
  disabled,
  dataTestId,
}: BadgeProps) {
  const iconSizeClass = {
    "w-3.5 h-3.5": size === "sm",
    "w-4 h-4": size === "sm-md",
    "w-5 h-5":
      size === "md" || !["sm", "sm-md", "lg", "x-lg", "none"].includes(size),
    "w-6 h-6": size === "lg",
    "w-8 h-8": size === "xl",
    "ml-1": includeMargin && reverse,
    "mr-1": includeMargin && !reverse,
  };
  const As = as;
  return (
    <As
      className={clsx(
        "max-w-full",
        {
          "inline-flex": inline,
          "flex items-center": !inline,
          "flex-row-reverse justify-end": reverse,
        },
        className
      )}
      onClick={onClick}
      onMouseOut={onMouseOut}
      size={size}
      disabled={disabled}
      data-testid={dataTestId}
      {...linkProps}
    >
      {Icon && (
        <Icon
          className={clsx(
            {
              "cursor-pointer": !!onClickIcon,
            },
            `text-${SIZE_TO_FONT_SIZE[size] || size}`,
            iconSizeClass,
            iconClass
          )}
          onClick={onClickIcon}
        />
      )}
      {label || children}
    </As>
  );
}
