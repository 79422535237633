import type { FieldProps } from "formik";

import { LabeledInput, LabeledInputVariants } from "../../library";
import BLAAutocompleteInput from "../BLAAutocompleteInput";

import type { UserEntityValue } from "./types";

interface BuyerLeadAgencyFieldProps
  extends FieldProps<UserEntityValue, Record<string, UserEntityValue>> {
  editable: boolean;
  dataTestId: string;
}

export default function BuyerLeadAgencyField({
  field,
  editable,
  dataTestId,
  form: { setFieldValue, errors, touched },
}: BuyerLeadAgencyFieldProps) {
  const { buyerProfile, searchZip } = field.value;

  if (!editable) {
    if (!buyerProfile && !searchZip) return <>(none)</>;
    return (
      <>
        {buyerProfile.governmentAffiliationDisplayName}
        {searchZip ? `, ${searchZip}` : ""}
      </>
    );
  }

  return (
    <div className="w-full">
      <div className="grid grid-flow-col gap-4">
        <div className="relative">
          <BLAAutocompleteInput
            analyticsClass="analytics-profile-page-govAffiliation-input"
            dataTestId={dataTestId}
            hasLabel
            initialValue={buyerProfile.governmentAffiliationDisplayName}
            initialId={buyerProfile.governmentAgency?.id}
            onChange={(name, agency) => {
              if (
                agency?.id === buyerProfile.governmentAgency?.id &&
                name === buyerProfile.governmentAffiliationDisplayName
              ) {
                return;
              }

              setFieldValue(
                field.name,
                {
                  buyerProfile: {
                    ...buyerProfile,
                    governmentAffiliationDisplayName: name,
                    governmentAgency: agency,
                  },
                  searchZip: agency?.zipCode || searchZip,
                },
                false
              );
            }}
            errorMessage={
              touched[field.name]?.buyerProfile &&
              errors[field.name]?.buyerProfile
                ? "Public agency is required"
                : ""
            }
          />
        </div>
        <LabeledInput
          name="searchZip"
          label="Zip Code"
          size="md"
          type="text"
          value={searchZip}
          onChange={(e) =>
            setFieldValue(field.name, {
              buyerProfile,
              searchZip: e.target.value,
            })
          }
          initialVariant={
            touched[field.name]?.searchZip && errors[field.name]?.searchZip
              ? LabeledInputVariants.ERROR
              : LabeledInputVariants.DEFAULT
          }
          message={
            (touched[field.name]?.searchZip && errors[field.name]?.searchZip) ||
            ""
          }
        />
      </div>
    </div>
  );
}
