import clsx from "clsx";
import type { ReactNode } from "react";

interface CardProps {
  className?: string;
  children?: ReactNode;
  responsive?: boolean;
  showShadow?: boolean;
  bgColor?: string;
  padding?: string;
}

export default function Card({
  className,
  responsive,
  children,
  showShadow = true,
  bgColor = "bg-cp-white-100",
  padding = "p-10",
}: CardProps) {
  return (
    <div
      className={clsx(
        bgColor,
        padding,
        "rounded-xl",
        {
          "md:shadow-popup": responsive && showShadow,
          "shadow-popup": !responsive && showShadow,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
