import type { SupplierAliasRequest } from "../../../generated";
import { Bullets, Typography } from "../../../library";
import {
  CheckboxField,
  LabeledInputField,
  TextareaField,
} from "../../../library/form";
import GoogleAutocompleteSearchField from "../../../shared/GoogleAutocompleteSearch/GoogleAutocompleteSearchField";
import type { SelectComponentOption } from "../../../shared/types";
import { EIN_REGEX, stateOptions } from "../../../utils/constants";
import { SupplierBusinessTypes } from "../../../utils/enums";
import yup from "../../../utils/yupPhone";

import { SupplierBusinessCategoryField } from "./SupplierBusinessCategoryField";
import { SupplierServiceAreaField } from "./SupplierServiceAreaField";
import type {
  CustomFieldProps,
  SupplierAboutValues,
  SupplierAddressValues,
  SupplierBusinessCategoryValues,
  SupplierCompetitorValues,
  SupplierEinValues,
  SupplierLocationValues,
  SupplierNameValues,
  SupplierServiceAreaValues,
  SupplierWebsiteValues,
} from "./types";

export const SUPPLIER_NAME_FIELDS: CustomFieldProps<SupplierNameValues>[] = [
  {
    name: "displayName",
    label: "Business Name",
    placeholder: "Enter your business's name",
    component: LabeledInputField,
    validationIcons: true,
    validate: yup
      .string()
      .trim()
      .max(512, "Your business name cannot exceed 512 characters")
      .required("Business name is required."),
    dataTestId: "supplier-business-name-input",
    className: "analytics-supplier-input-name",
  },
];

export const SUPPLIER_ADDRESS_FIELDS: CustomFieldProps<SupplierAddressValues>[] =
  [
    {
      name: "address",
      label: "Primary Address",
      sublabel:
        "Enter the primary address for your business. If there are multiple locations, this should be the HQ. Add additional locations below.",
      component: GoogleAutocompleteSearchField,
      validate: yup.object().shape({
        addressLine1: yup.string().required(),
        addressLine2: yup.string().nullable(),
        addressCity: yup.string().required(),
        addressStateCode: yup.string().required(),
        addressZip: yup.string().nullable(),
        addressCountryCode: yup.string().nullable(),
      }),
      dataTestId: "supplier-main-address-input",
      className: "analytics-input-address",
    },
  ];

export const SUPPLIER_ADDITIONAL_LOCATIONS_FIELDS: CustomFieldProps<SupplierLocationValues>[] =
  [
    {
      name: "address",
      label: "New address",
      placeholder: "Enter in the full address",
      component: GoogleAutocompleteSearchField,
      validate: yup.object().shape({
        addressLine1: yup.string().required(),
        addressLine2: yup.string().nullable(),
        addressCity: yup.string().required(),
        addressStateCode: yup.string().required(),
        addressZip: yup.string().nullable(),
      }),
      dataTestId: "supplier-additional-address-input",
      className: "analytics-input-additional-address",
    },
    {
      name: "locationName",
      label: "Address name",
      sublabel: "Adding a name helps you and buyers identify this address.",
      placeholder: "Add a name for this address",
      component: LabeledInputField,
      validate: yup
        .string()
        .trim("Your Address name cannot include leading and trailing spaces"),
      dataTestId: "supplier-additional-location-name-input",
      className: "analytics-input-additional-location-name",
    },
  ];

export const SUPPLIER_EIN_FIELDS: CustomFieldProps<SupplierEinValues>[] = [
  {
    name: "einNumber",
    label: "FEIN Number",
    placeholder: "XX-XXXXXXX",
    component: LabeledInputField,
    validationIcons: true,
    validate: yup
      .string()
      .trim()
      .matches(EIN_REGEX, "A valid EIN is required (e.g. 01-1234567)."),
    dataTestId: "supplier-ein-number-input",
    className: "analytics-supplier-input-ein-number",
  },
];

export const SUPPLIER_WEBSITE_FIELDS: CustomFieldProps<SupplierWebsiteValues>[] =
  [
    {
      name: "website",
      label: "Website",
      placeholder: "Enter your business's URL",
      component: LabeledInputField,
      validationIcons: true,
      validate: yup
        .string()
        .url(
          "You must enter a valid URL in the format: https://mybusinessurl.com"
        )
        .nullable(),
      dataTestId: "supplier-website-input",
      className: "analytics-input-website",
    },
  ];

export const SUPPLIER_BUSINESS_CATEGORY_FIELDS: CustomFieldProps<SupplierBusinessCategoryValues>[] =
  [
    {
      name: "businessTypes",
      label: "Business Category",
      sublabel:
        "In general, which of the following does your business provide? (Select all that apply.)",
      component: SupplierBusinessCategoryField,
      validate: yup.array(),
      dataTestId: "supplier-select-business-category",
      className: "analytics-select-business-category",
    },
  ];

export const SUPPLIER_ABOUT_FIELDS: CustomFieldProps<SupplierAboutValues>[] = [
  {
    name: "about",
    label: "About your business",
    sublabel:
      "Provide a brief description about your business and what you can provide to public entities.",
    placeholder: "Enter your business description",
    component: TextareaField,
    validate: yup
      .string()
      .max(1024, "Your business description cannot exceed 1024 characters")
      .nullable(),
    validationIcons: true,
    className: "analytics-supplier-about-input",
  },
];

export const SUPPLIER_COMPETITOR_FIELDS: CustomFieldProps<SupplierCompetitorValues>[] =
  [
    {
      name: "competitors",
      label: "Competitors",
      sublabel: (
        <div className="flex flex-col gap-1">
          <Typography>
            Enter all competitors. Please separate each competitor name with a
            comma.
          </Typography>
          <Bullets
            itemsList={[
              "If a competitor has popular subsidiaries or DBAs, please also list these.",
              "Please only list competitors that have a large overlap with your catalog, in order to ensure your views are relevant.",
            ]}
            capitalize={false}
            columns={1}
          />
        </div>
      ),
      component: TextareaField,
      validationIcons: true,
      className: "analytics-supplier-competitors-input",
      placeholder: "Competitor A, Competitor B, Competitor C",
    },
  ];

export const SUPPLIER_SERVICE_AREA_FIELDS: CustomFieldProps<SupplierServiceAreaValues>[] =
  [
    {
      name: "serviceArea",
      component: SupplierServiceAreaField,
      validate: yup.object().shape({
        manualServiceAreaNational: yup.bool(),
        manualServiceAreaState: yup.array(),
      }),
      dataTestId: "supplier-select-service-area-national",
      className: "analytics-select-service-area-national",
    },
  ];

export const SUPPLIER_ALIAS_FIELDS: CustomFieldProps<SupplierAliasRequest>[] = [
  {
    name: "displayAlias",
    component: LabeledInputField,
    label: "New business name",
    validate: yup
      .string()
      .trim()
      .max(512, "Your additional name cannot exceed 512 characters")
      .required("Please enter a name"),
    className: "analytics-supplier-alias-input",
  },
];

export const SUPPLIER_ROLE_CONTACT_FIELDS = [
  {
    name: "title",
    // id field allows us to associtate the label with the input field using htmlFor
    id: "title",
    component: LabeledInputField,
    label: "Name/description",
    sublabel: "Include your territory, region, or vertical when available",
    placeholder: "Sales Inbounds",
    validate: yup.string().trim().required("Name/description cannot be empty"),
    className: "analytics-supplier-contact-title-input",
  },
  {
    name: "phoneNumber",
    id: "phoneNumber",
    placeholder: "(000) 000-0000",
    label: "Phone number",
    component: LabeledInputField,
    validate: yup.string().when({
      is: (value: Maybe<string>) => value?.length,
      then: (rule) => rule.phone(),
      otherwise: (rule) => rule.optional(),
    }),
  },
  {
    name: "extension",
    id: "extension",
    placeholder: "0000",
    label: "Phone extension (optional)",
    component: LabeledInputField,
    validate: yup
      .string()
      .trim()
      .matches(/^\d+$/, "Extension must be a number")
      .nullable(),
  },
  {
    name: "email",
    id: "email",
    placeholder: "sales@example.com",
    label: "Email",
    component: LabeledInputField,
    validate: yup.string().email().required("Email cannot be empty"),
    dataTestId: "supplier-contact-email-input",
  },
  {
    name: "isDefaultContact",
    id: "isDefaultContact",
    label: "Set as default contact",
    sublabel:
      "On my business's profile and contract pages, show this contact first",
    component: CheckboxField,
    validate: yup.boolean(),
    className: "analytics-supplier-contact-default-checkbox",
  },
];

export const SUPPLIER_PERSON_CONTACT_FIELDS = [
  {
    name: "firstName",
    id: "firstName",
    component: LabeledInputField,
    label: "First name",
    validate: yup
      .string()
      .trim()
      .max(35, "First name cannot exceed 35 characters")
      .required("First name cannot be empty"),

    className: "analytics-supplier-contact-first-name-input",
  },
  {
    name: "lastName",
    id: "lastName",
    component: LabeledInputField,
    label: "Last name",
    validate: yup
      .string()
      .trim()
      .max(35, "Last name cannot exceed 35 characters")
      .required("Last name cannot be empty"),
    className: "analytics-supplier-contact-last-name-input",
  },
  {
    name: "title",
    id: "title",
    component: LabeledInputField,
    label: "Role/title",
    sublabel: "Include your territory, region, or vertical when available",
    validate: yup.string().trim().nullable(),
    className: "analytics-supplier-contact-title-input",
  },
  {
    name: "phoneNumber",
    id: "phoneNumber",
    placeholder: "(000) 000-0000",
    label: "Phone number",
    component: LabeledInputField,
    validate: yup.string().phone(),
  },
  {
    name: "extension",
    id: "extension",
    placeholder: "0000",
    label: "Phone extension (optional)",
    component: LabeledInputField,
    validate: yup
      .string()
      .trim()
      .matches(/^\d+$/, "Extension must be a number")
      .nullable(),
  },
  {
    name: "email",
    id: "email",
    placeholder: "you@example.com",
    label: "Email",
    component: LabeledInputField,
    validate: yup.string().email().required("Email cannot be empty"),
    dataTestId: "supplier-contact-email-input",
  },
  {
    name: "isDefaultContact",
    id: "isDefaultContact",
    label: "Set as default contact",
    sublabel:
      "On my business's profile and contract pages, show this contact first",
    component: CheckboxField,
    validate: yup.boolean(),
    className: "analytics-supplier-contact-default-checkbox",
  },
];

export const CONTINENTAL_US = stateOptions
  .filter(
    (option: SelectComponentOption) =>
      option.value !== "AK" && option.value !== "HI"
  )
  .map((option: SelectComponentOption) => option.value);

export const TEMPLATE_MAP = {
  products: {
    type: "product",
    name: "Product list template",
    source: `https://pavilion-public-files-${process.env.ENVIRONMENT}.s3.amazonaws.com/Pavilion-products-template.csv`,
  },
  services: {
    type: "service",
    name: "Services template",
    source: `https://pavilion-public-files-${process.env.ENVIRONMENT}.s3.amazonaws.com/Pavilion-services-template.csv`,
  },
};

export const TEMPLATES_BY_BUSINESS_TYPE = {
  [SupplierBusinessTypes.GOODS]: TEMPLATE_MAP.products,
  [SupplierBusinessTypes.SOFTWARE]: TEMPLATE_MAP.products,
  [SupplierBusinessTypes.SERVICES]: TEMPLATE_MAP.services,
};
