import AddRoundedIcon from "@mui/icons-material/AddRounded";

import useShowModal from "../../hooks/useShowModal";
import { Button, ButtonSizes, ButtonThemes } from "../../library";
import { modals } from "../../utils/enums";

import type { ProjectCreationSource } from "./types";

export default function CreateProjectButton({
  source,
}: {
  source: ProjectCreationSource;
}) {
  const showModal = useShowModal(modals.NEW_PROJECT_MODAL);

  return (
    <Button
      className="h-fit"
      badgeProps={{
        Icon: AddRoundedIcon,
      }}
      onClick={() => {
        showModal({ source });
      }}
      size={ButtonSizes.LARGE}
      theme={ButtonThemes.PRIMARY_DARK}
      type="button"
    >
      Create Project
    </Button>
  );
}
