import clsx from "clsx";

import contracts from "../../../../assets/img/icons/contracts-heart.svg";
import convo from "../../../../assets/img/icons/conversation.svg";
import quotes from "../../../../assets/img/icons/quotes-prices.svg";
import useShowModal from "../../hooks/useShowModal";
import { Link, Typography } from "../../library";
import { borderColorClass } from "../../utils/colors";
import { modals } from "../../utils/enums";
import { trackSaveToProject } from "../../utils/tracking";

import { ApiService, ItemTypeEnum } from "../../generated";
import { handleError } from "../../utils/generatedApi";
import { ProjectCreationSource, type Search, type SearchResult } from "./types";

const SECTIONS = [
  {
    text: "Add contracts in one place",
    img: contracts,
  },
  {
    text: "Get quotes from many suppliers",
    img: quotes,
  },
  {
    text: "Share research with your team",
    img: convo,
  },
];

const DEFAULT_SEARCHES: Search[] = [
  {
    query: "forklift",
    results: [
      { docid: "hgacbuy_em06-19_coastal-equipment-corp-" },
      { docid: "sourcewell_091520-div_doosan-industrial" },
    ],
  },
  {
    query: "shredding",
    results: [
      { docid: "gsa_gs-35f-625ga_titania-ltd" },
      { docid: "equalis-group_cog-2109a_shred-it" },
    ],
  },
  {
    query: "disinfectant",
    results: [
      { docid: "city-of-mesa_2022028_safeware-inc" },
      { docid: "city-of-tucson-az_222882_safeware-inc" },
    ],
  },
];

interface NoProjectSectionProps {
  searches: Search[];
}

export default function NoProjectSection({ searches }: NoProjectSectionProps) {
  const showNewProjectModal = useShowModal(modals.NEW_PROJECT_MODAL);
  const usingDefaultSearches = !!searches.length;
  const displayedSearches = usingDefaultSearches ? DEFAULT_SEARCHES : searches;

  async function saveProjectItem(
    projectId: string,
    itemId: string,
    itemType: ItemTypeEnum
  ) {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId,
        itemType,
      });
      trackSaveToProject({ itemId, itemType });
    } catch (e) {
      handleError(e);
    }
  }

  function showNewProjectModalWithSearch(
    projectName: string,
    contractsToSave: SearchResult[]
  ) {
    async function saveItems(projectId: string) {
      contractsToSave.forEach(({ docid }: SearchResult) => {
        saveProjectItem(projectId, docid, ItemTypeEnum.CONTRACT);
      });
    }

    let initialProjectName = projectName;
    if (projectName) {
      initialProjectName =
        projectName.charAt(0).toUpperCase() + projectName.slice(1);
    }

    showNewProjectModal({
      initialProjectName,
      saveProjectItem: saveItems,
      source: ProjectCreationSource.SUGGESTED_SEARCHES_PROJECT_PAGE,
    });
  }

  return (
    <>
      <div className="flex flex-col md:grid grid-cols-3 gap-6">
        {SECTIONS.map(({ text, img }) => (
          <div
            key={text}
            className={clsx(
              "p-6 border border-solid rounded-2xl text-center flex flex-col gap-4 items-center",
              borderColorClass.brand.subtle.enabled
            )}
          >
            <Typography
              color="neutral.bold.enabled"
              variant="monogram"
              size="sm"
            >
              {text}
            </Typography>
            <img src={img} />
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <Typography
          className="inline-block"
          color="neutral.bolder.enabled"
          variant="body"
          size="md"
        >
          Try creating a project from a{" "}
          {usingDefaultSearches ? "recommended" : "recent"} search:
          {displayedSearches?.map((search) => (
            <Link
              key={search.query}
              className="px-1"
              emphasis={false}
              onClick={() => {
                showNewProjectModalWithSearch(search.query, search.results);
              }}
            >
              {search.query}
            </Link>
          ))}
        </Typography>
      </div>
    </>
  );
}
