import * as yup from "yup";

import type { InputSizes } from "../../library/Input/LabeledInput";
import {
  BUYER_ROLE_FIELDS,
  BuyerLeadAgencyField,
  NAME_FIELDS,
  PHONE_FIELDS_WITH_EXTENSION,
  PasswordInputField,
  SUPPLIER_ROLE_FIELDS,
} from "../../library/form";
import SupplierInputField from "../../library/form/SupplierInputField";
import type { FormFieldProps } from "../../library/form/types";
import type { UserType } from "./types";

export const PASSWORD_FIELDS: FormFieldProps[][] = [
  [
    {
      name: "password",
      label: "Set your password",
      sublabel: "Password must contain 8 characters",
      placeholder: null,
      component: PasswordInputField,
      validate: yup
        .string()
        .required("Password is required.")
        .min(8, "Password must contain 8 characters"),
      className: "analytics-signup-modal-password-input",
      autoComplete: "new-password",
    },
  ],
];

export const BUYER_FIELDS: FormFieldProps[][] = [
  NAME_FIELDS,
  [
    {
      name: "buyerProfile",
      placeholder: null,
      component: BuyerLeadAgencyField,
      className: "analytics-signup-modal-govAffiliation-input",
      dataTestId: "gov-affiliation-input",
      validate: yup.object().shape(
        {
          governmentAffiliationDisplayName: yup
            .string()
            .when("governmentAgency", {
              is: (val?: { id: string }) => !val || !val.id,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
          governmentAgency: yup
            .object({ id: yup.string().nullable() })
            .when("governmentAffiliationDisplayName", {
              is: (val?: string) => !val,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
        },
        [["governmentAgency", "governmentAffiliationDisplayName"]]
      ),
    },
  ],
  BUYER_ROLE_FIELDS,
  ...PASSWORD_FIELDS,
];

export const SUPPLIER_FIELDS: (FormFieldProps[][] | FormFieldProps[])[] = [
  [
    NAME_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
    PHONE_FIELDS_WITH_EXTENSION.map((f) => ({
      ...f,
      size: "sm" as InputSizes,
    })),
    PASSWORD_FIELDS[0].map((f) => ({ ...f, size: "sm" as InputSizes })),
  ],
  [
    {
      name: "supplier",
      placeholder: null,
      component: SupplierInputField,
      validate: yup.object().shape(
        {
          handle: yup.string().nullable(),
          displayName: yup.string().when("handle", {
            is: (val: string | undefined) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          }),
        },
        [["handle", "displayName"]]
      ),
      dataTestId: "business-input",
      className: "analytics-signup-modal-business-input",
      size: "sm",
    },
    ...SUPPLIER_ROLE_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
  ],
];

export const POST_SOCIAL_AUTH_SUPPLIER_FIELDS: (
  | FormFieldProps[][]
  | FormFieldProps[]
)[] = [
  [
    PHONE_FIELDS_WITH_EXTENSION.map((f) => ({
      ...f,
      size: "sm" as InputSizes,
    })),
  ],
  [
    {
      name: "supplier",
      placeholder: null,
      component: SupplierInputField,
      validate: yup.object().shape(
        {
          handle: yup.string().nullable(),
          displayName: yup.string().when("handle", {
            is: (val: string | undefined) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          }),
        },
        [["handle", "displayName"]]
      ),
      dataTestId: "business-input",
      className: "analytics-signup-modal-business-input",
      size: "sm",
    },
    ...SUPPLIER_ROLE_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
  ],
];

export interface UserTypeTab {
  index: number;
  label: string;
  type: UserType;
  placeholder: string;
  title: string;
  subtitle: string;
}

export const findSupplierContactOptions = [
  "Google for contact info",
  "Check supplier's website",
  "Look up supplier in government database",
];

export const contactSupplierMethodOptions = [
  "I email suppliers",
  "I call suppliers",
  "I pass to someone else who contacts",
];

export const signupSurveyOtherOption = "Other (please specify)";

export const userTypeTabData: Record<UserType, UserTypeTab> = {
  supplier: {
    index: 1,
    type: "supplier",
    label: "I'm a supplier",
    placeholder: "name@work.com",
    title: "More sales with less effort",
    subtitle:
      "Sell more to government through contracts you've already won! Get started for free.",
  },
  buyer: {
    index: 0,
    label: "I'm a buyer",
    type: "buyer",
    placeholder: "name@agency.gov",
    title: "Create your account to access contracts, for free always",
    subtitle: "View information like pricing, contact suppliers, and more.",
  },
};

export function reloadPage() {
  window.location.hash = "";
  window.location.reload();
}

export const BUYER_SIGNUP_CONFIRMATION_KEYWORD = [
  "city",
  "county",
  "district",
  "authority",
  "airport",
  "school",
  "regional",
  "state",
  "transit",
  "town",
  "village",
  "department",
  "port",
  "utilities",
  "police",
  "metropolitan",
  "college",
  " of ",
  "water",
  "fire",
  "works",
  "electric",
  "council",
  "commission",
  "boces",
  "sheriff",
  "agency",
  "corporation",
  "center",
  "ISD",
];
