import Table from "../../../library/Table";
import Typography from "../../../library/Typography";
import { formatDate } from "../../../utils/date";
import type { AgencyView } from "../types";

export default function PackageViewAnalyticsTable({
  agencyViewList,
}: { agencyViewList: AgencyView[] }) {
  return (
    <div className="overflow-y-scroll max-h-[32rem] w-full">
      <Table
        columns={[
          {
            key: "displayName",
            label: "Entity",
            render: (v, row) => (
              <Typography
                color={
                  row.obscureName
                    ? "neutral.bold.enabled"
                    : "neutral.bolder.enabled"
                }
                italic={row.obscureName}
              >
                {v}
              </Typography>
            ),
            isSortable: true,
          },
          {
            key: "createdAt",
            label: "Date viewed",
            render: (v) => formatDate(v as string, "MM/dd/yyyy"),
            isSortable: true,
          },
          {
            key: "views",
            label: "Views",
            render: (v) => v?.toLocaleString() || 0,
            isSortable: true,
          },
        ]}
        data={agencyViewList}
        defaultSort={{ key: "createdAt", descending: true }}
        className="w-full"
      />
    </div>
  );
}
