import clsx from "clsx";
import { Field, Form, Formik, type FormikHelpers } from "formik";
import { type ReactElement, useMemo } from "react";
import * as yup from "yup";

import Button, { ButtonThemes } from "../Button";

import type { FormFieldProps, Validate } from "./types";

interface FormWrapperProps<T> {
  fields: FormFieldProps[];
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  submitClassName?: string;
  submitCta?: string;
  disabled?: boolean;
  children?: ReactElement;
  inline?: boolean;
}

export default function FormWrapper<T extends object>({
  fields,
  initialValues,
  onSubmit,
  submitClassName,
  submitCta,
  disabled,
  children,
  inline = false,
}: FormWrapperProps<T>) {
  const validationSchema = useMemo(() => {
    return fields.reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, [fields]);

  const fieldMapFn = (
    {
      name,
      label,
      sublabel,
      placeholder,
      component,
      validate,
      dataTestId,
      ...rest
    }: FormFieldProps,
    index: number
  ) => (
    <div key={index} className="flex flex-col grow gap-2">
      <Field
        key={name}
        name={name}
        label={label}
        message={sublabel}
        sublabel={sublabel}
        component={component}
        placeholder={placeholder}
        validate={validate}
        editable
        dataTestId={dataTestId}
        hasLabel={false}
        {...rest}
      />
    </div>
  );

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      validationSchema={yup.object(validationSchema)}
    >
      <Form>
        <div
          className={clsx("flex w-full", {
            "flex-col gap-6 mb-4": !inline,
            "gap-4": inline,
          })}
        >
          {fields.map(fieldMapFn)}
          {submitCta && (
            <Button
              className={clsx(submitClassName, {
                "w-full": !inline,
                "w-fit h-fit": inline,
              })}
              type="submit"
              disabled={disabled}
              theme={ButtonThemes.PRIMARY_DARK}
            >
              {submitCta}
            </Button>
          )}
          {children}
        </div>
      </Form>
    </Formik>
  );
}
