import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import clsx from "clsx";
import pluralize from "pluralize";
import { useState } from "react";
import { useRecoilValue } from "recoil";

import paperPlaneSparkles from "../../../img/welcome/paperPlaneSparkles.svg";
import useShowModal from "../../hooks/useShowModal";
import { Badge, Button, Link, Typography } from "../../library";
import { AnimatedPopupTypes, SuccessPopup } from "../../popups/AnimatedPopup";
import {
  MessageSupplierRecommendationTypes,
  messageSupplierState,
} from "../../recoil/messageSupplier";
import { profileTypeState } from "../../recoil/user";
import { BUTTON_PROPS } from "../../shared/saveToProjectUtils";
import { bgColorClass } from "../../utils/colors";
import { ProfileType, modals } from "../../utils/enums";
import { trackSaveToProject } from "../../utils/tracking";
import { ProjectCreationSource } from "../Project/types";

import { ApiService, ItemTypeEnum } from "../../generated";
import { handleError } from "../../utils/generatedApi";
import SimilarContractSuppliers from "./SimilarContractSuppliers";

interface MessageSupplierSuccessPageProps {
  userHasProjects: boolean;
  supplierDisplayName: string;
  supplierHandle: string;
  supplierId: number;
  contractId?: string;
  solicitationId?: string;
}

export default function MessageSupplierSuccessPage({
  userHasProjects,
  supplierDisplayName,
  supplierHandle,
  supplierId,
  contractId,
  solicitationId,
}: MessageSupplierSuccessPageProps) {
  const isContract = !!contractId && !!solicitationId;
  const rootKey = isContract ? contractId : supplierHandle;
  const currentMessageSupplierState = useRecoilValue(messageSupplierState);
  const profileType = useRecoilValue(profileTypeState);
  const rootState = currentMessageSupplierState[rootKey];
  const messagedSuppliers = rootState?.messagedSuppliers;
  const isRecommendationPage = rootState?.recommendationTypeLastUsed;
  const numAdditionalSuppliersToMessage = messagedSuppliers
    ? messagedSuppliers.length - 1
    : 0;

  const shouldShowSimilarContracts =
    isContract &&
    rootState?.recommendationTypeLastUsed !==
      MessageSupplierRecommendationTypes.SIMILAR_CONTRACTS;

  const getMessage = () => {
    if (isRecommendationPage) {
      return "We'll pass along your message to the suppliers you contacted. You can expect a response directly from them to your email inbox in 1-3 days.";
    }
    return `We'll send an email to ${supplierDisplayName} representatives
    ${
      numAdditionalSuppliersToMessage > 0
        ? `and ${numAdditionalSuppliersToMessage} other ${pluralize(
            "supplier",
            numAdditionalSuppliersToMessage
          )} `
        : ""
    }
    and help you follow up if needed. Most suppliers respond in 1-3
    business days.`;
  };
  return (
    <div className="bg-cp-white-100 mt-12 mb-80 cp-page-container text-left grid gap-y-10">
      <div className="flex flex-col lg:w-2/3">
        <div
          className={clsx(
            bgColorClass.accent.limeade.enabled,
            "p-6 rounded-2xl flex gap-12"
          )}
        >
          <div className="flex flex-col gap-2">
            <Typography
              color="brand.default.secondary.enabled"
              variant="headline"
              emphasis
            >
              Message sent!
            </Typography>
            <Typography color="neutral.default.primary.enabled">
              {getMessage()}
            </Typography>
            {!userHasProjects && profileType === ProfileType.BUYER && (
              <NewProjectPrompt
                itemId={isContract ? contractId : supplierId.toString()}
                itemType={
                  isContract ? ItemTypeEnum.CONTRACT : ItemTypeEnum.SUPPLIER
                }
              />
            )}
          </div>
          <img src={paperPlaneSparkles} className="h-16 self-center" />
        </div>
      </div>
      {shouldShowSimilarContracts && (
        <SimilarContractSuppliers
          supplierId={supplierId}
          supplierHandle={supplierHandle}
          contractId={contractId}
          solicitationId={solicitationId}
        />
      )}
    </div>
  );
}

type NewProjectPromptProps = {
  itemId: string;
  itemType: ItemTypeEnum;
};

function NewProjectPrompt({ itemId, itemType }: NewProjectPromptProps) {
  const showNewProjectModal = useShowModal(modals.NEW_PROJECT_MODAL);
  const [savedProjectId, setSavedProjectId] = useState("");
  const [showSavedPopup, setShowSavedPopup] = useState(false);

  async function saveProjectItem(projectId: string) {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId,
        itemType,
      });
      setSavedProjectId(projectId);
      setShowSavedPopup(true);
      trackSaveToProject({ itemId, itemType });
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <>
      <Typography
        variant="headline"
        size="sm"
        color="brand.boldest.enabled"
        className="mt-8"
        emphasis
      >
        Want to keep track of this {itemType.toLowerCase()}?
      </Typography>
      <Typography color="neutral.default.primary.enabled">
        You can save this {itemType.toLowerCase()} to an existing project or
        create a new project to ensure that you don{"'"}t lose these contract
        details.
      </Typography>
      <Button
        theme={Button.themes.PRIMARY_DARK}
        onClick={() => {
          showNewProjectModal({
            newProjectPrompt: true,
            saveProjectItem,
            itemId,
            itemType,
            source: ProjectCreationSource.MESSAGE_SUPPLIER,
          });
        }}
        className="analytics-post-message-new-project-prompt-button w-fit"
        dataTestId="post-message-new-project-prompt-button"
        size={Button.sizes.SMALL}
      >
        Save {itemType.toLowerCase()}
      </Button>
      <SuccessPopup
        type={AnimatedPopupTypes.TIMED}
        show={showSavedPopup}
        durationSeconds={5}
        setShow={setShowSavedPopup}
      >
        <Badge Icon={CheckRoundedIcon} iconClass="!mr-4">
          <Typography color="inverse">
            {BUTTON_PROPS[itemType].viewSavedCtaCopy}{" "}
            <Link
              color="inverse"
              emphasis={false}
              href={`/projects/${savedProjectId}`}
            >
              here
            </Link>
            .
          </Typography>
        </Badge>
      </SuccessPopup>
    </>
  );
}
