import { atom } from "recoil";

import { browserLocalStorage, browserSessionStorage } from "../utils";
import { isFreshSEOUser } from "../utils/tracking";

import { getLocalStorageEffect } from "./util";

export const isFreshSEOUserState = atom({
  key: "isFreshSEOUserState",
  default: isFreshSEOUser(),
});

export const isFreshSignupState = atom({
  key: "isFreshSignupState",
  default: browserSessionStorage.get("isFreshSignupState") || false,
  effects: [getLocalStorageEffect("isFreshSignupState", true)],
});

export const hasSeenProjectPromptState = atom({
  key: "hasSeenProjectPromptState",
  default: browserLocalStorage.get("hasSeenProjectPromptState") || false,
  effects: [getLocalStorageEffect("hasSeenProjectPromptState", true)],
});

export const hasVisitedProfilePageState = atom({
  key: "hasVisitedProfilePageState",
  default: browserLocalStorage.get("userHasVisitedProfilePage") || false,
  effects: [getLocalStorageEffect("userHasVisitedProfilePage")],
});

export const optedInOutreachSuppliersState = atom({
  key: "optedInOutreachSuppliers",
  default: browserLocalStorage.get("optedInOutreachSuppliers") || [],
  effects: [getLocalStorageEffect("optedInOutreachSuppliers")],
});

export const hasDismissedPurchasingGuideBannerState = atom({
  key: "hasDismissedPurchasingGuideBanner",
  default:
    browserSessionStorage.get("hasDismissedPurchasingGuideBanner") || false,
  effects: [getLocalStorageEffect("hasDismissedPurchasingGuideBanner", true)],
});
