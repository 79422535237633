import clsx from "clsx";
import { useEffect, useState } from "react";

import usePaginatedQuery from "../../../../../hooks/usePaginatedQuery";
import { RadioButtonGroup, Typography } from "../../../../../library";
import { LoadingCards } from "../../../../../shared/Loading/LoadingCards";
import NoContractsInfo from "../../../../../shared/NoContractsInfo";
import { getParam } from "../../../../../utils";
import {
  getSupplierConfirmedExpiredContracts,
  getSupplierContractLimitedNonProContracts,
  getSupplierNeedsReviewContracts,
  getSupplierOptimizedContracts,
  getSupplierUnsupportedContracts,
} from "../../../../../utils/api";
import { borderColorClass } from "../../../../../utils/colors";
import { MANAGE_CONTRACTS_FILTER_OPTIONS } from "../../../../../utils/constants";

import SupplierManageContractList from "./SupplierManageContractsList";

const CONTAINER_STYLE = "flex flex-col gap-6";

const SECTION_STYLE = clsx(
  "flex flex-col gap-8 border-b last:border-b-0 border-solid pb-12",
  borderColorClass.neutral.subtle.enabled
);
interface ProSupplierContractsProps {
  id: number;
  handle: string;
}
export default function SupplierManageContracts({
  id,
  handle,
}: ProSupplierContractsProps) {
  const filterParam = getParam("filterBy");
  const [filterBy, setFilterBy] = useState(
    filterParam || (MANAGE_CONTRACTS_FILTER_OPTIONS.ALL as string)
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hasLoadedInitial, setHasLoadedInitial] = useState(0);

  const {
    list: needsReviewContracts,
    fetchMore: fetchMoreNeedsReviewContracts,
    isLoading: needsReviewContractsLoading,
    hasNext: hasMoreNeedsReviewContracts,
    count: needsReviewContractsCount,
    refreshList: refreshNeedsReviewContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      getSupplierNeedsReviewContracts({
        page,
        pageSize: 10,
        supplierId: id,
        filterBy,
      }),
    onResponse: ({
      contracts,
      count,
      numExpiredContracts,
      numMissingDocsContracts,
    }) => {
      setHasLoadedInitial((prev) => prev + 1);
      if (count === 0 && filterBy !== MANAGE_CONTRACTS_FILTER_OPTIONS.ALL) {
        setFilterBy(MANAGE_CONTRACTS_FILTER_OPTIONS.ALL);
        return {
          list: contracts,
          count,
        };
      }
      if (numExpiredContracts === 0 || numMissingDocsContracts === 0) {
        setShowFilter(false);
      } else {
        setShowFilter(true);
      }
      return {
        list: contracts,
        count,
      };
    },
  });

  const {
    list: optimizedContractList,
    fetchMore: fetchMoreOptimizedContracts,
    isLoading: optimizedContractsLoading,
    hasNext: hasMoreOptimizedContracts,
    count: optimizedContractsCount,
    refreshList: refreshOptimizedContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      getSupplierOptimizedContracts({
        page,
        pageSize: 10,
        supplierId: id,
      }),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const {
    list: confirmedExpiredContractList,
    fetchMore: fetchMoreConfirmedExpiredContracts,
    isLoading: confirmedExpiredContractsLoading,
    hasNext: hasMoreConfirmedExpiredContracts,
    count: confirmedExpiredContractsCount,
    refreshList: refreshConfirmedExpiredContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      getSupplierConfirmedExpiredContracts({
        page,
        pageSize: 10,
        supplierId: id,
      }),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const {
    list: unsupportedContractList,
    fetchMore: fetchMoreUnsupportedContracts,
    isLoading: unsupportedContractsLoading,
    hasNext: hasMoreUnsupportedContracts,
    count: unsupportedContractsCount,
    refreshList: refreshUnsupportedContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      getSupplierUnsupportedContracts({
        page,
        pageSize: 10,
        supplierId: id,
      }),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const {
    list: contractLimitedNonProContractList,
    fetchMore: fetchMoreContractLimitedNonProContracts,
    isLoading: contractLimitedNonProContractsLoading,
    hasNext: hasMoreContractLimitedNonProContracts,
    count: contractLimitedNonProContractsCount,
    refreshList: refreshContractLimitedNonProContracts,
  } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      getSupplierContractLimitedNonProContracts({
        page,
        pageSize: 10,
        supplierId: id,
      }),
    onResponse: ({ contracts, count }) => {
      setHasLoadedInitial((prev) => prev + 1);
      return {
        list: contracts,
        count,
      };
    },
  });

  const refreshAllContracts = () => {
    refreshNeedsReviewContracts();
    refreshConfirmedExpiredContracts();
    refreshOptimizedContracts();
    refreshUnsupportedContracts();
    refreshContractLimitedNonProContracts();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch when the filter clause changes.
  useEffect(() => {
    refreshNeedsReviewContracts();
  }, [filterBy]);

  const noContractsAvailable =
    !needsReviewContractsCount &&
    !optimizedContractsCount &&
    !confirmedExpiredContractsCount &&
    !unsupportedContractsCount &&
    !contractLimitedNonProContractsCount;

  if (
    noContractsAvailable &&
    !needsReviewContractsLoading &&
    !optimizedContractsLoading &&
    !confirmedExpiredContractsLoading &&
    !unsupportedContractsLoading &&
    !contractLimitedNonProContractsLoading &&
    hasLoadedInitial >= 3
  ) {
    return <NoContractsInfo />;
  }

  return (
    <div className="flex flex-col gap-6">
      {(!!needsReviewContractsCount || needsReviewContractsLoading) && (
        <div className={SECTION_STYLE}>
          <div className="flex flex-col gap-3">
            <Typography
              emphasis
              size="sm"
              variant="headline"
              color="brand.default.secondary.enabled"
            >
              Contracts that need review{" "}
              {needsReviewContractsCount
                ? `(${needsReviewContractsCount})`
                : ""}
            </Typography>
            {showFilter && (
              <div className="flex gap-4">
                <Typography variant="meta">Sort by:</Typography>

                <RadioButtonGroup
                  value={filterBy}
                  onChange={(option) => {
                    setFilterBy(option as string);
                  }}
                  options={[
                    {
                      key: MANAGE_CONTRACTS_FILTER_OPTIONS.ALL,
                      label: "All contracts",
                      className:
                        "analytics-supplier-manage-contracts-filter-by-all",
                    },
                    {
                      key: MANAGE_CONTRACTS_FILTER_OPTIONS.MISSING_DOCS,
                      label: "Contracts missing documents",
                      className:
                        "analytics-supplier-manage-contracts-filter-by-missing-docs",
                    },
                    {
                      key: MANAGE_CONTRACTS_FILTER_OPTIONS.EXPIRED,
                      label: "Expired contracts",
                      className:
                        "analytics-supplier-manage-contracts-filter-by-expired-contracts",
                    },
                  ]}
                  labelClassName="mt-0"
                  className="flex !flex-row"
                />
              </div>
            )}
          </div>
          {!!needsReviewContractsCount && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={needsReviewContracts}
              fetchMore={fetchMoreNeedsReviewContracts}
              isLoading={needsReviewContractsLoading}
              hasNext={hasMoreNeedsReviewContracts}
              refreshAllContracts={refreshAllContracts}
              actionFilter={filterBy}
            />
          )}
          {!needsReviewContractsCount && needsReviewContractsLoading && (
            <LoadingCards className={CONTAINER_STYLE} />
          )}
        </div>
      )}
      {(!!optimizedContractsCount || optimizedContractsLoading) && (
        <div className={SECTION_STYLE}>
          <Typography
            emphasis
            size="sm"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Optimized contracts
          </Typography>
          {!optimizedContractsCount && optimizedContractsLoading && (
            <LoadingCards className={CONTAINER_STYLE} />
          )}
          {!!optimizedContractsCount && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={optimizedContractList}
              fetchMore={fetchMoreOptimizedContracts}
              isLoading={optimizedContractsLoading}
              hasNext={hasMoreOptimizedContracts}
              refreshAllContracts={refreshAllContracts}
              showActions={true}
            />
          )}
        </div>
      )}
      {(!!confirmedExpiredContractsCount ||
        confirmedExpiredContractsLoading) && (
        <div className={SECTION_STYLE}>
          <Typography
            emphasis
            size="sm"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Expired contracts
          </Typography>
          {!confirmedExpiredContractsCount &&
            confirmedExpiredContractsLoading && (
              <LoadingCards className={CONTAINER_STYLE} />
            )}
          {!!confirmedExpiredContractsCount && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={confirmedExpiredContractList}
              fetchMore={fetchMoreConfirmedExpiredContracts}
              isLoading={confirmedExpiredContractsLoading}
              hasNext={hasMoreConfirmedExpiredContracts}
              refreshAllContracts={refreshAllContracts}
              showActions={true}
            />
          )}
        </div>
      )}
      {(!!contractLimitedNonProContractsCount ||
        contractLimitedNonProContractsLoading) && (
        <div className={SECTION_STYLE}>
          <Typography
            emphasis
            size="sm"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Contracts that are not within your pro package
          </Typography>
          {!contractLimitedNonProContractsCount &&
            contractLimitedNonProContractsLoading && (
              <LoadingCards className={CONTAINER_STYLE} />
            )}
          {!!contractLimitedNonProContractsCount && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={contractLimitedNonProContractList}
              fetchMore={fetchMoreContractLimitedNonProContracts}
              isLoading={contractLimitedNonProContractsLoading}
              hasNext={hasMoreContractLimitedNonProContracts}
              refreshAllContracts={refreshAllContracts}
              showActions={false}
            />
          )}
        </div>
      )}
      {(!!unsupportedContractsCount || unsupportedContractsLoading) && (
        <div className={SECTION_STYLE}>
          <Typography
            emphasis
            size="sm"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Unsupported contracts
          </Typography>
          {!unsupportedContractsCount && unsupportedContractsLoading && (
            <LoadingCards className={CONTAINER_STYLE} />
          )}
          {!!unsupportedContractsCount && (
            <SupplierManageContractList
              id={id}
              handle={handle}
              contracts={unsupportedContractList}
              fetchMore={fetchMoreUnsupportedContracts}
              isLoading={unsupportedContractsLoading}
              hasNext={hasMoreUnsupportedContracts}
              refreshAllContracts={refreshAllContracts}
              showActions={false}
            />
          )}
        </div>
      )}
    </div>
  );
}
