import clsx from "clsx";
import _compact from "lodash/compact";
import _escapeRegExp from "lodash/escapeRegExp";
import { singular } from "pluralize";
import type { ElementType } from "react";
import { v4 as uuid } from "uuid";
import Typography from "./Typography";
import type { TypographyColor, TypographySize } from "./Typography/types";

interface BoldedTextProps {
  text: string;
  highlight: string;
  size?: TypographySize;
  textColor?: TypographyColor;
  highlightColor?: string;
  includeSingular?: boolean;
  className?: string;
  component?: ElementType;
}

export default function BoldedText({
  text,
  highlight,
  size,
  textColor,
  highlightColor,
  includeSingular = true,
  className,
  component,
}: BoldedTextProps) {
  const regExp = includeSingular
    ? `(${_escapeRegExp(highlight)}|${_escapeRegExp(singular(highlight))})`
    : `(${_escapeRegExp(highlight)})`;
  const parts = _compact(text.split(new RegExp(regExp, "gi")));
  return (
    <Typography
      size={size}
      component={component}
      className={className}
      color={textColor}
      aria-label={text}
    >
      {parts.map((part) => (
        <Typography
          size={size}
          key={uuid()}
          component="span"
          className={
            highlight.toLowerCase().includes(part.toLowerCase())
              ? clsx("font-semibold", highlightColor)
              : ""
          }
        >
          {part}
        </Typography>
      ))}
    </Typography>
  );
}
