import { type CallbackInterface, DefaultValue, atom, selector } from "recoil";
import { ApiService, type SemanticKeywordAndOffering } from "../generated";
import { handleError } from "../utils/generatedApi";

export const contractScopeListState = atom<SemanticKeywordAndOffering>({
  key: "contractScopeListState",
  default: { keywords: [], offerings: [] },
});

export const contractScopeInitializedState = atom<boolean>({
  key: "contractScopeInitializedState",
  default: false,
});

export const contractScopeState = selector({
  key: "contractScopeState",
  get: ({ get }) => {
    return {
      isInitialized: get(contractScopeInitializedState),
      scope: get(contractScopeListState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    set(contractScopeListState, value.scope);
    set(contractScopeInitializedState, value.isInitialized);
  },
});
1;
export function initializeContractScopeCallback({
  snapshot,
  set,
}: CallbackInterface) {
  return async (contractId: string, query: string) => {
    const [contractScope] = await Promise.all([
      snapshot.getPromise(contractScopeState),
    ]);
    if (contractScope.isInitialized) return;
    try {
      const response = await ApiService.apiV1ContractsSemanticScopeRetrieve(
        contractId,
        query
      );
      set(contractScopeState, {
        scope: response,
        isInitialized: true,
      });
    } catch (error) {
      handleError(error);
      set(contractScopeState, {
        scope: { keywords: [], offerings: [] },
        isInitialized: true,
      });
    }
  };
}
