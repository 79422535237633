import type { FormikValues } from "formik";
import pluralize from "pluralize";
import * as yup from "yup";

import useShowModal from "../hooks/useShowModal";
import { ChipInputField, FormWrapper, TextareaField } from "../library/form";
import Modal from "../shared/Modal/Modal";
import { getRequestID } from "../utils";
import { createSharedLink, handleError } from "../utils/api";
import { type SharedLinkType, modals } from "../utils/enums";
import { trackShareSearchSubmit } from "../utils/tracking";

import { useSubmitAndSave } from "./constants";

const FIELDS = [
  {
    name: "emails",
    label: "Email addresses",
    component: ChipInputField,
    validate: yup
      .array()
      .min(
        1,
        "Enter at least one email address to share this search with your colleagues."
      ),
  },
  {
    name: "message",
    label: "Add a personal message (optional)",
    placeholder: "Let your teammates know why you're sharing this search.",
    component: TextareaField,
  },
];

export default function ShareLinkModal({
  hideModal,
  sharedUrl,
  shareType,
}: InviteTeamModalProps) {
  const showConfirmationModal = useShowModal(modals.CONFIRMATION);
  const [handleSubmit, loading] = useSubmitAndSave(
    () => hideModal,
    ({ emails, message }: FormikValues) => {
      trackShareSearchSubmit({
        requestID: getRequestID(),
        sharedUrl,
        shareType,
        numEmails: emails.length,
      });
      showConfirmationModal({
        title: "Search Shared!",
        ctaText: "Close",
        subtitle: `Your ${pluralize("colleague", emails.length)}
        will be able to view the contracts and suppliers you shared.`,
      });
      createSharedLink({ emails, message, sharedUrl, shareType }).catch((e) =>
        handleError(e, {
          logToSentry: true,
          log400ErrorsToSentry: true,
        })
      );
    }
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Do you want to share this search with a colleague?"
      subtitle="Invite your coworkers to Pavilion to view the same contract results. We’ll send them an email with a personal invite link."
    >
      <FormWrapper
        fields={FIELDS}
        initialValues={{
          emails: [],
          message: "",
        }}
        submitCta="Share"
        disabled={loading}
        submitClassName="analytics-share-link"
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}

interface InviteTeamModalProps {
  hideModal: () => void;
  sharedUrl: string;
  shareType: SharedLinkType;
}
