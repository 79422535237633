import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { type ReactNode, Suspense, lazy, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import pluralize from "pluralize";
import { Badge, ProgressBar, Tooltip, Typography } from "../../../library";
import GenericCard from "../../../library/GenericCard";
import { userState } from "../../../recoil/user";
import Tag, { TagVariants } from "../../../shared/Tag";
import { fetchSupplierViewAnalytics, handleError } from "../../../utils/api";
import { formatDate, parseDate } from "../../../utils/date";
import PackageViewAnalyticsTable from "../SupplierWelcomePage/PackageViewAnalyticsTable";
import type { ViewAnalytics } from "../types";

const NoAnalyticsNotice = lazy(() => import("./NoAnalyticsNotice"));

function MetricHeading({
  title,
  subtitle,
  tooltip,
}: {
  title: string;
  subtitle: string;
  tooltip: ReactNode;
}) {
  return (
    <div className="grid gap-1">
      <Tooltip info={tooltip} className="max-w-88">
        <Badge
          as={Typography}
          linkProps={{
            variant: "headline",
            size: "sm",
            color: "brand.boldest.enabled",
          }}
          Icon={InfoOutlinedIcon}
          reverse
          size="sm-md"
        >
          {title}
        </Badge>
      </Tooltip>
      <Typography variant="meta" color="neutral.bold.enabled">
        {subtitle}
      </Typography>
    </div>
  );
}

function MetricCard({
  title,
  subtitle,
  loading = false,
  tooltip,
  children,
}: {
  title: string;
  subtitle: string;
  loading?: boolean;
  tooltip: ReactNode;
  children: ReactNode;
}) {
  return (
    <GenericCard responsive className="gap-y-6">
      <MetricHeading title={title} subtitle={subtitle} tooltip={tooltip} />
      {loading ? (
        <div className="rounted-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine h-20 w-full m-auto" />
      ) : (
        children
      )}
    </GenericCard>
  );
}

export default function SupplierAnalytics() {
  const { supplier } = useRecoilValue(userState);

  const [loading, setLoading] = useState(false);

  const [viewAnalytics, setViewAnalytics] = useState<ViewAnalytics>({
    qualifiedEntityViews: 0,
    agencyViewList: [],
    totalViews: 0,
    viewsInPackage: 0,
    dateViewsStart: "",
    totalViewsThisWeek: 0,
    leads: [],
    sessions: [],
  });

  useEffect(() => {
    if (!supplier?.handle) return;

    (async () => {
      setLoading(true);
      const response = await fetchSupplierViewAnalytics(
        supplier.handle as string
      );
      setLoading(false);
      if (handleError(response)) return;
      const data = await response.json();
      setViewAnalytics(data);
    })();
  }, [supplier]);

  // Avoid divide by 0 errors if the package has no views.
  const progress = viewAnalytics.viewsInPackage
    ? Math.min(
        (viewAnalytics.qualifiedEntityViews / viewAnalytics.viewsInPackage) *
          100,
        100
      )
    : 0;

  return (
    <div className="grid gap-4">
      <Typography variant="meta" color="neutral.bold.enabled">
        Views start counting when your Pro account is activated.
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <MetricCard
          title="Qualified entity views tracker"
          subtitle="Updated live"
          loading={loading}
          tooltip={
            <Typography size="sm" color="inverse">
              The number of unique entities who have viewed your pages through a
              relevant search on Pavilion. Multiple views from the same entity
              within a given month are as 1 qualified entity view.
              <br />
              <br />
              <i>
                Views start counting on{" "}
                {formatDate(
                  parseDate(viewAnalytics.dateViewsStart),
                  "MM/dd/yyyy"
                )}
                .
              </i>
            </Typography>
          }
        >
          <Typography
            variant="display"
            size="xl"
            color="brand.boldest.enabled"
            className="text-center"
          >
            {
              // Never show more qualified entity views than views in package.
              Math.min(
                viewAnalytics.qualifiedEntityViews,
                viewAnalytics.viewsInPackage
              )
            }
          </Typography>
          <div className="grid gap-1">
            <ProgressBar items={[{ progress }]} />
            <Typography
              variant="meta"
              color="neutral.bold.enabled"
              className="text-right"
            >
              {viewAnalytics.viewsInPackage} Qualified Entity Views purchased
              starting{" "}
              {formatDate(parseDate(viewAnalytics.dateViewsStart), "MMMM d, y")}
            </Typography>
          </div>
        </MetricCard>
        <MetricCard
          title="Total entity views"
          subtitle="Updated live"
          loading={loading}
          tooltip="The number of times unique entities view your pages. Multiple views from the same entity are counted as one view within a given month."
        >
          <Typography
            variant="display"
            size="xl"
            color="brand.boldest.enabled"
            className="text-center"
          >
            {viewAnalytics.totalViews}
          </Typography>
          {!!viewAnalytics.totalViewsThisWeek && (
            <Tag
              variant={TagVariants.NEW_VIEWS}
              Icon={AutoAwesomeOutlinedIcon}
              className="w-full justify-center py-2"
            >
              <Typography size="sm" color="feedback.bold.success">
                {viewAnalytics.totalViewsThisWeek} new{" "}
                {pluralize("view", viewAnalytics.totalViewsThisWeek)} this week!
              </Typography>
            </Tag>
          )}
        </MetricCard>
      </div>
      <GenericCard responsive>
        <MetricHeading
          title="Buyer insights"
          subtitle="Updated live"
          tooltip={
            <Typography size="sm" color="inverse">
              Public entities that have viewed your pages on Pavilion.
            </Typography>
          }
        />
        <PackageViewAnalyticsTable
          agencyViewList={viewAnalytics.agencyViewList}
        />
        {!viewAnalytics.agencyViewList.length && (
          <Suspense fallback={null}>
            <NoAnalyticsNotice />
          </Suspense>
        )}
      </GenericCard>
    </div>
  );
}
