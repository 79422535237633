import type { FormikValues } from "formik";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import * as yup from "yup";

import { AccountsService, type ValidEmailChangeRequest } from "../../generated";
import { Typography } from "../../library";
import { InlineFormWrapper, PasswordInputField } from "../../library/form";
import LabeledInputField from "../../library/form/LabeledInputField";
import type { FormFieldProps } from "../../library/form/types";
import { userEmailState } from "../../recoil/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { GOV_EMAIL_REGEX } from "../../utils/constants";
import { handleError } from "../../utils/generatedApi";

function getFields(
  email: string,
  requireGovEmail: boolean
): FormFieldProps[][] {
  let emailValidation = yup
    .string()
    .email("Please enter a valid email")
    .test(
      "is-same",
      "New email entered is the same as old email.",
      (value) => value !== email
    );
  if (requireGovEmail) {
    emailValidation = emailValidation.matches(
      GOV_EMAIL_REGEX,
      "Email address is not a government email"
    );
  }

  return [
    [
      {
        name: "newEmail",
        label: "New email address",
        component: LabeledInputField,
        validate: emailValidation.required("Email cannot be empty."),
      },
    ],
    [
      {
        name: "password",
        label: "Confirm your password",
        placeholder: null,
        component: PasswordInputField,
        validate: yup.string().required("Password is required."),
        className: "analytics-signup-modal-password-input",
      },
    ],
  ];
}
export default function ChangeEmailModal({
  hideModal,
  onComplete,
  title,
  subtitle,
  isBlocking = false,
  canSkip = true,
  requireGovEmail = false,
}: ChangeEmailModalProps) {
  const email = useRecoilValue(userEmailState);
  const [isCTALoading, setIsCTALoading] = useState(false);
  const [invalidRequest, setInvalidRequest] = useState("");

  async function updateEmail(values: FormikValues) {
    setIsCTALoading(true);
    try {
      await AccountsService.accountsEmailChangeCreate(
        values as ValidEmailChangeRequest
      );
      hideModal();
      if (onComplete) {
        onComplete(values.newEmail);
      }
      setIsCTALoading(false);
    } catch (err) {
      handleError(err);
      setInvalidRequest("Invalid email or password.");
      setIsCTALoading(false);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      title={title}
      subtitle={subtitle}
      contentClassName="no-scrollbar"
      className="analytics-change-email-modal w-full"
      modalSize={modalSizes.SMALL}
    >
      <InlineFormWrapper
        fields={getFields(email, requireGovEmail)}
        initialValues={{ newEmail: "", password: "" }}
        onSubmit={updateEmail}
        submitClassName="analytics-change-email-cta"
        submitCta="Save email"
        disabled={isCTALoading}
      />
      {canSkip && (
        <Typography
          emphasis
          color="brand.default.primary.enabled"
          className="analytics-signup-contract-preferences-skip max-w-fit text-center mx-auto mt-3 cursor-pointer"
          onClick={hideModal}
        >
          Skip
        </Typography>
      )}
      {invalidRequest && (
        <Typography
          className="mt-2 text-center"
          color="destructive.default.primary.enabled"
          emphasis
        >
          Error changing email. Reason: {invalidRequest}
        </Typography>
      )}
    </Modal>
  );
}

interface ChangeEmailModalProps {
  title?: string;
  subtitle?: string;
  hideModal: () => void;
  onComplete?: (x: unknown) => void;
  isBlocking?: boolean;
  canSkip?: boolean;
  requireGovEmail?: boolean;
}
