import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import { type RecoilState, useRecoilCallback } from "recoil";
import { v4 as uuidv4 } from "uuid";

import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
} from "../components/ContractSearch/types";
import {
  type SearchParamsValidation,
  formatSearchPageParams,
} from "../components/ContractSearch/utils";
import {
  type EntityContractResponse,
  entityContractsAnalyticsParamsState,
  entityContractsIsLoadingState,
  entityContractsParamsState,
  entityContractsResponseDataState,
} from "../recoil/search";
import { handleError as handleGeneratedError } from "../utils/generatedApi";

import { ApiService } from "../generated";

export function shouldUpdateRequestID(
  oldSearchParams: SearchParams,
  newSearchParams: SearchParams
) {
  // Only update the request ID if we have meaningfully changed the
  // params. The only fields that count this as a new request for analytics
  // are query, zip, and filters.
  const paramsToInclude = ["query", "filters"];

  const oldComparedParams = _pick(oldSearchParams, paramsToInclude);
  const newComparedParams = _pick(newSearchParams, paramsToInclude);

  if (!oldSearchParams || _isEqual(oldComparedParams, newComparedParams)) {
    return false;
  }
  return true;
}

export function validateEntityContractsParams(
  contractSearchParams: ContractSearchParams
): SearchParamsValidation {
  if (contractSearchParams.query.length > 512) {
    return {
      valid: false,
      errorMessage: "Your search is too long, please shorten it and try again",
    };
  }
  return { valid: true };
}

const searchContracts = async (
  requestID: string,
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  query?: string,
  filters?: string[]
) => {
  set(entityContractsIsLoadingState, true);
  set(entityContractsResponseDataState, null);
  try {
    const response = await ApiService.apiV1EntityContractsCreate({
      query: query || "",
      filters,
      requestID,
    });
    set(entityContractsResponseDataState, response as EntityContractResponse);
    set(entityContractsIsLoadingState, false);
    return response;
  } catch (err) {
    handleGeneratedError(err);
    set(entityContractsResponseDataState, {
      contractData: {
        numAllResults: 0,
        numShowingResults: 0,
        numStrongResults: 0,
        results: [],
      },
      metadata: null,
      params: null,
      agencyData: null,
      prioritizedEntityMatchData: null,
    });
    set(entityContractsIsLoadingState, false);
    return null;
  }
};

export default function useEntityContracts() {
  const search = useRecoilCallback<[SearchOptions], void>(
    ({ snapshot, set }) =>
      async ({ newParams = null }) => {
        const [searchParams, analyticsParams] = await Promise.all([
          snapshot.getPromise(entityContractsParamsState),
          snapshot.getPromise(entityContractsAnalyticsParamsState),
        ]);
        const params = formatSearchPageParams(searchParams);
        const combinedParams = {
          ...params,
          ...newParams,
        } as ContractSearchParams;

        const validation = validateEntityContractsParams(combinedParams);
        if (!validation.valid) {
          set(entityContractsResponseDataState, {
            contractData: {
              numAllResults: 0,
              numShowingResults: 0,
              numStrongResults: 0,
              results: [],
            },
            metadata: null,
            params: null,
            agencyData: null,
            prioritizedEntityMatchData: null,
            errorMessage: validation.errorMessage || "",
          });
          return;
        }
        const { query, page, filters, searchSource } = combinedParams;

        // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
        // PLEASE COORDINATE IF THESE CHANGE.
        const updatedParams: SearchParams = {
          query: query || "",
          zip: "",
          page: page?.toString() || "",
          filters: filters?.join(";") || "",
        };

        // Update search params local state, which binds to URL param changes.
        if (!_isEqual(searchParams, updatedParams)) {
          set(entityContractsParamsState, updatedParams);
        }

        const requestID = shouldUpdateRequestID(searchParams, updatedParams)
          ? uuidv4()
          : analyticsParams.requestID || uuidv4();

        const contractResponse = await searchContracts(
          requestID,
          set,
          query,
          filters
        );
        if (contractResponse) {
          let params: SearchParams;
          params = {
            query: contractResponse.params?.query || "",
            zip: contractResponse.params?.zip || "",
            page: contractResponse.params?.page?.toString() || "",
            filters: contractResponse.params?.filters?.join(";") || "",
          };
          if (contractResponse.params?.embedSourceEntityId) {
            params = {
              ...params,
              embedSourceEntityId: contractResponse.params.embedSourceEntityId,
            };
          }
          set(entityContractsParamsState, params);
          set(entityContractsAnalyticsParamsState, {
            requestID: contractResponse.params?.requestId || "",
            searchSource: searchSource || "",
          });
        }
        return null;
      },
    []
  );

  return search;
}
