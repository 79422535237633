import type { SearchOptions } from "../../../components/ContractSearch/types";
import useResetAndGetFilters from "../../../hooks/useResetAndGetFilters";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { SearchActions } from "../../../utils/enums";

export default function useResetFiltersAndSearch(
  onSearch?: (options: SearchOptions) => void
) {
  const searchContractWithParams = onSearch || useSearchContractWithParams();
  const resetAndGetFilters = useResetAndGetFilters();
  return () => {
    const filters = resetAndGetFilters();
    searchContractWithParams({
      newParams: { filters },
      action: SearchActions.SAVE_FILTERS,
    });
  };
}
