import { EditOutlined, SettingsOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { isFeatureEnabled } from "../../../utils/split";

import useSendEmailVerification from "../../../hooks/useSendEmailVerification";
import useShowModal from "../../../hooks/useShowModal";
import useShowSignupSurvey from "../../../hooks/useShowSignupSurvey";
import { Button, Link, PageSection, Typography } from "../../../library";
import { CARD_WIDTH_STYLE } from "../../../library/GenericCard";
import { recentSuccessfulSearchesState } from "../../../recoil/search";
import {
  type IUserStateState,
  buyerProfileState,
  userState as recoilUserState,
  userEmailVerifiedState,
  userInitializedState,
  userStateState,
} from "../../../recoil/user";
import { ContractSourcesIcon } from "../../../shared/CustomIcons/ContractSourcesIcon";
import { InviteTeamIcon } from "../../../shared/CustomIcons/InviteTeamIcon";
import { ManagePreferencesIcon } from "../../../shared/CustomIcons/ManagePreferencesIcon";
import { VerifyEmailIcon } from "../../../shared/CustomIcons/VerifyEmailIcon";
import { LoadingCards } from "../../../shared/Loading/LoadingCards";
import { recommendedSearchesByAgencyType } from "../../../shared/SuggestedSearches";
import { patchUserState } from "../../../utils/api";
import { INVITE_TEAM_SOURCE, MODAL_SOURCE, modals } from "../../../utils/enums";
import {
  trackClickInviteTeam,
  trackViewHomepage,
} from "../../../utils/tracking";
import { bannerClass, bannerHeightClass } from "../constants";
import BuyerSupportCard from "./BuyerSupportCard";
import SidebarCard, { type SidebarCardProps } from "./SidebarCard";
import WorkspaceSection from "./WorkspaceSection";

const SearchBarCard = lazy(() => import("../../../shared/SearchBarCard"));
const WelcomeBanner = lazy(() => import("../WelcomeBanner"));
import { QuoteRequestFormBanner } from "../../../components/QuoteRequest/QuoteRequestCard";

function QuickLinkButtons() {
  const buttonConfig = [
    {
      analyticsSuffix: "manage-preferences",
      icon: SettingsOutlined,
      href: "/profile#preferences",
      text: "Manage preferences",
    },
    {
      analyticsSuffix: "edit-profile",
      icon: EditOutlined,
      href: "/profile#myInfo",
      text: "Edit profile",
    },
  ];
  return (
    <>
      {buttonConfig.map((button) => (
        <Button
          key={`quick-link-${button.analyticsSuffix}`}
          as={Link}
          className={clsx(
            `analytics-welcome-quick-link-${button.analyticsSuffix}`,
            "w-fit"
          )}
          badgeProps={{ Icon: button.icon, reverse: true }}
          linkProps={{
            // Keep in sync with the tab hash in ProfilePage.tsx
            href: button.href,
            underline: false,
            newWindow: false,
          }}
          size={Button.sizes.SMALL}
          theme={Button.themes.PRIMARY_LIGHT}
        >
          {button.text}
        </Button>
      ))}
    </>
  );
}

function BuyerProfileInfo({
  userRole,
  governmentAffiliationDisplayName,
}: {
  userRole: Maybe<string>;
  governmentAffiliationDisplayName: Maybe<string>;
}) {
  const addYourTitleLink = (
    <Link
      href="/profile?open=role#myInfo"
      color="neutral.bolder.enabled"
      className="analytics-welcome-add-your-role"
      emphasis={false}
      newWindow={false}
    >
      Add your title
    </Link>
  );
  return (
    // For large breakpoints, match `CARD_WIDTH_STYLE` maximum width
    <div className="w-full lg:max-w-[21rem]">
      {!userRole && addYourTitleLink}
      <Typography
        className="text-ellipsis overflow-hidden"
        color="brand.boldest.enabled"
      >
        {userRole ? `${userRole} at ` : ""}
        {governmentAffiliationDisplayName}
      </Typography>
    </div>
  );
}

function EmailVerificationError({
  emailVerificationError,
}: {
  emailVerificationError: Maybe<string>;
}) {
  if (!emailVerificationError) return <></>;
  return (
    <Typography
      color="accent.persimmon.enabled"
      variant="meta"
      size="lg"
      className="mb-4"
    >
      {emailVerificationError}
    </Typography>
  );
}

export default function BuyerProfilePage({
  numAgencyContracts,
  numExpiringAgencyContracts,
}: {
  numAgencyContracts: number;
  numExpiringAgencyContracts: number;
}) {
  const isInitialized = useRecoilValue(userInitializedState);

  const buyerProfile = useRecoilValue(buyerProfileState);
  const [userState, setUserState] =
    useRecoilState<IUserStateState>(userStateState);
  const recentSuccessfulSearches = useRecoilValue(
    recentSuccessfulSearchesState
  );
  const [emailVerificationError, setEmailVerificationError] = useState("");
  const emailVerified = useRecoilValue(userEmailVerifiedState);
  const { firstName } = useRecoilValue(recoilUserState);
  const showSignupSurvey = useShowSignupSurvey();

  const recommendedSearchTerms = useMemo(() => {
    if (recentSuccessfulSearches.length) return recentSuccessfulSearches;

    const agencyType = buyerProfile?.governmentAgency?.agencyType || "";
    const recommendedSearchKey =
      agencyType && agencyType in recommendedSearchesByAgencyType
        ? agencyType
        : "DEFAULT";
    return recommendedSearchesByAgencyType[recommendedSearchKey];
  }, [buyerProfile, recentSuccessfulSearches]);
  const showInviteTeamModal = useShowModal(modals.INVITE_TEAM);
  const sendEmail = useSendEmailVerification({
    source: MODAL_SOURCE.WELCOME_PAGE,
    onError: setEmailVerificationError,
    successModalType: modals.EMAIL_VERIFICATION_SUCCESS,
  });
  const showQuoteRequestForm = isFeatureEnabled("quotingServiceBuyerProfile");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run after the user has been initialized.
  useEffect(() => {
    if (!isInitialized) return;

    updateUserState({ ...userState, hasVisitedProfile: true });
    trackViewHomepage({
      layout: "welcome",
      suggestedSearch: recommendedSearchTerms,
    });

    if (!userState.hasSeenContactSurvey) showSignupSurvey({});
  }, [isInitialized]);

  const updateUserState = (state: Partial<IUserStateState>) => {
    const updatedState = {
      ...userState,
      ...state,
    };
    patchUserState(updatedState);
    setUserState(updatedState);
  };

  const sidebarCardConfigs: SidebarCardProps[] = [
    {
      onClick: () => {
        trackClickInviteTeam({
          source: INVITE_TEAM_SOURCE.WELCOME_PAGE,
        });
        showInviteTeamModal({
          inviteSource: INVITE_TEAM_SOURCE.WELCOME_PAGE,
        });
      },
      iconComponent: InviteTeamIcon,
      iconBackgroundColor: "accent.persimmon.enabled",
      headline: "Invite your team",
      body: "Invite your coworkers to Pavilion to find compliant contracts and collaborate on your contract search. We'll email them a personal invite link.",
      analyticsSuffix: "invite-your-team",
      show: true,
    },
    {
      onClick: sendEmail,
      onDismiss: () => updateUserState({ hasDismissedVerifyEmailCard: true }),
      iconComponent: VerifyEmailIcon,
      iconBackgroundColor: "accent.limeade.enabled",
      headline: "Verify your email",
      body: "Once you've verified your public entity email, you can contact suppliers about pricing, availability, and other contract information.",
      analyticsSuffix: "verify-email",
      show: !emailVerified && !userState.hasDismissedVerifyEmailCard,
    },
    {
      onClick: () => {
        window.open("/profile#approved-sources", "_parent");
      },
      onDismiss: () =>
        updateUserState({ hasDismissedApprovedSourcesCard: true }),
      iconComponent: ContractSourcesIcon,
      iconBackgroundColor: "brand.subtler.enabled",
      headline: "Manage contract sources",
      body: "Prioritize approved contract sources in search results for your whole team.",
      analyticsSuffix: "approved-sources",
      show:
        !!buyerProfile.governmentAgency?.id &&
        !userState.hasDismissedApprovedSourcesCard,
    },
    {
      onClick: () => {
        window.open("/profile#preferences", "_parent");
      },
      onDismiss: () =>
        updateUserState({ hasDismissedUpdatePreferencesCard: true }),
      iconComponent: ManagePreferencesIcon,
      iconBackgroundColor: "accent.leaf.enabled",
      headline: "Update your preferences",
      body: "Pavilion uses your public entity location and diversity criteria preferences to show you the contracts that meet your requirements when you search.",
      analyticsSuffix: "update-preferences",
      show: !userState.hasDismissedUpdatePreferencesCard,
    },
  ];

  /**
   * Explanation:
   * - `GenericCard` has a minimum width of `21rem`.
   * - When two `GenericCard` components share a row, there is
   *   a gap of width `1.5rem`. This is defined by the `gap-6` class.
   * - In total, this gives a minimum width of
   *   `21rem + 21rem + 1.5rem = 43.5rem`
   */
  const secondColumnWidthClass = clsx(CARD_WIDTH_STYLE, "md:min-w-[43.5rem]");

  /**
   * For `lg` breakpoints, match `PageSection` width at `max-w-[75rem]`
   */
  const bannerWidthClass = clsx(CARD_WIDTH_STYLE, "lg:max-w-[75rem]");

  return (
    <div className="pb-10">
      <PageSection>
        <Suspense fallback={<div className={bannerClass(false)} />}>
          <WelcomeBanner
            agencyType={buyerProfile?.governmentAgency?.agencyType}
          />
        </Suspense>
        <div
          className={clsx(
            "py-6 m-auto",
            bannerWidthClass,
            bannerHeightClass({ isSupplier: false })
          )}
        >
          <Typography
            variant="display"
            className="mb-1 sm:text-cp-display-md text-cp-display-sm"
            color="brand.boldest.enabled"
          >
            Welcome{firstName ? `, ${firstName}` : ""}
          </Typography>
          <BuyerProfileInfo
            userRole={userState.role}
            governmentAffiliationDisplayName={
              buyerProfile?.governmentAffiliationDisplayName
            }
          />
        </div>
      </PageSection>

      <PageSection>
        <div className="flex flex-col gap-6 items-center pb-10 lg:flex-row lg:items-start lg:gap-12">
          {/* Left column */}
          <div className={clsx(CARD_WIDTH_STYLE, "pt-6 flex-1")}>
            <div className="flex justify-start gap-3 mb-8 lg:flex-col lg:mb-12">
              {QuickLinkButtons()}
            </div>
            {EmailVerificationError({ emailVerificationError })}
            <div className="grid gap-6 lg:grid-cols-1 md:grid-cols-2">
              {isInitialized &&
                sidebarCardConfigs.map((props) => (
                  <SidebarCard
                    key={`sidebar-card-${props.analyticsSuffix}`}
                    {...props}
                  />
                ))}
              <BuyerSupportCard />
            </div>
          </div>
          {/* Right column (for "lg" 1024px screens) */}
          <div
            className={clsx(
              secondColumnWidthClass,
              "flex flex-col gap-12 lg:flex-2 lg:-mt-12 w-full"
            )}
          >
            {showQuoteRequestForm && <QuoteRequestFormBanner />}
            <Suspense fallback={<LoadingCards />}>
              <SearchBarCard
                suggestedSearchTerms={recommendedSearchTerms}
                suggestedSearchTitle={
                  recentSuccessfulSearches.length > 1
                    ? "Recent searches"
                    : "Example searches"
                }
              />
            </Suspense>
            <WorkspaceSection
              numAgencyContracts={numAgencyContracts}
              numExpiringAgencyContracts={numExpiringAgencyContracts}
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
}
