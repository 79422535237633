import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorById, getParams } from "../../utils";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

const DEFAULT_GOV_AFFILIATION = "University of South Florida";
const DEFAULT_SOURCE_ENTITY_ID = "46fbfb6d-6fc4-46d1-8a5e-9e241f301a37";
const DEFAULT_GOV_ZIP = "33620";

function USF() {
  const { entity, zip } = getParams();
  function getCustomizedUrl(url) {
    url.searchParams.set("zip", zip || DEFAULT_GOV_ZIP);
    url.searchParams.set("embedSourceEntityId", DEFAULT_SOURCE_ENTITY_ID);
    url.searchParams.set(
      "governmentAffiliation",
      entity || DEFAULT_GOV_AFFILIATION
    );
    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle
        title="Find shareable contracts you can use!"
        subtitle="Thousands of contracts you can use to purchase equipment, software, services, and more."
        titleTag="h3"
      />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          getCustomizedUrl={getCustomizedUrl}
          searchSource={agencySearchSource[agencies.USF]}
          queryPlaceholder="What are you looking for? Equipment, services, vehicles..."
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const container = getDOMAnchorById("iframe-usf");
if (container) {
  const root = createRoot(container);
  root.render(
    <RecoilRoot>
      <USF />
    </RecoilRoot>
  );
}
