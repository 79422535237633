import { useCallback, useState } from "react";
import { useRecoilCallback } from "recoil";

import { userState } from "../recoil/user";
import { handleError, postContactMissingDocuments } from "../utils/api";
import { LoginWallTriggers, modals } from "../utils/enums";
import { trackHeapEvent } from "../utils/tracking";

import useLoginWall from "./useLoginWall";
import useRequestID from "./useRequestID";
import useShowModal from "./useShowModal";

export default function useRequestMissingDocuments(
  contractId: string,
  solicitationId: string
): [() => Promise<void>, boolean] {
  const requireLogin = useLoginWall();
  const requestID = useRequestID();
  const show = useShowModal(modals.CONFIRMATION);
  const [loading, setLoading] = useState(false);

  const sendMissingDocsRequest = useRecoilCallback<[string], Promise<Response>>(
    ({ snapshot }) =>
      async (id: string) => {
        const user = await snapshot.getPromise(userState);

        return postContactMissingDocuments({
          contractId: id,
          name: [user.firstName, user.lastName].join(" ").trim(),
          email: user.email,
          publicAgency: user.buyerProfile?.governmentAffiliationDisplayName,
        });
      },
    []
  );

  const onRequestMissingDocuments = useCallback(async () => {
    requireLogin({
      triggerId: solicitationId,
      onComplete: async () => {
        setLoading(true);
        trackHeapEvent("request-missing-docs", {
          contractId,
          solicitationId,
          requestID,
        });
        const response = await sendMissingDocsRequest(contractId);
        setLoading(false);
        if (handleError(response)) return;

        show({
          contractId,
          title: "Document request submitted",
          subtitle:
            "Thanks for submitting a request! Our team will reach out when we have more information.",
          ctaText: "Close",
        });
      },
      triggerType: LoginWallTriggers.MISSING_DOCUMENTS_REQUEST,
    });
  }, [
    contractId,
    sendMissingDocsRequest,
    solicitationId,
    requireLogin,
    requestID,
    show,
  ]);

  return [onRequestMissingDocuments, loading];
}
