import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "../library";
import AnimatedPopup, { AnimatedPopupTypes } from "../popups/AnimatedPopup";
import { hasDismissedPurchasingGuideBannerState } from "../recoil/history";
import { buyerProfileState } from "../recoil/user";
import {
  trackClickedPurchsingGuide,
  trackDismissedPurchasingGuide,
} from "../utils/tracking";

export default function PurchasingGuideBanner() {
  const { governmentAffiliationDisplayName, governmentAgency } =
    useRecoilValue(buyerProfileState);
  const [
    hasDismissedPurchasingGuideBanner,
    setHasDismissedPurchasingGuideBanner,
  ] = useRecoilState(hasDismissedPurchasingGuideBannerState);

  if (!governmentAgency?.purchasingGuide) {
    return null;
  }

  return (
    <AnimatedPopup
      bgColor="brand.boldest.enabled"
      className="flex justify-between top-[5rem] sticky z-2"
      contentClassName="w-full px-5 md:px-30"
      floating={false}
      onClose={() => {
        setHasDismissedPurchasingGuideBanner(true);
        trackDismissedPurchasingGuide();
      }}
      show={!hasDismissedPurchasingGuideBanner}
      type={AnimatedPopupTypes.CLICK}
      typographyProps={{
        emphasis: true,
        size: "md",
      }}
    >
      <span>
        Unsure if you can use a contract? Learn more about{" "}
        {governmentAffiliationDisplayName}’s cooperative purchasing policy{" "}
        <Link
          className="analytics-purchasing-guide-url"
          color="neutral.subtlest.enabled"
          href={governmentAgency.purchasingGuide}
          onClick={() => {
            trackClickedPurchsingGuide();
          }}
        >
          here
        </Link>
        .
      </span>
    </AnimatedPopup>
  );
}
