import { Typography } from "../../library";
import { COMPANY_NAME } from "../../utils/constants";
import { SearchBarIntentVariants } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/split";
import SearchBar, { SearchBarCtaTypes } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";

interface GoogleHeaderProps {
  title?: JSX.Element;
  subtitle?: string;
  className?: string;
  searchSource?: string;
  isLocationRelevant?: boolean;
}
export default function GoogleHeader({
  title = <span>Find the contract you need. Now.</span>,
  subtitle = `Search shareable, cooperative contracts from 400+ national, state, and local sources on ${COMPANY_NAME}. All in one place. For free.`,
  searchSource,
  isLocationRelevant = false,
}: GoogleHeaderProps) {
  return (
    <div className="w-full">
      <div className="text-center">
        <Typography
          variant="headline"
          size="lg"
          color="brand.boldest.enabled"
          emphasis
        >
          {title}
        </Typography>
        <Typography
          variant="headline"
          color="interactive.default.primary.pressed"
          className="mt-2"
        >
          {subtitle}
        </Typography>
      </div>
      <div className="hidden sm:block row-span-1 col-start-2 col-span-10 justify-center mt-5 mb-10">
        <SearchBar
          isLocationRelevant={isLocationRelevant}
          searchSource={searchSource}
          theme={SearchBarThemes.DARK}
          submitCta={SearchBarCtaTypes.TEXT}
          intentVariant={SearchBarIntentVariants.TOGGLE}
          disambiguationModalEnabled={isFeatureEnabled("disambiguationModal")}
          syncIntent
        />
      </div>
    </div>
  );
}
