import { useRecoilValue } from "recoil";

import useRequestMissingDocuments from "../../../hooks/useRequestMissingDocuments";
import { Button, Typography } from "../../../library";
import { profileTypeState } from "../../../recoil/user";
import { borderColorClass } from "../../../utils/colors";
import { ProfileType } from "../../../utils/enums";

export default function NoDocumentsCTA({
  solicitationId,
  contractId,
}: {
  solicitationId: string;
  contractId: string;
}) {
  const [onRequestDocuments, loading] = useRequestMissingDocuments(
    contractId,
    solicitationId
  );

  const profileType = useRecoilValue(profileTypeState);

  // Do not show this CTA for suppliers.
  if (profileType === ProfileType.SUPPLIER) return null;

  return (
    <>
      <hr className={borderColorClass.neutral.default} />
      <div className="grid gap-5">
        <div className="grid gap-2">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="brand.default.secondary.enabled"
          >
            Looking for contract documents?
          </Typography>
          <Typography size="sm" color="neutral.default.secondary.enabled">
            Submit a document request to our team and we will try to obtain
            documents for this contract.
          </Typography>
        </div>
        <Button
          disabled={loading}
          size={Button.sizes.SMALL}
          theme={Button.themes.SECONDARY_DARK}
          onClick={onRequestDocuments}
          className="analytics-supplier-cta-no-documents-request"
        >
          Submit document request
        </Button>
      </div>
    </>
  );
}
