import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import clsx from "clsx";
import type { FormikValues } from "formik";
import { useRecoilValue } from "recoil";

import quoteTeam from "../../../img/team-icons/quote-team.png";
import type { QuoteRequestRequest } from "../../generated/models/QuoteRequestRequest";
import { ApiService } from "../../generated/services/ApiService";
import {
  Badge,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../library";
import {
  InlineFormLayoutVariants,
  InlineFormWrapper,
} from "../../library/form";
import { useSubmitAndSave } from "../../modals/constants";
import { requestIDState } from "../../recoil/search";
import Tag, { TagVariants } from "../../shared/Tag";
import { getParams, goToURL } from "../../utils";
import { elevationClass } from "../../utils/designTokens";
import { handleError } from "../../utils/generatedApi";
import { trackNewProject, trackQuoteRequestSubmit } from "../../utils/tracking";
import { ProjectCreationSource } from "../Project/types";
import { CARD_FORM_QUESTIONS } from "./constants";

export function QuoteRequestFormCard() {
  const params = getParams();
  const requestID = useRecoilValue(requestIDState);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      try {
        const queryString = params.query?.toString();
        const projectName = queryString
          ? `Quote request for "${queryString}"`
          : "My new project";
        const project = await ApiService.apiV1ProjectsCreate({
          name: projectName,
        });

        trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });

        await ApiService.apiV1QuoteRequestsCreate(
          values as QuoteRequestRequest
        );

        trackQuoteRequestSubmit({
          searchQuery: queryString || "",
          requestID,
        });

        goToURL(
          "/quote-request/success",
          { ...params, newProjectId: project.id },
          false
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }
  );

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-5 w-full md:max-w-108 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader"
      >
        Get 3 quotes in 48 hours
      </Typography>
      <Typography color="neutral.boldest.enabled">
        Tell us what you need and your concierge will connect you with qualified
        suppliers.
      </Typography>
      <InlineFormWrapper
        className="!gap-6"
        fields={CARD_FORM_QUESTIONS}
        initialValues={{
          description: "",
          additionalDetails: "",
        }}
        onSubmit={handleSubmit}
        submitCta="Get concierge support"
        submitClassName="!w-full"
        disabled={loading}
      />
      <QuoteRequestRepsOnline />
    </CardContainer>
  );
}

export function QuoteRequestFormBanner({ className }: { className?: string }) {
  const params = getParams();
  const requestID = useRecoilValue(requestIDState);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      try {
        const queryString = params.query?.toString();
        const projectName = queryString
          ? `Quote request for "${queryString}"`
          : "My new project";
        const project = await ApiService.apiV1ProjectsCreate({
          name: projectName,
        });

        trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });

        await ApiService.apiV1QuoteRequestsCreate(
          values as QuoteRequestRequest
        );

        trackQuoteRequestSubmit({
          searchQuery: queryString || "",
          requestID,
        });

        goToURL(
          "/quote-request/success",
          { ...params, newProjectId: project.id },
          false
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }
  );

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 w-full gap-6 max-w-[49.5rem] rounded-6",
        elevationClass["elevation-1"],
        className
      )}
    >
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2 flex flex-col gap-2">
          <div className="flex flex-wrap items-center gap-y-1 gap-x-4">
            <Typography
              size="xl"
              color="brand.boldest.enabled"
              className="font-homeHeader"
            >
              Get 3 quotes in 48 hours
            </Typography>
            <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
          </div>

          <Typography color="neutral.boldest.enabled">
            Tell us what you need and your concierge will connect you with
            qualified suppliers.
          </Typography>
        </div>
        <QuoteRequestRepsOnline />
      </div>

      <InlineFormWrapper
        fields={CARD_FORM_QUESTIONS}
        initialValues={{
          description: "",
          additionalDetails: "",
        }}
        onSubmit={handleSubmit}
        submitCta="Get concierge support"
        submitClassName="h-12"
        disabled={loading}
        layoutVariant={InlineFormLayoutVariants.HORIZONTAL_THREE_COLUMN}
      />
    </CardContainer>
  );
}

export function QuoteRequestRepsOnline() {
  return (
    <div className="flex flex-none flex-col items-center">
      <img className="h-16 object-scale-down" src={quoteTeam} />
      <Badge
        Icon={FiberManualRecordIcon}
        size="sm-md"
        className="gap-1"
        iconClass="text-cp-limeade-700"
      >
        Pavilion reps online
      </Badge>
    </div>
  );
}
