import clsx from "clsx";
import { Suspense, lazy } from "react";
import { useRecoilValue } from "recoil";

import { PageSection, Typography } from "../../../library";
import { userState as recoilUserState } from "../../../recoil/user";
import Tag, { TagVariants } from "../../../shared/Tag";
import SupplierProfileCTAs from "../SupplierProfileCTAs";
import { SUPPLIER_BANNER_CLASS, SUPPLIER_BANNER_HEIGHT } from "../constants";

const ProSupplierBanner = lazy(() => import("../ProSupplierBanner"));
const SupplierBanner = lazy(() => import("../SupplierBanner"));

interface SupplierWelcomeBannerProps {
  isProSupplier: boolean;
}
export default function SupplierWelcomeBanner({
  isProSupplier,
}: SupplierWelcomeBannerProps) {
  const { firstName, userState, supplier } = useRecoilValue(recoilUserState);

  return (
    <PageSection>
      <Suspense fallback={<div className={SUPPLIER_BANNER_CLASS} />}>
        {isProSupplier ? <ProSupplierBanner /> : <SupplierBanner />}
      </Suspense>
      <div className={clsx("pt-8 flex flex-col gap-4", SUPPLIER_BANNER_HEIGHT)}>
        <div className="flex flex-col gap-1">
          <Tag variant={TagVariants.PLACEHOLDER} showIcon={false}>
            {getAccountType(isProSupplier)}
          </Tag>

          <Typography
            className="text-cp-display-sm md:text-cp-display-lg"
            variant="display"
            color="neutral.inverse.primary.enabled"
            aria-label="welcome message"
          >
            {getTimeOfDay()}
            {firstName ? `, ${firstName}` : ""}
          </Typography>
        </div>
        <Typography
          variant="headline"
          size="sm"
          color="neutral.inverse.primary.enabled"
        >
          {userState?.role && `${userState.role} at `}
          {supplier?.displayName}
        </Typography>
        <SupplierProfileCTAs isProSupplier={isProSupplier} />
      </div>
    </PageSection>
  );
}

function getAccountType(isProSupplier: boolean) {
  if (isProSupplier) {
    return "Pro Account";
  }
  return "Basic Account";
}

function getTimeOfDay() {
  const date = new Date();
  const hours = date.getHours();
  if (hours < 12) {
    return "Good morning";
  }
  if (hours < 18) {
    return "Good afternoon";
  }
  return "Good evening";
}
