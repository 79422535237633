import { useEffect } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";

import { ApiService } from "../generated";
import {
  intermediatedEmailState,
  showIntermediatedEmailState,
} from "../recoil/messageSupplier";
import { handleError } from "../utils/generatedApi";

function useInitializeIntermediatedEmailCallback() {
  return useRecoilCallback(({ snapshot, set }) => {
    return async (supplierId: number) => {
      const showIntermediatedEmail = await snapshot.getPromise(
        showIntermediatedEmailState
      );

      if (!showIntermediatedEmail) return;

      try {
        set(intermediatedEmailState, { loading: true, email: "" });
        const { alias, domain } =
          await ApiService.apiV1SuppliersIntermediatedEmailRetrieve(supplierId);
        set(intermediatedEmailState, {
          loading: false,
          email: [alias, domain].join("@"),
        });
      } catch (err) {
        handleError(err);
        set(intermediatedEmailState, { loading: false, email: "" });
      }
    };
  }, []);
}

export default function useInitializeIntermediatedEmail(supplierId: number) {
  const showIntermediatedEmail = useRecoilValue(showIntermediatedEmailState);
  const initializeIntermediatedEmail =
    useInitializeIntermediatedEmailCallback();

  useEffect(() => {
    if (!showIntermediatedEmail) return;
    initializeIntermediatedEmail(supplierId);
  }, [showIntermediatedEmail, supplierId, initializeIntermediatedEmail]);
}
