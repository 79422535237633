import _keyBy from "lodash/keyBy";
import { useRecoilState, useSetRecoilState } from "recoil";

import { Button, Typography } from "../../../../library";
import type { SubmitFn } from "../../../../library/form/types";
import { modalState } from "../../../../recoil/page";
import { supplierState } from "../../../../recoil/user";
import { modals } from "../../../../utils/enums";
import SupplierContactContainer from "../../SupplierContactContainer";
import SupplierLockedField from "../SupplierLockedField";
import type { Supplier, SupplierDefaultContactValue } from "../types";

import { useMemo } from "react";
import type { AddContactModalUpsertOptions } from "../../../../modals/SupplierModals/AddContactModal";
import type { SupplierContact } from "../../../../shared/types";
interface SupplierContactSectionProps {
  handle: string;
  supplierEmail: string;
  disabled?: boolean;
  setUpdateDate: (value: Date | undefined) => void;
  setCurrentSupplier: (supplier: Supplier) => void;
  currentSupplier: Supplier;
  updateSupplier: SubmitFn<SupplierDefaultContactValue>;
}

export default function SupplierContactSection({
  handle,
  supplierEmail,
  disabled = false,
  setUpdateDate,
  currentSupplier,
  setCurrentSupplier,
  updateSupplier,
}: SupplierContactSectionProps) {
  const setCurrentModal = useSetRecoilState(modalState);
  const [supplierRecoilState, setSupplierRecoilState] =
    useRecoilState(supplierState);

  const onContactUpsert = (
    newContact: SupplierContact,
    { newDefaultContactId, removeDefaultContact }: AddContactModalUpsertOptions
  ) => {
    const contactsById = {
      ..._keyBy(currentSupplier.contacts, "id"),
      [newContact.id]: newContact,
    };

    let defaultContactId = currentSupplier.defaultContactId;
    if (newDefaultContactId) {
      defaultContactId = newDefaultContactId;
    } else if (removeDefaultContact) {
      defaultContactId = null;
    }
    setCurrentSupplier({
      ...currentSupplier,
      contacts: Object.values(contactsById),
      defaultContactId,
    });
    setUpdateDate(new Date());
    setSupplierRecoilState({
      ...supplierRecoilState,
      manualContacts: Object.values(contactsById),
      defaultContactId,
    });

    if (
      defaultContactId &&
      defaultContactId !== currentSupplier.defaultContactId
    ) {
      updateSupplier({
        defaultContactId,
      });
    }
  };

  const onContactDelete = (contactToDelete: SupplierContact) => {
    const newContacts =
      currentSupplier.contacts?.filter(({ id }) => id !== contactToDelete.id) ||
      [];
    setCurrentSupplier({
      ...currentSupplier,
      contacts: newContacts,
      defaultContactId:
        contactToDelete.id === currentSupplier.defaultContactId
          ? null
          : currentSupplier.defaultContactId,
    });
    setUpdateDate(new Date());
    setSupplierRecoilState({
      ...supplierRecoilState,
      manualContacts: newContacts,
    });
  };

  // Although the contacts are sorted in the backend, we need to sort them again
  // because of the upsert/delete operations that can change the order.
  const currentSupplierContactsDefaultFirst = useMemo(() => {
    if (currentSupplier.contacts) {
      const newContacts = [...currentSupplier.contacts];
      return newContacts.sort(({ id }) =>
        id === currentSupplier.defaultContactId ? -1 : 1
      );
    }
    return [];
  }, [currentSupplier.contacts, currentSupplier.defaultContactId]);

  return (
    <div className="mb-16 flex flex-col gap-10">
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center">
        <Typography
          variant="headline"
          size="sm"
          color="brand.default.secondary.enabled"
          emphasis
        >
          Active business contacts
        </Typography>
        {!disabled && (
          <Button
            size={Button.sizes.SMALL}
            theme={Button.themes.SECONDARY_DARK}
            className="analytics-add-supplier-contact"
            onClick={() => {
              setCurrentModal({
                name: modals.ADD_CONTACT,
                handle,
                onUpsert: onContactUpsert,
              });
            }}
          >
            Add new contact +
          </Button>
        )}
      </div>
      <SupplierLockedField disabled={disabled}>
        <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full">
          {currentSupplierContactsDefaultFirst?.map((contact) => (
            <SupplierContactContainer
              key={contact.id}
              contact={contact}
              handle={handle}
              supplierName={currentSupplier.displayName}
              supplierEmail={supplierEmail}
              onContactUpsert={onContactUpsert}
              onContactDelete={onContactDelete}
              isDefaultContact={contact.id === currentSupplier.defaultContactId}
            />
          ))}
        </ul>
        {(!currentSupplier?.contacts || !currentSupplier.contacts.length) && (
          <div className="p-8 bg-cp-white-200 w-full mb-10 rounded-lg text-center">
            <Typography>
              To add contacts, click on the {'"'}Add contact{'"'} button and
              enter the required information.
            </Typography>
          </div>
        )}
      </SupplierLockedField>
    </div>
  );
}
