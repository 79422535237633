import {
  DefaultValue,
  type SetRecoilState,
  atom,
  selector,
  useRecoilState,
} from "recoil";

import type {
  AnalyticsParamsProps,
  SearchParams,
  TrackingCountsProps,
} from "../components/ContractSearch/types";
import { bothSearchesLoaded } from "../components/ContractSearch/utils";
import type {
  ContractSearchResponse,
  SupplierSearchResponse,
} from "../generated";
import { NoExactMatchesVariants } from "../shared/SearchPage/NoExactMatches";
import { FILTER_DURATIONS } from "../shared/SearchPage/SearchResults/constants";
import type { ContractSearchLimitedData } from "../shared/types";
import {
  browserLocalStorage,
  getParam,
  hasWindow,
  setParamNoHistory,
} from "../utils";
import {
  ContractDocumentsFilterOptions,
  type ExpirationFilterType,
  ExpirationStatuses,
  SupplierLocationFilterOptions,
} from "../utils/enums";
import { isFeatureEnabled } from "../utils/split";
import { trackSearchFilterMatches } from "../utils/tracking";
import { getLocalStorageEffect } from "./util";

// Used under the Welcome search bar to demonstrate personalization.
export const recentSuccessfulSearchesState = atom<string[]>({
  key: "recentSuccessfulSearchesState",
  default: [],
  effects: [getLocalStorageEffect("recentSuccessfulSearches")],
});

export function useAddRecentSuccessfulSearch() {
  const [successfulSearches, setSuccessfulSearches] = useRecoilState(
    recentSuccessfulSearchesState
  );

  return (newSuccessfulSearch: string) => {
    const normalizedSearch = newSuccessfulSearch.trim();
    if (
      !normalizedSearch.trim() ||
      successfulSearches.includes(normalizedSearch)
    )
      return;

    setSuccessfulSearches([
      normalizedSearch,
      ...successfulSearches.slice(0, 2),
    ]);
  };
}

// TODO: Refactor searchSource to use this state variable.
export const widgetSearchSourceState = atom({
  key: "widgetSearchSourceState",
  default: getParam("widget-search-source"),
  effects: [getLocalStorageEffect("widgetSearchSource")],
});

export const initializeSearch = ({ set }: { set: SetRecoilState }) => {
  // TODO: This is highly redundant w/ atom effects so should be seen as a temporary fix.
  const initialWidgetSearchSource = getParam("widget-search-source");
  if (initialWidgetSearchSource) {
    browserLocalStorage.set("widgetSearchSource", initialWidgetSearchSource);
    set(widgetSearchSourceState, initialWidgetSearchSource);
  }
};

export const approvedSourcesOnlyFilterState = atom<boolean>({
  key: "approvedSourcesOnlyFilterState",
  default: false,
});

export const expirationFilterState = atom<ExpirationFilterType>({
  key: "expirationFilterState",
  default: ExpirationStatuses.ALL_ACTIVE,
});

export const contractSourceFilterState = atom<string[]>({
  key: "contractSourceFilterState",
  default: [],
});

export const diversityCertificationsFilterState = atom<boolean>({
  key: "diversityCertificationsFilterState",
  default: false,
});

export const singleAwardOnlyFilterState = atom<boolean>({
  key: "singleAwardOnlyFilterState",
  default: false,
});

export const supplierLocationFilterState = atom({
  key: "supplierLocationFilterState",
  default: SupplierLocationFilterOptions.ALL_SUPPLIERS,
});

export const contractDocumentsFilterState = atom({
  key: "contractDocumentsFilterState",
  default: ContractDocumentsFilterOptions.ALL_CONTRACTS,
});

export const matchedSearchResultCountState = atom<number>({
  key: "matchedSearchResultCount",
  default: -1,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue, oldValue) => {
        if (newValue === -1 || newValue === oldValue) return;

        const [
          analyticsParams,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          expirationFilter,
          contractSourceFilter,
          contractDocumentsFilter,
        ] = await Promise.all([
          getPromise(contractSearchAnalyticsParamsState),
          getPromise(diversityCertificationsFilterState),
          getPromise(approvedSourcesOnlyFilterState),
          getPromise(singleAwardOnlyFilterState),
          getPromise(expirationFilterState),
          getPromise(contractSourceFilterState),
          getPromise(contractDocumentsFilterState),
        ]);

        trackSearchFilterMatches({
          requestID: analyticsParams.requestID || "",
          matches: newValue,
          expirationFilter,
          contractSourceFilter,
          contractDocumentsFilter,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
        });
      });
    },
  ],
});

export const contractSourceCooperativesState = atom<string[]>({
  key: "contractSourceCooperativesState",
  default: [],
});

export const contractSourceLocalAgenciesState = atom<string[]>({
  key: "contractSourceLocalAgenciesState",
  default: [],
});

export const contractSourceAgenciesState = atom<string[]>({
  key: "contractSourceAgenciesState",
  default: [],
});

interface IContractSourcesState {
  agencies: string[];
  localAgencies: string[];
  cooperatives: string[];
}

export const contractSourcesState = selector<IContractSourcesState>({
  key: "contractSourcesState",
  get: ({ get }) => {
    return {
      agencies: get(contractSourceAgenciesState),
      cooperatives: get(contractSourceCooperativesState),
      localAgencies: get(contractSourceLocalAgenciesState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    const { agencies, cooperatives, localAgencies } = value;
    set(contractSourceAgenciesState, agencies);
    set(contractSourceCooperativesState, cooperatives);
    set(contractSourceLocalAgenciesState, localAgencies);
  },
});

export const contractSearchParamsState = atom<SearchParams>({
  key: "contractSearchParamsState",
  default: {} as SearchParams,
});

export const contractSearchResponseDataState =
  atom<ContractSearchResponse | null>({
    key: "contractSearchResponseDataState",
    default: null,
  });

export const entityContractsParamsState = atom<SearchParams>({
  key: "entityContractsParamsState",
  default: {} as SearchParams,
});

export type EntityContractResponse = Pick<
  ContractSearchResponse,
  | "agencyData"
  | "params"
  | "metadata"
  | "prioritizedEntityMatchData"
  | "errorMessage"
> & { contractData: ContractSearchLimitedData };
export const entityContractsResponseDataState =
  atom<EntityContractResponse | null>({
    key: "entityContractsResponseDataState",
    default: null,
  });

export const supplierSearchResponseDataState =
  atom<SupplierSearchResponse | null>({
    key: "supplierSearchResponseDataState",
    default: null,
  });
export const supplierSearchIsLoadingState = atom<boolean>({
  key: "supplierSearchIsLoadingState",
  default: false,
});

export const entityContractsAnalyticsParamsState = atom<AnalyticsParamsProps>({
  key: "entityContractsAnalyticsParamsState",
  default: {} as AnalyticsParamsProps,
});

export const contractSearchAnalyticsParamsState = atom<AnalyticsParamsProps>({
  key: "contractSearchAnalyticsParamsState",
  default: {} as AnalyticsParamsProps,
});

export const contractSearchTrackingCountsState = atom<TrackingCountsProps>({
  key: "contractSearchTrackingCountsState",
  default: {
    firstPageStrongMatchCount: 0,
    firstPagePossibleMatchCount: 0,
    firstPageSemanticMatchCount: 0,
  },
});

export const entityContractsIsLoadingState = atom<boolean>({
  key: "entityContractsIsLoadingState",
  default: false,
});

export const contractSearchIsLoadingState = atom<boolean>({
  key: "contractSearchIsLoadingState",
  default: false,
});

function getInitialPage() {
  let parsedPageNumber = Number.parseInt(getParam("page"));
  if (Number.isNaN(parsedPageNumber)) {
    parsedPageNumber = 0;
  }
  return parsedPageNumber;
}

export const contractSearchPageState = atom<number>({
  key: "contractSearchPageState",
  default: getInitialPage(),
  effects: [
    ({ onSet }) => {
      onSet(async (newValue) => {
        setParamNoHistory("page", newValue.toString());
      });
    },
  ],
});

export const elementIdToFocusState = atom<string | null>({
  key: "elementIdToFocusState",
  default: null,
});

export const allSearchesLoadedState = selector<boolean>({
  key: "allSearchesLoadedState",
  get: ({ get }) => {
    return bothSearchesLoaded({
      contractResponseData: get(contractSearchResponseDataState),
      supplierResponseData: get(supplierSearchResponseDataState),
      contractSearchIsLoading: get(contractSearchIsLoadingState),
      supplierSearchIsLoading: get(supplierSearchIsLoadingState),
    });
  },
});

export const numFiltersAppliedState = selector<number>({
  key: "numFiltersAppliedState",
  get: ({ get }) => {
    const expirationFilter = get(expirationFilterState);
    const contractSourceFilter = get(contractSourceFilterState);
    const approvedSourcesOnlyFilter = get(approvedSourcesOnlyFilterState);
    const diversityCertificationsFilter = get(
      diversityCertificationsFilterState
    );
    const singleAwardOnlyFilter = get(singleAwardOnlyFilterState);
    const supplierLocationFilter = get(supplierLocationFilterState);
    const contractDocumentsFilter = get(contractDocumentsFilterState);

    let filters = contractSourceFilter.length;
    if (diversityCertificationsFilter) filters++;
    if (approvedSourcesOnlyFilter) filters++;
    if (singleAwardOnlyFilter) filters++;
    if (FILTER_DURATIONS[expirationFilter]) filters++;
    if (
      supplierLocationFilter !== SupplierLocationFilterOptions.ALL_SUPPLIERS
    ) {
      filters++;
    }
    if (
      contractDocumentsFilter !== ContractDocumentsFilterOptions.ALL_CONTRACTS
    ) {
      filters++;
    }
    return filters;
  },
});

export const requestIDState = selector<string>({
  key: "requestIDState",
  get: ({ get }) => {
    const urlParamsRequestID = hasWindow() ? getParam("requestID", "") : "";
    const contractRequestId =
      get(contractSearchResponseDataState)?.params?.requestId || "";
    const analyticsRequestId =
      get(contractSearchAnalyticsParamsState).requestID || "";

    return contractRequestId || analyticsRequestId || urlParamsRequestID;
  },
});

export const searchQueryState = selector<string>({
  key: "searchQueryState",
  get: ({ get }) => {
    const urlParamsQuery = hasWindow() ? getParam("query") : "";
    const contractDataQuery = get(contractSearchResponseDataState)?.params
      ?.query;
    return contractDataQuery || urlParamsQuery;
  },
});

export const noExactMatchesVariantState = selector<NoExactMatchesVariants>({
  key: "noExactMatchesVariantState",
  get: ({ get }) => {
    const numFiltersApplied = get(numFiltersAppliedState);
    const hasMatchedSearchResults = get(hasMatchedSearchResultsState);
    if (!hasMatchedSearchResults) {
      return numFiltersApplied
        ? NoExactMatchesVariants.RESTRICTIVE_FILTERS
        : NoExactMatchesVariants.NO_RESULTS;
    }

    const showOtherResults = get(showOtherResultsState);
    if (showOtherResults) return NoExactMatchesVariants.OTHER_RESULTS;

    return NoExactMatchesVariants.NULL;
  },
});

export const hasMatchedSearchResultsState = selector<boolean>({
  key: "hasMatchedSearchResultsState",
  get: ({ get }) => {
    const numResults =
      get(contractSearchResponseDataState)?.contractData?.results?.length || 0;
    const matchedSearchResultCount = get(matchedSearchResultCountState);
    return !!numResults && !!matchedSearchResultCount;
  },
});

const showOtherResultsState = selector<boolean>({
  key: "showOtherResultsNoExactMatches",
  get: ({ get }) => {
    const matchedSearchResultCount = get(matchedSearchResultCountState);
    const numResults =
      get(contractSearchResponseDataState)?.contractData?.results.length || 0;
    const numFiltersApplied = get(numFiltersAppliedState);
    const hasMatchedSearchResults = get(hasMatchedSearchResultsState);
    return (
      hasMatchedSearchResults &&
      numResults > matchedSearchResultCount &&
      !numFiltersApplied
    );
  },
});

export const hasSupplierMatchState = selector<boolean>({
  key: "hasSupplierMatchState",
  get: ({ get }) => {
    const supplierResponseData = get(supplierSearchResponseDataState);
    const numResults =
      supplierResponseData?.supplierData?.suppliers?.length || 0;
    return !!numResults;
  },
});

export const showSupplierMatchState = atom<boolean>({
  key: "showSupplierMatchState",
  default: true,
});

export function showSupplierRedirect({
  supplierResponseData,
}: {
  supplierResponseData: SupplierSearchResponse | null;
}) {
  return (
    supplierResponseData?.supplierData?.suggestedSearches?.length !== 0 &&
    supplierResponseData?.supplierData?.suppliers?.length !== 0 &&
    supplierResponseData?.supplierData?.suppliers[0].supplierDisplay
      .numActiveContracts === 0
  );
}

export const showSupplierRedirectState = selector<boolean>({
  key: "showSupplierRedirect",
  get: ({ get }) => {
    const supplierResponseData = get(supplierSearchResponseDataState);
    return showSupplierRedirect({ supplierResponseData });
  },
});

export const disallowedSupplierSearchQueryState = atom<string | null>({
  key: "disallowedSupplierSearchQueryState",
  default: null,
});

export const originalAmbiguousQueryState = atom<string | null>({
  key: "originalAmbiguousQueryState",
  default: null,
  effects: [getLocalStorageEffect("originalAmbiguousQuery")],
});

export const searchResultTypeState = atom<"supplier" | "contract">({
  key: "searchResultType",
  default: isFeatureEnabled("supplierDiscovery") ? "supplier" : "contract",
});

export const debugState = atom<boolean>({
  key: "debug",
  default: false,
});
