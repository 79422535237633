import { useSetRecoilState } from "recoil";
import { flattenPiggybackedContracts } from "../../components/EntityContractsPage/PiggybackedContracts/usePiggybackedContracts";
import { ApiService, type ContractWithBuyerLeadAgency } from "../../generated";
import { piggybackedContractsState } from "../../recoil/piggyback";
import { handleError } from "../../utils/generatedApi";
import { trackAddPiggybackedContract } from "../../utils/tracking";

export default function usePiggybackOnSelectedContracts(
  checkedContracts: string[],
  contracts: ContractWithBuyerLeadAgency[]
) {
  const setPiggybackedContracts = useSetRecoilState(piggybackedContractsState);
  return async () => {
    try {
      const response = await ApiService.apiV1PiggybackCreate({
        contractIds: checkedContracts,
      });
      const piggybackedContracts = flattenPiggybackedContracts(response);
      // Merge the new contracts with the existing contracts since we call
      // the api in parallel and don't know the order of the responses
      setPiggybackedContracts(piggybackedContracts);
      for (const contractId of checkedContracts) {
        // biome-ignore lint/style/noNonNullAssertion: We are positive that the contract exists
        const { supplierHandle, supplierId, solicitation } = contracts.find(
          (c) => c.docid === contractId
        )!;
        trackAddPiggybackedContract({
          contractId,
          supplierHandle,
          supplierId,
          solicitationId: solicitation,
        });
      }
      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  };
}
