import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import { type RecoilState, useRecoilCallback } from "recoil";
import { v4 as uuidv4 } from "uuid";

import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
} from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import {
  contractSearchAnalyticsParamsState,
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  disallowedSupplierSearchQueryState,
  originalAmbiguousQueryState,
  searchResultTypeState,
  showSupplierMatchState,
  showSupplierRedirect,
  showSupplierRedirectState,
  supplierSearchIsLoadingState,
  supplierSearchResponseDataState,
} from "../recoil/search";
import { getParam, hasWindow } from "../utils";
import { MAX_RESULTS } from "../utils/constants";
import { SearchActions } from "../utils/enums";
import { handleError as handleGeneratedError } from "../utils/generatedApi";

import { captureException } from "@sentry/browser";
import { ApiService } from "../generated";
import useHasMaxWalkthroughSearches from "./useHasMaxWalkthroughSearches";
import useTrackContractSearch from "./useTrackContractSearch";

export function shouldUpdateRequestID(
  oldSearchParams: SearchParams,
  newSearchParams: SearchParams
) {
  // Only update the request ID if we have meaningfully changed the
  // params. The only fields that count this as a new request for analytics
  // are query, zip, and filters.
  const paramsToInclude = ["query", "zip", "filters", "collapseBySupplier"];

  const oldComparedParams = _pick(oldSearchParams, paramsToInclude);
  const newComparedParams = _pick(newSearchParams, paramsToInclude);

  if (!oldSearchParams || _isEqual(oldComparedParams, newComparedParams)) {
    return false;
  }
  return true;
}

export const searchContracts = async (
  requestID: string,
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  query?: string,
  filters?: string[],
  zip?: string,
  embedSourceEntityId?: string,
  collapseBySupplier?: boolean,
  parentRequestID?: string,
  originalAmbiguousQuery?: Maybe<string>
) => {
  set(contractSearchIsLoadingState, true);
  set(contractSearchResponseDataState, null);
  set(searchResultTypeState, collapseBySupplier ? "supplier" : "contract");
  try {
    const response = await ApiService.apiV1ContractSearchCreate({
      query: query || "",
      filters,
      zip,
      embedSourceEntityId,
      // When filtering clientside, retrieve results that includes all
      // strong matches in one go to offer the largest bucket of filterable
      // results.
      numResultsPerPage: MAX_RESULTS,
      page: 0,
      requestID,
      parentRequestID,
      proBoost: getProBoost(),
      productBoost: getProductBoost(),
      rankStrategy: getParam("rankStrategy"),
      collapseBySupplier,
      originalAmbiguousQuery,
    });
    set(originalAmbiguousQueryState, null);
    set(contractSearchResponseDataState, response);
    set(contractSearchIsLoadingState, false);
    return response;
  } catch (err) {
    handleGeneratedError(err);
    set(contractSearchResponseDataState, {
      contractData: {
        numAllResults: 0,
        numShowingResults: 0,
        numStrongResults: 0,
        results: [],
      },
      metadata: null,
      params: null,
      agencyData: null,
      prioritizedEntityMatchData: null,
    });
    set(contractSearchIsLoadingState, false);
    return null;
  }
};

export const searchSuppliers = async (
  requestId: string,
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T)
  ) => void,
  query?: string,
  filters?: string[],
  zip?: string
) => {
  set(supplierSearchIsLoadingState, true);
  set(supplierSearchResponseDataState, null);
  try {
    const response = await ApiService.apiV1SupplierSearchCreate({
      query: query || "",
      filters: filters,
      zip: zip,
      requestId,
    });
    set(supplierSearchResponseDataState, response);
    set(supplierSearchIsLoadingState, false);
    return response;
  } catch (err) {
    handleGeneratedError(err);
    set(supplierSearchResponseDataState, null);
    set(supplierSearchIsLoadingState, false);
    return null;
  }
};

export function getProBoost() {
  const rawProBoost = hasWindow() && getParam("proBoost");
  if (!rawProBoost) {
    return null;
  }
  const parsedProBoost = Number.parseInt(rawProBoost);
  if (Number.isNaN(parsedProBoost)) {
    return null;
  }
  return parsedProBoost;
}

export function getProductBoost() {
  const rawProductBoost = hasWindow() && getParam("productBoost");
  if (!rawProductBoost) {
    return null;
  }
  const parsedProductBoost = Number.parseInt(rawProductBoost);
  if (Number.isNaN(parsedProductBoost)) {
    return null;
  }
  return parsedProductBoost;
}

export default function useSearchContractWithParams() {
  const trackContractSearch = useTrackContractSearch();
  const [, incrementSearches] = useHasMaxWalkthroughSearches();

  const search = useRecoilCallback<[SearchOptions], void>(
    ({ snapshot, set }) =>
      async ({ newParams = null, action = SearchActions.SEARCH }) => {
        const [
          searchParams,
          analyticsParams,
          showingSupplierRedirect,
          searchResultType,
          originalAmbiguousQuery,
        ] = await Promise.all([
          snapshot.getPromise(contractSearchParamsState),
          snapshot.getPromise(contractSearchAnalyticsParamsState),
          snapshot.getPromise(showSupplierRedirectState),
          snapshot.getPromise(searchResultTypeState),
          snapshot.getPromise(originalAmbiguousQueryState),
        ]);
        const params = formatSearchPageParams(searchParams);
        const combinedParams = {
          ...params,
          collapseBySupplier: searchResultType === "supplier",
          ...newParams,
        } as ContractSearchParams;

        const validation = validateSearchParams(combinedParams);
        if (!validation.valid) {
          set(contractSearchResponseDataState, {
            contractData: {
              numAllResults: 0,
              numShowingResults: 0,
              numStrongResults: 0,
              results: [],
            },
            metadata: null,
            params: null,
            agencyData: null,
            prioritizedEntityMatchData: null,
            errorMessage: validation.errorMessage || "",
          });
          return;
        }
        const {
          query,
          zip,
          page,
          filters,
          embedSourceEntityId,
          searchSource,
          collapseBySupplier,
          intent,
        } = combinedParams;

        // If we are searching contracts only, we don't want to update the query state -
        // this supports the supplier redirect experience.
        const updatedSearchQuery =
          action === SearchActions.SEARCH_CONTRACTS_ONLY
            ? searchParams.query
            : query || "";

        // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
        // PLEASE COORDINATE IF THESE CHANGE.
        const updatedParams: SearchParams = {
          query: updatedSearchQuery,
          zip: zip || "",
          page: page?.toString() || "",
          filters: filters?.join(";") || "",
          intent: intent || "",
        };

        const disallowedSupplierSearchQuery = await snapshot.getPromise(
          disallowedSupplierSearchQueryState
        );

        // For supplier redirects, we want to skip updating the supplier data when:
        const skipSupplierSearch =
          // - the user clicked "search for <supplier name> contracts" and wants a contract-only search
          action === SearchActions.SEARCH_CONTRACTS_ONLY ||
          // - the user is applying filters on a contract-only search
          query === disallowedSupplierSearchQuery ||
          // - the user is applying filters on suggested searches
          (action === SearchActions.SAVE_FILTERS && showingSupplierRedirect);

        // Once we have a non-skipped search, we should reset all the redirect state.
        // This state is managed and used in useHideSupplierSearch.ts.
        if (!skipSupplierSearch) {
          set(showSupplierMatchState, true);
          set(disallowedSupplierSearchQueryState, "");
        }

        // Update search params local state, which binds to URL param changes.
        if (!_isEqual(searchParams, updatedParams)) {
          set(contractSearchParamsState, updatedParams);
        }
        const requestID = shouldUpdateRequestID(searchParams, {
          ...updatedParams,
          collapseBySupplier,
        })
          ? uuidv4()
          : analyticsParams.requestID || uuidv4();

        // If we change the request id, track this as a new search.
        if (requestID !== analyticsParams.requestID) {
          incrementSearches();
          set(contractSearchAnalyticsParamsState, {
            requestID,
            searchSource: searchSource || "",
          });
        }

        let [contractResponse, supplierResponse] = await Promise.all([
          searchContracts(
            requestID,
            set,
            query,
            filters,
            zip,
            embedSourceEntityId,
            collapseBySupplier,
            undefined, // parent request id
            originalAmbiguousQuery
          ),
          skipSupplierSearch
            ? Promise.resolve(null)
            : searchSuppliers(requestID, set, query, filters, zip),
        ]);

        // Track the original search.
        if (contractResponse && (supplierResponse || skipSupplierSearch)) {
          const numSupplierHits =
            supplierResponse?.supplierData?.suppliers?.length || 0;
          trackContractSearch(
            contractResponse,
            action,
            numSupplierHits,
            null,
            collapseBySupplier,
            intent
          );
        }

        // If we have a supplier response and the supplier redirect experience is enabled,
        // intercept the search and redirect to the first suggested search.
        // Give it a new request ID so that it is tracked in searchlog separately from the previous search.
        if (showSupplierRedirect({ supplierResponseData: supplierResponse })) {
          const newRequestID = uuidv4();
          const redirectedQuery =
            supplierResponse?.supplierData?.suggestedSearches?.[0];
          contractResponse = await searchContracts(
            newRequestID,
            set,
            redirectedQuery,
            filters,
            zip,
            embedSourceEntityId,
            collapseBySupplier,
            requestID // parent request id
          );

          // Track the auto redirected search.
          if (contractResponse && (supplierResponse || skipSupplierSearch)) {
            const numSupplierHits =
              supplierResponse?.supplierData?.suppliers?.length || 0;
            trackContractSearch(
              contractResponse,
              action,
              numSupplierHits,
              query,
              collapseBySupplier,
              intent
            );
          }
        }

        // After all the redirects have been handled, update the search params state
        if (contractResponse) {
          let params: SearchParams;
          params = {
            query: updatedSearchQuery,
            zip: contractResponse.params?.zip || "",
            page: contractResponse.params?.page?.toString() || "",
            filters: contractResponse.params?.filters?.join(";") || "",
            intent: intent || "",
          };
          if (contractResponse.params?.embedSourceEntityId) {
            params = {
              ...params,
              embedSourceEntityId: contractResponse.params.embedSourceEntityId,
            };
          }
          set(contractSearchParamsState, params);

          if (!contractResponse.params?.requestId) {
            // Track if the contract search response does not have a request ID - this is a problem
            captureException(
              new Error("Contract search response does not have a request ID"),
              {
                extra: { contractResponse },
              }
            );
          }

          set(contractSearchAnalyticsParamsState, {
            requestID: contractResponse.params?.requestId || requestID,
            searchSource: searchSource || "",
          });
        }
      },
    []
  );

  return search;
}
