import type { UserType } from "../../modals/SignupSteps/types";

import type { EducationalInfo } from "./types";

export const educationalData: Record<UserType, EducationalInfo> = {
  buyer: {
    type: "buyer",
    title: "Create your buyer account",
    educationalInfo: [
      {
        header: "Fast and free",
        body: "Pavilion is free for public entities, forever—no terms and conditions to sign up.",
      },
      {
        header: "Comprehensive",
        body: "With shareable contracts from hundreds of local and national sources, there’s no need to look anywhere else.",
      },
      {
        header: "Tailored to your compliance needs and preferences",
        body: "We help you find the best contracts for your agency’s needs.",
      },
    ],
  },
  supplier: {
    type: "supplier",
    title: "Create your supplier account",
    educationalInfo: [
      {
        header: "Win new customers",
        body: "Pavilion connects thousands of high-intent public buyers with new suppliers like you every month.",
      },
      {
        header: "Sell more to existing customers",
        body: "Help new users from your current customers find and buy more from your business.",
      },
      {
        header: "Lower your costs",
        body: "Lower the costs of selling to government customers and improve your team’s ability to close deals.",
      },
      {
        header: "Get started for free",
        body: "Get discovered by public sector buyers for free.",
      },
    ],
  },
};
