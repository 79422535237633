import { useRecoilValue } from "recoil";

import { userStateState } from "../recoil/user";
import { searchFilters } from "../utils/enums";

export default function useDefaultSearchPreferences() {
  const userState = useRecoilValue(userStateState);

  if (!userState) {
    return { filters: [] };
  }

  const filters: string[] = [];
  if (userState.showOnlyCoop !== undefined && !userState.showOnlyCoop) {
    filters.push(searchFilters.INCLUDE_NON_COOP);
  }
  if (userState.showExpired) {
    filters.push(searchFilters.INCLUDE_EXPIRED);
  }
  if (userState.ignoreAgencyLocation) {
    filters.push(searchFilters.IS_NOT_LOCATION_RELEVANT);
  }
  if (userState.showGsa === false) {
    filters.push(searchFilters.EXCLUDE_GSA);
  }

  return { filters };
}
