import Cookies from "js-cookie";
import { useRecoilValue } from "recoil";

import useCookie from "../../hooks/useCookie";
import LoginForm from "../../modals/LoginModals/LoginForm";
import PushToLoginForm from "../../modals/SignupSteps/PushToLoginForm";
import {
  type AccountModalKeys,
  WindowType,
} from "../../modals/SignupSteps/types";
import AccountPage from "../../pages/Account";
import { userTypeSignupState } from "../../recoil/user";
import { SOCIAL_LOGIN_SOURCE_COOKIE_KEY } from "../../utils/constants";
import { LoginType, SignupOrigin, accountModals } from "../../utils/enums";
import { type SocialProvider, SocialProviders } from "../../utils/social";
import {
  trackAccountAccessToggle,
  trackSignupLoginExit,
} from "../../utils/tracking";

import type { ExistingUser } from "../../generated";
import {
  goToHomePage,
  goToSignupPage,
  goToWelcomePage,
  redirectOnComplete,
} from "./helpers";

export default function LoginPage({
  defaultSocialLoginSource,
}: {
  defaultSocialLoginSource: string;
}) {
  const [socialLoginSource, setSocialLoginSource] = useCookie<
    SocialProvider | string
  >(SOCIAL_LOGIN_SOURCE_COOKIE_KEY, defaultSocialLoginSource);
  const userType = useRecoilValue(userTypeSignupState);

  const redirectSocialUser = (data: ExistingUser) => {
    if (data.existingUser && data.socialAccountProvider) {
      setSocialLoginSource(data.socialAccountProvider as SocialProvider);
      window.open("/accounts/login", "_parent");
      return true;
    }
    return false;
  };

  const isSocialLogin =
    socialLoginSource &&
    SocialProviders.includes(socialLoginSource as SocialProvider);
  const loginType = isSocialLogin
    ? (socialLoginSource as unknown as LoginType)
    : LoginType.PAVILION;
  const loginStage = (
    !isSocialLogin
      ? accountModals.PUSH_TO_LOGIN
      : accountModals.SIGNUP_STEPS_LOGIN
  ) as AccountModalKeys;

  function closePage() {
    trackSignupLoginExit({
      accountType: userType,
      emailEntered: isSocialLogin ? "" : socialLoginSource,
      loginExperience: WindowType.Page,
      loginType,
      loginStage,
    });

    Cookies.remove(SOCIAL_LOGIN_SOURCE_COOKIE_KEY);
    goToHomePage();
  }

  function redirectToSignupPage() {
    trackAccountAccessToggle({
      toggle: "sign up",
      loginExperience: WindowType.Page,
    });
    goToSignupPage(SignupOrigin.LOGIN_BUTTON);
  }

  return (
    <AccountPage
      title="Welcome back"
      ctaText="Sign in to your account"
      closePage={closePage}
    >
      {socialLoginSource ? (
        <PushToLoginForm
          onComplete={() => {
            Cookies.remove(SOCIAL_LOGIN_SOURCE_COOKIE_KEY);
            goToWelcomePage();
          }}
          socialLoginSource={socialLoginSource}
          parentWindow={WindowType.Page}
        />
      ) : (
        <LoginForm
          goToSignupPageOrModal={redirectToSignupPage}
          redirectSocialUser={redirectSocialUser}
          onComplete={redirectOnComplete}
          parentWindow={WindowType.Page}
        />
      )}
    </AccountPage>
  );
}
