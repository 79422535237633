import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import clsx from "clsx";

import type { ReactNode } from "react";
import { Badge, Typography } from "../library";
import { bgColorClass } from "../utils/colors";

export interface Callout {
  title: string;
  description: string;
  children?: ReactNode;
}
interface ClarityCalloutProps {
  callouts: Callout[];
  className?: string;
}

export default function ClarityCallout({
  callouts,
  className,
}: ClarityCalloutProps) {
  return (
    <div
      className={clsx(
        "flex flex-col gap-6 p-6 rounded-8",
        bgColorClass.brand.subtlest.enabled,
        className
      )}
    >
      <Badge
        as={Typography}
        linkProps={{
          color: "neutral.bolder.enabled",
          size: "sm",
        }}
        Icon={AutoAwesomeOutlinedIcon}
        size="sm-md"
      >
        Clarity callout
      </Badge>
      {callouts.map(({ title, description, children }) => (
        <div key={title} className="flex flex-col gap-2">
          <Typography size="sm" emphasis color="neutral.boldest.enabled">
            {title}
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            {description}
          </Typography>
          {children}
        </div>
      ))}
    </div>
  );
}
