import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HelpIcon from "@mui/icons-material/Help";
import clsx from "clsx";
import pluralize from "pluralize";
import { type ReactNode, useEffect, useState } from "react";
import { Badge, Typography } from "../../library";
import { iconColorClass } from "../../utils/colors";
import { trackClickComplianceChecklistSectionHeading } from "../../utils/tracking";

import {
  ChecklistItemState,
  type ComplianceChecklistSectionData,
  type ComplianceChecklistSectionItem,
  ICONS_CONFIG,
  SectionStatus,
} from "./utils";

type ItemDataByKey = {
  [key: string]: Pick<
    ComplianceChecklistSectionItem,
    "body" | "state" | "link"
  >;
};

function toggleCheckbox(state: ChecklistItemState) {
  if (state === ChecklistItemState.CHECKED) {
    return ChecklistItemState.UNCHECKED;
  }
  if (state === ChecklistItemState.UNCHECKED) {
    return ChecklistItemState.CHECKED;
  }
  return state;
}

export function ComplicanceChecklistSection({
  contractId,
  heading,
  sectionKey,
  items,
  defaultOpen,
  className,
}: ComplianceChecklistSectionData & {
  contractId: string;
  defaultOpen: boolean;
  className?: string;
}) {
  // Make a local copy of states, since we might modify these via clicks
  const [itemStates, setItemStates] = useState<ItemDataByKey>({});
  useEffect(() => {
    const states: ItemDataByKey = {};
    items.forEach(({ key, state, body, link }) => {
      states[key] = { state, body, link };
    });
    setItemStates(states);
  }, [items]);

  const handleClick = (key: string, state: ChecklistItemState) => {
    const itemData = itemStates[key];
    if (!itemData) return;
    setItemStates((prev) => ({
      ...prev,
      [key]: { ...itemData, state: toggleCheckbox(state) },
    }));
  };

  const itemStatesEntries = Object.entries(itemStates);
  const incompletedItemsCount = itemStatesEntries.filter(
    ([_, { state }]) => state !== ChecklistItemState.CONFIRMED
  ).length;
  const isConfirmedUnusable = !!itemStatesEntries.find(
    ([_, { state }]) => state === ChecklistItemState.CONFIRMED_UNUSABLE
  );
  const sectionStatus = isConfirmedUnusable
    ? SectionStatus.NOT_USABLE
    : incompletedItemsCount > 0
      ? SectionStatus.PARTIAL
      : SectionStatus.COMPLETED;

  return (
    <Disclosure
      as="div"
      className={clsx("flex flex-col gap-2", className)}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <DisclosureButton
            className="cursor-pointer"
            onClick={() =>
              trackClickComplianceChecklistSectionHeading({
                contractId,
                key: sectionKey,
                isOpening: !open,
              })
            }
          >
            <ComplicanceChecklistSectionHeading
              heading={heading}
              sectionStatus={sectionStatus}
              incompletedItemsCount={incompletedItemsCount}
            />
          </DisclosureButton>
          <Transition
            as={"div"}
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <DisclosurePanel className="flex flex-col gap-1 ml-6" static>
              {itemStatesEntries.length > 0 &&
                itemStatesEntries.map(([key, { state, body, link }]) => {
                  const isCheckbox =
                    state === ChecklistItemState.CHECKED ||
                    state === ChecklistItemState.UNCHECKED;
                  return (
                    <Badge
                      key={key}
                      Icon={ICONS_CONFIG[state].icon}
                      as={isCheckbox ? "button" : "div"}
                      size="sm-md"
                      iconClass={clsx(
                        ICONS_CONFIG[state].colorClass,
                        "mt-[3px]"
                      )}
                      className={clsx("flex gap-2", {
                        "cursor-pointer": isCheckbox,
                      })}
                      onClick={() => {
                        if (isCheckbox) handleClick(key, state);
                      }}
                      includeMargin={false}
                      inline
                    >
                      <Typography
                        color="neutral.default.primary.enabled"
                        className="flex flex-col gap-1 items-start sm:flex-row sm:gap-2 sm:items-center"
                      >
                        {body}
                        {link}
                      </Typography>
                    </Badge>
                  );
                })}
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export function ComplicanceChecklistSectionHeading({
  heading,
  sectionStatus,
  incompletedItemsCount,
}: {
  heading: string;
  sectionStatus: SectionStatus;
  incompletedItemsCount: number;
}) {
  const sectionStatusToConfirmationText = {
    [SectionStatus.COMPLETED]: "Yes, confirmed",
    [SectionStatus.PARTIAL]: `Maybe, review ${incompletedItemsCount} ${pluralize(
      "item",
      incompletedItemsCount
    )}`,
    [SectionStatus.NOT_USABLE]: "No, this contract is not usable",
  };
  const confirmationText = sectionStatusToConfirmationText[sectionStatus];
  return (
    <>
      <div className="flex gap-4 items-center justify-between text-left">
        <div className="flex gap-1 items-start">
          <Badge
            Icon={ChevronRightRoundedIcon}
            size="lg"
            iconClass={clsx(
              iconColorClass.brand.bold.enabled,
              "transition ui-open:rotate-90 ui-open:transform"
            )}
            includeMargin={false}
          />
          <StatusBadge sectionStatus={sectionStatus} className="sm:hidden" />
          <Typography color="neutral.boldest.enabled" emphasis>
            {heading}
          </Typography>
        </div>
        <div className={clsx("flex items-center gap-1")}>
          <ConfirmationText
            className="hidden mt-1 ml-6 sm:block"
            body={confirmationText}
          />
          <StatusBadge
            sectionStatus={sectionStatus}
            className="hidden sm:block"
          />
        </div>
      </div>
      <ConfirmationText
        className="mt-1 ml-6 sm:hidden"
        body={confirmationText}
      />
    </>
  );
}

function StatusBadge({
  sectionStatus,
  className,
}: { sectionStatus: SectionStatus; className: string }) {
  const sectionStatusToIcon = {
    [SectionStatus.COMPLETED]: {
      icon: CheckCircleRoundedIcon,
      iconColor: iconColorClass.feedback.bold.success,
    },
    [SectionStatus.PARTIAL]: {
      icon: HelpIcon,
      iconColor: iconColorClass.disabled,
    },
    [SectionStatus.NOT_USABLE]: {
      icon: CancelIcon,
      iconColor: iconColorClass.feedback.bold.error,
    },
  };
  return (
    <Badge
      Icon={sectionStatusToIcon[sectionStatus].icon}
      iconClass={clsx("mt-0.5", sectionStatusToIcon[sectionStatus].iconColor)}
      includeMargin={false}
      className={className}
    />
  );
}
function ConfirmationText({
  className,
  body,
}: {
  className: string;
  body: ReactNode;
}) {
  return (
    <Typography
      size="sm"
      color="neutral.bold.enabled"
      className={clsx("whitespace-nowrap text-left", className)}
    >
      {body}
    </Typography>
  );
}
