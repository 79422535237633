import { v4 as uuid } from "uuid";

import type {
  ContractHit,
  ContractResult,
  ContractSearchResponse,
  MatchLevelEnum,
  MatchResult,
  RelevantContract,
  SupplierSearchResponse,
} from "../../generated";
import { isContractHit } from "../../shared/SearchPage/utils";
import { contractMatchTypes } from "../../utils/enums";
import type { ContractSearchParams, SearchParams } from "./types";

const snippetFields = [
  "summary",
  "contractContent",
  "otherDocsContent",
  "amendmentsContent",
  "pricingContent",
  "pricingDetails",
  "pricingSummary",
];

export function getNumberSupplierHits(
  responseData: SupplierSearchResponse | null
) {
  return responseData?.supplierData?.suppliers?.length || 0;
}

export function getCountsForTracking(contract_results?: ContractResult[]) {
  let strongMatchesCount = 0;
  let possibleMatchesCount = 0;
  let scopeMatchesCount = 0;
  let supplierNameMatchesCount = 0;
  let semanticMatchesCount = 0;
  let competitorNameMatchesCount = 0;

  contract_results?.forEach((r) => {
    const result = isContractHit(r) ? r : r.relevantSuppliers[0];
    switch (result.matchTier) {
      case contractMatchTypes.OCR:
        possibleMatchesCount += 1;
        break;
      case contractMatchTypes.SCOPE:
        scopeMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      case contractMatchTypes.EXACT_SUPPLIER:
        supplierNameMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      case contractMatchTypes.COMPETITOR_MATCH:
        competitorNameMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      case contractMatchTypes.SEMANTIC:
        semanticMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      default:
        strongMatchesCount += 1;
    }
  });

  return {
    strongMatchesCount,
    possibleMatchesCount,
    scopeMatchesCount,
    supplierNameMatchesCount,
    semanticMatchesCount,
    competitorNameMatchesCount,
  };
}

export interface SearchParamsValidation {
  valid: boolean;
  errorMessage?: string;
}

export function validateSearchParams(
  contractSearchParams: ContractSearchParams
): SearchParamsValidation {
  if (!contractSearchParams.query && !contractSearchParams.landingPageSlug) {
    return { valid: false, errorMessage: "Please enter a search" };
  }

  if (contractSearchParams.query.length > 512) {
    return {
      valid: false,
      errorMessage: "Your search is too long, please shorten it and try again",
    };
  }
  return { valid: true };
}

export function formatSearchPageParams(
  searchParams: SearchParams
): ContractSearchParams {
  let parsedPageNumber = 0;
  if (searchParams.page) {
    parsedPageNumber = Number.parseInt(searchParams.page);
    if (Number.isNaN(parsedPageNumber)) {
      parsedPageNumber = 0;
    }
  }

  let parsedFilters: string[] = [];
  if (searchParams.filters) {
    parsedFilters = `${searchParams.filters}`.split(";").filter((f) => !!f);
  }

  const csp: ContractSearchParams = {
    query: searchParams.query,
    zip: searchParams.zip || "",
    embedSourceEntityId: searchParams.embedSourceEntityId,
    landingPageSlug: searchParams.landingPageSlug,
    intent: searchParams.intent || "",
    page: parsedPageNumber,
    searchSource: searchParams["analytics-search-source"],
    filters: parsedFilters,
    userSelectedFromDefault:
      searchParams["user-selected-from-default"] === "true",
    collapseBySupplier: searchParams.collapseBySupplier,
  };

  return csp;
}

const matchCopyOCR = "Found in contract documents: ";
const matchCopyByKey = {
  contractOfferings: "Confirmed in contract scope: ",
  autoExtractedOfferingsList: "Confirmed in contract document: ",
  contractBrands: "From brand on contract: ",
  contractNumber: "From contract number: ",
  semanticOffering: "Identified by AI in contract: ",
} as const;

export function matchesForKey(
  hitSection: ContractHit["SnippetResult"] | ContractHit["HighlightResult"],
  key: string,
  isOCR = false
): MatchResult[] {
  if (!hitSection || !(key in hitSection)) {
    return [];
  }

  const potentialMatches = [hitSection[key]].flat();
  const matches = potentialMatches.filter((m) => m.matchLevel !== "none");

  return matches.map((match) => ({
    value: match.value,
    matchedWords: match.matchedWords,
    copyText: isOCR
      ? matchCopyOCR
      : matchCopyByKey[key as keyof typeof matchCopyByKey],
    matchLevel: match.matchLevel,
    isOCR,
    matchKind: key,
  }));
}

export function matchesForContractHit(hit: ContractHit | RelevantContract) {
  const result = hit.HighlightResult;
  const snippetMatches = snippetFields.flatMap((key) =>
    matchesForKey(hit.SnippetResult, key, true)
  );
  // The list of fields we highlight in _highlightResult
  // is defined in shared_constants.json as HIGHLIGHT_RESULT_KEYS.
  const contractNumberMatches = matchesForKey(result, "contractNumber");
  const offeringMatches = matchesForKey(result, "contractOfferings");
  const semanticMatches = matchesForKey(result, "semanticOffering");
  const autoExtractedOfferingsMatches = matchesForKey(
    result,
    "autoExtractedOfferingsList"
  ).map((match) => ({ ...match, isAutoExtracted: true }));
  const contractBrandMatches = matchesForKey(result, "contractBrands");
  return [
    ...offeringMatches,
    ...contractNumberMatches,
    ...contractBrandMatches,
    ...snippetMatches,
    ...autoExtractedOfferingsMatches,
    ...semanticMatches,
  ];
}

export function hasNoSearchResults({
  contractResponseData,
  supplierResponseData,
}: {
  contractResponseData: Maybe<ContractSearchResponse>;
  supplierResponseData: Maybe<SupplierSearchResponse>;
}) {
  return (
    contractResponseData?.contractData?.numAllResults === 0 &&
    !contractResponseData?.prioritizedEntityMatchData &&
    !supplierResponseData?.supplierData?.suppliers &&
    !contractResponseData.agencyData?.agency
  );
}

export function bothSearchesLoaded({
  contractResponseData,
  supplierResponseData,
  contractSearchIsLoading,
  supplierSearchIsLoading,
}: {
  contractResponseData: Maybe<ContractSearchResponse>;
  supplierResponseData: Maybe<SupplierSearchResponse>;
  contractSearchIsLoading: boolean;
  supplierSearchIsLoading: boolean;
}) {
  if (
    !contractSearchIsLoading &&
    !supplierSearchIsLoading &&
    contractResponseData &&
    supplierResponseData
  ) {
    return true;
  }
  return false;
}

export const matchTiers: Record<MatchLevelEnum, number> = {
  full: 3,
  partial: 2,
  semantic: 1,
  none: 0,
};

// Split and style text with highlighted segments based on bolding returned from search.
export function styleSearchResponseText(
  responseValue: string,
  highlightMarker: string, // This is "em" (<em></em>) for some cases, but may be more complex in other cases.
  baseStyling: string,
  emphasizedStyling: string
) {
  const stringBreakpoints: [string, boolean][] = []; // List of tuples (substring, shouldBeStyled)
  let pointer = 0; // Furthest in the string we've looked.

  // Essentially split up the string based on occurrences of <highlightMarker> and </highlightMarker>
  // And mark whether each "chunk" of the string should be styled or not.
  // We'll remove the highlighting markers before returning the actual components.
  while (pointer < responseValue.length) {
    const startIndex = responseValue.indexOf(`<${highlightMarker}>`, pointer);
    const endIndex = responseValue.indexOf(
      `</${highlightMarker}>`,
      pointer + 1
    );

    if (startIndex === -1 || endIndex === -1) {
      stringBreakpoints.push([responseValue.substring(pointer), false]);
      break;
    }

    stringBreakpoints.push([
      responseValue.substring(pointer, startIndex),
      false,
    ]);
    stringBreakpoints.push([
      responseValue.substring(startIndex, endIndex),
      true,
    ]);
    pointer = endIndex;
  }

  // In all cases, we might have vestiges of the highlight markers.
  const cleanedBreakpoints = stringBreakpoints
    .map(([substring, shouldBeStyled]) => [
      substring
        .replace(`<${highlightMarker}>`, "")
        .replace(`</${highlightMarker}>`, ""),
      shouldBeStyled,
    ])
    .filter(
      ([substring]) => typeof substring !== "boolean" && substring.length > 0
    );

  const styledComponents = cleanedBreakpoints.map(
    ([substring, shouldBeStyled]) => {
      return (
        <span
          key={uuid()}
          className={shouldBeStyled ? emphasizedStyling : baseStyling}
        >
          {substring}
        </span>
      );
    }
  );
  return <>{styledComponents}</>;
}
