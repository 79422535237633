import { useRecoilValue } from "recoil";

import cloudFiles from "../../../img/icons/contracts-cloud.svg";
import useShowModal from "../../hooks/useShowModal";
import { Button, Typography } from "../../library";
import { userEmailState } from "../../recoil/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { MODAL_SOURCE, modals } from "../../utils/enums";
import { trackGetHelpPiggybacking } from "../../utils/tracking";

interface GetHelpModalProps {
  hideModal: () => void;
  source?: MODAL_SOURCE;
}
export default function GetHelpModal({ hideModal, source }: GetHelpModalProps) {
  const showGetHelpSuccessModal = useShowModal(modals.GET_HELP_SUCCESS_MODAL);
  const userEmail = useRecoilValue(userEmailState);

  const modalTitle =
    source === MODAL_SOURCE.RECENTLY_VIEWED_CONTRACTS
      ? "No worries, there are other ways to add contracts"
      : "Add more contracts";

  function handleGetHelp() {
    trackGetHelpPiggybacking({ userEmail });
    showGetHelpSuccessModal({});
  }

  return (
    <Modal
      hideModal={hideModal}
      title={modalTitle}
      showLogoHeader={false}
      modalSize={modalSizes.SMALL}
      className="analytics-get-help-modal"
    >
      <div className="flex flex-col items-center gap-8">
        <img
          src={cloudFiles}
          className="w-[192px] h-[112px]"
          alt="Uploading contracts"
        />
        <Typography>
          Adding contracts here will help you stay ahead of expiring contracts.
          Click the button below to indicate interest, and our team will reach
          back out to you to help you add contracts.
        </Typography>
        <Button onClick={handleGetHelp}>I'm interested</Button>
      </div>
    </Modal>
  );
}
