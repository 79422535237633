import { Typography } from "../library";

const MAX_OFFERINGS = 3;

export default function SimilarOfferings({
  offerings,
  className,
}: {
  offerings: string[];
  className?: string;
}) {
  const text = offerings
    .map((o) => o.trim())
    .slice(0, MAX_OFFERINGS)
    .join("; ");

  return (
    <Typography color="neutral.bolder.enabled" className={className}>
      Similar offerings: {text}
    </Typography>
  );
}
