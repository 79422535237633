import { useRecoilValue } from "recoil";

import anne from "../../../../img/team-icons/anne.png";
import ryan from "../../../../img/team-icons/ryan.png";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Link,
  Typography,
} from "../../../library";
import { buyerProfileTypeState, userStateState } from "../../../recoil/user";
import { BuyerProfileTypes } from "../../../shared/types";
import { getCalendlyLink } from "../../../utils";
import { generateHrefForEmail } from "../../../utils/format";
import { trackContactRegionalManager } from "../../../utils/tracking";

type RegionalManager = {
  name: string;
  email: string;
  image: string;
  scheduleWalkthroughLink: string;
  states: string[];
};

const regionalManagers: RegionalManager[] = [
  {
    name: "Anne",
    email: "anne@withpavilion.com",
    image: anne,
    scheduleWalkthroughLink:
      "https://hello.withpavilion.com/c/annewithpavilion-com/t/7",
    states: ["FL", "VA"],
  },
  {
    name: "Ryan",
    email: "ryan@withpavilion.com",
    image: ryan,
    scheduleWalkthroughLink: "https://calendly.com/ryanpavilion/45",
    states: ["TX", "AZ"],
  },
];

function GenericBuyerSupportCard() {
  return (
    <CardContainer
      variant={CardContainerVariant.PRIMARY}
      className="p-4 max-w-[49.5rem] flex-1"
    >
      <Typography emphasis variant="headline" size="sm" className="mb-2">
        Need additional support?
      </Typography>

      <Typography className="mb-4">
        Feel free to set up time with a member of our team using this calendar
        link.
      </Typography>

      <Button
        as={Link}
        size={ButtonSizes.SMALL}
        theme={ButtonThemes.SECONDARY_DARK}
        className="analytics-welcome-schedule-walkthrough"
        linkProps={{
          href: getCalendlyLink("bpsc", "website"),
          underline: false,
        }}
      >
        Schedule a meeting
      </Button>
    </CardContainer>
  );
}

function RegionalManagerCard({
  regionalManager,
  buyerProfileType,
}: {
  regionalManager: RegionalManager;
  buyerProfileType: Maybe<BuyerProfileTypes>;
}) {
  const isLeadership = buyerProfileType === BuyerProfileTypes.LEADERSHIP;

  const headingText = isLeadership
    ? "Free technical assistance for your team"
    : "Get hands-on support";
  const introductionText = isLeadership
    ? `Pavilion now offers more customized support — from trainings to educational materials to online tools — to make your team's cooperative purchasing work easier. Reach out to your local partnerships manager, ${regionalManager.name}, to learn more!`
    : `Welcome! I'm ${regionalManager.name} — your dedicated point of contact at Pavilion. Reach out to me with any questions, requests, or feedback. I can also provide research support, or set your team up with additional tools and training to make cooperative purchasing easier.`;
  const buttonHref = isLeadership
    ? regionalManager.scheduleWalkthroughLink
    : generateHrefForEmail(regionalManager.email);
  const interactionType = isLeadership
    ? "SCHEDULE_WALKTHROUGH"
    : "CONTACT_MANAGER";
  const buttonText = isLeadership
    ? "Schedule a meeting"
    : `Contact ${regionalManager.name}`;

  return (
    <CardContainer
      variant={CardContainerVariant.PRIMARY}
      className="p-5 max-w-[49.5rem] flex-1"
    >
      <div className="flex items-center gap-3 mb-2">
        <img src={regionalManager.image} />
        <div>
          <Typography
            color="brand.boldest.enabled"
            emphasis
            variant="headline"
            size="sm"
          >
            {regionalManager.name}
          </Typography>
          <Typography color="neutral.bolder.enabled" variant="meta">
            Partnerships Manager
          </Typography>
        </div>
      </div>
      <Typography
        color="neutral.boldest.enabled"
        emphasis
        className="mb-1"
        variant="headline"
        size="xs"
      >
        {headingText}
      </Typography>
      <Typography color="neutral.boldest.enabled" className="mb-4">
        {introductionText}
      </Typography>
      <div className="flex gap-3">
        <Button
          as={Link}
          size={ButtonSizes.SMALL}
          theme={ButtonThemes.SECONDARY_DARK}
          linkProps={{
            href: buttonHref,
            underline: false,
          }}
          onClick={() => {
            trackContactRegionalManager({
              email: regionalManager.email,
              interactionType,
            });
          }}
        >
          {buttonText}
        </Button>
      </div>
    </CardContainer>
  );
}

export default function BuyerSupportCard() {
  const userState = useRecoilValue(userStateState);
  const buyerProfileType = useRecoilValue(buyerProfileTypeState);

  if (!userState.location) {
    return <></>;
  }

  const regionalManager = regionalManagers.find(
    (rm) =>
      userState?.location?.state &&
      rm.states.includes(userState?.location?.state)
  );

  if (!regionalManager) {
    return <GenericBuyerSupportCard />;
  }

  return (
    <RegionalManagerCard
      regionalManager={regionalManager}
      buyerProfileType={buyerProfileType}
    />
  );
}
