import { useEffect, useState } from "react";
import { ApiService } from "../../../generated";
import useRequestID from "../../../hooks/useRequestID";
import { Typography } from "../../../library";
import type { SupplierContactWithPhoneNumber } from "../../../shared/types";
import { formatPhoneNumber } from "../../../utils/format";
import { handleError } from "../../../utils/generatedApi";
import { trackHeapEvent } from "../../../utils/tracking";
import {
  type Sentiment,
  SupplierContactFeedbackRow,
} from "./SupplierContactFeedbackRow";

interface SupplierContactPhoneNumberProps {
  supplierId: number;
  contact: SupplierContactWithPhoneNumber;
  showSuccessPopup: () => void;
}

export function SupplierContactPhoneNumber({
  supplierId,
  contact,
  showSuccessPopup,
}: SupplierContactPhoneNumberProps) {
  const requestID = useRequestID();
  const [sentiment, setSentiment] = useState<Sentiment | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Reset sentiment when contact changes
  useEffect(() => {
    setSentiment(null);
  }, [contact]);

  // Placeholder to prevent layout change
  if (!contact) return <div className="h-[50.4px]" />;

  const submitFeedback = async ({
    sentiment,
    contactId,
  }: {
    sentiment: Sentiment;
    contactId: number;
  }) => {
    setSentiment(sentiment);
    showSuccessPopup();
    trackHeapEvent("supplier-contact-sentiment-click", {
      requestID,
      contactID: contactId,
      sentiment: sentiment,
    });
    try {
      await ApiService.apiV1SuppliersContactsFeedbackCreate(
        +`${contactId}`, // convert to number for api type adherence
        supplierId,
        { sentiment }
      );
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <Typography size="sm" color="neutral.bolder.enabled">
        {formatPhoneNumber(contact.phoneNumber)}
      </Typography>
      <SupplierContactFeedbackRow
        contactPhoneNumber={contact.phoneNumber}
        contactId={contact.id}
        submitFeedback={submitFeedback}
        sentimentState={sentiment}
      />
    </div>
  );
}
