import { Tooltip, Typography } from "../../../library";
import type { Bookmark, OCRFile } from "../../../shared/types";

import LockIcon from "@mui/icons-material/Lock";
import clsx from "clsx";
import Tag, { TagVariants } from "../../../shared/Tag";
import { borderColorClass } from "../../../utils/colors";
import { isFeatureEnabled } from "../../../utils/split";
import { getCleanedFileName } from "./utils";

export type HandleClickBookmarkOptions = {
  file: OCRFile;
  bookmark: Bookmark;
};

interface FileItemProps {
  file: OCRFile;
  contractNumber?: string;
  supplierName: string;
  className?: string;
}

export function HiddenFileItem({
  file,
  contractNumber,
  supplierName,
  className,
}: FileItemProps) {
  const hasFeature = isFeatureEnabled("simplifiedContractPage");
  return (
    <>
      <div className={clsx("flex w-full items-center gap-3", className)}>
        <Typography
          className="break-words line-clamp-1"
          color="neutral.bold.enabled"
        >
          {getCleanedFileName(file, contractNumber)}
        </Typography>
        <Tooltip
          info={`Document hidden until your account is verified. Alternatively, reach out to ${supplierName} to request access to this document.`}
          placement="right-start"
          className="w-72"
        >
          <Tag
            key={`${file.id}-hidden`}
            variant={TagVariants.SUBTLE}
            Icon={LockIcon}
          >
            Hidden
          </Tag>
        </Tooltip>
      </div>
      {hasFeature && <hr className={borderColorClass.neutral.subtle.enabled} />}
    </>
  );
}
