import type { FormikHelpers, FormikValues } from "formik";
import { useState } from "react";

import { InlineFormWrapper } from "../../library/form";
import { handleError, postSetPassword } from "../../utils/api";
import { PASSWORD_FIELDS } from "../SignupSteps/constants";

interface PasswordFormProps {
  onComplete: () => void;
}
export default function PasswordForm({ onComplete }: PasswordFormProps) {
  const [loading, setLoading] = useState(false);

  async function submitSetPassword(
    values: FormikValues,
    { setFieldError }: FormikHelpers<FormikValues>
  ) {
    setLoading(true);
    const response = await postSetPassword(values.password);
    setLoading(false);

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const error = await response.json();
        if (error?.password) {
          setFieldError("password", error.password);
        }
      }
      return;
    }
    onComplete();
  }

  return (
    <div className="grid gap-8 text-center">
      <InlineFormWrapper
        fields={PASSWORD_FIELDS}
        initialValues={{
          password: "",
        }}
        submitCta="Save password"
        submitClassName="analytics-password-reset-cta"
        onSubmit={submitSetPassword}
        disabled={loading}
      />
    </div>
  );
}
