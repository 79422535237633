import { useState } from "react";

import pluralize from "pluralize";
import type { BuyerLeadAgency } from "../../generated";
import { AutocompleteInput, Typography } from "../../library";
import type {
  AutocompleteInputOnSelect,
  AutocompleteOption,
} from "../../library/AutocompleteInput";
import type { InputSizes } from "../../library/Input/LabeledInput";
import { debouncedGetBuyerLeadAgenciesList } from "./debouncedGetBuyerLeadAgecies";

type BuyerLeadAgencyItem = AutocompleteOption<BuyerLeadAgency>;

export type BLAAutocompleteInputOnChange =
  AutocompleteInputOnSelect<BuyerLeadAgency>;

interface BLAAutocompleteInputProps {
  analyticsClass?: string;
  initialValue?: Maybe<string>;
  initialId?: string;
  onChange: BLAAutocompleteInputOnChange;
  hasLabel?: boolean;
  placeholder?: string;
  errorMessage?: string;
  size?: InputSizes;
  dataTestId?: string;
  label?: string;
  sublabel?: string;
  useAddComponent?: boolean;
  excludeCooperatives?: boolean;
  freeSolo?: boolean;
}

function BLAOptionComponent(agency: BuyerLeadAgency) {
  return (
    <div>
      <div className="w-full inline-flex justify-between">
        <Typography>{agency.displayName}</Typography>
        {agency.inUserApprovedSources ? (
          <Typography color="neutral.bold.enabled">Added</Typography>
        ) : (
          <Typography color="brand.bold.enabled" variant="cta">
            Add
          </Typography>
        )}
      </div>
      <Typography
        className="mt-2"
        color="neutral.default.tertiary.enabled"
        variant="meta"
      >
        {agency.contractCount} {pluralize("contract", agency.contractCount)}
      </Typography>
    </div>
  );
}

export default function BLAAutocompleteInput({
  initialValue,
  initialId,
  analyticsClass,
  onChange,
  hasLabel = true,
  placeholder = "ex. City of Mesa, AZ",
  errorMessage,
  size,
  dataTestId,
  label,
  sublabel,
  excludeCooperatives = true,
  useAddComponent = false,
  freeSolo = true,
}: BLAAutocompleteInputProps) {
  const [agenciesList, setAgenciesList] = useState<BuyerLeadAgencyItem[]>([]);
  return (
    <AutocompleteInput
      name="governmentAgency"
      initialValue={initialValue}
      initialSelectedId={initialId || null}
      onChange={(value) =>
        debouncedGetBuyerLeadAgenciesList(value, {
          excludeCooperatives,
          onComplete: (agencies) => {
            const agenciesList = agencies.map((agency) => {
              const agencyData: AutocompleteOption<BuyerLeadAgency> = {
                id: agency.id,
                label: agency.displayName,
                data: agency,
              };
              if (useAddComponent) {
                agencyData.Component = BLAOptionComponent;
                agencyData.disabled = agency.inUserApprovedSources;
              }
              return agencyData;
            });
            setAgenciesList(agenciesList);
          },
        })
      }
      onSelect={onChange}
      options={agenciesList}
      label={hasLabel ? label || "Public Entity" : undefined}
      analyticsClass={analyticsClass}
      dataTestId={dataTestId}
      placeholder={placeholder}
      freeSolo={freeSolo}
      freeSoloLabel="Add a new public entity"
      errorMessage={errorMessage}
      sublabel={sublabel}
      size={size}
    />
  );
}
