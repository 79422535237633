import clsx from "clsx";
import { Link, Typography } from "../library";
import type { TypographySize } from "../library/Typography/types";
import { isFeatureEnabled } from "../utils/split";

interface PrivacyPolicyNoticeProps {
  size?: TypographySize;
  className?: string;
}
export default function PrivacyPolicyNotice({
  size,
  className,
}: PrivacyPolicyNoticeProps) {
  if (!isFeatureEnabled("privacyPolicyNotice")) return <></>;
  return (
    <div className={clsx("flex w-full", className)}>
      <Typography variant="meta" color="neutral.bolder.enabled" size={size}>
        By clicking “Create your account,” you agree to Pavilion{"'"}s{" "}
        <Link variant="meta" href="/privacy" underline={false} size={size}>
          Privacy Policy
        </Link>
        .
      </Typography>
    </div>
  );
}
