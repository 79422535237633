import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { type ReactNode, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ProjectCreationSource } from "../../components/Project/types";
import { ApiService, ItemTypeEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  DropdownPicker,
  Link,
  Typography,
} from "../../library";
import { popupState } from "../../recoil/page";
import { isAuthenticatedState } from "../../recoil/user";
import Modal from "../../shared/Modal/Modal";
import { BUTTON_PROPS } from "../../shared/saveToProjectUtils";
import { PopupType, modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { trackSaveToProject } from "../../utils/tracking";

interface PostDownloadModalProps {
  hideModal: () => void;
  contractId: string;
  savedProjectId: string;
  setSavedProjectId?: (projectId: string) => void;
}

export default function PostDownloadModal({
  hideModal,
  contractId,
  savedProjectId,
  setSavedProjectId,
}: PostDownloadModalProps) {
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const showNewProjectModal = useShowModal(modals.NEW_PROJECT_MODAL);
  const setPopupState = useSetRecoilState(popupState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [dropdownOptions, setDropdownOptions] = useState<
    { key: string; value: string; label: ReactNode; className?: string }[]
  >([]);

  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList();
        const options = projects.map((project) => ({
          key: project.id,
          value: project.id,
          label: <Typography>{project.name}</Typography>,
        }));
        options.push({
          key: "add-project",
          value: "add-project",
          label: (
            <Typography
              className="analytics-save-project-dropdown-add-project"
              color="brand.bold.enabled"
              emphasis
            >
              + New project
            </Typography>
          ),
        });

        setDropdownOptions(options);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [isAuthenticated]);

  const itemType = ItemTypeEnum.CONTRACT;

  async function saveProjectItem(projectId: string) {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId: contractId,
        itemType,
      });
      setSavedProjectId?.(projectId);
      setPopupState({
        analyticsClassName: `analytics-new-project-success-${ProjectCreationSource.DOWNLOAD_DOCS}`,
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: (
          <Badge Icon={CheckRoundedIcon} iconClass="!mr-4">
            <Typography color="inverse">
              {BUTTON_PROPS[itemType].viewSavedCtaCopy}{" "}
              <Link
                color="inverse"
                emphasis={false}
                href={`/projects/${projectId}`}
              >
                here
              </Link>
              .
            </Typography>
          </Badge>
        ),
        show: true,
      });
      trackSaveToProject({ itemId: contractId, itemType });
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      title="Contract docs are downloading"
      subtitle="While your documents download, save this contract to a project for easy reference and to request quotes from suppliers."
      contentClassName="overflow-visible w-[400px] mx-auto !pt-0"
      className="analytics-post-download-modal"
      centerSubtitle={false}
    >
      <div className="flex flex-col items-center mt-4 gap-8">
        <DropdownPicker
          placeholder="Select or create a project"
          initialValue={savedProjectId ? savedProjectId : "add-project"}
          options={dropdownOptions}
          label="Project name"
          onChange={(projectId) => {
            if (projectId === "add-project") {
              showNewProjectModal({
                source: ProjectCreationSource.DOWNLOAD_DOCS,
                saveProjectItem,
                itemId: contractId,
                itemType,
              });
            } else {
              setSelectedProjectId(projectId);
            }
          }}
        />
        <div className="flex gap-6">
          <Button
            className="analytics-skip-save-to-project-button"
            size={ButtonSizes.LARGE}
            theme={ButtonThemes.TERTIARY_DARK}
            onClick={hideModal}
          >
            Skip
          </Button>
          <Button
            className="analytics-save-to-project-button"
            size={ButtonSizes.LARGE}
            theme={ButtonThemes.PRIMARY_DARK}
            onClick={() => {
              saveProjectItem(selectedProjectId);
              hideModal();
            }}
            disabled={!selectedProjectId}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
