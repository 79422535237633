import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import type { RelevantContract } from "../../generated";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
} from "../../recoil/search";
import { buyerProfileState } from "../../recoil/user";

/**
 * A hook that returns the most important source (Coop or BLA) for a contract
 * based on the Buyer's filters and data on the contract. If no filters are
 * present, use the Coop, falling back to the BLA. Otherwise, return the Coop or BLA
 * that matches filters.
 **/
export function useMatchingSource() {
  const { governmentAgency } = useRecoilValue(buyerProfileState);
  const contractSourceFilter = useRecoilValue(contractSourceFilterState);
  const approvedSourcesOnlyFilter = useRecoilValue(
    approvedSourcesOnlyFilterState
  );

  return useCallback(
    (c: RelevantContract) => {
      const hasApprovedSourceFilter =
        approvedSourcesOnlyFilter &&
        (governmentAgency?.filterSources.length ||
          governmentAgency?.approvedStates?.length);
      const hasContractSourceFilter = !!contractSourceFilter.length;
      if (!hasApprovedSourceFilter && !hasContractSourceFilter)
        return c.cooperativeAffiliation || c.buyerLeadAgency;

      const hasCooperativeMatch =
        c.cooperativeAffiliation &&
        (contractSourceFilter.includes(c.cooperativeAffiliation) ||
          governmentAgency?.filterSources.includes(c.cooperativeAffiliation));
      if (hasCooperativeMatch) return c.cooperativeAffiliation;

      const hasAgencyMatch =
        c.buyerLeadAgency &&
        (contractSourceFilter.includes(c.buyerLeadAgency) ||
          governmentAgency?.filterSources.includes(c.buyerLeadAgency) ||
          (c.buyerLeadAgencyState &&
            governmentAgency?.approvedStates?.includes(
              c.buyerLeadAgencyState
            )));
      if (hasAgencyMatch) return c.buyerLeadAgency;
      return c.cooperativeAffiliation || c.buyerLeadAgency;
    },
    [governmentAgency, contractSourceFilter, approvedSourcesOnlyFilter]
  );
}
