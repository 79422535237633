import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import { type ReactNode, type RefObject, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { ApiService, ItemTypeEnum } from "../../../generated";
import useLoginWall from "../../../hooks/useLoginWall";
import useRequestMissingDocuments from "../../../hooks/useRequestMissingDocuments";
import useShowModal from "../../../hooks/useShowModal";
import { Badge, Button, Link, Typography } from "../../../library";
import { ErrorPopup } from "../../../popups/AnimatedPopup";
import {
  isAuthenticatedState,
  profileTypeState,
  userInitializedState,
  userStateState,
  userZipState,
} from "../../../recoil/user";
import { BUTTON_PROPS } from "../../../shared/saveToProjectUtils";
import {
  LoginWallTriggers,
  MODAL_SOURCE,
  PopupType,
  ProfileType,
  modals,
} from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import {
  trackContactBLAFromSolicitation,
  trackFileDownloaded,
  trackGTMEvent,
  trackSaveToProject,
  trackSupplierOutreachOptInDownload,
  trackSupplierOutreachOptOutDownload,
  trackViewSourceClick,
} from "../../../utils/tracking";

import type { ShowBuyerOptInBeforeDownloadModal } from "../../../modals/BuyerOptInModals/BuyerOptinBeforeDownloadModal";
import type { ContractSolicitationDetails } from "../../../pages/Contract";
import {
  hasSeenProjectPromptState,
  optedInOutreachSuppliersState,
} from "../../../recoil/history";
import { popupState } from "../../../recoil/page";
import { isFeatureEnabled } from "../../../utils/split";
import DownloadAllButton from "../DownloadAllButton";
import { downloadAllFiles } from "../utils";
import FilesSection from "./FilesSection";
import NoContractFilesBanner from "./NoContractFilesBanner";

interface ContractFilesProps {
  contractId: string;
  contractDetails: ContractSolicitationDetails;
  title: string;
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
  query?: string;
  sourceKey: string;
  solicitationId: string;
  requestID?: string;
  bottomViewerRef: RefObject<HTMLDivElement>;
  scrollToViewer: () => void;
}

export default function ContractFiles({
  contractId,
  contractDetails,
  title,
  buyerLeadAgency,
  cooperativeAffiliation,
  query,
  solicitationId,
  requestID,
  bottomViewerRef,
  scrollToViewer,
}: ContractFilesProps) {
  const {
    contract_id,
    contract_number,
    file_information,
    source_url,
    supplier,
    supplier_contact,
  } = contractDetails;
  const [onRequestDocuments, isLoading] = useRequestMissingDocuments(
    contractId,
    solicitationId
  );
  const hasFiles = file_information?.length;
  const downloadableFiles =
    file_information?.filter((f) => !f.buyer_verification_gated) ?? [];
  const [downloadError, setDownloadError] = useState<ReactNode>(null);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const isInitialized = useRecoilValue(userInitializedState);
  const profileType = useRecoilValue(profileTypeState);
  const userZip = useRecoilValue(userZipState);
  const setPopupState = useSetRecoilState(popupState);
  const { supplierContactOptIn } = useRecoilValue(userStateState);
  const [optedInOutreachSuppliers, setOptedInOutreachSuppliers] =
    useRecoilState(optedInOutreachSuppliersState);
  const [hasSeenProjectPrompt, setHasSeenProjectPrompt] = useRecoilState(
    hasSeenProjectPromptState
  );
  const requireLogin = useLoginWall();
  const showContactBlaModal = useShowModal(modals.CONTACT_BUYER_LEAD_AGENCY);
  const showPostDownloadModal = useShowModal(modals.POST_DOWNLOAD_MODAL);
  const showBuyerBeforeDownloadOptInModal: ShowBuyerOptInBeforeDownloadModal =
    useShowModal(modals.BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL);
  const [savedProjectId, setSavedProjectId] = useState("");
  const blaRestrictsDocumentAccess = file_information?.some(
    ({ has_access }) => !has_access
  );
  const accessDenied = !isAuthenticated || blaRestrictsDocumentAccess;
  const restrictDocumentAccess = isAuthenticated && blaRestrictsDocumentAccess;

  function showMessageContractAdminModal() {
    showContactBlaModal({
      contractId: contractDetails.contract_id,
      searchQuery: query,
      queryZip: userZip,
      solicitationId,
      supplierId: contractDetails.supplier.id,
      supplierDisplayName: contractDetails.supplier.displayName,
      buyerLeadAgency,
      blaPOC: contractDetails.bla_poc,
      requestID,
      source: "contractFiles",
    });
    trackContactBLAFromSolicitation({
      contractId: contractDetails.contract_id,
      searchQuery: query || "",
      queryZip: userZip,
      solicitationId: solicitationId,
      supplierId: contractDetails.supplier.id,
      buyerLeadAgency: buyerLeadAgency,
      blaPOC: contractDetails.bla_poc,
      requestID,
      source: "contractFiles",
    });
  }

  const onClickMessageContractAdmin = () => {
    void requireLogin({
      triggerId: contractDetails.contract_id,
      triggerType: LoginWallTriggers.MESSAGE_SUPPLIER_FROM_CONTRACT,
      onComplete: () => showMessageContractAdminModal(),
    });
  };

  async function saveProjectItem(projectId: string) {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId: contractId,
        itemType: ItemTypeEnum.CONTRACT,
      });
      setSavedProjectId(projectId);
      setPopupState({
        analyticsClassName: "analytics-create-projects-success",
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: (
          <Typography color="inverse">
            {BUTTON_PROPS[ItemTypeEnum.CONTRACT].viewSavedCtaCopy}{" "}
            <Link
              color="inverse"
              emphasis={false}
              href={`/projects/${savedProjectId}`}
            >
              here
            </Link>
            .
          </Typography>
        ),
        show: true,
      });
      trackSaveToProject({
        itemId: contractId,
        itemType: ItemTypeEnum.CONTRACT,
      });
    } catch (e) {
      handleError(e);
    }
  }

  function showOutreachSuccessPopup() {
    setPopupState({
      analyticsClassName: "analytics-supplier-outreach-opt-in-download-success",
      name: PopupType.SUCCESS,
      children: (
        <Typography color="inverse">
          Supplier has been notified and will be in touch soon!
        </Typography>
      ),
      show: true,
    });
  }

  function showPostDownloadModalIfNotSeen() {
    if (!hasSeenProjectPrompt) {
      setHasSeenProjectPrompt(true);
      showPostDownloadModal({
        contractId,
        saveProjectItem,
        savedProjectId,
        setSavedProjectId,
      });
    }
  }

  const onClickDownloadAll = async () => {
    try {
      if (
        supplierContactOptIn &&
        profileType === ProfileType.BUYER &&
        !optedInOutreachSuppliers.includes(supplier.id)
      ) {
        setOptedInOutreachSuppliers([...optedInOutreachSuppliers, supplier.id]);
        showBuyerBeforeDownloadOptInModal({
          supplier,
          contractId,
          contractTitle: title,
          solicitationId,
          query,
          supplierPOC: supplier_contact?.full_name || "",
          source: MODAL_SOURCE.BEFORE_DOWNLOAD,
          trackOptIn: trackSupplierOutreachOptInDownload,
          trackOptOut: trackSupplierOutreachOptOutDownload,
          onSkip: showPostDownloadModalIfNotSeen,
          onComplete: () => {
            showOutreachSuccessPopup();
            showPostDownloadModalIfNotSeen();
          },
          downloadFiles: async () =>
            await downloadAllFiles(
              downloadableFiles,
              `${supplier.displayName}_${title}`
            ),
          shouldHideModalAfterCta: hasSeenProjectPrompt,
          modalType: "BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL",
        });
      } else {
        await downloadAllFiles(
          downloadableFiles,
          `${supplier.displayName}_${title}`
        );
        showPostDownloadModalIfNotSeen();
      }
    } catch (err) {
      captureException(err);
      setDownloadError(err as string);
    }

    trackFileDownloaded({
      supplierId: supplier.id,
      supplierName: supplier.displayName,
      supplierHandle: supplier.handle,
      contractId,
      buyerLeadAgency,
      cooperativeAffiliation,
      query,
      queryZip: userZip,
      numDocuments: downloadableFiles.length,
      downloadSource: "downloadAllCTA",
      requestID,
    });
    trackGTMEvent("file-opened");
  };

  function viewSource() {
    trackViewSourceClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      sourceUrl: source_url,
      requestID,
    });
    window.open(source_url, "_blank");
  }

  if (!hasFiles || restrictDocumentAccess) {
    return (
      <NoContractFilesBanner
        viewSource={viewSource}
        onRequestDocuments={onRequestDocuments}
        isLoading={isLoading}
        cooperativeAffiliation={cooperativeAffiliation}
        buyerLeadAgency={buyerLeadAgency}
        sourceUrl={source_url}
        numDocuments={file_information?.length || 0}
        onClickMessageContractAdmin={onClickMessageContractAdmin}
      />
    );
  }

  return (
    <div className="grid gap-8">
      <ErrorPopup show={!!downloadError} onClose={() => setDownloadError(null)}>
        <Badge
          Icon={WarningAmberRoundedIcon}
          className="line-clamp-2 hover:line-clamp-none"
          iconClass="mr-4"
        >
          <span>{downloadError}</span>
        </Badge>
      </ErrorPopup>
      {!isFeatureEnabled("simplifiedContractPage") && (
        <div
          data-testid="contract-documents-heading"
          className="flex flex-col gap-4"
        >
          <Typography
            emphasis
            size="sm"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Documents
          </Typography>
          <div className="flex gap-2">
            {isInitialized && isAuthenticated && (
              <DownloadAllButton
                onClick={onClickDownloadAll}
                disabled={accessDenied || downloadableFiles.length === 0}
                className="analytics-download-all-files-from-sidebar"
              />
            )}
            {!(profileType === ProfileType.SUPPLIER) && (
              <Button
                size={Button.sizes.SMALL}
                theme={Button.themes.SECONDARY_DARK}
                dataTestId="message-contract-admin"
                onClick={onClickMessageContractAdmin}
                className="analytics-contact-buyer-lead-agency"
              >
                Email contract admin
              </Button>
            )}
          </div>
        </div>
      )}
      <div className="file-header relative">
        <div className={clsx({ "analytics-not-blocked": !accessDenied })}>
          <FilesSection
            files={file_information}
            contractNumber={contract_number}
            supplier={supplier}
            contractId={contractId}
            title={title}
            solicitationId={solicitationId}
            supplierPOC={contractDetails.supplier_contact?.full_name}
            saveProjectItem={saveProjectItem}
            buyerLeadAgency={buyerLeadAgency}
            cooperativeAffiliation={cooperativeAffiliation}
            query={query}
            blockFiles={accessDenied}
            setDownloadError={setDownloadError}
            savedProjectId={savedProjectId}
            setSavedProjectId={setSavedProjectId}
            showOutreachSuccessPopup={showOutreachSuccessPopup}
            requestID={requestID}
            bottomViewerRef={bottomViewerRef}
            scrollToViewer={scrollToViewer}
            onClickDownloadAll={onClickDownloadAll}
            onClickMessageContractAdmin={onClickMessageContractAdmin}
            hasDownloadableFiles={!!downloadableFiles.length}
          />
        </div>
      </div>
    </div>
  );
}
