import clsx from "clsx";
import { type RefObject, useRef, useState } from "react";

import { scrollToSection } from "../../components/SolicitationPage/utils";
import { useIntersection } from "../../hooks/useIntersection";
import useLoginWall from "../../hooks/useLoginWall";
import { borderColorClass } from "../../utils/colors";
import { LoginWallTriggers } from "../../utils/enums";
import {
  type TrackAnchorLinkClickParams,
  trackAnchorLinkClick,
} from "../../utils/tracking";
import TabLink from "../Tabs/TabLink";

const defaultScrollOptions: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "start",
  inline: "nearest",
};

const defaultIntersectionOptions: IntersectionObserverInit = {
  root: null,
  threshold: 0.5,
  rootMargin: "0px",
};

export interface AnchorRefLink {
  pageId: string; // identifier for the page where the link is located (e.g. contract_id, solicitation_id)
  name: string;
  ref: RefObject<HTMLDivElement>;
  className?: string;
  scrollOptions?: ScrollIntoViewOptions;
  intersectionOptions?: IntersectionObserverInit;
  visible?: boolean;
  trackingParams: Omit<
    TrackAnchorLinkClickParams,
    "linkName" | "linkClassName"
  >;
  requireLogin: boolean;
  hidden?: boolean;
}

interface AnchorRefLinksProps {
  links: AnchorRefLink[];
}

export default function AnchorRefLinks({ links }: AnchorRefLinksProps) {
  const [activeLink, setActiveLink] = useState<string>(links[0].name);
  const headerRef = useRef<HTMLUListElement>(null);
  const requireLogin = useLoginWall();

  function handleClick(link: AnchorRefLink) {
    if (link.requireLogin) {
      void requireLogin({
        triggerId: link.pageId,
        onComplete: () => clickAction(link),
        triggerType: LoginWallTriggers.SOLICITATION_PAGE_VIEW_DOCUMENTS_CLICK,
      });
    } else {
      clickAction(link);
    }
  }

  function clickAction(link: AnchorRefLink) {
    scrollToSection({
      sectionRef: link.ref,
      options: link.scrollOptions || defaultScrollOptions,
    });
    setActiveLink(link.name);
    trackAnchorLinkClick({
      linkName: link.name,
      linkClassName: link.className,
      ...link.trackingParams,
    });
  }

  for (const link of links) {
    link.visible = useIntersection(
      link.ref,
      link.intersectionOptions || defaultIntersectionOptions
    );
  }
  // Get all linked sections that are within the viewport
  const visibleLinks = links.filter((link) => link.visible);
  // If the current active link section is not visible, set the active link to the first visible link
  if (visibleLinks.length && activeLink !== visibleLinks[0].name) {
    setActiveLink(visibleLinks[0].name);
  }

  return (
    <ul
      className={clsx(
        "flex gap-10 items-end w-full h-15 sticky top-20 z-1 bg-cp-white-100 border-b border-solid mt-[-32px]",
        borderColorClass.neutral.subtle.enabled
      )}
      ref={headerRef}
    >
      {links.map((link) => (
        <li
          key={link.name}
          onClick={() => handleClick(link)}
          className={clsx({ hidden: link.hidden })}
        >
          <TabLink tabName={link.name} activeTabName={activeLink} />
        </li>
      ))}
    </ul>
  );
}
