import type { FormikProps } from "formik";
import { type ChangeEventHandler, useState } from "react";
import { LabeledInputVariants, VARIANTS } from "../Input/LabeledInput";
import SelectableChip from "../Input/SelectableChip";
import Typography from "../Typography";

interface FormikSelectableChipField {
  name: string;
  value: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

type SelectableChipFieldProps<T extends {}> = {
  variant?: LabeledInputVariants;
  field: FormikSelectableChipField;
  form: FormikProps<T>;
  id: string;
  label: string;
  sublabel?: string;
  message?: string;
  // biome-ignore lint/suspicious/noExplicitAny: This is the same type as in FormFieldProps
  options: { key: string; value: any; label: string }[];
  onChange?: (
    value: boolean,
    options: { form: FormikProps<T>; field: FormikSelectableChipField }
  ) => void;
};

export default function SelectableChipsField<T extends {}>({
  variant = LabeledInputVariants.DEFAULT,
  form,
  field,
  id,
  label,
  sublabel,
  message,
  options,
  onChange,
}: SelectableChipFieldProps<T>) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const { textColor, labelColor, messageColor } = VARIANTS[variant];

  const labelComponent = (
    <legend aria-label={`${label} ${sublabel}`} className="w-full mb-4">
      <Typography
        component="span"
        htmlFor={id}
        size="md"
        emphasis
        color={labelColor || textColor}
      >
        {label}
        {sublabel && (
          <Typography
            component="span"
            size="md"
            variant="meta"
            color="neutral.bold.enabled"
          >
            {" "}
            {sublabel}
          </Typography>
        )}
      </Typography>
    </legend>
  );
  return (
    <>
      <fieldset className="flex flex-wrap gap-4">
        {labelComponent}
        {options.map(({ key, value, label }) => (
          <SelectableChip
            key={key}
            label={label}
            selected={selectedOptions.includes(value)}
            name={key}
            onChange={(e) => {
              const newOptions = e.target.checked
                ? [...selectedOptions, value]
                : selectedOptions.filter((o) => o !== value);
              setSelectedOptions(newOptions);
              form.setFieldValue(field.name, newOptions);
              form.setFieldTouched(field.name, true);
              onChange?.(e.target.checked, {
                field,
                form,
              });
            }}
          />
        ))}
      </fieldset>
      {message && (
        <Typography
          size="sm"
          variant="meta"
          color={messageColor || textColor}
          className="mt-2 mr-2 text-left"
        >
          {message}
        </Typography>
      )}
    </>
  );
}
