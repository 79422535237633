import { useRecoilValue } from "recoil";
import {
  type BaseSupplier,
  type SupplierCompliance,
  type SupplierServiceAreaData,
  SupplierServiceAreaDataConfidenceEnum,
} from "../../generated";
import { userState } from "../../recoil/user";
import { SUPPLIER_TAG_TYPES } from "../../utils/constants";
import { TagVariants } from "../Tag";

export const TAG_TYPE_TO_VARIANT = {
  [SUPPLIER_TAG_TYPES.VERIFIED_CONTACT]: TagVariants.VERIFIED,
  [SUPPLIER_TAG_TYPES.LOCAL_SUPPLIER]: TagVariants.LOCATION,
  [SUPPLIER_TAG_TYPES.PRO_SUPPLIER]: TagVariants.PRO_SUPPLIER,
} as const;

export const TAG_TYPE_TO_TOOLTIP = {
  [SUPPLIER_TAG_TYPES.LOCAL_SUPPLIER]:
    "This supplier is located less than 100 miles from you",
};

type FormattedTag = {
  text: string;
  variant: TagVariants;
};

export const SUPPLIER_AGREEMENT_TO_TAG_TEXT: Record<string, string> = {
  UNLIMITED: "Referral Pro",
  CONTRACT_LIMITED: "Orange County Pro",
  VIEWS_LIMITED: "Membership Pro",
  TARGET: "EV Trial Pro",
};

export function getFormattedTagsForSupplier({
  compliance,
  serviceArea,
  activeAgreements,
  noActiveShareableContracts,
}: {
  compliance?: SupplierCompliance;
  serviceArea?: SupplierServiceAreaData;
  activeAgreements?: string[];
  noActiveShareableContracts?: boolean;
}) {
  const { isAdmin } = useRecoilValue(userState);
  let shouldAddServiceArea = true;
  let formattedTags: FormattedTag[] =
    compliance?.tags
      .filter(({ variant }) => {
        // Only add service area data if the supplier is not already marked as local.
        if (variant === SUPPLIER_TAG_TYPES.LOCAL_SUPPLIER) {
          shouldAddServiceArea = false;
        }
        return !!TAG_TYPE_TO_VARIANT[variant];
      })
      .map(({ copy, variant }) => ({
        text: copy,
        variant: TAG_TYPE_TO_VARIANT[variant],
        tooltip: TAG_TYPE_TO_TOOLTIP[variant],
      })) || [];

  if (noActiveShareableContracts) {
    formattedTags.push({
      text: "No usable contracts",
      variant: TagVariants.NO_CONTRACTS,
    });
  }

  if (compliance?.diversityCertifications.length) {
    formattedTags.push({
      text: "Diversity certifications",
      variant: TagVariants.DIVERSITY,
    });
  }

  if (
    shouldAddServiceArea &&
    !!serviceArea?.state &&
    !!serviceArea?.confidence &&
    serviceArea.confidence !== SupplierServiceAreaDataConfidenceEnum.NEGATIVE
  ) {
    formattedTags.push({
      text: `Serves ${serviceArea.state}`,
      variant: TagVariants.LOCATION,
    });
  }

  if (isAdmin && activeAgreements?.length) {
    formattedTags = formattedTags.concat(
      activeAgreements.map((text) => ({
        text: `${SUPPLIER_AGREEMENT_TO_TAG_TEXT[text]}`,
        variant: TagVariants.DEBUG_MATCH,
      }))
    );
  }

  return formattedTags;
}

export function dedupeSuppliers<T extends { supplier: BaseSupplier }>(
  list: T[]
) {
  const seenSuppliers = new Set();

  return list.filter((supplier) => {
    const duplicate = seenSuppliers.has(supplier.supplier.handle);
    seenSuppliers.add(supplier.supplier.handle);
    return !duplicate;
  });
}

export function getSupplierAddressSnippet(supplier: BaseSupplier) {
  return [supplier.addressCity, supplier.addressStateCode]
    .filter(Boolean)
    .join(", ");
}

export function getSupplierAliasSnippet({ aliases }: SupplierCompliance) {
  if (!aliases?.length) {
    return null;
  }
  if (aliases.length === 1) {
    return `Also known as ${aliases[0]}`;
  }
  if (aliases.length === 2) {
    return `Also known as ${aliases[0]} and ${aliases[1]}`;
  }
  if (aliases.length === 3) {
    return `Also known as ${aliases[0]}, ${aliases[1]}, and ${aliases[2]}`;
  }
  return `Also known as ${aliases[0]}, ${aliases[1]}, ${aliases[2]} and ${
    aliases.length - 3
  } more`;
}
