import { type ChangeEvent, type KeyboardEventHandler, useState } from "react";

import { trackForgotPasswordClick } from "../../utils/tracking";
import Checkbox from "../Input/Checkbox";
import LabeledInput, {
  LabeledInputVariants,
  type InputSizes,
} from "../Input/LabeledInput";
import Link from "../Link";
import Typography from "../Typography";

export const passwordErrorPolicies = {
  password_too_short: "at least 8 characters long",
  password_too_common: "not a commonly used password",
  password_entirely_numeric: "not entirely numbers",
};

interface PasswordError {
  code: keyof typeof passwordErrorPolicies;
}

export const getPasswordErrorMessage = (responseError: PasswordError[]) => {
  if (responseError && responseError.length > 0) {
    const errorCodes = responseError.map((msg) => msg.code);
    const errorConstraintString =
      errorCodes && errorCodes.length > 0
        ? errorCodes
            .filter(Boolean)
            .map((errorCode) => passwordErrorPolicies[errorCode])
            .join(" and ")
        : "";
    const errorMessage = errorConstraintString
      ? `Password must be ${errorConstraintString}`
      : "Signup failed please use a different password";
    return errorMessage;
  }
  return "";
};

interface PasswordInputProps {
  password?: string;
  setPassword?: (password: string) => void;
  errorMessage?: string;
  showForgotPassword?: boolean;
  showShowPassword?: boolean;
  label?: string;
  sublabel?: string;
  name?: string;
  placeholder?: string;
  size?: InputSizes;
  onKeyDown?: KeyboardEventHandler;
  className?: string;
  autoFocus?: boolean;
}

function PasswordInput({
  password,
  setPassword,
  errorMessage,
  showForgotPassword = false,
  showShowPassword = true,
  label,
  sublabel,
  name = "password",
  placeholder = "Password",
  size = "md",
  onKeyDown,
  className,
  autoFocus = false,
}: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={className}>
      <LabeledInput
        type={showPassword ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        required
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (setPassword) {
            setPassword(e.target.value);
          }
        }}
        label={label}
        initialVariant={
          errorMessage
            ? LabeledInputVariants.ERROR
            : LabeledInputVariants.DEFAULT
        }
        message={errorMessage || sublabel}
        onKeyDown={onKeyDown}
        size={size}
        className="mb-1 placeholder:text-sm"
        dataTestId="password-input"
        autoFocus={autoFocus}
      />
      <div className="flex justify-between">
        <div>
          {showShowPassword && (
            <Checkbox
              name={`${name}-showPassword`}
              size="md"
              label={
                <Typography color="neutral.bolder.enabled" variant="meta">
                  Show password
                </Typography>
              }
              onChange={() => setShowPassword(!showPassword)}
              checked={showPassword}
            />
          )}
        </div>
        {showForgotPassword && (
          <Link
            emphasis={false}
            underline={false}
            variant="meta"
            color="brand.default.primary.enabled"
            className="analytics-email-login-modal-password-reset font-normal whitespace-nowrap underline-offset-2"
            href="/accounts/password/reset"
            onClick={trackForgotPasswordClick}
          >
            Forgot your password?
          </Link>
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
