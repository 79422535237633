import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DomainRoundedIcon from "@mui/icons-material/DomainRounded";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import useDiversityPreferences from "../../hooks/useDiversityPreferences";
import { Badge, CalendlyButton, PageSection, Typography } from "../../library";
import { isFreshSEOUserState } from "../../recoil/history";
import { buyerProfileState } from "../../recoil/user";
import ContentNotFound from "../../shared/ContentNotFound/ContentNotFound";
import SearchBar from "../../shared/SearchBar";
import { ContentTypes } from "../../shared/types";
import { getParams, hasWindow } from "../../utils";
import {
  SearchActions,
  SearchIntent,
  SearchSource,
  searchFilters,
} from "../../utils/enums";
import { trackHeapEvent, trackSearchFilterToggle } from "../../utils/tracking";
import { getCountsForTracking } from "../ContractSearch/utils";

import useFilteredSearchResults from "../../hooks/search/useFilteredSearchResults";
import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useLandingPageSearchContractWithParams from "../../hooks/useLandingPageSearchContractWithParams";
import {
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  matchedSearchResultCountState,
} from "../../recoil/search";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import SearchFilterHeader from "../../shared/SearchPage/SearchResults/SearchFilterHeader";
import { isFeatureEnabled } from "../../utils/split";
import type { PageData } from "./LandingPage";
import ResultsSection from "./ResultSection";
import SearchBarSection from "./SearchBarSection";
import { PageTypeDisplayMap } from "./constants";
import {
  trackContractClickLandingPageSearch,
  trackContractSearchLandingPageSearch,
} from "./helpers";

const INITIAL_RESULTS_LEN = 4;
const ACTIVE_CONTRACT_MIN = 20;
const DEFAULT_BLA_FILTER = [
  searchFilters.INCLUDE_NON_COOP,
  searchFilters.INCLUDE_EXPIRED,
  searchFilters.IS_NOT_LOCATION_RELEVANT,
];

interface LoggedInLandingPageProps {
  page: PageData;
  isSSR: boolean;
}
export function LoggedInLandingPage({
  page: {
    source_value,
    slug,
    title,
    subtitle,
    display_name,
    search_params,
    active_shareable_total,
    page_type,
    total,
    buyer_lead_agency_id,
  },
  isSSR = false,
}: LoggedInLandingPageProps) {
  const isFreshSEOUser = useRecoilValue(isFreshSEOUserState);
  const buyerProfile = useRecoilValue(buyerProfileState);
  const landingPagePath = hasWindow() ? window.location.pathname : "";
  const diversityPreferences = useDiversityPreferences();
  const [trackingCounts, setTrackingCounts] = useState({
    firstPageStrongMatchCount: 0,
    firstPagePossibleMatchCount: 0,
    firstPageSemanticMatchCount: 0,
  });
  const [filters, setFilters] = useState<string[]>([]);
  const contractResponseData = useRecoilValue(contractSearchResponseDataState);
  const requestID = contractResponseData?.params?.requestId || "";
  const contractSearchIsLoading = useRecoilValue(contractSearchIsLoadingState);

  const [searchParams, setSearchParams] = useRecoilState(
    contractSearchParamsState
  );
  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  useBindPageParamDict(searchParams, setSearchParams);
  const searchContractWithParams = useLandingPageSearchContractWithParams(slug);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on first render
  useEffect(() => {
    setSearchParams({
      ...searchParams,
      landingPageSlug: slug,
      intent: SearchIntent.BUYING,
    });
    const urlParams = getParams();
    const initialFilters = searchParams?.filters
      ? searchParams.filters.split(";").filter((f) => !!f)
      : null;
    setFilters(initialFilters || DEFAULT_BLA_FILTER);
    searchContractWithParams({
      newParams: {
        query: (urlParams.query as string) || "",
        filters: initialFilters || DEFAULT_BLA_FILTER,
        landingPageSlug: slug,
        embedSourceEntityId: buyer_lead_agency_id,
        requestID,
      },
      action: SearchActions.SEARCH,
    });
  }, []);

  useEffect(() => {
    setFilters(searchParams.filters?.split(";").filter((f) => !!f) || []);
  }, [searchParams.filters]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on first render
  useEffect(() => {
    if (isFreshSEOUser) {
      trackHeapEvent("fresh-seo-user-landing-page");
    }
    trackHeapEvent("view-bla-page", {
      buyerLeadAgencyId: buyer_lead_agency_id,
    });
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when the page value changes.
  useEffect(() => {
    const {
      strongMatchesCount,
      possibleMatchesCount,
      scopeMatchesCount,
      supplierNameMatchesCount,
      semanticMatchesCount,
    } = getCountsForTracking(contractResponseData?.contractData?.results);
    const action = SearchActions.SEARCH;
    const firstPageTrackingCounts = {
      firstPageStrongMatchCount: strongMatchesCount,
      firstPagePossibleMatchCount: possibleMatchesCount,
      firstPageSemanticMatchCount: semanticMatchesCount,
    };
    setTrackingCounts(firstPageTrackingCounts);
    trackContractSearchLandingPageSearch({
      query: searchParams?.query as string,
      total,
      page: 0,
      searchParams: search_params,
      trackingCounts: firstPageTrackingCounts,
      scopeMatchesCount,
      supplierNameMatchesCount,
      buyerProfile,
      requestID,
      action,
    });
  }, []); // TODO: this needs to depend on params?

  const matchedSearchResultCount = useRecoilValue(
    matchedSearchResultCountState
  );

  const filteredHits = useFilteredSearchResults({
    results: contractResponseData?.contractData?.results || [],
    filterLowSimilarityResults: false,
    filterUnresponsiveContacts: false,
  });

  const matchedHits = filteredHits.slice(0, matchedSearchResultCount);

  return (
    <div>
      <PageSection className="py-4 xl:py-0">
        <div className="mt-16 max-w-fit md:max-w-screen-xl flex flex-col gap-1 md:gap-5">
          <div>
            <Typography
              variant="display"
              color="brand.bold"
              className="lg:w-2/3"
              component="h1"
            >
              {title}
            </Typography>
            <Typography color="subtle" className="lg:w-3/4 mt-6">
              {subtitle}
            </Typography>
            <Typography
              color="subtle"
              size="sm"
              className="flex flex-row gap-x-5 mt-5"
            >
              {PageTypeDisplayMap[page_type] && (
                <Badge Icon={DomainRoundedIcon}>
                  {PageTypeDisplayMap[page_type]}
                </Badge>
              )}
              {active_shareable_total >= ACTIVE_CONTRACT_MIN ? (
                <Badge Icon={DescriptionOutlinedIcon}>
                  {active_shareable_total} Active Shareable Contracts
                </Badge>
              ) : (
                <Badge Icon={DescriptionOutlinedIcon}>{total} Contracts</Badge>
              )}
            </Typography>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="cols-span-1">
              <Typography
                variant="headline"
                size="sm"
                color="subtle"
                className="mt-10"
              >
                Search through all {display_name} contracts
              </Typography>
              <div className="flex">
                <SearchBar
                  className="bla-search-bar mt-5"
                  theme={SearchBarThemes.DARK}
                  isLocationRelevant={false}
                  searchSource={SearchSource.LANDING_PAGE_ENTITY_SEARCH}
                  isSSR={isSSR}
                  searchUrl={landingPagePath}
                  disableAutocomplete
                  cbOnEmptyQuery={() => {
                    window.open(landingPagePath, "_self");
                  }}
                  onSearch={searchContractWithParams}
                  disambiguationModalEnabled={isFeatureEnabled(
                    "disambiguationModal"
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <SearchFilterHeader
            filters={filters}
            setFilters={setFilters}
            onFilterChange={(params) => {
              trackSearchFilterToggle({
                ...params,
                query: searchParams?.query,
                requestID,
              });
            }}
            onSearch={searchContractWithParams}
            searchSource={SearchSource.LANDING_PAGE_ENTITY_SEARCH}
          />
        </div>
      </PageSection>
      {contractResponseData?.contractData?.results.length === 0 && (
        <ContentNotFound contentType={ContentTypes.SEARCH_RESULTS} />
      )}
      <PageSection>
        {contractResponseData?.contractData?.results.length !== 0 && (
          <Typography variant="headline" className="pb-6">
            Contracts from {display_name}
          </Typography>
        )}
        <ResultsSection
          className="pb-6"
          query={(searchParams?.query as string) || ""}
          hits={matchedHits.slice(0, INITIAL_RESULTS_LEN)}
          isLoading={contractSearchIsLoading}
          trackContractClick={(hit) => {
            trackContractClickLandingPageSearch({
              hit,
              trackingCounts,
              query: searchParams?.query as string,
              sourceValue: source_value,
              requestID,
            });
          }}
          requestID={requestID}
          diversityPreferences={diversityPreferences}
        />
      </PageSection>
      <SearchBarSection isSSR={isSSR} displayName={display_name} />
      <PageSection>
        <ResultsSection
          query={(searchParams?.query as string) || ""}
          hits={matchedHits.slice(INITIAL_RESULTS_LEN)}
          isLoading={contractSearchIsLoading}
          trackContractClick={(hit) => {
            trackContractClickLandingPageSearch({
              hit,
              trackingCounts,
              query: searchParams?.query as string,
              sourceValue: source_value,
              requestID,
            });
          }}
          diversityPreferences={diversityPreferences}
        />
      </PageSection>
      <PageSection className="bg-cp-lapis-500">
        <div className="flex flex-col items-center py-20 text-white text-center">
          <Typography variant="display" color="inverse">
            Need help finding a contract?
          </Typography>
          <Typography
            variant="headline"
            size="sm"
            color="inverse"
            className="mt-4"
          >
            Tell us more about your search. Our team will help you find a
            contract you can use, and how your agency can use Pavilion.
          </Typography>
          <CalendlyButton
            rootContainer="landing-page"
            className="analytics-landingpage-scheduleDemo mt-10 bg-cp-lapis-100 text-cp-midnight-300 hover:bg-cp-lapis-100/80"
          />
        </div>
      </PageSection>
    </div>
  );
}
