import clsx from "clsx";
import _get from "lodash/get";

import type {
  SupplierCompliance,
  SupplierServiceAreaData,
} from "../../../generated";
import { ItemTypeEnum } from "../../../generated";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import Avatar, { AvatarSizes } from "../../../library/Avatar";
import Logo, { LogoSizes } from "../../../library/Logo";
import type { TypographySize } from "../../../library/Typography/types";
import SaveToProjectButton from "../../../shared/SaveToProjectButton";
import SupplierTagSubsection from "../../../shared/SupplierCard/SupplierTagSubsection";
import { getFormattedTagsForSupplier } from "../../../shared/SupplierCard/utils";
import { ProjectCreationSource } from "../../Project/types";

type ConfigType = {
  titleSize: TypographySize;
  titleEmphasis: boolean;
  titleGap: boolean;
};

export const VARIANT_CONFIG = {
  large: {
    titleSize: "lg",
    titleEmphasis: false,
    titleGap: "gap-y-2",
  },
  compact: {
    titleSize: "sm",
    titleEmphasis: true,
    titleGap: "gap-y-1",
  },
};

interface SupplierHeaderProps {
  title: string;
  supplierName?: string;
  supplierId: number;
  supplierActiveAgreements?: string[];
  supplierServiceArea: SupplierServiceAreaData;
  supplierCompliance?: SupplierCompliance;
  satUrl?: string;
  logoUrl?: string;
  size?: keyof typeof VARIANT_CONFIG;
  className?: string;
  initialSavedProjectId?: string;
  showSaveToProject?: boolean;
}

export default function SupplierHeader({
  title,
  supplierName,
  supplierId,
  supplierActiveAgreements,
  supplierCompliance,
  supplierServiceArea,
  size = "large",
  className,
  satUrl,
  logoUrl,
  initialSavedProjectId,
  showSaveToProject = false,
}: SupplierHeaderProps) {
  const config = _get(VARIANT_CONFIG, `${size}`) || ({} as ConfigType);
  return (
    <div
      className={clsx("flex col-start-1 col-end-10 justify-between", className)}
    >
      <div className="flex gap-x-5 items-center">
        {logoUrl && <Logo imageSrc={logoUrl} size={LogoSizes.XL} />}
        {!logoUrl && supplierName && (
          <Avatar
            className="min-w-[64px]"
            monogram={supplierName[0]}
            size={AvatarSizes.XL}
            bgColor="success.bold.primary.hovered"
          />
        )}
        <div className={clsx("flex flex-col", config.titleGap)}>
          <Typography
            variant="headline"
            size={config.titleSize as TypographySize}
            emphasis={config.titleEmphasis}
            color="brand.bold"
          >
            {title}
          </Typography>
          <SupplierTagSubsection
            tags={getFormattedTagsForSupplier({
              compliance: supplierCompliance,
              serviceArea: supplierServiceArea,
              activeAgreements: supplierActiveAgreements,
            })}
          />
        </div>
        {satUrl && (
          <Button
            as={Link}
            linkProps={{
              href: satUrl,
              underline: false,
            }}
            size={ButtonSizes.LARGE}
            theme={ButtonThemes.SECONDARY_DARK}
          >
            SAT 🪑
          </Button>
        )}
      </div>
      {showSaveToProject && (
        <SaveToProjectButton
          initialSavedProjectId={initialSavedProjectId}
          itemId={supplierId.toString()}
          itemType={ItemTypeEnum.SUPPLIER}
          source={ProjectCreationSource.SUPPLIER_PAGE}
        />
      )}
    </div>
  );
}
