import type { BaseContract } from "../../generated";
import type { MessageSupplierData } from "../../recoil/messageSupplier";
import { supplierHasFeature } from "../../utils/featureManagement";
import type { EntityContract } from "../ContractCardContainer";
import type { SimilarContract } from "../SolicitationPage/types";

type PromotedSuppliersData = {
  promotedSupplierHandles: string[];
  promotedSupplierIds: number[];
  promotedSupplierCount: number;
};

export const DETAIL_PLACEHOLDER = "[Add more details to your request]";

export function getPrefillText({
  isRecommendationPage,
}: {
  isRecommendationPage?: boolean;
}) {
  if (isRecommendationPage) {
    return `I'm reaching out because I'm interested in purchasing ${DETAIL_PLACEHOLDER}.
    
Do you have any availability and cooperative pricing for this purchase? Is this need available on other contracts for a better price?

Thank you!`;
  }
  return `I'm reaching out because I'm interested in purchasing ${DETAIL_PLACEHOLDER}.

Please confirm availability, as well as cooperative pricing for purchase.

Thank you!`;
}

export function getPromotedSuppliersDataFromMessageSupplierData(
  suppliers: MessageSupplierData[]
): PromotedSuppliersData {
  const promotedSuppliers = suppliers.filter(({ supplierAgreement }) =>
    supplierHasFeature(
      supplierAgreement.activeAgreements,
      "analyticsTrackIsPro"
    )
  );
  const promotedSupplierHandles = promotedSuppliers.map(
    ({ supplier }) => supplier.handle
  );
  const promotedSupplierIds = promotedSuppliers.map(
    ({ supplier }) => supplier.id
  );
  return {
    promotedSupplierHandles,
    promotedSupplierIds,
    promotedSupplierCount: promotedSuppliers.length,
  };
}
export function getPromotedSuppliersDataFromContract(
  suppliers: SimilarContract[]
): PromotedSuppliersData {
  const promotedSuppliers = suppliers.filter(
    ({ pro_treatment }) => !!pro_treatment
  );
  const promotedSupplierHandles = promotedSuppliers.map(
    ({ supplier_handle }) => supplier_handle
  );
  const promotedSupplierIds = promotedSuppliers.map(
    ({ supplier_id }) => supplier_id
  );
  return {
    promotedSupplierHandles,
    promotedSupplierIds,
    promotedSupplierCount: promotedSuppliers.length,
  };
}

export function getPromotedSuppliersDataFromBaseContract(
  suppliers: BaseContract[]
): PromotedSuppliersData {
  const promotedSuppliers = suppliers.filter(({ supplierAgreement }) =>
    supplierHasFeature(
      supplierAgreement.activeAgreements,
      "analyticsTrackIsPro"
    )
  );
  const promotedSupplierHandles = promotedSuppliers.map(
    ({ supplier }) => supplier.handle
  );
  const promotedSupplierIds = promotedSuppliers.map(
    ({ supplier }) => supplier.id
  );
  return {
    promotedSupplierHandles,
    promotedSupplierIds,
    promotedSupplierCount: promotedSuppliers.length,
  };
}

export const getMessageSupplierDataFromBaseContract = (
  contract: BaseContract
): MessageSupplierData => {
  const supplierCard: MessageSupplierData = {
    contact: {
      fullName: contract.supplierContact?.fullName,
      title: contract.supplierContact?.title,
    },
    ...contract,
  };
  return supplierCard;
};

/**
 * Used to save recommended suppliers from `<SimilarContractSuppliers>` in recoil state
 */
export function getMessageSupplierDataFromSimilarContract(
  contract: SimilarContract
): MessageSupplierData {
  return {
    supplier: {
      id: contract.supplier_id,
      handle: contract.supplier_handle,
      logoUrl: "",
      displayName: contract.suppliers[0],
      about: "",
      addressCity: "",
      addressStateCode: null,
      offeringsList: [],
      displayAddress: "",
    },
    contractIsPro: contract.pro_treatment,
    contractId: contract.docid,
    supplierAgreement: { activeAgreements: [] },
    contact: {
      fullName: "",
      title: "",
    },
  };
}

/**
 * Used to render `<ContractCardContainer>` from data returned by `getRecommendationContract()`
 */
export function getEntityContractFromSimilarContract(
  similarContract: SimilarContract
): EntityContract {
  return {
    title: similarContract.title,
    docid: similarContract.docid,
    cooperativeAffiliation: similarContract.cooperative_affiliation || "",
    cooperativeLanguage: similarContract.cooperative_language,
    buyerLeadAgency: similarContract.buyer_lead_agency,
    url: "",
    expirationDate: similarContract.expiration_date || "",
    expirationTimestamp: similarContract.expiration_timestamp,
    suppliers: similarContract.suppliers,
    contractNumber: similarContract.contract_number,
    solicitationId: similarContract.solicitation_id,
  };
}
