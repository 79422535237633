import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import { useRecoilValue } from "recoil";
import { Button, Typography } from "../../../library";
import type { BadgeProps } from "../../../library/Badge";
import { profileTypeState } from "../../../recoil/user";
import type { SupplierContactPhoneNumber } from "../../../shared/types";
import { iconColorClass } from "../../../utils/colors";
import { ProfileType } from "../../../utils/enums";

export type Sentiment = "positive" | "negative";

function getIconProps(
  type: Sentiment,
  isSelected: boolean
): Partial<BadgeProps> {
  switch (type) {
    case "positive":
      return {
        Icon: isSelected ? ThumbUpRoundedIcon : ThumbUpOutlinedIcon,
        iconClass: iconColorClass.feedback.bold.success,
      };
    case "negative":
      return {
        Icon: isSelected ? ThumbDownRoundedIcon : ThumbDownOutlinedIcon,
        iconClass: iconColorClass.feedback.bold.error,
      };
    default:
      return {};
  }
}

export function SupplierContactFeedbackRow({
  contactId,
  contactPhoneNumber,
  sentimentState,
  submitFeedback,
}: {
  contactId: number;
  contactPhoneNumber: SupplierContactPhoneNumber;
  sentimentState: Sentiment | null;
  submitFeedback: ({
    sentiment,
    contactId,
  }: {
    sentiment: Sentiment;
    contactId: number;
  }) => void;
}) {
  const profileType = useRecoilValue(profileTypeState);

  if (!contactPhoneNumber) return null;

  // Do not show this CTA for suppliers.
  if (profileType === ProfileType.SUPPLIER) return null;

  function handleClick(sentiment: Sentiment) {
    submitFeedback({
      sentiment,
      contactId,
    });
  }

  return (
    <div className="flex width-full gap-2 items-center">
      <Typography size="sm" color="neutral.bold.enabled">
        Is this correct?
      </Typography>
      <div className="flex gap-1">
        <SentimentButton
          type="positive"
          sentimentState={sentimentState}
          handleClick={handleClick}
        />
        <SentimentButton
          type="negative"
          sentimentState={sentimentState}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}

export default function SentimentButton({
  handleClick,
  type,
  sentimentState,
}: {
  handleClick: (sentiment: Sentiment) => void;
  type: Sentiment;
  sentimentState: Sentiment | null;
}) {
  const isSelected = type === sentimentState;
  const iconProps = getIconProps(type, isSelected);
  return (
    <Button
      theme={Button.themes.TERTIARY_DARK}
      size={Button.sizes.XS}
      badgeProps={{
        size: "sm-md",
        includeMargin: false,
        ...iconProps,
      }}
      onClick={() => handleClick(type)}
    />
  );
}
