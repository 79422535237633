import { useEffect, useState } from "react";

import clsx from "clsx";
import {
  type ApiError,
  ApiService,
  type BuyerLeadAgency,
  DiscoverySourceEnum,
} from "../../../../generated";
import { ActionMenu, Table, Typography } from "../../../../library";
import { ErrorPopup } from "../../../../popups/AnimatedPopup";
import ClarityCallout from "../../../../shared/ClarityCallout";
import { handleError } from "../../../../utils/generatedApi";
import {
  trackSupplierAddBLARelationship,
  trackSupplierRemoveBLARelationship,
} from "../../../../utils/tracking";
import BLAAutocompleteInput from "../../../BLAAutocompleteInput";
import SupplierLockedField from "../SupplierLockedField";
import type { PublicEntity } from "../types";

export default function SupplierPublicAgencies({
  handle,
  publicEntitiesServed,
  disabled = true,
}: {
  handle: string;
  publicEntitiesServed: PublicEntity[];
  disabled: boolean;
}) {
  const [entityRelationships, setEntityRelationships] = useState<
    {
      id: number | null;
      agencyName: string;
      agencyTypeLabel: string;
      source: Maybe<DiscoverySourceEnum>;
    }[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch the list on first render.
  useEffect(() => {
    (async () => {
      try {
        const response =
          await ApiService.apiV1SupplierEditBuyerLeadAgenciesList(handle);
        const relationships = response.map(
          ({ id, buyerLeadAgency, discoverySource }) => {
            return {
              id,
              agencyName: buyerLeadAgency?.displayName || "",
              agencyTypeLabel: buyerLeadAgency?.agencyTypeLabel || "",
              source: discoverySource,
            };
          }
        );
        const agenciesFromContract = publicEntitiesServed.map(
          ({ agencyName, agencyTypeLabel }) => {
            return {
              id: null,
              agencyName,
              agencyTypeLabel,
              source: DiscoverySourceEnum.PAVILION_REPORTED,
            };
          }
        );
        setEntityRelationships([...relationships, ...agenciesFromContract]);
      } catch (err) {
        handleError(err);
      }
    })();
  }, []);

  const onChange = async (
    _name: string | null,
    agency: BuyerLeadAgency | null,
    { clear }: { clear: () => void }
  ) => {
    if (!agency) return;

    const trackData = {
      supplierHandle: handle,
      agencyId: agency.id,
    };

    try {
      const response =
        await ApiService.apiV1SupplierEditBuyerLeadAgenciesCreate(handle, {
          buyerLeadAgencyId: agency.id,
          discoverySource: DiscoverySourceEnum.SUPPLIER_REPORTED,
        });
      clear();
      setEntityRelationships([
        ...entityRelationships,
        {
          id: response.id,
          agencyName: agency.displayName,
          agencyTypeLabel: agency.agencyTypeLabel,
          source: DiscoverySourceEnum.SUPPLIER_REPORTED,
        },
      ]);
      trackSupplierAddBLARelationship(trackData);
    } catch (err) {
      handleError(err);
      setError("Unable to add public entity. Please try again.");
      trackSupplierAddBLARelationship({
        ...trackData,
        error: (err as ApiError).message,
      });
    }
  };

  const onDelete = async (id: number) => {
    const trackData = {
      supplierHandle: handle,
      relationshipId: id,
    };

    try {
      await ApiService.apiV1SupplierEditBuyerLeadAgenciesDestroy(id, handle);
      setEntityRelationships([
        ...entityRelationships.filter((entity) => entity.id !== id),
      ]);
      setEntityRelationships([
        ...entityRelationships.filter((exclusion) => exclusion.id !== id),
      ]);
      trackSupplierRemoveBLARelationship(trackData);
    } catch (err) {
      handleError(err);
      setError("Unable to remove public entity. Please try again.");
      trackSupplierRemoveBLARelationship({
        ...trackData,
        error: (err as ApiError).message,
      });
    }
  };

  return (
    <div className="grid gap-6">
      <ErrorPopup show={!!error} onClose={() => setError(null)}>
        {error}
      </ErrorPopup>
      <div className="grid gap-2">
        <div className="flex gap-2">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="brand.boldest.enabled"
          >
            Public sector business
          </Typography>
          {/* TODO Add status chip */}
        </div>
        <Typography color="neutral.bolder.enabled">
          Based on your contracts, we have created a preliminary list of public
          sector customers you've served. Please add additional public sector
          customers, past or present.
        </Typography>
        <Typography color="neutral.bolder.enabled">
          Tips: We recommend adding a mix of entities across different states
          and entity types that you are hoping to sell to. We recommend several
          entities per entity type (cities, counties, K-12, higher ed, transit)
          spread across different locations.
        </Typography>
      </div>
      <SupplierLockedField disabled={disabled}>
        <ClarityCallout
          callouts={[
            {
              title: "What value does past public sector business add?",
              description:
                "Seeing your experience with relevant customers helps public sector buyers trust you. Unverified buyers and other suppliers are unable to see this list.",
            },
          ]}
        />
        <div className="relative">
          <BLAAutocompleteInput onChange={onChange} label="Add public entity" />
        </div>
      </SupplierLockedField>
      {!!entityRelationships.length && (
        <Table
          columns={[
            {
              key: "agencyName",
              label: "Entity",
              isSortable: true,
              render: (v, { source }) => (
                <div
                  className={clsx("flex flex-col justify-center gap-1", {
                    "min-h-[32px]":
                      source === DiscoverySourceEnum.SUPPLIER_REPORTED,
                  })}
                >
                  <Typography color="neutral.bolder.enabled">{v}</Typography>
                  {source === DiscoverySourceEnum.PAVILION_REPORTED && (
                    <Typography variant="meta" color="neutral.bold.enabled">
                      Added from contract
                    </Typography>
                  )}
                </div>
              ),
            },
            {
              key: "agencyTypeLabel",
              label: "Entity type",
              isSortable: true,
              render: (v, { source }) => {
                if (!v) return null;
                const initialLabel = v.toString();
                const label =
                  initialLabel.charAt(0).toUpperCase() +
                  initialLabel.slice(1).toLowerCase();
                return (
                  <Typography
                    className={clsx("flex items-center", {
                      "min-h-[32px]":
                        source === DiscoverySourceEnum.SUPPLIER_REPORTED,
                    })}
                    color="neutral.bolder.enabled"
                  >
                    {label}
                  </Typography>
                );
              },
            },
            {
              key: "source",
              label: null,
              isSortable: true,
              render: (v, { id }) =>
                id &&
                v === DiscoverySourceEnum.SUPPLIER_REPORTED &&
                !disabled && (
                  <ActionMenu
                    align="right"
                    buttonClassName="analytics-public-agency-item-menu"
                    ariaLabel={`Menu for ${v}`}
                    items={[
                      {
                        analyticsClassName:
                          "analytics-public-agency-item-delete",
                        text: "Remove agency",
                        color: "destructive.default.primary.enabled",
                        onClick: () => {
                          onDelete(id);
                        },
                      },
                    ]}
                  />
                ),
            },
          ]}
          data={entityRelationships}
          defaultSort={{ key: "source", descending: true }}
        />
      )}
    </div>
  );
}
