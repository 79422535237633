import FeatureMapJson from "../../../shared_constants/feature_map.json";
import SharedConstantsJson from "../../../shared_constants/shared_constants.json";

// In order to add a new shared constant between back-end and front-end, add it to shared_constants.json
// and you can expose the var in the front-end by adding the constant name below
// you can expose the var in the back-end by adding it to constants.py
export const {
  AUTORENEWAL_EXPIRATION_MS,
  BASE_DOMAIN_WWW,
  BUYER_LEAD_AGENCY_RANKS,
  COMPANY_NAME,
  HELLO_EMAIL_ADDRESS,
  SUPPLIERS_EMAIL_ADDRESS,
  SUPPORT_EMAIL_ADDRESS,
  CONTRACT_EMAIL_ADDRESS,
  CSM_EMAIL_ADDRESS,
  DEFAULT_LANDING_PAGE_SIZE,
  CALENDLY_LINK,
  SUPPLIER_TAG_TYPES,
  CONTRACT_TAG_TYPES,
  SOCIAL_SIGNUP_TYPES,
  SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
  MAX_RESULTS,
  SUPPLIER_ENROLLMENT_STATUS,
  SPLIT_COOKIE_KEY,
  CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW,
  MANAGE_CONTRACTS_FILTER_OPTIONS,
  LOGIN_REDIRECT_COOKIE_KEY,
  RENEWAL_INFO_RESPONSE,
  CONTRACT_FILE_TYPE,
  SUPPLIER_PROFILE_TYPES,
} = SharedConstantsJson;
export const { PLAN_SUPPLIER_FEATURE_MAP, PLAN_CONTRACT_FEATURE_MAP } =
  FeatureMapJson;

export const CONTRACT_SEARCH_PAGE_LENGTH = 10;

export const FIVE_MB_IN_BYTES = 5 * 1024 * 1024;

// Some anti-virus software run on client side emails obfuscates email addresses,
// resulting in junk addresses (such as yjbyabee@tebcfivafgfkbf.tbi). We don't want
// to keep these emails and don't have a way to decode them.
// This list can be used to check for known bad TLDs.
export const SUFFIX_ALLOWLIST = [
  ".ai",
  ".au",
  ".biz",
  ".br",
  ".ca",
  ".cc",
  ".city",
  ".cm",
  ".co",
  ".com",
  ".dev",
  ".edu",
  ".education",
  ".gov",
  ".info",
  ".io",
  ".md",
  ".mil",
  ".ms",
  ".net",
  ".nyc",
  ".ord",
  ".org",
  ".sc",
  ".tech",
  ".tv",
  ".tx",
  ".uk",
  ".us",
  ".za",
];

export const BLOCKED_EMAIL_DOMAINS = [
  "yopmail.com",
  "mailinator.com",
  "devaza.id", // Pointer to tool4spam.com
];

export const ONLY_APPROVED_SOURCES = "ONLY_APPROVED_SOURCES";

// Regex only solution for now
// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Negative look ahead regex allows any email that doesn't contain
// gmail.com, yahoo.com or aol.com
export const GOV_EMAIL_REGEX = /^(?!.*(gmail\.com|aol\.com|yahoo\.com)).*$/;
export const EIN_REGEX = /^\d{2}-\d{7}$/;

export enum EmailErrorMessages {
  NON_GOV = "Email address is not a government email",
  INVALID = "Please enter a valid email",
  SAME = "New email entered is the same as old email",
  REQUIRED = "Required",
}

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const stateLabels: Record<string, string> = stateOptions.reduce(
  (r, { value, label }) => {
    r[value] = label;
    return r;
  },
  {} as Record<string, string>
);

export const POST_SOCIAL_AUTH_PAGE = "/accounts/social/post_auth";

export const BONFIRE_USER_DETAILS_COOKIE = "bonfireUserDetails";
export const IONWAVE_USER_DETAILS_COOKIE = "ionwaveUserDetails";

export const HAS_SEEN_PROJECT_PROMPT_COOKIE = "hasSeenProjectPrompt";

export const OUTLINE_FOCUS_CLASS =
  "focus-visible:outline focus-visible:outline-offset-4 focus-visible:outline-1";

export const AGENCY_TYPE_DISPLAY_STRING: Record<string, string> = {
  AIRPORT: "Airport",
  CITY: "City",
  CITY_AND_COUNTY: "City and County",
  COUNTY: "County",
  COURT: "Court",
  FEDERAL_AGENCY: "Federal Agency",
  UNIVERSITY: "University",
  COMMUNITY_COLLEGE: "Community College",
  NATIONAL_COOPERATIVE: "National Cooperative",
  NON_PROFIT: "Non-profit",
  PORT: "Port",
  REGIONAL_COOPERATIVE: "Regional Cooperative",
  REGIONAL_EDUCATIONAL_ENTITY: "Regional Educational Entity",
  CHARTER_SCHOOL: "Charter School",
  REGIONAL_ENTITY: "Regional Entity",
  SCHOOL_OR_SCHOOL_DISTRICT: "Education",
  SPECIAL_DISTRICT: "Special District",
  STATE: "State",
  TRANSIT_AGENCY: "Transit Agency",
  UTILITY: "Utility",
  WATER_DISTRICT: "Water District",
};

export const AGENCIES_DISPLAY_STRING: Record<string, string> = {
  AIRPORT: "airports",
  UNIVERSITY: "education agencies",
  COMMUNITY_COLLEGE: "education agencies",
  REGIONAL_EDUCATIONAL_ENTITY: "education agencies",
  CHARTER_SCHOOL: "K12 agencies",
  SCHOOL_OR_SCHOOL_DISTRICT: "K12 agencies",
  TRANSIT_AGENCY: "transit agencies",
  UTILITY: "utility districts",
  WATER_DISTRICT: "utility districts",
  FIRE_DISTRICT: "utility districts",
};

export const SIGNUP_ORIGIN_COOKIE_KEY = "signup_origin";
