import { Field } from "formik";

import type { FormFieldProps } from "./types";

export const fieldMapFn = (
  {
    name,
    label,
    sublabel,
    placeholder,
    component,
    validate,
    dataTestId,
    ...rest
  }: FormFieldProps,
  index: number
) => (
  <div key={index} className="flex flex-col grow gap-2">
    <Field
      key={name}
      name={name}
      label={label}
      message={sublabel}
      sublabel={sublabel}
      component={component}
      placeholder={placeholder}
      validate={validate}
      editable
      dataTestId={dataTestId}
      hasLabel={false}
      {...rest}
    />
  </div>
);
