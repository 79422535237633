import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot, useRecoilValue } from "recoil";

import GovAgencyMatch from "../../components/ContractSearch/GovAgencyMatch";
import { formatSearchPageParams } from "../../components/ContractSearch/utils";
import useCookie from "../../hooks/useCookie";
import {
  contractSearchParamsState,
  contractSearchResponseDataState,
} from "../../recoil/search";
import {
  BaseWidgetSearchBar,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import WidgetSearchPage from "../../shared/SearchPage/WidgetSearchPage";
import SuggestedSearches from "../../shared/SuggestedSearches";
import { getDOMAnchorById, getParams } from "../../utils";
import { IONWAVE_USER_DETAILS_COOKIE } from "../../utils/constants";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

const EXAMPLE_SEARCHES = ["Tires", "Janitorial Services", "Ricoh"];

function IonWaveSERP() {
  const contractResponseData = useRecoilValue(contractSearchResponseDataState);
  const searchParams = useRecoilValue(contractSearchParamsState);
  const params = formatSearchPageParams(searchParams);
  const [, setUserIonwaveDetails] = useCookie(IONWAVE_USER_DETAILS_COOKIE);

  const currentSearchQuery = searchParams?.query;

  // email comes from IonWave
  const { entity, email } = getParams();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Update only when email changes.
  useEffect(() => {
    if (email) {
      setUserIonwaveDetails(JSON.stringify({ email }));
    }
  }, [email]);

  return (
    // Add a slight padding around the width of the widget so that box shadows and edges are not cut off
    <div className="px-1">
      <div className="flex flex-col max-w-[49.5rem]">
        <WidgetSearchBarTitle
          title="Find and use contracts"
          subtitle="Search contracts from public entities near you and trusted national sources, all in one place for free."
          showPavilionLogo
        />
        <WidgetSearchBarWrapper>
          <BaseWidgetSearchBar
            searchSource={agencySearchSource[agencies.IONWAVE_SERP]}
            queryPlaceholder="Search products, services, brands, and suppliers"
            additionalSearchParams={{
              governmentAffiliation: entity,
            }}
            isSerpSearchBar
          />
        </WidgetSearchBarWrapper>
        {!currentSearchQuery && (
          <SuggestedSearches
            className="mt-6"
            searchTerms={EXAMPLE_SEARCHES}
            isWidget
            searchSource={agencySearchSource[agencies.IONWAVE_SERP]}
          />
        )}
      </div>

      {contractResponseData && (
        <GovAgencyMatch
          agency={contractResponseData.agencyData?.agency}
          relatedAgencies={contractResponseData.agencyData?.relatedAgencies}
          requestID={contractResponseData?.params?.requestId}
          rounded
        />
      )}
      <WidgetSearchPage
        params={params}
        requestID={contractResponseData?.params?.requestId}
        isWidget
      />
    </div>
  );
}

const container = getDOMAnchorById("iframe-ionwave-serp");
if (container) {
  const root = createRoot(container);
  root.render(
    <RecoilRoot>
      <IonWaveSERP />
    </RecoilRoot>
  );
}
