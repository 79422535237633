import { useRecoilValue } from "recoil";

import useIsDebug from "../../hooks/useIsDebug";
import { Typography } from "../../library";
import { contractSearchResponseDataState } from "../../recoil/search";
import { getParam } from "../../utils";
import { useGetSearchMethod } from "./utils";

export default function SearchDebugInfo() {
  const isDebug = useIsDebug();
  const contractResponseData = useRecoilValue(contractSearchResponseDataState);

  const results = contractResponseData?.contractData?.results || [];
  const numAllResults = contractResponseData?.contractData?.numAllResults || 0;
  const numStrongResults = contractResponseData?.contractData?.numStrongResults;
  const prioritizedEntityData =
    contractResponseData?.prioritizedEntityMatchData;
  const rankStrategy = getParam("rankStrategy");
  const searchMethodType = useGetSearchMethod({
    results,
    prioritizedEntityData,
  });

  if (!isDebug || !numAllResults || !numStrongResults) {
    return null;
  }

  return (
    <Typography component="div">
      <div>
        <strong>Search: </strong> {searchMethodType}
      </div>
      {rankStrategy && (
        <div>
          <strong>Rank Strategy: </strong> {rankStrategy}
        </div>
      )}
      <div>
        <strong>Total Results: </strong>
        {numAllResults} <strong>Strong Results: </strong> {numStrongResults} (
        {(numStrongResults / numAllResults).toLocaleString(undefined, {
          style: "percent",
        })}
        )
      </div>
    </Typography>
  );
}
