import usePaginatedQuery from "../../../../../hooks/usePaginatedQuery";
import Typography from "../../../../../library/Typography";
import { LoadingCards } from "../../../../../shared/Loading/LoadingCards";
import NoContractsInfo from "../../../../../shared/NoContractsInfo";
import { getSupplierContracts } from "../../../../../utils/api";
import SupplierContractList from "../../../SupplierProfile/SupplierContractList";
import type { Supplier } from "../../types";

const CONTAINER_STYLE = "flex flex-col gap-4";
interface SupplierManageContractsSectionProps {
  supplier: Supplier;
}

export default function SupplierContracts({
  supplier,
}: SupplierManageContractsSectionProps) {
  const { list, fetchMore, isLoading, hasNext, count } = usePaginatedQuery({
    initialList: [],
    initialPage: 0,
    total: 0,
    fetchList: ({ page }: { page: number }) =>
      getSupplierContracts({
        page,
        pageSize: 10,
        supplierId: Number.parseInt(supplier.id || ""),
        sortByLocation: false,
      }),
    onResponse: ({ contracts, count }) => ({
      list: contracts,
      count,
    }),
  });

  if (!count && isLoading) {
    return <LoadingCards className={CONTAINER_STYLE} />;
  }

  if (!count) {
    return <NoContractsInfo />;
  }

  return (
    <div className={CONTAINER_STYLE}>
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.boldest.enabled"
      >
        {count} Contracts
      </Typography>

      <SupplierContractList
        id={Number.parseInt(supplier.id || "")}
        handle={supplier.handle}
        contracts={list}
        fetchMore={fetchMore}
        hasNext={hasNext}
        isLoading={isLoading}
      />
    </div>
  );
}
