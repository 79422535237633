import { PageSection, Typography } from "../../library";

import type { FormikValues } from "formik";
import { useRecoilValue } from "recoil";
import type { QuoteRequestRequest } from "../../generated/models/QuoteRequestRequest";
import { ApiService } from "../../generated/services/ApiService";
import { useSubmitAndSave } from "../../modals/constants";
import { requestIDState } from "../../recoil/search";
import { getParams, goToURL } from "../../utils";
import { handleError } from "../../utils/generatedApi";
import { trackNewProject, trackQuoteRequestSubmit } from "../../utils/tracking";
import { ProjectCreationSource } from "../Project/types";
import { HowItWorksCard } from "./HowItWorksCard";
import { QuoteRequestForm } from "./QuoteRequestForm";

export default function QuoteRequestPage() {
  const params = getParams();
  const requestID = useRecoilValue(requestIDState);
  // const [showQuoteRequestForm, setShowQuoteRequestForm] = useState(false);
  // const [selectedServiceType, setSelectedServiceType] = useState<
  //   "nonLocal" | "local" | undefined
  // >();

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      try {
        const queryString = params.query?.toString();
        const projectName = queryString
          ? `Quote request for "${queryString}"`
          : "My new project";
        const project = await ApiService.apiV1ProjectsCreate({
          name: projectName,
        });

        trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });

        await ApiService.apiV1QuoteRequestsCreate(
          values as QuoteRequestRequest
        );

        trackQuoteRequestSubmit({
          searchQuery: queryString || "",
          requestID,
        });

        goToURL(
          "/quote-request/success",
          { ...params, newProjectId: project.id },
          false
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }
  );

  return (
    <PageSection className="pt-12 pb-48">
      <Typography
        variant="display"
        size="sm"
        color="brand.default.secondary.enabled"
        className="mb-12"
      >
        Get a quote
      </Typography>
      <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col-reverse flex-col gap-12 ">
        <div className="lg:col-span-4">
          <HowItWorksCard />
        </div>
        <div className="lg:col-span-8">
          {/*
            Skipping local service form for now:
            https://app.shortcut.com/coprocure/story/23648/disable-local-service-screen-in-quick-quotes
            Maybe remove code if change becomes permanent.
          */}
          <QuoteRequestForm handleSubmit={handleSubmit} isLoading={loading} />
          {/*            <LocalServiceForm
              selectedServiceType={selectedServiceType}
              onSelectServiceType={setSelectedServiceType}
              onNext={() => {
                if (selectedServiceType === "local") {
                  goToURL(
                    "/quote-request/success",
                    { ...params, local: true },
                    false
                  );
                } else if (selectedServiceType === "nonLocal") {
                  setShowQuoteRequestForm(true);
                }
              }}
            />
          )}
*/}
        </div>
      </div>
    </PageSection>
  );
}
