import { Typography } from "../../library";
import { ScopeBookmarkLink } from "./ScopeSummary";

export default function ScopeSectionHeader({
  title,
  bookmarkProps,
}: {
  title: string;
  bookmarkProps?: {
    onClick: () => void;
    bookmarkType: string;
  };
}) {
  return (
    <div className="flex items-center gap-3">
      <Typography color="neutral.boldest.enabled" emphasis>
        {title}
      </Typography>
      {bookmarkProps && <ScopeBookmarkLink {...bookmarkProps} />}
    </div>
  );
}
