import clsx from "clsx";
import { useSetRecoilState } from "recoil";

import { Button } from "../library";
import { modalState } from "../recoil/page";
import { modals } from "../utils/enums";

interface MobileSearchButtonProps {
  className?: string;
  searchSource: string;
}

export default function MobileSearchButton({
  className,
  searchSource,
}: MobileSearchButtonProps) {
  const setCurrentModal = useSetRecoilState(modalState);

  const showSearchModal = () => {
    setCurrentModal({
      name: modals.SEARCH,
      source: searchSource,
    });
  };

  return (
    <Button
      className={clsx(
        "analytics-mobile-search md:hidden px-3 py-2 shrink-0 text-cp-body-sm",
        className
      )}
      size={Button.sizes.SMALL}
      onClick={showSearchModal}
    >
      <span>Search for free</span>
    </Button>
  );
}
