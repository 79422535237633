import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ApiService } from "../../../generated";
import { Typography } from "../../../library";
import AccordionGroup from "../../../library/AccordionGroup";
import {
  supplierExpiredAgreementsState,
  userState,
} from "../../../recoil/user";
import { formatDate } from "../../../utils/date";
import { isFeatureEnabled } from "../../../utils/split";
import type { ViewAnalytics } from "../types";
import EntityActivityTable from "./BuyerInsights/EntityActivityTable";
import PackageViewAnalyticsTable from "./PackageViewAnalyticsTable";

export default function ExpiredAgreementsAccordion() {
  const expiredAgreements = useRecoilValue(supplierExpiredAgreementsState);
  const { supplier } = useRecoilValue(userState);
  const [viewAnalyticsMap, setViewAnalyticsMap] = useState<
    Record<number, ViewAnalytics>
  >({});
  const hasSupplierDashboardUnification = isFeatureEnabled(
    "supplierDashboardUnification"
  );

  useEffect(() => {
    if (!supplier?.handle) return;

    (async () => {
      await Promise.all(
        expiredAgreements.map(async (a) => {
          const views: ViewAnalytics =
            await ApiService.apiV1SuppliersViewAnalyticsRetrieve2(
              a.id,
              supplier.handle as string
            );
          setViewAnalyticsMap((prev) => ({ ...prev, [a.id]: views }));
        })
      );
    })();
  }, [expiredAgreements, supplier]);

  if (!expiredAgreements.length) return null;

  const children = (
    <>
      {expiredAgreements.map((a) => (
        <div
          key={a.id}
          className="grid gap-4 border-b border-solid-white-300 pb-4 mb-4 last:pb-0 last:mb-0 last:border-none"
        >
          <Typography>
            Package: {a.maxViews} qualified entity views{" "}
            <Typography component="span" color="neutral.bold.enabled">
              ({formatDate(a.dateCompleted, "M/d/yyyy")}-
              {formatDate(a.dateExpiry, "M/d/yyyy")})
            </Typography>
          </Typography>
          {hasSupplierDashboardUnification ? (
            <EntityActivityTable
              viewAnalytics={viewAnalyticsMap[a.id]}
              isLoading={!viewAnalyticsMap[a.id]}
            />
          ) : (
            <PackageViewAnalyticsTable
              agencyViewList={viewAnalyticsMap[a.id]?.agencyViewList || []}
            />
          )}
          <hr className="my-3 last:hidden" />
        </div>
      ))}
    </>
  );

  return (
    <AccordionGroup
      cards={[
        {
          key: "expired-agreements",
          header: (
            <Typography
              variant="headline"
              size="xs"
              color="brand.boldest.enabled"
            >
              Previous entity view{" "}
              {pluralize("package", expiredAgreements.length)}
            </Typography>
          ),
          subheader: (
            <Typography size="sm" color="neutral.bolder.enabled">
              Detailed entity session activity from your past entity view
              {pluralize("package", expiredAgreements.length)}.
            </Typography>
          ),
          children,
        },
      ]}
    />
  );
}
