import _keyBy from "lodash/keyBy";
import { useSetRecoilState } from "recoil";

import { Button, Typography } from "../../../../../library";
import { modalState } from "../../../../../recoil/page";
import { deleteSupplierLocation, handleError } from "../../../../../utils/api";
import { modals } from "../../../../../utils/enums";
import SupplierLockedField from "../../SupplierLockedField";
import type { Supplier, SupplierLocation } from "../../types";

import SupplierLocationContainer from "./SupplierLocationContainer";

interface AdditionalLocationsProps {
  disabled?: boolean;
  currentSupplier: Supplier;
  setCurrentSupplier: (supplier: Supplier) => void;
  handle: string;
  setUpdateDate: (value: Date | undefined) => void;
}

export default function AdditionalLocations({
  disabled = false,
  currentSupplier,
  setCurrentSupplier,
  handle,
  setUpdateDate,
}: AdditionalLocationsProps) {
  const setCurrentModal = useSetRecoilState(modalState);

  const onLocationUpsert = (newLocation: SupplierLocation) => {
    const locationsById = {
      ..._keyBy(currentSupplier?.additionalLocations, "id"),
      [newLocation.id]: newLocation,
    };
    setCurrentSupplier({
      ...currentSupplier,
      additionalLocations: Object.values(locationsById),
    });
    setUpdateDate(new Date());
  };

  function addLocation() {
    setCurrentModal({
      name: modals.ADD_LOCATION,
      handle,
      onUpsert: onLocationUpsert,
    });
  }

  const deleteLocation = async (locationId: number) => {
    if (locationId) {
      const response = await deleteSupplierLocation(locationId, handle);
      if (handleError(response)) {
        return;
      }
      setCurrentSupplier({
        ...currentSupplier,
        additionalLocations: currentSupplier?.additionalLocations?.filter(
          (location) => {
            return location?.id !== locationId;
          }
        ),
      });
      setUpdateDate(new Date());
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <div className="flex flex-col flex-1 gap-2">
          <Typography
            variant="headline"
            size="xs"
            color="brand.default.secondary.enabled"
            emphasis
          >
            Additional locations
          </Typography>
          <Typography
            size="md"
            variant="body"
            color="neutral.default.primary.enabled"
          >
            Add additional locations or branch offices associated with your
            business.
          </Typography>
        </div>
        {!disabled && (
          <Button
            size={Button.sizes.SMALL}
            theme={Button.themes.SECONDARY_DARK}
            className="analytics-add-supplier-contact h-fit w-fit"
            onClick={addLocation}
          >
            Add location +
          </Button>
        )}
      </div>

      <SupplierLockedField disabled={disabled}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {currentSupplier?.additionalLocations?.map((location) => (
            <SupplierLocationContainer
              key={location.id}
              location={location}
              onDeleteLocation={deleteLocation}
              onLocationUpsert={onLocationUpsert}
              handle={handle}
            />
          ))}
        </div>
        {currentSupplier?.additionalLocations?.length === 0 && (
          <div className="p-8 bg-cp-white-200 w-full rounded-lg text-center">
            <Typography>
              To add additional locations, click on the {'"'}Add location
              {'"'} button and enter the required information.
            </Typography>
          </div>
        )}
      </SupplierLockedField>
    </div>
  );
}
