import { useRecoilCallback } from "recoil";
import {
  disallowedSupplierSearchQueryState,
  showSupplierMatchState,
  supplierSearchResponseDataState,
} from "../recoil/search";

import type { SupplierSearchResponse } from "../generated/models/SupplierSearchResponse";

export function useHideSupplierSearch() {
  const clear = useRecoilCallback<[string, boolean], void>(
    ({ set }) =>
      async (query, showRedirect) => {
        if (!showRedirect) {
          set(supplierSearchResponseDataState, {} as SupplierSearchResponse);
          set(disallowedSupplierSearchQueryState, query);
        } else {
          set(showSupplierMatchState, false);
        }
      },
    []
  );

  return clear;
}
