import { useRecoilValue } from "recoil";

import { profileTypeState, supplierAccountHandleState } from "../recoil/user";
import { ProfileType } from "../utils/enums";

export default function useSupplierEditUrl() {
  const profileType = useRecoilValue(profileTypeState);
  const supplierAccountHandle = useRecoilValue(supplierAccountHandleState);
  if (profileType !== ProfileType.SUPPLIER) return null;
  return `/suppliers/${supplierAccountHandle}/edit`;
}
