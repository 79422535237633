import { useRecoilValue } from "recoil";
import useShowModal from "../../hooks/useShowModal";
import { Link } from "../../library";
import BookmarkLink from "../../library/BookmarkLink";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import { userZipState } from "../../recoil/user";
import { modals } from "../../utils/enums";
import {
  trackComplianceChecklistBookmarkClick,
  trackComplianceChecklistContactContractAdminClick,
  trackComplianceChecklistLandingPageClick,
  trackComplianceChecklistSeeApprovedSources,
  trackContactBLAFromSolicitation,
} from "../../utils/tracking";

export type AnalyticsParams = {
  contractId: string;
  buyerLeadAgency?: string;
  buyerLeadAgencyId?: string;
  buyerLeadAgencyUrl?: string;
  cooperativeAffiliation?: string;
  cooperativeAffiliationId?: string;
  cooperativeAffiliationUrl?: string;
};

export type LinkParams = {
  contractId: string;
  sourceName: string;
  sourceId: string;
};

export function LandingPageLink({
  landingPageUrl,
  contractId,
  sourceName,
  sourceId,
}: {
  landingPageUrl: string;
} & LinkParams) {
  return (
    <Link
      href={landingPageUrl}
      onClick={() =>
        trackComplianceChecklistLandingPageClick({
          contractId,
          sourceName,
          sourceId,
        })
      }
      emphasis={false}
    >
      {sourceName}
    </Link>
  );
}

export function ApprovedSourcesLink({
  contractId,
  sourceName,
  sourceId,
}: LinkParams) {
  return (
    <Link
      href={`/profile?sourceName=${sourceName}&sourceId=${sourceId}#approved-sources`}
      onClick={() =>
        trackComplianceChecklistSeeApprovedSources({
          contractId,
          sourceName,
          sourceId,
        })
      }
      underline={false}
      size="sm"
      className="whitespace-nowrap"
    >
      Check approved sources
    </Link>
  );
}

export function ComplianceBookmarkLink({
  onClick,
  bookmarkType,
  contractId,
  ...rest
}: AnalyticsParams & {
  onClick: () => void;
  bookmarkType: string;
}) {
  return (
    <BookmarkLink
      onClick={() => {
        onClick();
        trackComplianceChecklistBookmarkClick({
          bookmarkType,
          contractId,
          ...rest,
        });
      }}
      label={`Jump to ${bookmarkType.toLowerCase()}`}
      background
    />
  );
}

export function ContactContractAdminLink({
  contractId,
  query,
  requestID,
  solicitationId,
  buyerLeadAgency,
  contractDetails,
  ...rest
}: {
  query: string;
  requestID: string;
  solicitationId: string;
  buyerLeadAgency: string;
  contractDetails: ContractSolicitationDetails;
} & AnalyticsParams) {
  const showContactBlaModal = useShowModal(modals.CONTACT_BUYER_LEAD_AGENCY);
  const userZip = useRecoilValue(userZipState);
  function showMessageContractAdminModal() {
    showContactBlaModal({
      contractId,
      searchQuery: query,
      queryZip: userZip,
      solicitationId,
      supplierId: contractDetails.supplier.id,
      supplierDisplayName: contractDetails.supplier.displayName,
      buyerLeadAgency,
      blaPOC: contractDetails.bla_poc,
      requestID,
      source: "complianceChecklist",
    });
    trackContactBLAFromSolicitation({
      contractId,
      searchQuery: query,
      queryZip: userZip,
      solicitationId: solicitationId,
      supplierId: contractDetails.supplier.id,
      buyerLeadAgency: buyerLeadAgency,
      blaPOC: contractDetails.bla_poc,
      requestID,
      source: "complianceChecklist",
    });
    trackComplianceChecklistContactContractAdminClick({
      contractId,
      ...rest,
    });
  }
  return (
    <Link
      onClick={showMessageContractAdminModal}
      underline={false}
      size="sm"
      className="whitespace-nowrap"
    >
      Email contract admin
    </Link>
  );
}
