import {
  DescriptionOutlined,
  NoteAltOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import clsx from "clsx";
import { useRecoilValue } from "recoil";

import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import { Button, ButtonSizes, Link } from "../../library";
import { userState } from "../../recoil/user";

interface SupplierProfileCTAsProps {
  isProSupplier: boolean;
}
export default function SupplierProfileCTAs({
  isProSupplier = true,
}: SupplierProfileCTAsProps) {
  const supplierEditUrl = useSupplierEditUrl();
  const { supplier } = useRecoilValue(userState);
  return (
    <div className="flex gap-3">
      {isProSupplier && (
        <Button
          theme={Button.themes.TERTIARY_DARK}
          className="analytics-supplier-manage-contracts-upload-cta text-cp-white-100 hover:bg-cp-transparent-light-100 ml-[-16px]"
          size={ButtonSizes.SMALL}
          as={Link}
          linkProps={{
            href: `/suppliers/${supplier?.handle}/upload-contracts`,
            underline: false,
            newWindow: false,
          }}
          badgeProps={{
            Icon: DescriptionOutlined,
          }}
          dataTestId="upload-contracts-cta"
        >
          Upload Contracts
        </Button>
      )}
      <Button
        theme={Button.themes.TERTIARY_DARK}
        className={clsx(
          "analytics-supplier-welcome-page-manage-preferences-cta text-cp-white-100 hover:bg-cp-transparent-light-100",
          { "ml-[-16px]": !isProSupplier }
        )}
        size={ButtonSizes.SMALL}
        as={Link}
        linkProps={{
          href: "/profile",
          underline: false,
          newWindow: false,
        }}
        badgeProps={{
          Icon: SettingsOutlined,
        }}
        dataTestId="manage-preferences-cta"
      >
        Manage Preferences
      </Button>
      {supplierEditUrl && (
        <Button
          theme={Button.themes.TERTIARY_DARK}
          className="analytics-supplier-welcome-page-edit-profile-cta text-cp-white-100 hover:bg-cp-transparent-light-100"
          as={Link}
          linkProps={{
            href: supplierEditUrl,
            underline: false,
            newWindow: false,
          }}
          size={ButtonSizes.SMALL}
          badgeProps={{
            Icon: NoteAltOutlined,
          }}
          dataTestId="edit-business-profile-cta"
        >
          Edit business profile
        </Button>
      )}
    </div>
  );
}
