import type {
  ContractHit,
  ContractResult,
  RelevantContract,
} from "../../generated";
import { FILTER_DURATIONS } from "../../shared/SearchPage/SearchResults/constants";
import { dateWithinDuration } from "../../utils/date";
import {
  ExpirationDurations,
  type ExpirationFilterType,
} from "../../utils/enums";

export function matchesExpiration(
  { expirationTimestamp }: ContractHit | RelevantContract,
  filter: ExpirationFilterType
) {
  const filterDuration = FILTER_DURATIONS[filter];
  if (!filterDuration) return true;

  if (filter === ExpirationDurations.LESS_THAN_6_MONTHS) {
    return dateWithinDuration(expirationTimestamp, filterDuration);
  }
  return !dateWithinDuration(expirationTimestamp, filterDuration);
}

export function matchesContractSource(
  {
    buyerLeadAgency,
    cooperativeAffiliation,
    membershipAffiliations,
  }: ContractHit | RelevantContract,
  filter: string[]
) {
  if (!filter.length) return true;
  return (
    filter.includes(buyerLeadAgency) ||
    (cooperativeAffiliation && filter.includes(cooperativeAffiliation)) ||
    membershipAffiliations?.some((affiliation) => filter.includes(affiliation))
  );
}

export function matchesApprovedSource(
  {
    buyerLeadAgency,
    cooperativeAffiliation,
    buyerLeadAgencyState,
  }: ContractHit | RelevantContract,
  filter: boolean,
  approvedAgencies: string[],
  approvedStates: string[]
) {
  if (!filter || (!approvedAgencies.length && !approvedStates.length))
    return true;
  return !!(
    approvedAgencies.includes(buyerLeadAgency) ||
    (cooperativeAffiliation &&
      approvedAgencies.includes(cooperativeAffiliation)) ||
    (buyerLeadAgencyState && approvedStates.includes(buyerLeadAgencyState))
  );
}

export function matchesDiversity(
  { supplierDiversityCertificationIds }: ContractResult,
  filter: boolean,
  diversityPreferences: Maybe<string>[]
) {
  if (!filter) return true;

  return supplierDiversityCertificationIds.some((cert) =>
    diversityPreferences.includes(cert)
  );
}

export function matchesSingleAward(
  contract: ContractHit | RelevantContract,
  filter: boolean
) {
  if (!("numSuppliers" in contract)) return true;
  return !filter || contract.numSuppliers === 1;
}

export function hasResponsiveContact(
  { supplierHasPotentiallyResponsiveContact }: ContractResult,
  filter: boolean
) {
  return !filter || supplierHasPotentiallyResponsiveContact;
}
