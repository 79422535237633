import { useCallback, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import Chip from "../Chip/Chip";
import Typography from "../Typography";

const GAP_WIDTH = 8;
const SHOW_MORE_BUTTON_WIDTH = 101;

/**
 * ChipList component displays a list of text chips clamped to 2 lines.
 * Displays a "Show more" button that reveals 3 more lines when clicked.
 *
 * @param parentRef - Reference to the parent HTMLDivElement
 * @param query - When provided, the query text is highlighted with BoldedText
 */
export function ChipList({
  parentRef,
  keywords,
  trackShowMore,
  query,
  lines,
  dataTestId,
}: {
  parentRef: React.RefObject<HTMLDivElement>;
  keywords: string[];
  trackShowMore?: () => void;
  query?: string;
  lines?: number;
  dataTestId?: string;
}) {
  const [linesShown, setLinesShown] = useState(lines);
  const [showClamp, setShowClamp] = useState(false);
  const [chips, setChips] = useState<JSX.Element[]>([]);

  const getClampedChips = useCallback(
    (tags: Array<{ text: string; width: number }>) => {
      if (linesShown === undefined) return;
      const clampedChips = clampChips({
        containerWidth: parentRef.current?.clientWidth || 0,
        tags,
        query,
        linesShown,
      });
      if (clampedChips) {
        setChips(clampedChips);
        // If we're not showing all keywords, show the "Show more" button
        setShowClamp(clampedChips.length < keywords.length);
      }
    },
    [parentRef.current?.clientWidth, query, linesShown, keywords]
  );

  useEffect(() => {
    if (linesShown === undefined) {
      setChips(
        keywords.map((keyword) => (
          <Chip
            As="li"
            key={keyword}
            keyword={keyword}
            query={query}
            size="sm"
          />
        ))
      );
      return;
    }
    // Measure the actual rendered width of each tag by rendering it invisibly
    const widthPromises = [];
    // Keep a running total width to minimize the number of calculations
    let totalWidth = 0;
    const containerWidth = parentRef.current?.clientWidth || 0;
    for (const keyword of keywords) {
      if (totalWidth >= linesShown * containerWidth) {
        // Stop measuring when we have enough chips to fill all lines
        break;
      }
      widthPromises.push(
        new Promise<{ text: string; width: number }>((resolve) => {
          const container = document.createElement("div");
          container.style.cssText =
            "padding: 4px 8px; position: absolute; width: auto; white-space: nowrap;";
          document.body.appendChild(container);
          const root = createRoot(container);
          root.render(
            <Chip
              As="li"
              key={keyword}
              keyword={keyword}
              query={query}
              size="sm"
            />
          );
          setTimeout(() => {
            const width = container.offsetWidth;
            totalWidth += width + GAP_WIDTH;
            root.unmount();
            resolve({
              text: keyword,
              width: width,
            });
          }, 1);
        })
      );
    }

    // Wait for all width measurements to complete
    Promise.all(widthPromises).then((results) => getClampedChips(results));
  }, [
    parentRef.current?.clientWidth,
    getClampedChips,
    linesShown,
    query,
    keywords,
  ]);

  return (
    <ul
      className="relative flex flex-wrap gap-2 overflow-hidden"
      data-testid={dataTestId}
    >
      {chips}
      {showClamp && (
        <Typography
          component="button"
          underline
          className="px-2 py-1 cursor-pointer whitespace-nowrap"
          color="neutral.boldest.enabled"
          onClick={() => {
            trackShowMore?.();
            if (linesShown) {
              setLinesShown(linesShown + 3);
            }
          }}
        >
          Show more
        </Typography>
      )}
    </ul>
  );
}

function clampChips({
  containerWidth,
  tags,
  linesShown,
  query,
}: {
  containerWidth: number;
  tags: { text: string; width: number }[];
  linesShown: number;
  query?: string;
}) {
  if (containerWidth === 0) return null;

  const chips = [];
  let currentLine = 1;
  let currentLineWidth = 0;
  let currentLineMaxWidth = containerWidth;
  for (const { text, width } of tags) {
    const addWidth = width + GAP_WIDTH;
    // The last line has to accommodate the show more button
    if (currentLine === linesShown) {
      currentLineMaxWidth = containerWidth - SHOW_MORE_BUTTON_WIDTH;
    }
    // Each line has a maximum width of `containerWidth`
    if (currentLineWidth + addWidth <= currentLineMaxWidth) {
      currentLineWidth += addWidth;
      chips.push(
        <Chip As="li" key={text} keyword={text} query={query} size="sm" />
      );
    } else if (currentLine < linesShown) {
      currentLine += 1;
      currentLineWidth = addWidth;
      chips.push(
        <Chip As="li" key={text} keyword={text} query={query} size="sm" />
      );
    } else {
      break;
    }
  }

  return chips;
}
