import BLAAutocompleteInput from "../../components/BLAAutocompleteInput";
import type { GovernmentAgency } from "../../shared/types";
import type { InputSizes } from "../Input/LabeledInput";
import Typography from "../Typography";
import type { CustomFormProps } from "./types";

interface BuyerLeadAgencyFieldProps {
  field: {
    name: string;
    value: {
      governmentAffiliationDisplayName: string;
      governmentAgency: GovernmentAgency;
    };
  };
  editable: boolean;
  hasLabel: boolean;
  placeholder?: string;
  form: CustomFormProps;
  dataTestId?: string;
  label?: string;
  sublabel?: string;
  size?: InputSizes;
}

export default function BuyerLeadAgencyField({
  field,
  editable,
  placeholder = "ex. City of Mesa, AZ",
  form: { setFieldValue, errors, touched },
  dataTestId,
  label,
  sublabel,
  size,
}: BuyerLeadAgencyFieldProps) {
  const buyerProfile = field.value;

  if (!editable) {
    const text = !buyerProfile
      ? "(none)"
      : buyerProfile.governmentAffiliationDisplayName;
    return <Typography>{text}</Typography>;
  }

  return (
    <div className="grid grid-flow-col gap-4">
      <div className="relative" data-testid="bla-input-container">
        <BLAAutocompleteInput
          analyticsClass="analytics-profile-page-govAffiliation-input"
          placeholder={placeholder}
          initialValue={buyerProfile.governmentAffiliationDisplayName}
          initialId={buyerProfile.governmentAgency?.id}
          size={size}
          dataTestId={dataTestId}
          errorMessage={
            touched[field.name] && errors[field.name]
              ? "Public entity is required"
              : ""
          }
          label={label}
          sublabel={sublabel}
          onChange={(name, agency) => {
            if (
              agency?.id === buyerProfile.governmentAgency?.id &&
              name === buyerProfile.governmentAffiliationDisplayName
            ) {
              return;
            }

            setFieldValue(
              field.name,
              {
                ...buyerProfile,
                governmentAffiliationDisplayName: name,
                governmentAgency: agency,
              },
              false
            );
          }}
        />
      </div>
    </div>
  );
}
