import { createRoot } from "react-dom/client";

import QuoteRequestSuccessPage from "../components/QuoteRequest/QuoteRequestSuccessPage";
import { getDOMAnchorById } from "../utils";
import Page, { type AuthPageProps } from "./Page/Page";

interface QuoteRequestSuccessProps extends AuthPageProps {}
export default function QuoteRequestSuccess({
  is_authenticated,
  profile_type,
  active_agreements,
}: QuoteRequestSuccessProps) {
  return (
    <Page
      searchSource="quote-request-success-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
    >
      <QuoteRequestSuccessPage />
    </Page>
  );
}

const container = getDOMAnchorById("quote-request-success");
if (container) {
  const root = createRoot(container);
  root.render(<QuoteRequestSuccess />);
}
