import { useSetRecoilState } from "recoil";

import { modalState } from "../../recoil/page";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { accountModals } from "../../utils/enums";
import { trackAccountAccessToggle } from "../../utils/tracking";
import { WindowType } from "../SignupSteps/types";

import type { ExistingUser } from "../../generated";
import LoginForm from "./LoginForm";

interface LoginModalProp {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  title?: string;
  initialEmail?: string;
}

export default function LoginModal({
  hideModal,
  onComplete,
  isBlocking = false,
  initialEmail,
}: LoginModalProp) {
  const setCurrentModal = useSetRecoilState(modalState);

  function goToSignupModal() {
    setCurrentModal({
      name: accountModals.INITIAL_SIGN_UP,
      isBlocking,
      showGoBackToSearch: false,
      initialEmail,
      onComplete,
    });
    trackAccountAccessToggle({
      toggle: "sign up",
      loginExperience: WindowType.Page,
    });
  }

  function goBack() {
    setCurrentModal({
      name: accountModals.SIGNUP_STEPS_LOGIN,
      showGoBackToSearch: false,
      initialEmail,
      onComplete,
    });
  }

  const redirectSocialUser = (data: ExistingUser) => {
    if (data.existingUser && data.socialAccountProvider) {
      setCurrentModal({
        name: accountModals.PUSH_TO_LOGIN,
        onComplete,
        isBlocking,
        goBack,
        socialLoginSource: data.socialAccountProvider,
      });
      return true;
    }
    return false;
  };

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-login-modal account"
      contentClassName="no-scrollbar"
      formID="analytics-login-modal-submit"
      title="Welcome back!"
      subtitle="Log into your account."
      modalSize={modalSizes.SMALL}
      showLogoHeader
    >
      <LoginForm
        onComplete={onComplete}
        initialEmail={initialEmail}
        goToSignupPageOrModal={goToSignupModal}
        redirectSocialUser={redirectSocialUser}
        parentWindow={WindowType.Modal}
      />
    </Modal>
  );
}
