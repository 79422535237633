import clsx from "clsx";
import { Suspense, lazy, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import useShowModal from "../../../hooks/useShowModal";
import { PageSection } from "../../../library";
import {
  supplierExpiredAgreementsState,
  userState,
} from "../../../recoil/user";
import { fetchSupplierViewAnalytics, handleError } from "../../../utils/api";
import { borderColorClass } from "../../../utils/colors";
import { SupplierApprovalStatus, accountModals } from "../../../utils/enums";
import { trackSupplierWelcomePageView } from "../../../utils/tracking";
import VerificationSuccessPopup from "../VerificationSuccessPopup";
import { INITIAL_VIEW_ANALYTICS } from "../constants";
import type { SupplierWelcomePageData, ViewAnalytics } from "../types";
import BuyerInsights from "./BuyerInsights";
import ExpiredAgreementsAccordion from "./ExpiredAgreementsAccordion";
import ExpiredAgreementsUpsell from "./ExpiredAgreementsUpsell";
import PavilionAtAGlance from "./PavilionAtAGlance";
import SupplierFAQ from "./SupplierFAQ";
import SupplierTasksSection from "./SupplierTasksSection";
import { SupplierUpsell } from "./SupplierUpsell";
import SupplierWelcomeBanner from "./SupplierWelcomeBanner";
import UserBaseBreakdown from "./UserBaseBreakdown";

// Lazily load svg-heavy components to avoid inflating the main bundle.
const SearchSessions = lazy(() => import("./SearchSessions"));
const EmailVerificationSection = lazy(() => import("./UnverifiedEmailSection"));
const AccountVerificationSection = lazy(
  () => import("./UnapprovedAccountSection")
);

interface SupplierWelcomePageProps {
  emailVerified: boolean;
  supplierApprovalStatus: SupplierApprovalStatus;
  numActiveShareableContracts: number;
}

export default function SupplierWelcomePage({
  emailVerified,
  supplierApprovalStatus,
  numActiveShareableContracts,
}: SupplierWelcomePageProps) {
  const { email, supplier, supplierProfile } = useRecoilValue(userState);
  const expiredAgreements = useRecoilValue(supplierExpiredAgreementsState);
  const showExpiredAgreements = !!expiredAgreements.length;
  const [loading, setLoading] = useState(false);
  const [viewAnalytics, setViewAnalytics] = useState<ViewAnalytics>(
    INITIAL_VIEW_ANALYTICS
  );

  const showUnapprovedAccountSection =
    emailVerified && supplierApprovalStatus !== SupplierApprovalStatus.APPROVED;
  const showVerifiedApprovedSection =
    emailVerified && supplierApprovalStatus === SupplierApprovalStatus.APPROVED;
  const supplierData: SupplierWelcomePageData = {
    supplierId: supplier?.id,
    supplierHandle: supplier?.handle,
    supplierName: supplier?.displayName,
    isProSupplier: false,
    userEmail: email,
    numActiveShareableContracts,
    numLeads: viewAnalytics.leads.length,
    numBuyerSessions: viewAnalytics.sessions?.length || 0,
    numEntitySessions: viewAnalytics.entityActivity?.length || 0,
    totalViews: viewAnalytics.totalViews,
  };

  const showSetPasswordModal = useShowModal(accountModals.SET_PASSWORD);
  if (supplierProfile?.needPasswordChange) {
    showSetPasswordModal({});
  }

  useEffect(() => {
    if (!supplier?.handle || !showVerifiedApprovedSection) return;

    (async () => {
      setLoading(true);
      const response = await fetchSupplierViewAnalytics(
        supplier.handle as string
      );
      setLoading(false);
      if (handleError(response)) return;
      const data = await response.json();
      setViewAnalytics(data);
    })();
  }, [supplier, showVerifiedApprovedSection]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once on mount.
  useEffect(() => {
    trackSupplierWelcomePageView(supplierData);
  }, []);

  return (
    <>
      <VerificationSuccessPopup />
      <div className="analytics-supplier-welcome-page flex flex-col">
        <SupplierWelcomeBanner isProSupplier={false} />
        <div className="flex flex-col gap-8 mt-6">
          <PageSection className="px-[16px]">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-8">
                {showExpiredAgreements ? (
                  <ExpiredAgreementsUpsell />
                ) : (
                  <PavilionAtAGlance />
                )}
                {!emailVerified && (
                  <Suspense fallback={null}>
                    <EmailVerificationSection />
                  </Suspense>
                )}
                {showUnapprovedAccountSection && (
                  <Suspense fallback={null}>
                    <AccountVerificationSection />
                  </Suspense>
                )}

                {showVerifiedApprovedSection && (
                  <BuyerInsights
                    viewAnalytics={viewAnalytics}
                    supplierData={supplierData}
                    isLoading={loading}
                  />
                )}
                {showVerifiedApprovedSection && showExpiredAgreements && (
                  <ExpiredAgreementsAccordion />
                )}
                {numActiveShareableContracts > 0 && !showExpiredAgreements && (
                  <div
                    className={clsx(
                      "flex flex-col gap-8 rounded-8 p-6 border border-solid",
                      borderColorClass.neutral.subtle.enabled
                    )}
                  >
                    <SupplierUpsell supplierData={supplierData} />
                    <div className="flex flex-col md:flex-row gap-6">
                      <UserBaseBreakdown className="md:w-1/2" />
                      <Suspense fallback={null}>
                        <SearchSessions className="md:w-1/2" />
                      </Suspense>
                    </div>
                  </div>
                )}
                <SupplierTasksSection />
              </div>
            </div>
          </PageSection>
          <SupplierFAQ />
        </div>
      </div>
    </>
  );
}
