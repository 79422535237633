import useShowModal from "../../../../../hooks/useShowModal";
import { Typography } from "../../../../../library";
import type { UploadContractDetails } from "../../../../../modals/UploadDocumentsModal";
import type {
  Contract,
  ContractDocumentData,
  ContractEditRequestData,
} from "../../../../../shared/ContractBase/types";
import {
  handleError,
  postSupplierContractRenewalConfirmation,
} from "../../../../../utils/api";
import {
  CONTRACT_FILE_TYPE,
  MANAGE_CONTRACTS_FILTER_OPTIONS,
  RENEWAL_INFO_RESPONSE,
} from "../../../../../utils/constants";
import { modals } from "../../../../../utils/enums";

import ContractDialog from "./ContractDialog";

interface ManageContractActionsProps {
  supplierId: number;
  contract: Contract;
  contractDocumentData: ContractDocumentData;
  contractEditRequestData: ContractEditRequestData;
  refreshAllContracts: () => void;
  actionFilter?: string;
}

export default function ManageContractActions({
  contractDocumentData,
  contractEditRequestData,
  supplierId,
  contract,
  actionFilter = MANAGE_CONTRACTS_FILTER_OPTIONS.ALL,
  refreshAllContracts,
}: ManageContractActionsProps) {
  const showUploadDocumentsModal = useShowModal(modals.UPLOAD_DOCUMENTS);
  const contractDetails: UploadContractDetails = {
    docid: contract.docid,
    title: contract.title,
    contractNumber: contract.contractNumber || "",
    buyerLeadAgency: contract.buyerLeadAgency,
    cooperativeAffiliation: contract.cooperativeAffiliation,
    expirationDate: contract.expirationDate,
  };
  const handleRenewalConfirmation = async (renewalConfirmation: string) => {
    const response = await postSupplierContractRenewalConfirmation(
      supplierId,
      contract.docid,
      {
        renewalConfirmation,
      }
    );
    if (handleError(response)) {
      return;
    }
    refreshAllContracts();
  };
  // If expired filter is on OR (all filter and the contract needs expiration review), show those actions
  // If missing docs filter is on OR (all filter and the contract needs doc review), show that action
  if (
    actionFilter === MANAGE_CONTRACTS_FILTER_OPTIONS.EXPIRED ||
    (actionFilter === MANAGE_CONTRACTS_FILTER_OPTIONS.ALL &&
      contractEditRequestData.needsExpirationReview)
  ) {
    if (
      contractEditRequestData.renewalInfoConfirmation ===
      RENEWAL_INFO_RESPONSE.YES
    ) {
      return (
        <ContractDialog
          primaryCta={{
            text: "Upload renewal documents",
            onClick: () => {
              showUploadDocumentsModal({
                supplierId,
                contractDetails,
                documentType: CONTRACT_FILE_TYPE.RENEWAL,
                onComplete: () => {
                  refreshAllContracts();
                },
              });
            },
          }}
        />
      );
    }
    return (
      <ContractDialog
        text="Does this contract have renewal documentation?"
        primaryCta={{
          text: "Yes",
          onClick: () => {
            handleRenewalConfirmation(RENEWAL_INFO_RESPONSE.YES as string);
          },
        }}
        secondaryCta={{
          text: "No",
          onClick: () => {
            handleRenewalConfirmation(RENEWAL_INFO_RESPONSE.NO as string);
          },
        }}
      />
    );
  }

  if (
    actionFilter === MANAGE_CONTRACTS_FILTER_OPTIONS.MISSING_DOCS ||
    (actionFilter === MANAGE_CONTRACTS_FILTER_OPTIONS.ALL &&
      contractEditRequestData.needsDocReview)
  ) {
    return (
      <ContractDialog
        text="Contract is missing the following documents:"
        primaryCta={{
          text: "Upload contract documents",
          onClick: () => {
            showUploadDocumentsModal({
              supplierId,
              contractDetails,
              onComplete: () => {
                refreshAllContracts();
              },
            });
          },
        }}
      >
        <ul className="list-disc ml-5">
          {!contractDocumentData.hasContractDoc && (
            <li>
              <Typography color="neutral.bolder.enabled" size="sm">
                Signed contract document (Required)
              </Typography>
            </li>
          )}

          {!contractDocumentData.hasRfpBidDoc && (
            <li>
              <Typography color="neutral.bolder.enabled" size="sm">
                RFP documents
              </Typography>
            </li>
          )}
          {!contractDocumentData.hasPricingDoc && (
            <li>
              <Typography color="neutral.bolder.enabled" size="sm">
                Pricing documents
              </Typography>
            </li>
          )}
        </ul>
      </ContractDialog>
    );
  }

  // By default, always show the upload docs action
  return (
    <ContractDialog
      text="Upload any additional documents for this contract:"
      primaryCta={{
        text: "Upload contract documents",
        onClick: () => {
          showUploadDocumentsModal({
            supplierId,
            contractDetails,
            onComplete: () => {
              refreshAllContracts();
            },
          });
        },
      }}
    />
  );
}
