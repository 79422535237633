import type { InputSizes } from "../Input/LabeledInput";
import PasswordInput from "../PasswordInput";
import type { CustomFormProps } from "./types";

interface PasswordInputFieldProps {
  field: {
    name: string;
    value: string;
  };
  form: CustomFormProps;
  label: string;
  sublabel?: string;
  placeholder: string;
  size: InputSizes;
  className?: string;
}
export default function PasswordInputField({
  field,
  form: { setFieldValue, touched, errors },
  placeholder,
  label,
  sublabel,
  size,
  className,
}: PasswordInputFieldProps) {
  return (
    <PasswordInput
      name={field.name}
      placeholder={placeholder}
      setPassword={(value) => setFieldValue(field.name, value)}
      password={field.value}
      errorMessage={touched[field.name] ? (errors[field.name] as string) : ""}
      label={label}
      sublabel={sublabel}
      size={size}
      className={className}
    />
  );
}
