import { atom, useSetRecoilState } from "recoil";

import type { VisibilityPriority } from "../library/FeatureHighlightTooltip";
import type {
  ClickAnimatedPopupProps,
  TimedAnimatedPopupProps,
} from "../popups/AnimatedPopup";
import { NO_MODAL } from "../utils";
import type { PageType, PopupType } from "../utils/enums";

export const pageTypeState = atom<Maybe<PageType>>({
  key: "pageTypeState",
  default: null,
});

export const pageIdState = atom<Maybe<string>>({
  key: "pageIdState",
  default: null,
});

export const modalState = atom({
  key: "modalState",
  default: NO_MODAL,
});

type CombinedPopupType = TimedAnimatedPopupProps & ClickAnimatedPopupProps;

type PopupStateProps = CombinedPopupType & {
  analyticsClassName?: string;
  name?: PopupType;
  type?: string;
  durationSeconds?: number;
};
export const popupState = atom<PopupStateProps | null>({
  key: "popupState",
  default: null,
});

export const tooltipState = atom<(keyof typeof VisibilityPriority)[]>({
  key: "tooltipState",
  default: [],
});

/**
 * Keep track of multiple active tooltips in order to control
 * which one is shown at a time.
 * See `assets/js/library/FeatureHighlightTooltip/index.tsx`
 */
export function useAddActiveTooltip() {
  const setTooltips = useSetRecoilState(tooltipState);

  return (tooltipKey: keyof typeof VisibilityPriority) => {
    setTooltips((prev) => {
      if (prev.includes(tooltipKey)) return prev;
      return [...prev, tooltipKey];
    });
  };
}

export function useRemoveActiveTooltip() {
  const setTooltips = useSetRecoilState(tooltipState);

  return (tooltipKey: keyof typeof VisibilityPriority) => {
    setTooltips((prev) => prev.filter((key) => key !== tooltipKey));
  };
}
