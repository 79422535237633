import { useRecoilValue } from "recoil";

import { profileTypeState, userAdminState } from "../../recoil/user";
import type { FileUploadSource } from "../../utils/enums";
import { trackUploadContractFiles } from "../../utils/tracking";
import FileDropzone from "../FileDropzone";

import type { Accept } from "react-dropzone";
import type { CustomFormProps } from "./types";

interface FileUploadsFieldProps {
  field: {
    name: string;
    value: File[];
  };
  form: CustomFormProps;
  label: string;
  sublabel?: string;
  placeholder: string;
  source: FileUploadSource;
  accept?: Accept;
}

export default function FileUploadsField({
  field,
  form: { setFieldValue, touched, errors },
  label,
  source,
  accept,
}: FileUploadsFieldProps) {
  const profileType = useRecoilValue(profileTypeState);
  const isAdmin = useRecoilValue(userAdminState);

  const onDeleteFile = (fileName: string) => {
    setFieldValue(
      field.name,
      field.value.filter((file) => file.name !== fileName)
    );
  };
  const onDropFiles = (files: File[]) => {
    touched[field.name] = true;
    setFieldValue(field.name, field.value.concat(files));
    trackUploadContractFiles({
      fileNames: files.map((file) => file.name),
      fileTypes: files.map((file) => file.type),
      uploader: isAdmin ? "admin" : profileType || "",
      source,
    });
  };
  return (
    <FileDropzone
      onDeleteFile={onDeleteFile}
      onDropFiles={onDropFiles}
      files={field.value}
      accept={accept}
      label={label}
      error={errors[field.name] as string}
    />
  );
}
