import { createRoot } from "react-dom/client";

import EntityListPage from "../components/EntityList/EntityList";
import { PageSection } from "../library";
import { getDOMAnchorById } from "../utils";

import type { GovernmentAgency } from "../shared/types";
import { PageType } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

interface EntityListProps extends AuthPageProps {
  entities: GovernmentAgency[];
  pageTitle: string;
  pageSubtitle: string;
}

export default function EntityList({
  entities,
  is_authenticated,
  pageTitle,
  pageSubtitle,
  profile_type,
  active_agreements,
}: EntityListProps) {
  return (
    <Page
      searchSource="entity-list-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      pageType={PageType.ENTITY_LIST_PAGE}
    >
      <PageSection className="analytics-entity-list-page">
        <EntityListPage
          entities={entities}
          pageTitle={pageTitle}
          pageSubtitle={pageSubtitle}
        />
      </PageSection>
    </Page>
  );
}

const container = getDOMAnchorById("entity-list");
if (container) {
  const root = createRoot(container);
  const props = JSON.parse(container.dataset.data || "{}");
  root.render(<EntityList {...props} />);
}
