/**
 * A single instance of a supplier's details, with optional CTAs
 */
import { type ReactNode, useMemo } from "react";

import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import { Avatar, AvatarSizes, Typography } from "../../library";
import type { LoginWallTriggers } from "../../utils/enums";

import ProductSection from "../../components/ContractSearch/ProductSection";
import {
  matchTiers,
  styleSearchResponseText,
} from "../../components/ContractSearch/utils";
import type { MatchResult, ProductHitDict } from "../../generated";
import { LogoSizes } from "../../library/Logo";
import { textColorClass } from "../../utils/colors";
import { isFeatureEnabled } from "../../utils/split";
import Tag, { TagVariants } from "../Tag";
import ContractCTASection from "./ContractCTASection";
import type { CTA } from "./types";

interface SupplierDetailComponentProps {
  supplierId?: Maybe<number>;
  supplierName: string;
  supplierLogoUrl?: string;
  supplierKeywords: string[];
  matchTier?: string;
  highlightResult?: Record<string, Array<MatchResult>>;
  tags?: ReactNode[];
  ctas?: CTA[];
  loginWallTriggerId?: string;
  loginWallTrigger?: LoginWallTriggers;
  productHits: ProductHitDict[];
}
export default function SupplierDetailComponent({
  supplierId,
  supplierName,
  supplierLogoUrl,
  supplierKeywords,
  matchTier,
  highlightResult,
  tags,
  ctas,
  loginWallTrigger,
  loginWallTriggerId,
  productHits,
}: SupplierDetailComponentProps) {
  const hasRedesignedSerpCards = isFeatureEnabled("redesignedSERPCards");
  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId,
    supplierName,
  });
  const keywords = useMemo(() => {
    if (!highlightResult?.supplierKeywords)
      return (supplierKeywords || []).map((value) => ({ value }));
    // Sort full keyword matches to the start of the list.
    return [...(highlightResult?.supplierKeywords || [])]
      .sort((a, b) => {
        return matchTiers[b.matchLevel] - matchTiers[a.matchLevel];
      })
      .map((match) => match);
  }, [highlightResult?.supplierKeywords, supplierKeywords]);

  const keywordMatches = [...(highlightResult?.supplierKeywords || [])]
    // Sort more complete keyword matches to the start of the list.
    .sort((a, b) => {
      return matchTiers[b.matchLevel] - matchTiers[a.matchLevel];
    })
    .map((match) => match.value)
    // For now, only show the first keyword match
    .splice(0, 1);

  const keywordMatchesText = styleSearchResponseText(
    keywordMatches.join(", "),
    "em",
    `${textColorClass.neutral.bolder.enabled} font-normal`,
    `${textColorClass.neutral.bolder.enabled} font-semibold bg-cp-persimmon-100`
  );

  const showSupplierKeywords =
    isFeatureEnabled("improvedSupplierNameMatchingV2") &&
    !!keywordMatches.length &&
    matchTier !== "EXACT_SUPPLIER";

  return (
    <div className="flex flex-col sm:flex-row w-full">
      <div className="flex flex-col gap-y-3 w-full">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center mr-6 my-auto">
            {supplierLogoUrl ? (
              <Avatar
                className="mr-4"
                size={AvatarSizes.MED_LG}
                logoImageSrc={supplierLogoUrl}
                logoSize={LogoSizes.XS}
              />
            ) : (
              <Avatar
                className="mr-4"
                size={AvatarSizes.MED_LG}
                bgColor="accent.default.secondary.enabled"
                textColor="accent.leaf.enabled"
                monogram={supplierName[0]}
              />
            )}
            <Typography size="sm" color="brand.bold" emphasis>
              {supplierName}
            </Typography>
            {exactSupplierMatchAlias && (
              <Typography size="sm" color="subtler">
                &nbsp;{`Also known as ${exactSupplierMatchAlias}`}
              </Typography>
            )}
            {!hasRedesignedSerpCards &&
              !exactSupplierMatchAlias &&
              showSupplierKeywords && (
                <Typography size="sm" color="subtler">
                  &nbsp;offers: {keywordMatchesText}
                </Typography>
              )}
          </div>
          {ctas && (
            <div className="mt-4 sm:mt-0 sm:ml-auto">
              <ContractCTASection
                loginWallTrigger={loginWallTrigger}
                loginWallTriggerId={loginWallTriggerId}
                ctas={ctas}
              />
            </div>
          )}
        </div>
        {/* If there's an alias, show the keyword matches below instead */}
        {!hasRedesignedSerpCards &&
          exactSupplierMatchAlias &&
          showSupplierKeywords && (
            <Typography size="sm" color="subtler">
              &nbsp;offers: {keywordMatchesText}
            </Typography>
          )}
        {!!tags?.length && <div className="flex flex-row gap-x-2">{tags}</div>}
        {hasRedesignedSerpCards &&
          showSupplierKeywords &&
          keywords.length > 1 && (
            <div className="flex flex-wrap gap-2 max-h-[26px] overflow-hidden empty:hidden">
              {keywords.slice(0, 5).map((k) => (
                <Tag key={k.value} variant={TagVariants.SUBTLE} size="md">
                  <p>
                    {styleSearchResponseText(
                      k.value,
                      "em",
                      `${textColorClass.neutral.bolder.enabled} font-normal`,
                      `${textColorClass.neutral.bolder.enabled} font-semibold bg-cp-persimmon-100`
                    )}
                  </p>
                </Tag>
              ))}
            </div>
          )}
        <ProductSection products={productHits} />
      </div>
    </div>
  );
}
