/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckUserRequest } from '../models/CheckUserRequest';
import type { ExistingUser } from '../models/ExistingUser';
import type { ValidEmailChangeRequest } from '../models/ValidEmailChangeRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountsService {
    /**
     * @param requestBody
     * @returns ExistingUser
     * @throws ApiError
     */
    public static accountsCheckExistingUserCreate(
        requestBody: CheckUserRequest,
    ): CancelablePromise<ExistingUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/check-existing-user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static accountsEmailChangeCreate(
        requestBody: ValidEmailChangeRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/email-change',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static accountsSendLoginMagicLinkCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/send-login-magic-link',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static accountsSetPasswordCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/set-password',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static accountsSocialSignupCancelCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/social/signup/cancel',
        });
    }
}
