import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import clsx from "clsx";
import { type MouseEvent, useState } from "react";

import { Badge, Tooltip, Typography } from "../../../library";
import BookmarkLink from "../../../library/BookmarkLink";
import type { Bookmark, OCRFile } from "../../../shared/types";
import { borderColorClass } from "../../../utils/colors";
import { isFeatureEnabled } from "../../../utils/split";
import { getCleanedFileName } from "./utils";

const ICON_CLASSNAMES =
  "flex items-center justify-center hover:bg-cp-neutral-palette-200 rounded-3 cursor-pointer";

export type HandleClickBookmarkOptions = {
  file: OCRFile;
  bookmark: Bookmark;
};

interface FileItemProps {
  file: OCRFile;
  contractNumber?: string;
  selected?: boolean;
  handleClickBookmark: ({ file, bookmark }: HandleClickBookmarkOptions) => void;
  onClick: (file: OCRFile) => void;
  onDownload: (file: OCRFile) => void;
  onCopyLink: (file: OCRFile) => void;
  className?: string;
}

export function FileItem({
  file,
  contractNumber,
  selected = false,
  handleClickBookmark,
  onClick,
  onDownload,
  onCopyLink,
  className,
}: FileItemProps) {
  const [isFileLinkCopied, setIsFileLinkCopied] = useState(false);
  function handleCopyLink({
    event,
    file,
  }: {
    event: MouseEvent;
    file: OCRFile;
  }) {
    event.stopPropagation();
    onCopyLink(file);
    setIsFileLinkCopied(true);
    setTimeout(() => {
      setIsFileLinkCopied(false);
    }, 2000);
  }

  function handleDownload({
    event,
    file,
  }: {
    event: MouseEvent;
    file: OCRFile;
  }) {
    event.stopPropagation();
    onDownload(file);
  }

  const hasFeature = isFeatureEnabled("simplifiedContractPage");

  return (
    <>
      <div className={clsx("flex w-full items-center gap-3", className)}>
        <Typography
          className="cursor-pointer break-words hover:underline line-clamp-1"
          underline={selected}
          color="brand.bold.enabled"
          size={hasFeature ? "sm" : "md"}
          onClick={() => onClick(file)}
        >
          {getCleanedFileName(file, contractNumber)}
        </Typography>
        <div className="flex">
          {!hasFeature && (
            <Tooltip
              className="lg:whitespace-nowrap"
              info={
                isFileLinkCopied ? "Copied!" : "Copy file link to clipboard"
              }
              placement="right"
            >
              <Badge
                as="button"
                Icon={LinkRoundedIcon}
                size="sm-md"
                iconClass="text-cp-neutral-palette-600 ml-1"
                className={clsx(
                  "analytics-copy-file-link-from-sidebar p-1 pr-2",
                  ICON_CLASSNAMES
                )}
                onClick={(event) => handleCopyLink({ event, file })}
              >
                <Typography variant="meta" color="neutral.bold.enabled">
                  Share
                </Typography>
              </Badge>
            </Tooltip>
          )}
          <Badge
            as="button"
            Icon={FileDownloadOutlinedIcon}
            size="sm-md"
            iconClass="text-cp-neutral-palette-600 ml-1"
            className={clsx(
              "analytics-download-file-from-sidebar p-1 pr-2",
              ICON_CLASSNAMES
            )}
            onClick={(event) => handleDownload({ event, file })}
            disabled={!file.has_access}
          >
            {hasFeature ? null : (
              <Typography variant="meta" color="neutral.bold.enabled">
                Download
              </Typography>
            )}
          </Badge>
        </div>
      </div>
      {!!file.bookmarks.length && (
        <div className={clsx("grid", { "gap-1": hasFeature })}>
          {file.bookmarks.map((bookmark: Bookmark) => (
            <div key={bookmark.id} className="flex items-center">
              {!hasFeature && (
                <Typography
                  component="span"
                  color="interactive.inverse.primary.enabled"
                  emphasis
                  className="mr-3"
                >
                  |
                </Typography>
              )}
              <BookmarkLink
                onClick={() => handleClickBookmark({ file, bookmark })}
                label={`Jump to ${bookmark.bookmark_type.toLowerCase()}`}
                background
              />
            </div>
          ))}
        </div>
      )}
      {hasFeature && <hr className={borderColorClass.neutral.subtle.enabled} />}
    </>
  );
}
