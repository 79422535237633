import { Button, Typography } from "../../../library";

const featuredCoops = [
  { name: "Sourcewell", href: "/cooperatives/sourcewell" },
  {
    name: "Texas SmartBuy",
    href: "/cooperatives/texas-smartbuy",
  },
  {
    name: "OMNIA Partners",
    href: "/cooperatives/omnia-partners",
  },
  {
    name: "The Interlocal Purchasing System (TIPS)",
    href: "/cooperatives/tips",
  },
  {
    name: "Choice Partners",
    href: "/cooperatives/choice-partners",
  },
  {
    name: "1GPA",
    href: "/cooperatives/1gpa",
  },
];

const featuredAgencies = [
  {
    name: "City of Austin, TX",
    href: "/agencies/city-of-austin-texas",
  },
  {
    name: "Orange County, FL",
    href: "/agencies/orange-county-florida",
  },
  {
    name: "Orange County, CA",
    href: "/agencies/orange-county-california",
  },
  {
    name: "King County, WA",
    href: "/agencies/king-county-washington",
  },
  {
    name: "City of Tucson, AZ",
    href: "/agencies/city-of-tucson-arizona",
  },
  {
    name: "State of New York",
    href: "/agencies/state-of-new-york",
  },
];

const featuredLinks = [
  {
    name: "Featured Cooperatives",
    list: featuredCoops,
    viewMoreUrl: "/cooperatives",
  },
  {
    name: "Featured Agencies",
    list: featuredAgencies,
    viewMoreUrl: "/agencies",
  },
];

export default function LandingPageSection() {
  return (
    <div className="bg-cp-violet-200">
      <div className="flex flex-col py-16 cp-page-container xl:px-0 m-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-8">
          {featuredLinks.map(({ name, list, viewMoreUrl }) => (
            <div key={name}>
              <div className="flex flex-col space-y-1">
                <Typography
                  variant="headline"
                  color="brand.boldest.enabled"
                  emphasis
                  className="font-homeHeader mb-2"
                >
                  {name}
                </Typography>
                {list.map((landingPage) => (
                  <div key={landingPage.name}>
                    <a
                      className="analytics-homepage-landingPage text-cp-black-400"
                      href={landingPage.href}
                    >
                      {landingPage.name}
                    </a>
                  </div>
                ))}
              </div>
              <Button
                className="mt-4 md:mt-8"
                onClick={() => {
                  window.open(viewMoreUrl, "_blank");
                }}
                theme={Button.themes.SECONDARY_DARK}
              >
                View more
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
