import { useRecoilCallback, useSetRecoilState } from "recoil";

import {
  initializeUserCallback,
  initializeUserZipCallback,
  userInitializedState,
} from "../recoil/user";

export default function useInitializeUser() {
  const initializeUser = useRecoilCallback(initializeUserCallback, []);
  const initializeUserZip = useRecoilCallback(initializeUserZipCallback, []);
  const setUserInitializedState = useSetRecoilState(userInitializedState);

  return async () => {
    // After the user is initialized, we can initialize the zip code.
    await initializeUser();
    await initializeUserZip();
    setUserInitializedState(true);
  };
}
