import { Typography } from "../library";
import MobileSearchButton from "../shared/MobileSearchButton";
import Modal from "../shared/Modal/Modal";
import SearchBar, {
  SearchBarCtaTypes,
  SearchBarSizes,
} from "../shared/SearchBar";
import { SearchBarThemes } from "../shared/SearchBar/types";
import SuggestedSearches from "../shared/SuggestedSearches";
import { SearchBarIntentVariants, SearchSource } from "../utils/enums";
import { isFeatureEnabled } from "../utils/split";

interface AgencyLandingPageSearchModalProps {
  hideModal: () => void;
  blaId?: string;
  blaName?: string;
}
export default function AgencyLandingPageSearchModal({
  hideModal,
  blaId,
  blaName,
}: AgencyLandingPageSearchModalProps) {
  return (
    <Modal
      hideModal={hideModal}
      className="analytics-agency-landing-page-search-modal"
    >
      <Typography
        variant="headline"
        color="subtle"
        className="mb-2 text-center"
      >
        Find a cooperative contract from {blaName}
      </Typography>
      <Typography variant="body" className="text-center">
        Search the nation&apos;s largest network of over 100,000 cooperative and
        piggybackable contracts — for free
      </Typography>
      <div className="flex">
        <SearchBar
          className="mt-6 hidden md:flex"
          searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE_MODAL}
          size={SearchBarSizes.FULL}
          theme={SearchBarThemes.DARK}
          submitCta={SearchBarCtaTypes.TEXT}
          buyerLeadAgencyId={blaId}
          intentVariant={SearchBarIntentVariants.TOGGLE}
          disambiguationModalEnabled={isFeatureEnabled("disambiguationModal")}
        />
        <MobileSearchButton
          searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE_MODAL}
          className="mt-6"
        />
      </div>
      <SuggestedSearches
        className="mt-6"
        searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE_MODAL}
        buyerLeadAgencyId={blaId}
      />
    </Modal>
  );
}
