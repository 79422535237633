import _keyBy from "lodash/keyBy";
import { useSetRecoilState } from "recoil";

import SupplierAliasCard from "../../../../components/supplier/SupplierAlias";
import { ApiService, type SupplierAlias } from "../../../../generated";
import { Button, Typography } from "../../../../library";
import { modalState } from "../../../../recoil/page";
import { modals } from "../../../../utils/enums";
import { handleError } from "../../../../utils/generatedApi";
import SupplierLockedField from "../SupplierLockedField";
import type { Supplier } from "../types";

interface SupplierAliasSectionProps {
  currentSupplier: Supplier;
  handle: string;
  setCurrentSupplier: (supplier: Supplier) => void;
  setUpdateDate: (value: Date | undefined) => void;
  disabled?: boolean;
}

export default function SupplierAliasSection({
  currentSupplier,
  handle,
  setCurrentSupplier,
  setUpdateDate,
  disabled = false,
}: SupplierAliasSectionProps) {
  const setCurrentModal = useSetRecoilState(modalState);

  const onAliasUpsert = (newAlias: SupplierAlias) => {
    const aliasesById = {
      ..._keyBy(currentSupplier.aliases, "id"),
      [newAlias.id]: newAlias,
    };
    setCurrentSupplier({
      ...currentSupplier,
      aliases: Object.values(aliasesById),
    });
    setUpdateDate(new Date());
  };

  const showEditModal = (alias: SupplierAlias) => {
    setCurrentModal({
      name: modals.EDIT_ALIAS,
      alias,
      handle,
      title: "Edit business name",
      onAliasUpsert,
      setUpdateDate,
    });
  };

  const showAddModal = () => {
    setCurrentModal({
      name: modals.EDIT_ALIAS,
      handle,
      title: "Add an additional business name",
      onAliasUpsert,
      setUpdateDate,
    });
  };

  const onDeleteAlias = async (deletedAlias: SupplierAlias) => {
    if (!deletedAlias?.id) {
      return;
    }
    try {
      await ApiService.apiV1SupplierEditAliasesDestroy(deletedAlias.id, handle);
      setCurrentSupplier({
        ...currentSupplier,
        aliases: currentSupplier.aliases?.filter(
          (alias) => alias.id !== deletedAlias.id
        ),
      });
      setUpdateDate(new Date());
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <div className="flex flex-col flex-1 gap-1">
          <Typography
            emphasis
            size="xs"
            variant="headline"
            color="brand.default.secondary.enabled"
          >
            Additional names
          </Typography>
          <Typography color="neutral.default.primary.enabled" className="mt-1">
            Add additional names or DBAs associated with your business.
          </Typography>
        </div>
        {!disabled && (
          <Button
            size={Button.sizes.SMALL}
            theme={Button.themes.SECONDARY_DARK}
            className="analytics-add-business-contact h-fit w-fit"
            onClick={showAddModal}
          >
            Add name +
          </Button>
        )}
      </div>
      <SupplierLockedField disabled={disabled}>
        {currentSupplier?.aliases?.length !== 0 && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
            {currentSupplier?.aliases?.map((alias) => (
              <SupplierAliasCard
                key={alias.id}
                alias={alias}
                onClickEdit={() => {
                  showEditModal(alias);
                }}
                onClickDelete={() => {
                  onDeleteAlias(alias);
                }}
              />
            ))}
          </div>
        )}
        {(!currentSupplier?.aliases || !currentSupplier.aliases.length) && (
          <div className="p-8 bg-cp-white-200 w-full rounded-lg text-center">
            <Typography>
              To add additional names or DBAs, click on the “Add name” button
              and enter the required information.
            </Typography>
          </div>
        )}
      </SupplierLockedField>
    </div>
  );
}
