import { useRecoilValue } from "recoil";

import { userEmailState } from "../recoil/user";
import { handleError, postConfirmEmail } from "../utils/api";
import type { MODAL_SOURCE, modals } from "../utils/enums";
import { trackEmailVerification } from "../utils/tracking";

import useShowModal from "./useShowModal";

interface UseSendEmailVerificationProps {
  source: MODAL_SOURCE;
  onError?: (message: string) => void;
  successModalType?:
    | typeof modals.EMAIL_VERIFICATION_SUCCESS
    | typeof modals.PLEASE_VERIFY_EMAIL_MODAL;
  showCta?: boolean;
  onCtaClick?: (hideModal: () => void) => void;
  /**
   * If `successModalType` is not given, redirect to this URL
   */
  redirectUrl?: string;
}

export default function useSendEmailVerification({
  source,
  onError,
  successModalType,
  showCta = true,
  onCtaClick,
  redirectUrl,
}: UseSendEmailVerificationProps) {
  const userEmail = useRecoilValue(userEmailState);
  const showModal = successModalType
    ? useShowModal(successModalType)
    : undefined;

  async function sendVerificationEmail() {
    const sendVerificationEmail = await postConfirmEmail(userEmail);
    if (handleError(sendVerificationEmail)) {
      onError?.("Could not send verification email.");
      return;
    }
    trackEmailVerification({ source });
    if (showModal) {
      showModal({ showCta, onCtaClick });
    } else if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  return sendVerificationEmail;
}
