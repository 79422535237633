import _compact from "lodash/compact";
import { useEffect, useRef } from "react";
import { useRecoilCallback, useRecoilValue } from "recoil";
import useInitializeIntermediatedEmail from "../../hooks/useInitializeIntermediatedEmail";
import useLoginWall, {
  WHITELISTED_SEARCH_SOURCE,
} from "../../hooks/useLoginWall";
import useShowProQualificationEffect from "../../hooks/useShowProQualificationEffect";
import { useTrackContractPageView } from "../../hooks/useTrackContractPageView";
import { Typography } from "../../library";
import type { ISolicitation } from "../../pages/Contract";
import useInitializeProductServices from "../../recoil/products";
import { initializeContractScopeCallback } from "../../recoil/scope";
import { widgetSearchSourceState } from "../../recoil/search";
import {
  buyerProfileState,
  isAuthenticatedState,
  profileTypeState,
  supplierState,
  userStateState,
  userZipState,
} from "../../recoil/user";
import AnchorRefLinks, {
  type AnchorRefLink,
} from "../../shared/AnchorRefLinks";
import ProductList from "../../shared/ProductList";
import {
  LoginWallTriggers,
  ProfileType,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { getContractPath } from "../../utils/format";
import { isFeatureEnabled } from "../../utils/split";
import { trackAnchorLinkClick } from "../../utils/tracking";
import ComplianceChecklist from "../ComplianceChecklist";
import { getComplianceChecklistSectionData } from "../ComplianceChecklist/utils";
import AboutSupplier, { AboutSupplierSizes } from "../supplier/AboutSupplier";
import SupplierHeader from "../supplier/SupplierProfile/SupplierHeader";
import { AwardedSuppliers } from "./AwardedSuppliers";
import ContractBrands from "./ContractBrands";
import ContractFiles from "./ContractFiles";
import ContractRecommendations from "./ContractRecommendations";
import ContractSummary from "./ContractSummary";
import ModularSolicitationPage from "./ModularSolicitationPage";
import { ResellerSuppliers } from "./ResellerSuppliers";
import ScopeSummary from "./ScopeSummary";
import SolicitationPageCTA from "./SolicitationPageCTA";
import SolicitationPageHeader from "./SolicitationPageHeader";
import UnusableContractBanner from "./UnusableContractBanner";
import { scrollToSection } from "./utils";

export const BEST_MATCH_THRESHOLD = 70;

interface SolicitationPageProps {
  solicitationId?: string;
  pageType: Maybe<string>;
  solicitation: ISolicitation;
  rank?: string;
  contractId: string;
  query: string;
  analytics?: object;
  savedProjectId?: string;
  sourceKey?: string;
}

function SolicitationPage({
  solicitationId,
  pageType,
  solicitation: {
    cooperative_affiliation,
    cooperative_affiliation_id,
    cooperative_affiliation_url,
    buyer_lead_agency,
    buyer_lead_agency_id,
    buyer_lead_agency_url,
    title,
    contract_details,
    cooperative_affiliation_membership_url,
    cooperative_usage_summary,
    cooperative_usage_url,
    num_reseller_contracts,
    reseller_contracts,
    num_other_contracts,
    other_contracts,
  },
  rank,
  contractId,
  query,
  savedProjectId,
  sourceKey = "",
}: SolicitationPageProps) {
  const widgetSearchSource = useRecoilValue(widgetSearchSourceState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const { governmentAgency } = useRecoilValue(buyerProfileState);
  const { showDiversityCertifications } = useRecoilValue(userStateState);
  const initializeContractScope = useRecoilCallback(
    initializeContractScopeCallback,
    []
  );
  const requireLogin = useLoginWall();
  const overviewRef = useRef<HTMLDivElement>(null);
  const productsAndServicesRef = useRef<HTMLDivElement>(null);
  const complianceRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const documentsRef = useRef<HTMLDivElement>(null);
  const awardedSuppliersRef = useRef<HTMLDivElement>(null);
  const supplierRef = useRef<HTMLDivElement>(null);
  const bottomViewerRef = useRef<HTMLDivElement>(null);
  const userZip = useRecoilValue(userZipState);
  const profileType = useRecoilValue(profileTypeState);
  const supplier = useRecoilValue(supplierState);
  const diversityCertifications = _compact(
    contract_details.supplierCompliance?.diversityCertifications.map(
      (cert) => cert.shortName
    )
  );
  const showSupplierCTA =
    supplier?.id === contract_details.supplier.id ||
    profileType !== ProfileType.SUPPLIER;

  const showProductList =
    !!contract_details.supplierHasProducts &&
    (profileType !== ProfileType.SUPPLIER ||
      contract_details.supplier.handle === supplier.handle);

  const showScopeSummary =
    contract_details.offerings.length > 0 ||
    contract_details.supplierHasProducts ||
    contract_details.supplier_keywords.length > 0;

  useShowProQualificationEffect({
    proQualifications: contract_details.proQualifications,
    supplierId: contract_details.supplier.id,
    supplierName: contract_details.supplier.displayName,
    solicitationId,
  });
  useInitializeIntermediatedEmail(contract_details.supplier.id);
  useInitializeProductServices({
    hasProducts: contract_details.supplierHasProducts || false,
    handle: contract_details.supplier.handle,
    name: contract_details.supplier.displayName,
    query: query || "",
  });

  useEffect(() => {
    if (!query || query.length < 3) return;
    initializeContractScope(contract_details.contract_id, query);
  }, [initializeContractScope, contract_details.contract_id, query]);

  const numSuppliersOnSolicitation =
    num_reseller_contracts + num_other_contracts + 1; // Plus one for current supplier
  const { trackContractClick, requestID } = useTrackContractPageView({
    contractDetails: contract_details,
    contractId,
    solicitationId,
    title,
    otherContracts: other_contracts,
    query,
    numSuppliersOnSolicitation,
    buyerLeadAgency: buyer_lead_agency,
    cooperativeAffiliation: cooperative_affiliation,
    sourceKey,
    pageType,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once when the page first renders to track viewing.
  useEffect(() => {
    if (
      !isAuthenticated &&
      widgetSearchSource &&
      WHITELISTED_SEARCH_SOURCE.includes(widgetSearchSource)
    ) {
      void requireLogin({
        triggerId: widgetSearchSource,
        triggerType: LoginWallTriggers.VIEW_SOLICITATION_PAGE,
      });
    }
  }, []);

  const anchorLinkTrackingParams = {
    contractId,
    supplierId: contract_details.supplier.id,
    supplierHandle: contract_details.supplier.handle,
    solicitationId,
  };

  const documentsTabName = `Documents ${
    contract_details.file_information.length
      ? ` (${contract_details.file_information.length})`
      : ""
  }`;

  function getContractPathWithAnalytics(id: string) {
    return getContractPath({
      solicitationId,
      contractId: id,
      query,
      queryZip: userZip,
      pageNavigationSource:
        pageNavigationSourceTypes.OTHER_SUPPLIERS_ON_CONTRACT,
      requestID,
    });
  }

  if (isFeatureEnabled("simplifiedContractPage")) {
    return (
      <ModularSolicitationPage
        buyerLeadAgency={buyer_lead_agency}
        contractDetails={contract_details}
        contractId={contractId}
        cooperativeAffiliation={cooperative_affiliation}
        cooperativeUsageSummary={cooperative_usage_summary}
        cooperativeUsageUrl={cooperative_usage_url}
        membershipUrl={cooperative_affiliation_membership_url}
        numSuppliers={num_other_contracts + num_reseller_contracts}
        onDocumentsClick={handleDocumentsClick}
        onFirstSupplierClick={handleFirstSupplierClick}
        onOtherSuppliersClick={handleOtherSuppliersClick}
        query={query}
        savedProjectId={savedProjectId}
        solicitationId={solicitationId}
        title={title}
        anchorLinkTrackingParams={anchorLinkTrackingParams}
        documentsTabName={documentsTabName}
        showProductList={showProductList}
        showScopeSummary={showScopeSummary}
        sourceKey={sourceKey}
        otherContracts={other_contracts}
        numOtherContracts={num_other_contracts}
        resellerContracts={reseller_contracts}
        numResellerContracts={num_reseller_contracts}
        trackContractClick={trackContractClick}
        getContractPathWithAnalytics={getContractPathWithAnalytics}
        pageType={pageType}
      />
    );
  }

  const complianceChecklistSectionData = getComplianceChecklistSectionData({
    query,
    requestID,
    solicitationId: solicitationId || "",
    governmentAgency: governmentAgency,
    buyerLeadAgency: buyer_lead_agency,
    buyerLeadAgencyId: buyer_lead_agency_id,
    buyerLeadAgencyUrl: buyer_lead_agency_url,
    cooperativeAffiliation: cooperative_affiliation,
    cooperativeAffiliationId: cooperative_affiliation_id,
    cooperativeAffiliationUrl: cooperative_affiliation_url,
    contractDetails: contract_details,
    approvedSources: governmentAgency ? governmentAgency.approvedSources : null,
    diversityCertifications: showDiversityCertifications
      ? diversityCertifications
      : [],
    scrollToViewer,
  });

  const headerLinks: AnchorRefLink[] = [
    {
      pageId: contractId,
      name: "Overview",
      ref: overviewRef,
      className: "analytics-contract-overview-link",
      scrollOptions: { behavior: "smooth", block: "center" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
    {
      pageId: contractId,
      name: "Offerings",
      ref: productsAndServicesRef,
      className: "analytics-contract-scope-summary-link",
      scrollOptions: { behavior: "smooth", block: "end" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !showScopeSummary,
    },
    {
      pageId: contractId,
      name: "Product List",
      ref: productsRef,
      className: "analytics-contract-products-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !showProductList,
    },

    {
      pageId: contractId,
      name: documentsTabName,
      ref: documentsRef,
      className: "analytics-contract-documents-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: true,
    },
    {
      pageId: contractId,
      name: "Supplier",
      ref: supplierRef,
      className: "analytics-contract-supplier-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
  ];

  function handleFirstSupplierClick() {
    scrollToSection({
      sectionRef: supplierRef,
    });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary main supplier",
      linkClassName: "analytics-contract-main-supplier-link",
    });
  }

  function handleOtherSuppliersClick() {
    scrollToSection({ sectionRef: awardedSuppliersRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary other suppliers",
      linkClassName: "analytics-contract-other-suppliers-link",
    });
  }

  function handleDocumentsClick() {
    scrollToSection({ sectionRef: documentsRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Contract summary documents",
      linkClassName: "analytics-contract-summary-documents-link",
    });
  }

  function handleProductListClick() {
    if (!showProductList) return;
    scrollToSection({ sectionRef: productsRef });
    trackAnchorLinkClick({
      ...anchorLinkTrackingParams,
      linkName: "Jump to product list",
      linkClassName: "analytics-contract-product-list-link",
    });
  }

  function scrollToViewer() {
    scrollToSection({
      sectionRef: bottomViewerRef,
      options: { behavior: "smooth", block: "end" },
    });
  }

  return (
    <div className="flex flex-col gap-6 text-left" data-rank={rank}>
      <div className="grid grid-cols-4 md:grid-cols-12 gap-y-8 gap-x-4 md:gap-x-10 w-full ">
        <div className="grid col-start-1 col-span-full lg:col-end-9 gap-8">
          <SolicitationPageHeader
            title={title}
            query={query}
            savedProjectId={savedProjectId}
            contractDetails={contract_details}
            solicitationId={solicitationId}
          />
          <AnchorRefLinks links={headerLinks} />
          <UnusableContractBanner
            supplierHandle={contract_details.supplier.handle}
            contractTags={contract_details.contract_tags}
          />
          {showScopeSummary && (
            <>
              <div className="flex flex-col gap-6" ref={productsAndServicesRef}>
                <ScopeSummary
                  contractDetails={contract_details}
                  scrollToViewer={scrollToViewer}
                  showProductList={showProductList}
                  handleProductListClick={handleProductListClick}
                  handleDocumentsClick={handleDocumentsClick}
                />
              </div>
              <hr className="border-cp-neutral-200" />
            </>
          )}
          {profileType !== ProfileType.SUPPLIER && (
            <div
              ref={complianceRef}
              className="flex flex-col gap-6 scroll-m-[10.75rem]"
            >
              <Typography
                emphasis
                size="sm"
                variant="headline"
                color="brand.default.secondary.enabled"
              >
                Contract Details
              </Typography>
              <ComplianceChecklist
                contractId={contractId}
                sectionData={complianceChecklistSectionData}
              />
            </div>
          )}
          <div ref={overviewRef} className="grid gap-4">
            <ContractSummary
              title={title}
              query={query}
              contractDetails={contract_details}
              cooperativeAffiliation={cooperative_affiliation}
              cooperativeUsageSummary={cooperative_usage_summary}
              cooperativeUsageUrl={cooperative_usage_url}
              membershipUrl={cooperative_affiliation_membership_url}
              buyerLeadAgency={buyer_lead_agency}
              solicitationId={solicitationId}
              onFirstSupplierClick={handleFirstSupplierClick}
              onOtherSuppliersClick={handleOtherSuppliersClick}
              onClickDocuments={handleDocumentsClick}
              numSuppliers={num_other_contracts + num_reseller_contracts}
            />
          </div>
          {showSupplierCTA && (
            <div className="lg:hidden grid gap-8 max-w-fit">
              {/* CTA for mobile */}
              <SolicitationPageCTA
                contractDetails={contract_details}
                pageId={solicitationId}
                requestID={requestID}
              />
            </div>
          )}
          {showProductList && (
            <>
              <hr className="border-cp-neutral-200" />
              <div ref={productsRef} className="scroll-m-[10.75rem]">
                <ProductList
                  supplierHandle={contract_details.supplier.handle}
                  supplierName={contract_details.supplier.displayName}
                  supplierId={contract_details.supplier.id}
                  contractId={contractId}
                  contractScope={contract_details.contract_scope_type}
                />
              </div>
            </>
          )}
          <hr className="border-cp-neutral-200" />
          <div
            id="files-section"
            className="grid gap-6 scroll-m-[10.75rem]"
            ref={documentsRef}
          >
            <ContractFiles
              contractId={contractId}
              solicitationId={solicitationId as string}
              title={title}
              contractDetails={contract_details}
              buyerLeadAgency={buyer_lead_agency}
              cooperativeAffiliation={cooperative_affiliation}
              query={query}
              sourceKey={sourceKey}
              requestID={requestID}
              bottomViewerRef={bottomViewerRef}
              scrollToViewer={scrollToViewer}
            />
          </div>
          <hr className="border-cp-neutral-200" />
          <div ref={supplierRef} className="scroll-m-[10.75rem]">
            <AboutSupplier
              id={contract_details.supplier.id}
              contractId={contract_details.contract_id}
              handle={contract_details.supplier.handle}
              about={contract_details.supplier.about}
              addressCity={contract_details.supplier.addressCity}
              addressStateCode={contract_details.supplier.addressStateCode}
              activeAgreements={
                contract_details.supplierAgreement?.activeAgreements || []
              }
              aliases={contract_details.supplierCompliance?.aliases}
              className="flex flex-col gap-6"
              publicEntitiesServed={
                contract_details.supplierCompliance?.publicEntitiesServed
              }
              size={AboutSupplierSizes.COMPACT}
              header={
                <SupplierHeader
                  title={`About ${contract_details.supplier.displayName}`}
                  supplierId={contract_details.supplier.id}
                  supplierCompliance={contract_details.supplierCompliance}
                  supplierActiveAgreements={
                    contract_details.supplierAgreement?.activeAgreements || []
                  }
                  supplierName={contract_details.supplier.displayName}
                  supplierServiceArea={
                    contract_details.supplier_service_area_data
                  }
                  logoUrl={contract_details.supplier.logoUrl || ""}
                  size="compact"
                />
              }
            />
          </div>
          {contract_details.brands.length > 0 && (
            <>
              <hr className="border-cp-neutral-200" />
              <ContractBrands brands={contract_details.brands} />
            </>
          )}
          {!!num_other_contracts && (
            <>
              <hr className="border-cp-neutral-200" />
              <div
                id="other-supplier-section"
                ref={awardedSuppliersRef}
                className="flex flex-col gap-6 scroll-m-[10.75rem]"
              >
                <AwardedSuppliers
                  solicitationId={solicitationId}
                  contractId={contractId}
                  getContractPath={(id) => getContractPathWithAnalytics(id)}
                  track={trackContractClick}
                  totalNumSuppliers={num_other_contracts}
                  initialContracts={other_contracts}
                  query={query}
                />
              </div>
            </>
          )}
          {!!num_reseller_contracts && (
            <>
              <hr className="border-cp-neutral-200" />
              <ResellerSuppliers
                totalNumContracts={num_reseller_contracts}
                solicitationId={solicitationId}
                contractId={contractId}
                getContractPath={(id) => getContractPathWithAnalytics(id)}
                track={trackContractClick}
                count={num_reseller_contracts}
                initialContracts={reseller_contracts}
                displayName={contract_details.supplier.displayName}
              />
            </>
          )}
        </div>
        {showSupplierCTA && (
          <div
            className="col-span-4 xl:col-start-9 gap-8 lg:grid hidden h-fit sticky top-30 z-1"
            data-testid="large-screen-supplier-cta"
          >
            {/* CTA for large screens */}
            <SolicitationPageCTA
              contractDetails={contract_details}
              pageId={solicitationId}
              requestID={requestID}
            />
          </div>
        )}
      </div>
      <ContractRecommendations
        contractId={contractId}
        contractDetails={contract_details}
        query={query}
        title={title}
        solicitationId={solicitationId}
        numSuppliersOnSolicitation={numSuppliersOnSolicitation}
        buyerLeadAgency={buyer_lead_agency}
        cooperativeAffiliation={cooperative_affiliation}
        sourceKey={sourceKey}
        trackContractClickWithinSolicitation={trackContractClick}
        pageType={pageType}
      />
    </div>
  );
}

export default SolicitationPage;
