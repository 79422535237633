import { captureException } from "@sentry/browser";
import _pick from "lodash/pick";
import _pickBy from "lodash/pickBy";
import {
  type CallbackInterface,
  DefaultValue,
  type SetRecoilState,
  type Snapshot,
  atom,
  selector,
} from "recoil";
import * as yup from "yup";

import type { SupplierAgreement } from "../components/WelcomePage/types";
import { ApiService, type GovernmentAgency } from "../generated";
import { userTypeTabData } from "../modals/SignupSteps/constants";
import type { UserType } from "../modals/SignupSteps/types";
import type {
  BuyerProfile,
  BuyerProfileDetails,
  BuyerProfileTypes,
  SupplierContact,
  SupplierProfile,
  SupplierProfilePasswordDetails,
} from "../shared/types";
import {
  activeAgreementsOnLoad,
  browserLocalStorage,
  browserSessionStorage,
  getCSRFToken,
  getParam,
  hasAuthenticationOnLoad,
  onPostSocialAuthOnLoad,
  profileTypeOnLoad,
} from "../utils";
import { getUserData, handleError, postLogout } from "../utils/api";
import {
  BONFIRE_USER_DETAILS_COOKIE,
  IONWAVE_USER_DETAILS_COOKIE,
  SUPPLIER_ENROLLMENT_STATUS,
  type SUPPLIER_PROFILE_TYPES,
} from "../utils/constants";
import {
  LoginType,
  ProfileType,
  type ProfileTypes,
  SupplierApprovalStatus,
} from "../utils/enums";
import { handleError as handleGeneratedError } from "../utils/generatedApi";
import {
  addHeapUserProperties,
  addUserProperties,
  changeHeapEventLoginStatus,
  resetIdentity,
  trackFreshMagicLinkLogin,
  trackIdentity,
  trackLogout,
  trackZipFromIpFail,
} from "../utils/tracking";

import { getCookieEffect, getLocalStorageEffect } from "./util";

const isAuthenticatedState = atom({
  key: "isAuthenticatedState",
  default: hasAuthenticationOnLoad(),
});
export const profileTypeState = atom<ProfileTypes | null>({
  key: "profileTypeState",
  default: profileTypeOnLoad(),
});

const onPostSocialAuthState = atom({
  key: "onPostSocialAuthState",
  default: onPostSocialAuthOnLoad(),
});

/**
 *
 * @param CallbackInterface
 * @return (...args Array) => Promise<void>
 */
// TODO: should logoutCallback be an atom effect on isAuthenticatedState?
//  what local values to we expect for analytics after user logout?
function logoutCallback({
  set,
  snapshot,
}: {
  set: SetRecoilState;
  snapshot: Snapshot;
}) {
  return async () => {
    const form = new FormData();
    form.append("csrfmiddlewaretoken", getCSRFToken() || "");
    await postLogout(form);
    const [userType, socialAccountProvider] = await Promise.all([
      snapshot.getPromise(userTypeSignupState),
      snapshot.getPromise(userSocialAccountProviderState),
    ]);
    trackLogout({
      accountType: userType,
      // If there is no social account, pass pavilion loginType
      loginType: (socialAccountProvider as LoginType) || LoginType.PAVILION,
    });
    set(isAuthenticatedState, false);
    resetIdentity();
    browserLocalStorage?.clear();
    browserSessionStorage?.clear();
    window.location.href = "/";
  };
}

const governmentAffiliationDisplayNameLocalState = atom({
  key: "governmentAffiliationDisplayNameLocalState",
  default: "",
  effects: [getCookieEffect("govAffiliationDisplayName")],
});

export const governmentAgencyState = atom<Maybe<GovernmentAgency>>({
  key: "governmentAgencyState",
  default: {} as GovernmentAgency,
});

export const buyerProfileTypeState = atom<Maybe<BuyerProfileTypes>>({
  key: "buyerRoleState",
  default: "" as BuyerProfileTypes,
});

const buyerProfileDetailsState = atom<BuyerProfileDetails>({
  key: "buyerProfileDetailsState",
  default: { verified: false },
});

const buyerProfileState = selector<BuyerProfile>({
  key: "buyerProfileState",
  get: ({ get }) => {
    return {
      governmentAffiliationDisplayName: get(
        governmentAffiliationDisplayNameLocalState
      ),
      governmentAgency: get(governmentAgencyState),
      buyerProfileType: get(buyerProfileTypeState),
      ...get(buyerProfileDetailsState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    const {
      governmentAffiliationDisplayName,
      governmentAgency,
      buyerProfileType,
      ...rest
    } = value;
    set(
      governmentAffiliationDisplayNameLocalState,
      governmentAffiliationDisplayName || ""
    );
    set(governmentAgencyState, governmentAgency);
    set(buyerProfileTypeState, buyerProfileType);
    set(buyerProfileDetailsState, rest);
  },
});

export const supplierProfileTypeState = atom<
  Maybe<keyof typeof SUPPLIER_PROFILE_TYPES>
>({
  key: "supplierRoleState",
  default: "" as keyof typeof SUPPLIER_PROFILE_TYPES,
});

const supplierProfilePasswordState = atom<SupplierProfilePasswordDetails>({
  key: "supplierProfilePasswordState",
  default: { needPasswordChange: false },
});

export const supplierProfileState = selector<SupplierProfile>({
  key: "supplierProfileState",
  get: ({ get }) => {
    return {
      supplierProfileType: get(supplierProfileTypeState),
      ...get(supplierProfilePasswordState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    const { supplierProfileType, needPasswordChange } = value;
    set(supplierProfilePasswordState, {
      needPasswordChange: needPasswordChange || false,
    });
    set(supplierProfileTypeState, supplierProfileType);
  },
});

export const supplierExpiredAgreementsState = atom<SupplierAgreement[]>({
  key: "supplierExpiredAgreementsState",
  default: [],
});

interface ISupplierDetailsState {
  id: number;
  about: Maybe<string>;
  addressString: Maybe<string>;
  approvalStatus: SupplierApprovalStatus;
  displayName: Maybe<string>;
  manualContacts: SupplierContact[];
  manualServiceAreaLocal: Maybe<string>;
  manualServiceAreaNational: Maybe<string>;
  manualServiceAreaState: Maybe<string[]>;
  website: Maybe<string>;
  enrollmentStatus: number;
  targetedAgreementId: Maybe<number>;
  contractsNeedExpirationReview: boolean;
  contractsNeedDocReview: boolean;
  confirmedAllContracts: boolean;
  hasExclusions: boolean;
  confirmedNoExclusions: boolean;
  defaultContactId: Maybe<number>;
}

const defaultSupplierDetails = {
  id: -1,
  about: null,
  addressString: null,
  approvalStatus: SupplierApprovalStatus.PENDING,
  displayName: null,
  manualContacts: [],
  manualServiceAreaLocal: null,
  manualServiceAreaNational: null,
  manualServiceAreaState: null,
  website: null,
  enrollmentStatus: SUPPLIER_ENROLLMENT_STATUS.UNTARGETED,
  targetedAgreementId: null,
  contractsNeedExpirationReview: false,
  contractsNeedDocReview: false,
  confirmedAllContracts: false,
  hasExclusions: false,
  confirmedNoExclusions: false,
  defaultContactId: -1,
};

export interface ISupplierState extends ISupplierDetailsState {
  handle: Maybe<string>;
  activeAgreements: string[];
}

const supplierDetailsState = atom<ISupplierDetailsState>({
  key: "supplierDetailsState",
  default: defaultSupplierDetails,
});

const supplierAccountHandleState = atom({
  key: "supplierAccountHandleState",
  default: "",
});

export const supplierActiveAgreementsState = atom<string[]>({
  key: "supplierActiveAgreementsState",
  default: activeAgreementsOnLoad(),
});

export const userContactState = selector<Maybe<SupplierContact>>({
  // TOOD: Until there is a cleaner way to tie a contact to a user, we search for the one matching
  // the email, preferring one with an associated phone
  key: "userContactState",
  get: ({ get }) => {
    const user = get(userState);
    const supplier = user.supplier;
    const email = user.email;
    return (
      supplier?.manualContacts.find(
        (c) => c.phoneNumber?.phoneNumber && c.email === email
      ) ?? null
    );
  },
});

export const supplierState = selector<ISupplierState>({
  key: "supplierState",
  get: ({ get }) => {
    return {
      handle: get(supplierAccountHandleState),
      activeAgreements: get(supplierActiveAgreementsState),
      ...get(supplierDetailsState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;
    const { handle, activeAgreements, ...rest } = value;
    set(supplierAccountHandleState, handle || "");
    set(supplierActiveAgreementsState, activeAgreements || []);
    set(supplierDetailsState, rest);
  },
});

const userEmailState = atom({
  key: "userEmailState",
  default: "",
  effects: [getCookieEffect("userEmail")],
});
const userEmailVerifiedState = atom({
  key: "userEmailVerifiedState",
  default: false,
});
const userSocialAccountProviderState = atom({
  key: "userSocialAccountProviderState",
  default: "",
});

const userAdminState = atom<boolean>({
  key: "userAdminState",
  default: false,
});

const userLocalState = atom({
  key: "userLocalState",
  default: {},
});

const defaultUserBonfireDetailsState = { userId: "", email: "" };
const userBonfireDetailsState = atom({
  key: "userBonfireDetailsState",
  default: defaultUserBonfireDetailsState,
  effects: [
    getCookieEffect(BONFIRE_USER_DETAILS_COOKIE, {
      deserialize: (s) => {
        return JSON.parse(s);
      },
      serialize: (o) => {
        return JSON.stringify(o);
      },
      defaultValue: JSON.stringify(defaultUserBonfireDetailsState),
    }),
  ],
});

const defaultUserIonwaveDetailsState = { email: "" };
const userIonwaveDetailsState = atom({
  key: "userIonwaveDetailsState",
  default: defaultUserIonwaveDetailsState,
  effects: [
    getCookieEffect(IONWAVE_USER_DETAILS_COOKIE, {
      deserialize: (s) => {
        return JSON.parse(s);
      },
      serialize: (o) => {
        return JSON.stringify(o);
      },
      defaultValue: JSON.stringify(defaultUserIonwaveDetailsState),
    }),
  ],
});

interface IUserDetailsState {
  email?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  isSupplier?: boolean;
}

const userDetailsState = selector<IUserDetailsState>({
  key: "userDetailsState",
  get: ({ get }) => {
    const bonfireDetail = get(userBonfireDetailsState);
    return _pickBy({
      email: get(userEmailState) || bonfireDetail?.email,
      ...get(userLocalState),
    });
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    const { email, ...rest } = value;
    set(userEmailState, email || "");
    if (value) set(userLocalState, rest);
  },
});

// TODO: Replace with assets/js/generated/models/UserState.ts
export interface IUserStateState {
  diversityCertifications?: { [x: string]: boolean };
  id?: number;
  ignoreAgencyLocation?: boolean;
  searchZip?: Maybe<string>;
  showDiversityCertifications?: boolean;
  showOnlySingleAward?: boolean;
  showExpired?: boolean;
  showGsa?: boolean;
  showOnlyApprovedSources?: boolean;
  showOnlyCoop?: boolean;
  role?: Maybe<string>;
  location?: {
    zip: string;
    city?: string;
    state?: string;
    latLng?: number[];
  };
  buyerRole?: Maybe<string>;
  supplierRole?: Maybe<string>;
  hasVisitedProfile?: boolean;
  hasDismissedVerifyEmailCard?: boolean;
  hasDismissedUpdatePreferencesCard?: boolean;
  hasDismissedApprovedSourcesCard?: boolean;
  hasSeenContactSurvey?: boolean;
  hasSeenSupplierSourceChannelSurvey?: boolean;
  hasContactedSupplier?: boolean;
  lmPiggybackExpirations?: boolean;
  hasDismissedContactSuppliersTooltip?: boolean;
  hasAcceptedPrivacyPolicy?: boolean;
  supplierContactOptIn?: boolean;
  showIntentSurvey?: boolean;
}

const userStateState = atom<IUserStateState>({
  key: "userStateState",
  default: {},
});

const userInitializedState = atom({
  key: "userInitializedState",
  default: false,
});

const fetchUserState = selector({
  key: "fetchUserState",
  get: async ({ get }) => {
    const isAuthenticated = get(isAuthenticatedState);

    if (!isAuthenticated) return {};
    try {
      const response = await getUserData();
      if (handleError(response)) return {};
      return response.json();
    } catch (e) {
      captureException(e);
      return {};
    }
  },
});

type IUserState = {
  userState: IUserStateState;
  emailVerified?: boolean;
  socialAccountProvider?: string;
  isAdmin?: boolean;
  supplier?: ISupplierState;
  supplierProfile?: SupplierProfile;
  buyerProfile?: BuyerProfile;
} & IUserDetailsState;

// When changing this selector, not that sub-selctors should also be updated
// to handle modified keys.
const userState = selector<IUserState>({
  key: "userState",
  get: ({ get }) => {
    return {
      ...get(userDetailsState),
      emailVerified: get(userEmailVerifiedState),
      isAdmin: get(userAdminState),
      socialAccountProvider: get(userSocialAccountProviderState),
      buyerProfile: get(buyerProfileState),
      userState: get(userStateState),
      supplier: get(supplierState),
      supplierProfile: get(supplierProfileState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;
    userSchema.validateSync(value);

    set(userDetailsState, _pick(value, ["firstName", "lastName", "email"]));
    set(userEmailVerifiedState, value?.emailVerified || false);
    set(userSocialAccountProviderState, value?.socialAccountProvider || "");
    set(userAdminState, value?.isAdmin || false);
    set(userStateState, value?.userState || {});
    set(buyerProfileState, value?.buyerProfile || ({} as BuyerProfile));
    set(
      supplierState,
      value?.supplier || {
        ...defaultSupplierDetails,
        handle: null,
        activeAgreements: [],
      }
    );
    set(
      supplierProfileState,
      value?.supplierProfile || ({} as SupplierProfile)
    );

    updateHeapUserWithRecoil(value);
  },
});

const governmentAgencySchema = yup.object({
  id: yup.string(),
  agencyType: yup.string(),
  agencyTypeLabel: yup.string(),
  zipCode: yup.string().nullable(),
  approvedSources: yup.array().of(yup.string()).nullable(),
  approvedStates: yup.array().of(yup.string()).nullable(),
});

const buyerProfileSchema = yup.object({
  governmentAffiliationDisplayName: yup.string().nullable(),
  governmentAgency: governmentAgencySchema.nullable(),
  verified: yup.boolean(),
});

const supplierSchema = yup.object({
  handle: yup.string().nullable(),
  displayName: yup.string().nullable(),
  approvalStatus: yup.string().oneOf(Object.values(SupplierApprovalStatus)),
});

const userTypeSignupState = atom<UserType>({
  key: "userTypeSignupState",
  default: userTypeTabData.buyer.type,
  effects: [getLocalStorageEffect("userTypeSignupState", true)],
});

const userStateSchema = yup.object({
  ignoreAgencyLocation: yup.boolean(),
  role: yup.string().nullable(),
  buyerRole: yup.string().nullable(),
  supplierRole: yup.string().nullable(),
  searchZip: yup.string().nullable(),
  showExpired: yup.boolean(),
  showGsa: yup.boolean(),
  showOnlyCoop: yup.boolean(),
  showOnlyApprovedSources: yup.boolean(),
  showDiversityCertifications: yup.boolean(),
  showOnlySingleAward: yup.boolean(),
});

const userDetailsSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string(),
});

const userSchema = userDetailsSchema.concat(
  yup.object({
    emailVerified: yup.boolean(),
    socialAccountProvider: yup.string().nullable(),
    isAdmin: yup.bool().nullable(),
    userState: userStateSchema.nullable(),
    buyerProfile: buyerProfileSchema.nullable(),
    supplier: supplierSchema.nullable(),
  })
);

export const userZipState = atom({ key: "userZipState", default: "" });

// 1. Fetch user data from server via fetchUserState.
// 2. Load old user value from snapshot via userState.
// 3. Get values from URL parameters.
// 4. Set user, prioritizing server data, then user params,
//    then existing values.
// TODO: This function needs more robust testing of the above functionality.
function initializeUserCallback({ snapshot, set }: CallbackInterface) {
  return async () => {
    const [
      {
        buyerProfile,
        userState: _userState,
        emailVerified,
        socialAccountProvider,
        isAdmin,
        ...userDetails
      },
      profileType,
    ] = await Promise.all([
      snapshot.getPromise(fetchUserState),
      snapshot.getPromise(profileTypeState),
    ]);
    // NOTE: IT'S ONLY APPROPRIATE TO IGNORE THESE SINCE ALL OF
    //  THESE VALUES SHOULD BE SAVED ON BACKEND FOR LOGGED-IN USERS.
    const newValue = {
      ..._pickBy({
        // biome-ignore lint/suspicious/noMisleadingCharacterClass: I think the `-` in this regex is a false positive
        email: getParam("user").replace(/[\u200B-\u200D\uFEFF]/g, ""),
        firstName: getParam("firstName"),
        lastName: getParam("lastName"),
      }),
      ...userDetails,
      buyerProfile: {
        // When searching from widgets, we automatically set the affiliation via params.
        ..._pickBy({
          governmentAffiliationDisplayName: getParam("governmentAffiliation"),
        }),
        ...buyerProfile,
      },
      userState: _userState,
      emailVerified,
      socialAccountProvider,
      isAdmin,
    };
    set(userState, newValue);

    const newProfileType =
      profileType ||
      (buyerProfile
        ? ProfileType.BUYER
        : userDetails.supplierProfile
          ? ProfileType.SUPPLIER
          : null);

    set(profileTypeState, newProfileType);

    // Set heap identity at time of user initialization
    const userId = newValue?.id;
    if (userId) {
      trackIdentity(userId);
      changeHeapEventLoginStatus(true);
      trackFreshMagicLinkLogin(newValue.email);
    } else {
      changeHeapEventLoginStatus(false);
    }

    if (profileType) addUserProperties({ profileType });
  };
}

export function initializeUserZipCallback({
  snapshot,
  set,
}: CallbackInterface) {
  return async () => {
    const [userState, governmentAgency] = await Promise.all([
      snapshot.getPromise(userStateState),
      snapshot.getPromise(governmentAgencyState),
    ]);
    const zipParam = getParam("zip") || getParam("queryZip");
    const newZip =
      userState?.searchZip || governmentAgency?.zipCode || zipParam;
    if (newZip) {
      set(userZipState, newZip);
      return;
    }

    try {
      const ipZip = await ApiService.apiV1ZipCodeFromIpRetrieve();
      set(userZipState, ipZip);
    } catch (err) {
      handleGeneratedError(err);
      trackZipFromIpFail();
    }
  };
}

function updateHeapUserWithRecoil(user: IUserState) {
  addHeapUserProperties(
    _pickBy({
      userId: user?.id,
      email: user?.email,
      governmentAgencyId: user?.buyerProfile?.governmentAgency?.id,
      govAffiliationName: user?.buyerProfile?.governmentAffiliationDisplayName,
      govAffiliationState: user?.buyerProfile?.governmentAgency?.stateCode,
      role: user?.userState?.role,
    })
  );
}

export {
  buyerProfileState,
  governmentAffiliationDisplayNameLocalState,
  initializeUserCallback,
  isAuthenticatedState,
  logoutCallback,
  onPostSocialAuthState,
  supplierAccountHandleState,
  userAdminState,
  userBonfireDetailsState,
  userDetailsSchema,
  userDetailsState,
  userEmailState,
  userEmailVerifiedState,
  userInitializedState,
  userIonwaveDetailsState,
  userSocialAccountProviderState,
  userState,
  userStateState,
  userTypeSignupState,
};
