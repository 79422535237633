import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import clsx from "clsx";
import pluralize from "pluralize";
import { Badge, Typography } from "../../../../library";
import BookmarkLink from "../../../../library/BookmarkLink";
import { borderColorClass, iconColorClass } from "../../../../utils/colors";
import { elevationClass } from "../../../../utils/designTokens";

interface SupplierTasksProps {
  header?: string;
  tasks: Task[];
}

interface Task {
  label: string;
  sublabel?: string;
  completed: boolean;
  buttons?: Array<{
    label: string;
    onClick: () => void;
    href?: string;
  }>;
}

export default function SupplierTasks({
  header = "Tasks",
  tasks,
}: SupplierTasksProps) {
  const numCompletedTasks = tasks.filter(({ completed }) => completed).length;
  return (
    <div
      className={clsx(
        "flex flex-col h-fit rounded-3 p-6 border border-solid",
        borderColorClass.neutral.subtle.enabled,
        elevationClass["elevation-1"]
      )}
    >
      <div className="flex flex-col gap-2 pb-4 mb-4 border-b border-cp-neutral-200">
        <Typography color="brand.boldest.enabled" variant="headline" size="sm">
          {header}
        </Typography>
        <Typography color="neutral.bold.enabled" variant="meta">
          {`${numCompletedTasks} of ${tasks.length} ${pluralize("task", tasks.length)} complete`}
        </Typography>
      </div>
      {tasks.map(({ label, sublabel, completed, buttons }) => (
        <div key={label} className="flex gap-3 mb-6 last:mb-0">
          <Badge
            Icon={completed ? CheckCircleRoundedIcon : Brightness1OutlinedIcon}
            includeMargin={false}
            className="h-fit"
            iconClass={
              completed
                ? iconColorClass.feedback.bold.success
                : iconColorClass.neutral.boldest.enabled
            }
          />
          <div className="flex flex-col">
            <Typography
              color={
                completed ? "feedback.bold.success" : "neutral.boldest.enabled"
              }
              size="sm"
              emphasis
            >
              {label}
            </Typography>
            {sublabel && (
              <Typography
                color="neutral.bolder.enabled"
                size="sm"
                className="mt-1"
              >
                {sublabel}
              </Typography>
            )}
            {!completed && buttons?.length && (
              <div className="mt-3">
                {buttons?.map(({ label, onClick, href }) => (
                  <BookmarkLink
                    key={label}
                    label={label}
                    onClick={onClick}
                    href={href}
                    background
                    border
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
