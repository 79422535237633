import {
  Field,
  Form,
  Formik,
  type FormikErrors,
  type FormikHelpers,
} from "formik";
import _isEmpty from "lodash/isEmpty";
import { useMemo, useState } from "react";
import * as yup from "yup";

import { Typography } from "../../../library";
import type { Validate } from "../../../library/form/types";

import ClarityCallout from "../../../shared/ClarityCallout";
import StatusChip, { StatusType } from "./StatusChip";
import SupplierLockedField from "./SupplierLockedField";
import type { CustomFieldProps, SupplierEditForm } from "./types";

type SavedSet<T extends object> = Record<keyof T, boolean>;

export default function SupplierEditFormWrapper<T extends object>({
  initialValues,
  onSubmit,
  fields,
  disabled = false,
  savedFields = [],
  callouts,
  status,
}: SupplierEditForm<T>) {
  const [saved, setSaved] = useState<SavedSet<T>>(
    Object.fromEntries(savedFields.map((c) => [[c], true]))
  );
  const validationSchema = useMemo(() => {
    return fields.reduce(
      (schema, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {} as Record<keyof T, Validate>
    );
  }, [fields]);

  const fieldMapFn = (
    {
      name,
      label,
      sublabel,
      placeholder,
      component,
      size,
      validate,
      dataTestId,
      ...rest
    }: CustomFieldProps<T>,
    index: number,
    errors: FormikErrors<T>,
    setFieldTouched: FormikHelpers<T>["setFieldTouched"]
  ) => (
    <div key={index} className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          {label && (
            <Typography
              component="label"
              htmlFor={name.toString()}
              emphasis
              size="sm"
              variant="headline"
              color="brand.default.secondary.enabled"
            >
              {label}
            </Typography>
          )}
          {status && (
            <StatusChip
              status={
                disabled
                  ? StatusType.LOCKED
                  : Object.values(initialValues).some(Boolean)
                    ? StatusType.COMPLETE
                    : StatusType.INCOMPLETE
              }
            />
          )}
        </div>
        {sublabel && typeof sublabel === "string" ? (
          <Typography>{sublabel}</Typography>
        ) : (
          sublabel
        )}
      </div>
      <SupplierLockedField disabled={disabled}>
        {callouts?.length && (
          <ClarityCallout callouts={callouts} className="my-2" />
        )}
        <Field
          key={name}
          id={name}
          name={name}
          component={component}
          placeholder={placeholder}
          validate={validate}
          editable
          dataTestId={dataTestId}
          hasLabel={false}
          onChange={() => {
            setSaved({ ...saved, [name]: false });
            setFieldTouched(name as string, true);
          }}
          size={size}
          validationMessage={saved[name] && !errors[name] ? "Saved" : ""}
          {...rest}
        />
      </SupplierLockedField>
    </div>
  );

  return (
    <div>
      <Formik
        validateOnBlur
        validateOnChange
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          try {
            await onSubmit(values, formikHelpers);
          } catch {
            // Form is invalid.
          }
        }}
        validationSchema={yup.object(validationSchema)}
      >
        {({ handleBlur, touched, errors, submitForm, setFieldTouched }) => (
          <Form
            className="w-full"
            onBlur={async (e) => {
              handleBlur(e);
              if (_isEmpty(touched)) return;
              submitForm();
            }}
          >
            <div className="w-full">
              {fields.map((field, index) =>
                fieldMapFn(field, index, errors, setFieldTouched)
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
