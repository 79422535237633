import useShowModal from "../hooks/useShowModal";
import { Button, ButtonSizes } from "../library";
import { accountModals } from "../utils/enums";

export default function CreateAccountButton() {
  const showSignupModal = useShowModal(accountModals.INITIAL_SIGN_UP);
  return (
    <Button
      key="create-account"
      className="analytics-create-account-button"
      onClick={() => {
        showSignupModal({
          onComplete: () => {
            window.location.reload();
          },
        });
      }}
      size={ButtonSizes.SMALL}
    >
      Create your free account
    </Button>
  );
}
