import { useRecoilValue } from "recoil";
import { Typography } from "../../../library";
import {
  contractSearchResponseDataState,
  numFiltersAppliedState,
} from "../../../recoil/search";

export default function OtherResultsHeaderText({ query }: { query: string }) {
  const contractSearchResponseData = useRecoilValue(
    contractSearchResponseDataState
  );
  const numFiltersApplied = useRecoilValue(numFiltersAppliedState);

  const filtersApplied = numFiltersApplied > 0;

  return (
    <div className="grid gap-2">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        {filtersApplied
          ? `More contracts ${
              contractSearchResponseData?.agencyData?.agency
                ? `from ${contractSearchResponseData.agencyData.agency?.name}`
                : `for "${query}"`
            }`
          : "Other related contracts"}
      </Typography>
      <Typography color="neutral.bolder.enabled">
        {filtersApplied
          ? "We found some great contracts that are relevant to your query, but fall outside of your filters. To see more results, remove any filters you're not using."
          : "Here are some related options we found that might fit your needs."}
      </Typography>
    </div>
  );
}
