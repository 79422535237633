import { useMemo, useRef } from "react";

import { useRecoilValue } from "recoil";
import { ChipList } from "../../library/ChipList/ChipList";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import { contractScopeState } from "../../recoil/scope";
import { getParam, getSentenceCase, sortStringsWithQuery } from "../../utils";
import ScopeSectionHeader from "./ScopeSectionHeader";
import type { ScopeBookmarkLinkProps } from "./ScopeSummary";

interface ContractOfferingsProps {
  contractDetails: ContractSolicitationDetails;
  bookmarkProps?: ScopeBookmarkLinkProps;
}

export default function ContractOfferings({
  contractDetails,
  bookmarkProps,
}: ContractOfferingsProps) {
  const query = getParam("query");
  const { scope } = useRecoilValue(contractScopeState);
  const ref = useRef<HTMLDivElement>(null);

  const sortedOfferings = useMemo(() => {
    if (!contractDetails.offerings?.length) return [];

    if (scope.offerings.length) {
      return scope.offerings
        .map(({ offering }) => offering)
        .map(getSentenceCase);
    }

    const capitalizedOfferings = contractDetails.offerings.map(getSentenceCase);
    return sortStringsWithQuery(capitalizedOfferings, query);
  }, [contractDetails, query, scope.offerings]);

  if (!sortedOfferings.length) return null;

  return (
    <div ref={ref} className="flex flex-col gap-y-6">
      <div className="grid gap-4">
        <ScopeSectionHeader
          title="Listed on contract"
          bookmarkProps={bookmarkProps}
        />
        <ChipList
          dataTestId="contract-scope-list"
          parentRef={ref}
          keywords={sortedOfferings}
          query={query}
          lines={2}
        />
      </div>
    </div>
  );
}
