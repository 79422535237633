/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Amendment` - Amendment
 * * `Bid Solicitation` - Bid Solicitation
 * * `Bid Tabulation` - Bid Tabulation
 * * `Contract` - Contract
 * * `Pricing` - Pricing
 * * `Renewal` - Renewal
 * * `Award Summary` - Award Summary
 * * `Insurance` - Insurance
 * * `Supplier Response` - Supplier Response
 * * `Supplier` - Supplier
 * * `Other` - Other
 */
export enum FileTypeEnum {
    AMENDMENT = 'Amendment',
    BID_SOLICITATION = 'Bid Solicitation',
    BID_TABULATION = 'Bid Tabulation',
    CONTRACT = 'Contract',
    PRICING = 'Pricing',
    RENEWAL = 'Renewal',
    AWARD_SUMMARY = 'Award Summary',
    INSURANCE = 'Insurance',
    SUPPLIER_RESPONSE = 'Supplier Response',
    SUPPLIER = 'Supplier',
    OTHER = 'Other',
}
