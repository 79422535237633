import * as yup from "yup";
import { AdditionalRequirementsEnum } from "../../generated/models/AdditionalRequirementsEnum";
import type { InputSizes } from "../../library/Input/LabeledInput";
import { CheckboxField } from "../../library/form";
import { FloatingLabelChipInputField } from "../../library/form/ChipInputField";
import { LabeledInputFloatingLabelField } from "../../library/form/LabeledInputField";
import LabeledInputField from "../../library/form/LabeledInputField";
import SelectableChipsField from "../../library/form/SelectableChipsField";
import { TextareaFloatingLabelField } from "../../library/form/TextareaField";
import type { FormFieldProps } from "../../library/form/types";

const ADDITIONAL_REQUIREMENTS_OPTIONS = [
  {
    value: AdditionalRequirementsEnum.TIME_SENSITIVE_PURCHASE,
    label: "This is a time-sensitive purchase",
  },
  {
    value: AdditionalRequirementsEnum.INSTALLATION_OR_SERVICES,
    label: "I need installation or other services",
  },
  {
    value: AdditionalRequirementsEnum.SPECIFIC_BRAND,
    label: "I need a specific brand",
  },
  {
    value: AdditionalRequirementsEnum.PREFER_LOCAL_SUPPLIERS,
    label: "I prefer local suppliers",
  },
  {
    value: AdditionalRequirementsEnum.PRICE_CONSTRAINTS,
    label: "I have pricing constraints",
  },
  {
    value: AdditionalRequirementsEnum.MEET_DIVERSITY_CRITERIA,
    label: "Suppliers must meet diversity criteria",
  },
  {
    value: AdditionalRequirementsEnum.MEET_INSURANCE_REQUIREMENTS,
    label: "Supplier must meet insurance requirements",
  },
].map((option, index) => ({ ...option, key: index.toString() }));

export const REQUIREMENT_PAGE_QUESTIONS: FormFieldProps[][] = [
  [
    {
      name: "description",
      label: "What are you looking for?",
      sublabel:
        "Details like a description or list of items, quantity, and whether your requirements are flexible help us get you the best supplier matches.",
      component: TextareaFloatingLabelField,
      size: "sm" as InputSizes,
      validate: yup
        .string()
        .required("Please provide a description of your requirements"),
    },
  ],
  [
    {
      name: "isOpenToComparableProducts",
      label: "I'm open to comparable products",
      component: CheckboxField,
      className: "mt-[-2rem]",
    },
  ],
  [
    {
      name: "preferredSuppliers",
      label: "Add preferred suppliers you'd like to work with (optional).",
      sublabel: "We'll find comparable suppliers.",
      size: "md" as InputSizes,
      component: LabeledInputFloatingLabelField,
    },
  ],
  [
    {
      name: "additionalRequirements",
      label: "Let us know if you have additional requirements (optional).",
      sublabel: "",
      size: "md" as InputSizes,
      component: SelectableChipsField,
      options: ADDITIONAL_REQUIREMENTS_OPTIONS,
    },
  ],
  [
    {
      name: "additionalRequirementsDetails",
      label:
        "Add details about any other requirements, including preferred contract sources, if any (optional).",
      size: "md" as InputSizes,
      component: LabeledInputFloatingLabelField,
    },
  ],
  [
    {
      name: "requestedCc",
      label:
        "Add team member emails to include on supplier threads (optional).",
      size: "md" as InputSizes,
      component: FloatingLabelChipInputField,
    },
  ],
];

export const CARD_FORM_QUESTIONS: FormFieldProps[][] = [
  [
    {
      name: "description",
      label: "What are you buying?",
      component: LabeledInputField,
      size: "md" as InputSizes,
      validate: yup.string().required(" "),
    },
  ],
  [
    {
      name: "additionalRequirementsDetails",
      label: "Any special requirements?",
      size: "md" as InputSizes,
      component: LabeledInputField,
    },
  ],
];
