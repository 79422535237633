import { selector, useRecoilValueLoadable } from "recoil";

import type { DiversityCertification } from "../components/Profile/types";
import { isAuthenticatedState } from "../recoil/user";
import { getUserDiversityCertifications, handleError } from "../utils/api";

const diversityCertificationsState = selector<
  Record<string, DiversityCertification>
>({
  key: "diversityCertificationsState",
  get: async ({ get }) => {
    if (!get(isAuthenticatedState)) return {};

    const response = await getUserDiversityCertifications();
    if (handleError(response)) return {};

    const data: Record<
      string,
      Record<string, DiversityCertification>
    > = await response.json();
    return Object.values(data).reduce(
      (values, group) => Object.assign(values, group),
      {}
    );
  },
});

/**
 * When using async selectors along with server side rendering, it's required to
 * either use React.Suspense or a useRecoilValueLoadable like below. Since
 * diversity certifications should not be updated, limit the api to a safe
 * hook that always returns a value.
 */
export default function useDiversityCertificationsValue() {
  const { state, contents: diversityCertifications } = useRecoilValueLoadable(
    diversityCertificationsState
  );

  if (state !== "hasValue") return {};
  return diversityCertifications;
}
