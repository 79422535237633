import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { type ReactNode, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import {
  type GovernmentAgency,
  SupplierServiceAreaDataConfidenceEnum,
} from "../../generated";
import { Link, Typography } from "../../library";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import {
  currentFileIndexState,
  selectedBookmarkIdState,
} from "../../recoil/files";
import { ContractTagTypes } from "../../shared/ContractBase/types";
import { expirationOptions } from "../../shared/SearchPage/SearchResults/ExpirationFilter";
import type { Bookmark, OCRFile } from "../../shared/types";
import { getParam, sortFilesByType } from "../../utils";
import { iconColorClass } from "../../utils/colors";
import type { FilterObject } from "../../utils/filters";
import { getSupplierUrlPath } from "../../utils/format";
import { getFileIndex } from "../SolicitationPage/ContractFiles/utils";
import {
  ApprovedSourcesLink,
  ComplianceBookmarkLink,
  ContactContractAdminLink,
  LandingPageLink,
} from "./links";

export enum ChecklistItemState {
  CHECKED = "CHECKED",
  UNCHECKED = "UNCHECKED",
  CONFIRMED = "CONFIRMED",
  UNCONFIRMED = "UNCONFIRMED",
  WARNING = "WARNING",
  CONFIRMED_UNUSABLE = "CONFIRMED_UNUSABLE",
}

export const ICONS_CONFIG = {
  [ChecklistItemState.CONFIRMED]: {
    icon: CheckCircleOutlineRoundedIcon,
    colorClass: iconColorClass.feedback.bold.success,
  },
  [ChecklistItemState.CHECKED]: {
    icon: CheckBoxRoundedIcon,
    colorClass: iconColorClass.neutral.bold.enabled,
  },
  [ChecklistItemState.UNCHECKED]: {
    icon: CheckBoxOutlineBlankRoundedIcon,
    colorClass: iconColorClass.neutral.bold.enabled,
  },
  [ChecklistItemState.UNCONFIRMED]: {
    icon: HelpOutlineRoundedIcon,
    colorClass: iconColorClass.neutral.bold.enabled,
  },
  [ChecklistItemState.WARNING]: {
    icon: ErrorOutlineRoundedIcon,
    colorClass: iconColorClass.feedback.bold.error,
  },
  [ChecklistItemState.CONFIRMED_UNUSABLE]: {
    icon: ErrorOutlineRoundedIcon,
    colorClass: iconColorClass.feedback.bold.error,
  },
};

export type ComplianceChecklistSectionItem = {
  key: string;
  body: string | ReactNode;
  link?: ReactNode;
  state: ChecklistItemState;
};

export type ComplianceChecklistSectionData = {
  sectionKey: string;
  heading: string;
  items: ComplianceChecklistSectionItem[];
};

export enum SectionStatus {
  COMPLETED = "COMPLETED",
  PARTIAL = "PARTIAL",
  NOT_USABLE = "NOT_USABLE",
}

export function getComplianceChecklistSectionData({
  query,
  requestID,
  solicitationId,
  governmentAgency,
  buyerLeadAgency,
  buyerLeadAgencyId,
  buyerLeadAgencyUrl,
  cooperativeAffiliation,
  cooperativeAffiliationId,
  cooperativeAffiliationUrl,
  contractDetails,
  diversityCertifications,
  approvedSources,
  scrollToViewer,
}: {
  query: string;
  requestID: string;
  solicitationId: string;
  governmentAgency: Maybe<GovernmentAgency>;
  buyerLeadAgency: string;
  buyerLeadAgencyId: string;
  buyerLeadAgencyUrl: string;
  cooperativeAffiliation: string;
  cooperativeAffiliationId: string;
  cooperativeAffiliationUrl: string;
  contractDetails: ContractSolicitationDetails;
  diversityCertifications: string[];
  approvedSources: string[] | null;
  scrollToViewer: () => void;
}): ComplianceChecklistSectionData[] {
  const sections = [];
  const hasManualOfferingsMatch = getParam("hasManualOfferingMatch") === "true";
  const expirationFilterParam = getParam("expirationFilter");
  const setCurrentFileIndex = useSetRecoilState(currentFileIndexState);
  const setSelectedBookmarkId = useSetRecoilState(selectedBookmarkIdState);
  // TODO: Maybe we want to show this all the time, regardless of filter param
  const expirationLabel =
    expirationOptions[1].find(
      (option: FilterObject) => option.key === expirationFilterParam
    )?.label || "";

  const sortedFiles = useMemo(
    () => sortFilesByType(contractDetails.file_information),
    [contractDetails.file_information]
  );

  const getBookmarkAndFileForQuery = (query: string) => {
    const fileWithBookmark = sortedFiles.find(({ bookmarks }) =>
      bookmarks.some(({ bookmark_type }) =>
        bookmark_type.toLowerCase().includes(query)
      )
    );

    const bookmark = fileWithBookmark?.bookmarks.find(({ bookmark_type }) =>
      bookmark_type.toLowerCase().includes(query)
    );

    return { fileWithBookmark, bookmark };
  };

  const handleClickBookmark = (file: OCRFile, bookmark: Bookmark) => {
    const fileIndex = getFileIndex(sortedFiles, file.id);
    if (fileIndex === -1) return;
    setCurrentFileIndex(fileIndex);
    // Use the first bookmark available
    setSelectedBookmarkId(bookmark.id);
    scrollToViewer();
  };

  const analyticsParams = {
    contractId: contractDetails.contract_id,
    buyerLeadAgency,
    buyerLeadAgencyId,
    buyerLeadAgencyUrl,
    cooperativeAffiliation,
    cooperativeAffiliationId,
    cooperativeAffiliationUrl,
  };

  // Start building list below
  const contractScopeItems = [];
  if (hasManualOfferingsMatch) {
    const { fileWithBookmark, bookmark } = getBookmarkAndFileForQuery("scope");

    contractScopeItems.push({
      key: "contract-offerings",
      body: "Your need is covered by this contract",
      link: fileWithBookmark && bookmark && (
        <ComplianceBookmarkLink
          bookmarkType={bookmark?.bookmark_type}
          onClick={() => handleClickBookmark(fileWithBookmark, bookmark)}
          {...analyticsParams}
        />
      ),
      state: ChecklistItemState.CONFIRMED,
    });
  } else {
    contractScopeItems.push({
      key: "contract-offerings",
      body: "Is your need covered in the contract scope?",
      state: ChecklistItemState.UNCONFIRMED,
    });
  }

  const contractSourceItems = [];
  if (
    contractDetails.contract_tags.find((tag) =>
      [
        ContractTagTypes.UNSUPPORTED_BY_SOURCE,
        ContractTagTypes.UNSUPPORTED_BY_BLA,
      ].includes(tag.variant)
    )
  ) {
    contractSourceItems.push({
      key: "source-unsupported",
      body: "The lead agency has cancelled or removed this contract.",
      state: ChecklistItemState.CONFIRMED_UNUSABLE,
    });
  }
  if (
    contractDetails.contract_tags.find(
      (tag) => tag.variant === ContractTagTypes.UNSUPPORTED_BY_SUPPLIER
    )
  ) {
    contractSourceItems.push({
      key: "supplier-unsupported",
      body: (
        <Typography>
          Supplier does not support this contract.{" "}
          <Link
            href={getSupplierUrlPath(
              contractDetails.supplier.handle
            ).toString()}
          >
            See other usable contracts
          </Link>{" "}
          from this supplier.
        </Typography>
      ),
      state: ChecklistItemState.CONFIRMED_UNUSABLE,
    });
  }

  if (governmentAgency?.id === buyerLeadAgencyId) {
    // When user's entity is the contract's BLA, don't show items
    // about approved sources or cooperative language
    contractSourceItems.push({
      key: "approved-sources",
      body: <Typography>{buyerLeadAgency} is my entity</Typography>,
      state: ChecklistItemState.CONFIRMED,
    });
  } else {
    if (approvedSources) {
      // Show approved source status for both the BLA and the coop (if available)
      const sources = [
        {
          source: cooperativeAffiliation,
          url: cooperativeAffiliationUrl,
          id: cooperativeAffiliationId,
        },
        {
          source: buyerLeadAgency,
          url: buyerLeadAgencyUrl,
          id: buyerLeadAgencyId,
        },
      ];

      sources.forEach(({ source, url, id }) => {
        if (!source) return;
        const contractSourceTextOrLink = url ? (
          <LandingPageLink
            key={source}
            landingPageUrl={url}
            contractId={contractDetails.contract_id}
            sourceName={source}
            sourceId={id}
          />
        ) : (
          source
        );
        contractSourceItems.push(
          approvedSources.includes(source)
            ? {
                key: `approved-sources-${id}`,
                body: (
                  <Typography>
                    {contractSourceTextOrLink} is an approved source for my
                    entity
                  </Typography>
                ),
                state: ChecklistItemState.CONFIRMED,
              }
            : {
                key: `approved-sources-${id}`,
                body: (
                  <Typography>
                    Is {contractSourceTextOrLink} an approved source for my
                    entity?
                  </Typography>
                ),
                link: (
                  <ApprovedSourcesLink
                    contractId={contractDetails.contract_id}
                    sourceName={source}
                    sourceId={id}
                  />
                ),
                state: ChecklistItemState.UNCONFIRMED,
              }
        );
      });
    }
    if (contractDetails.cooperative_language) {
      const { fileWithBookmark, bookmark } =
        getBookmarkAndFileForQuery("cooperative");
      contractSourceItems.push({
        key: "cooperative-language",
        body: (
          <Typography className="flex items-center gap-2">
            Cooperative language is confirmed in contract
          </Typography>
        ),
        link: fileWithBookmark && bookmark && (
          <ComplianceBookmarkLink
            bookmarkType={bookmark.bookmark_type}
            onClick={() => handleClickBookmark(fileWithBookmark, bookmark)}
            {...analyticsParams}
          />
        ),
        state: ChecklistItemState.CONFIRMED,
      });
    } else if (contractDetails.cooperative_language === null) {
      contractSourceItems.push({
        key: "cooperative-language",
        body: (
          <Typography className="flex items-center gap-2">
            Is cooperative language confirmed in this contract?
            <ContactContractAdminLink
              query={query}
              requestID={requestID}
              solicitationId={solicitationId}
              contractDetails={contractDetails}
              {...analyticsParams}
            />
          </Typography>
        ),
        state: ChecklistItemState.UNCONFIRMED,
      });
    } else if (contractDetails.cooperative_language === false) {
      contractSourceItems.push({
        key: "cooperative-language",
        body: "Contract is non-cooperative",
        state: ChecklistItemState.CONFIRMED_UNUSABLE,
      });
    }
  }

  if (expirationLabel) {
    contractSourceItems.push({
      key: "expiration-filter",
      body: `Contract expires in ${expirationLabel}`,
      state: ChecklistItemState.CONFIRMED,
    });
  }

  const supplierItems = [];
  const supplierServiceAreaData = contractDetails.supplier_service_area_data;

  if (
    supplierServiceAreaData.confidence ===
    SupplierServiceAreaDataConfidenceEnum.HIGH
  ) {
    supplierItems.push({
      key: "supplier-service-area",
      body: `Supplier services ${supplierServiceAreaData.state}`,
      state: ChecklistItemState.CONFIRMED,
    });
  } else if (
    supplierServiceAreaData.confidence ===
    SupplierServiceAreaDataConfidenceEnum.NEGATIVE
  ) {
    // Only show when manual supplier service area does not include the user's state
    supplierItems.push({
      key: "supplier-service-area",
      body: `Supplier may not service ${supplierServiceAreaData.state}`,
      state: ChecklistItemState.WARNING,
    });
  } else {
    supplierItems.push({
      key: "supplier-service-area",
      body: "Does this supplier serve your state?",
      state: ChecklistItemState.UNCONFIRMED,
    });
  }

  if (diversityCertifications.length) {
    supplierItems.push({
      key: "diversity-certifications",
      body: `Supplier has certifications: ${diversityCertifications.join(
        ", "
      )}`,
      state: ChecklistItemState.CONFIRMED,
    });
  }

  if (contractScopeItems.length)
    sections.push({
      sectionKey: "needs",
      heading: "Does this supplier’s contract cover my need?",
      items: contractScopeItems,
    });
  if (contractSourceItems.length) {
    sections.push({
      sectionKey: "source",
      heading: "Can I use this contract source?",
      items: contractSourceItems,
    });
  }
  if (supplierItems.length) {
    sections.push({
      sectionKey: "supplier",
      heading: "Will this supplier work with me?",
      items: supplierItems,
    });
  }

  return sections;
}
