import { Chip } from "@mui/material";
import clsx from "clsx";
import {
  type ChangeEvent,
  type KeyboardEvent,
  type KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import { EMAIL_REGEX } from "../../../utils/constants";
import Typography from "../../Typography";
import type { TypographySize } from "../../Typography/types";
import {
  LABEL_VARIANTS,
  LabeledInputLabelStyles,
  LabeledInputVariants,
  VARIANTS,
} from "../LabeledInput";
import {
  INPUT_CONTENT_STYLE,
  INPUT_PADDING,
  type InputComponentType,
  SHARED_INPUT_BORDER_STYLE,
  borderClassName,
  delimiters,
} from "../constants";

export enum ChipInputType {
  Email = "email",
  Text = "text",
}

const chipValidationByType = {
  [ChipInputType.Email]: (email: string) => {
    return {
      isError: !EMAIL_REGEX.test(email),
      errorMessage: "Must be a valid email address",
    };
  },
  [ChipInputType.Text]: (value: string) => {
    return {
      isError: value.length === 0,
      errorMessage: "Must be non-empty input",
    };
  },
};
export interface ChipInputProps {
  type?: ChipInputType;
  initialVariant?: LabeledInputVariants;
  className?: string;
  onChange: (values?: string[]) => void;
  values: string[];
  message?: string;
  fieldError?: string;
  label?: string;
  labelStyle?: LabeledInputLabelStyles;
  leadingLabel?: string;
  trailingLabel?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  id?: string;
  dataTestId?: string;
  readOnly?: boolean;
  messageSize?: TypographySize;
}
export default function ChipInput({
  id,
  type = ChipInputType.Text,
  initialVariant = LabeledInputVariants.DEFAULT,
  className,
  onChange,
  values,
  message,
  label,
  labelStyle = LabeledInputLabelStyles.BORDER,
  leadingLabel,
  trailingLabel,
  placeholder,
  required = false,
  dataTestId = "chip-input",
  autoFocus = false,
  readOnly = false,
  messageSize = "sm",
}: ChipInputProps) {
  const [variant, setVariant] = useState(initialVariant);
  useEffect(() => {
    setVariant(initialVariant);
  }, [initialVariant]);

  const [draftValue, setDraftValue] = useState("");
  // setChipValidationError happens on _addValue()
  // props.messages are populated on form submission from Formik
  const [chipValidationError, setChipValidationError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const _addValue = ({ fromBlur }: { fromBlur?: boolean } = {}) => {
    const newValue = draftValue.trim();
    if (!newValue) return;
    const error = chipValidationByType[type](newValue);
    if (error.isError) {
      setChipValidationError(error.errorMessage);
      setVariant(
        fromBlur
          ? LabeledInputVariants.ERROR
          : LabeledInputVariants.ACTIVE_ERROR
      );
    } else {
      setDraftValue("");
      setChipValidationError("");
      setVariant(LabeledInputVariants.DEFAULT);
      onChange([...values, newValue]);
    }
  };

  const _removeValue = (index: number) => {
    values.splice(index, 1);
    // Create a copy to force re-render
    onChange([...values]);
  };

  const handleFocus = () => {
    if (readOnly) return;
    if (variant === LabeledInputVariants.DEFAULT) {
      setVariant(LabeledInputVariants.ACTIVE);
    } else if (variant === LabeledInputVariants.ERROR) {
      setVariant(LabeledInputVariants.ACTIVE_ERROR);
    }
  };

  const handleBlur = () => {
    if (variant === LabeledInputVariants.ACTIVE) {
      setVariant(LabeledInputVariants.DEFAULT);
    } else if (variant === LabeledInputVariants.ACTIVE_ERROR) {
      setVariant(LabeledInputVariants.ERROR);
    }
    _addValue({ fromBlur: true });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    if (delimiters.includes(e.key)) {
      _addValue();
      // Prevent handleChange from firing, which would add delimiter char to draftValue
      e.preventDefault();
    } else if (e.key === "Backspace" && values && draftValue.length === 0) {
      _removeValue(values.length - 1);
    }
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const { textColor, messageColor, containerClass } = VARIANTS[variant];
  const { labelClass, labelSize } = LABEL_VARIANTS[labelStyle];

  const labelComponent = (
    <Typography
      component="label"
      size={labelSize}
      emphasis
      color={textColor}
      className={labelClass}
    >
      {label}
    </Typography>
  );

  return (
    <>
      {labelStyle === "floating" && labelComponent}
      <div
        className={clsx(SHARED_INPUT_BORDER_STYLE, containerClass, className)}
      >
        <div
          className={clsx(
            borderClassName(variant).replace("items-center", ""),
            INPUT_PADDING,
            "flex gap-2",
            label ? "" : "truncate"
          )}
          onClick={focusInput}
        >
          {leadingLabel && (
            <Typography
              className="leading-6 pt-1"
              color="neutral.bolder.enabled"
            >
              {leadingLabel}
            </Typography>
          )}
          {labelStyle === "border" && label && labelComponent}
          <div className="flex flex-wrap gap-2 w-full">
            {values?.map((s, i) => (
              <Chip
                className="flex-none"
                key={s}
                label={s}
                onDelete={() => _removeValue(i)}
              />
            ))}
            <Typography
              component={"input" as InputComponentType}
              ref={inputRef}
              color={textColor}
              // @ts-ignore
              id={id || dataTestId}
              type="text"
              className={clsx(
                INPUT_CONTENT_STYLE,
                // Chip size="large" is height 32px
                // https://mui.com/material-ui/react-chip/#sizes-chip
                "flex-1 min-w-[128px] min-h-[32px]"
              )}
              placeholder={values.length ? "" : placeholder}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDraftValue(e.target.value)
              }
              onKeyDown={handleKeyDown}
              autoFocus={autoFocus}
              required={required}
              readOnly={readOnly}
              disabled={variant === LabeledInputVariants.DISABLED}
              value={draftValue}
              data-testid={dataTestId}
            />
          </div>
          {trailingLabel && (
            <Typography
              className="leading-6 pt-1"
              color="neutral.bolder.enabled"
            >
              {trailingLabel}
            </Typography>
          )}
        </div>
        {(chipValidationError || message) && (
          <Typography
            size={messageSize}
            variant="meta"
            color={messageColor || textColor}
            className="mt-2 mr-2 text-left"
          >
            {chipValidationError || message}
          </Typography>
        )}
      </div>
    </>
  );
}
