import useShowMore from "../../hooks/useShowMore";
import { Typography } from "../../library";
import SectionHeader from "./SectionHeader";

interface ContractBrandsProps {
  brands?: string[];
}

export default function ContractBrands({ brands }: ContractBrandsProps) {
  if (!brands?.length) return null;

  const { button, items } = useShowMore({
    items: brands.sort().map((brand) => {
      return (
        <Typography
          component="div"
          color="subtler"
          emphasis
          className="inline-block py-1 px-2 border rounded border-cp-neutral-20 border-solid mr-2.5 mt-2.5"
          key={brand}
        >
          {brand}
        </Typography>
      );
    }),
    numItemsWhenMinimized: 6,
    expandCtaOptions: { showAll: true, text: "Show all brands" },
  });

  return (
    <div>
      <SectionHeader title="Brands" />
      <div className="grid gap-4">
        <div>{items}</div>
        {button}
      </div>
    </div>
  );
}
