import { useRecoilCallback } from "recoil";

import type { TrackContractSearchArgs } from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  getCountsForTracking,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSearchAnalyticsParamsState,
  contractSearchParamsState,
  contractSearchTrackingCountsState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  showSupplierRedirectState,
  singleAwardOnlyFilterState,
} from "../recoil/search";
import { buyerProfileState } from "../recoil/user";
import { SearchActions, searchTypes } from "../utils/enums";
import { trackContractSearch } from "../utils/tracking";

export default function useTrackContractSearch() {
  const _trackContractSearch = useRecoilCallback<TrackContractSearchArgs, void>(
    ({ snapshot, set }) =>
      async (
        data,
        action,
        numSupplierHits,
        originalQuery = null,
        collapseBySupplier = false,
        intent = null
      ) => {
        const [
          searchParams,
          analyticsParams,
          trackingCounts,
          buyerProfile,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          contractSourceFilter,
          expirationFilter,
          contractDocumentsFilter,
          supplierRedirectShown,
        ] = await Promise.all([
          snapshot.getPromise(contractSearchParamsState),
          snapshot.getPromise(contractSearchAnalyticsParamsState),
          snapshot.getPromise(contractSearchTrackingCountsState),
          snapshot.getPromise(buyerProfileState),
          snapshot.getPromise(diversityCertificationsFilterState),
          snapshot.getPromise(approvedSourcesOnlyFilterState),
          snapshot.getPromise(singleAwardOnlyFilterState),
          snapshot.getPromise(contractSourceFilterState),
          snapshot.getPromise(expirationFilterState),
          snapshot.getPromise(contractDocumentsFilterState),
          snapshot.getPromise(showSupplierRedirectState),
        ]);

        const first10Counts = getCountsForTracking(
          data.contractData?.results.slice(0, 10)
        );
        const {
          scopeMatchesCount,
          supplierNameMatchesCount,
          semanticMatchesCount,
        } = getCountsForTracking(data.contractData?.results);
        const params = formatSearchPageParams(searchParams);

        const validation = validateSearchParams(params);
        if (!validation.valid) {
          return;
        }

        const firstPageTrackingCounts =
          action === SearchActions.SEARCH ||
          action === SearchActions.SEARCH_CONTRACTS_ONLY
            ? {
                firstPageStrongMatchCount: first10Counts.strongMatchesCount,
                firstPagePossibleMatchCount: first10Counts.possibleMatchesCount,
                firstPageSemanticMatchCount: first10Counts.semanticMatchesCount,
              }
            : { ...trackingCounts };
        if (
          action === SearchActions.SEARCH ||
          action === SearchActions.SEARCH_CONTRACTS_ONLY
        ) {
          set(contractSearchTrackingCountsState, firstPageTrackingCounts);
        }

        let searchQuery = data.params?.query || "";
        let redirectedQuery = null;
        // If the user was redirected from a supplier search, we want to use the original query
        // as searchQuery and log the redirected query separately.
        if (originalQuery) {
          redirectedQuery = searchQuery;
          searchQuery = originalQuery;
        }

        trackContractSearch({
          ...firstPageTrackingCounts,
          action,
          searchQuery,
          redirectedQuery,
          autoRedirected: !!redirectedQuery,
          queryZip: data.params?.zip || "",
          geoRankingActive: data.metadata?.isGeoRanking,
          govAffiliationName: buyerProfile?.governmentAffiliationDisplayName,
          govAffiliationState: buyerProfile?.governmentAgency?.stateCode,
          resultsCount: data.contractData?.numAllResults,
          searchType: collapseBySupplier
            ? searchTypes.SUPPLIER
            : searchTypes.CONTRACT,
          page: data.params?.page ? data.params?.page + 1 : -1, // page #s are 0-indexed
          filtersAndPreferences: {
            filters: data.params?.filters?.join(",") || "",
          },
          intent: intent || "",
          supplierCount: numSupplierHits,
          searchSource: analyticsParams.searchSource || "",
          userSelectedFromDefault: params.userSelectedFromDefault,
          requestID: analyticsParams.requestID || "",
          embedSourceEntityId: params.embedSourceEntityId,
          hasEntityMatch: !!data?.agencyData?.agency,
          scopeMatchesCount,
          supplierNameMatchesCount,
          semanticMatchesCount,
          expirationFilter,
          diversityCertificationsFilter,
          approvedSourcesOnlyFilter,
          singleAwardOnlyFilter,
          contractSourceFilter,
          contractDocumentsFilter,
          supplierRedirectShown,
        });
      },
    []
  );
  return _trackContractSearch;
}
