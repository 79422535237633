import { useRecoilValue, useSetRecoilState } from "recoil";
import { PageSection, Typography } from "../../library";
import { isFreshSEOUserState } from "../../recoil/history";
import { modalState } from "../../recoil/page";
import SearchBar from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import {
  SearchBarIntentVariants,
  SearchSource,
  modals,
} from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/split";

export default function SearchBarSection({
  displayName,
  isSSR,
}: {
  displayName: string;
  isSSR: boolean;
}) {
  const isFreshSEOUser = useRecoilValue(isFreshSEOUserState);

  const setCurrentModal = useSetRecoilState(modalState);
  function showSearchModal() {
    setCurrentModal({
      name: modals.SEARCH,
      source: "landing-page",
      isLocationRelevant: false,
    });
  }

  if (!isFreshSEOUser) return null;

  return (
    <PageSection className="bg-cp-violet-100 mb-6">
      <div className="landing-page-searchbar-section flex flex-col xl:px-0 items-start py-[5rem]">
        <Typography variant="display" color="brand.bold" className="lg:w-3/4">
          Find and use thousands of shareable contracts, all in one place
        </Typography>
        <Typography variant="headline" size="sm" className="lg:w-3/4 mt-4">
          Search across 65,000 active shareable contracts from over 400 sources
          across the U.S., including those from {displayName}. For free.
        </Typography>
        <SearchBar
          className="mt-10"
          theme={SearchBarThemes.DARK}
          isLocationRelevant={false}
          searchSource={SearchSource.LANDING_PAGE_SEARCH}
          isSSR={isSSR}
          cbOnEmptyQuery={showSearchModal}
          intentVariant={SearchBarIntentVariants.TOGGLE}
          disambiguationModalEnabled={isFeatureEnabled("disambiguationModal")}
          syncIntent
        />
      </div>
    </PageSection>
  );
}
