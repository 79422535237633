import { useState } from "react";

import {
  SUPPLIER_PERSON_CONTACT_FIELDS,
  SUPPLIER_ROLE_CONTACT_FIELDS,
} from "../../../components/supplier/SupplierEditForm/constants";
import { Typography } from "../../../library";
import { ToggleTabs } from "../../../library/ToggleTabs";
import { FormWrapper } from "../../../library/form";
import Modal from "../../../shared/Modal/Modal";
import type { SupplierContact } from "../../../shared/types";
import {
  addSupplierContact,
  getErrorMessage,
  handleError,
  patchSupplierContact,
} from "../../../utils/api";

interface AddContactModalProps {
  hideModal: () => void;
  handle: string;
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  extension?: string;
  email: string;
  title: string;
  isDefaultContact: boolean;
  onUpsert: (c: SupplierContact, options: AddContactModalUpsertOptions) => void;
}

export interface AddContactModalUpsertOptions {
  newDefaultContactId?: number;
  removeDefaultContact?: boolean;
}

interface ContactFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  extension?: string;
  email: string;
  title: string;
  isDefaultContact: boolean;
}

export default function AddContactModal({
  hideModal,
  handle,
  id,
  firstName,
  lastName,
  phoneNumber,
  extension,
  email,
  title,
  isDefaultContact,
  onUpsert,
}: AddContactModalProps) {
  const isNewContact = !id;
  const modalTitle = isNewContact ? "Add new contact" : "Edit contact";
  const submitCtaText = isNewContact ? "Save contact" : "Update contact";

  const [errorMessage, setErrorMessage] = useState("");
  // It's a team email if an id exists and first name + last name are not set
  const [isTeamEmail, setIsTeamEmail] = useState(
    !(firstName || lastName) && !!id
  );

  const initialContactValues = {
    firstName,
    lastName,
    phoneNumber,
    extension,
    email,
    title,
    isDefaultContact,
  };

  const saveContact = async (contactData: ContactFormValues) => {
    const data: Record<string, string | number> = {
      id,
      phoneNumber: contactData.phoneNumber,
      email: contactData.email,
    };

    if (contactData.title) {
      data.title = contactData.title;
    }

    if (contactData.extension) {
      data.extension = contactData.extension;
    }

    if (!isTeamEmail) {
      data.firstName = contactData.firstName;
      data.lastName = contactData.lastName;
    }

    let response: Response;
    if (!isNewContact) {
      response = await patchSupplierContact(data, id, handle);
    } else {
      response = await addSupplierContact(data, handle);
    }

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const errorMessage = await getErrorMessage(response);
        setErrorMessage(errorMessage);
      }
      return;
    }
    // Response data has new contact id
    const result = await response.json();

    const args: AddContactModalUpsertOptions = {};
    if (contactData.isDefaultContact) {
      args.newDefaultContactId = result.id;
    } else if (isDefaultContact && !contactData.isDefaultContact) {
      // If the contact was the default contact and the user unchecks the default contact box,
      // we need to remove the default contact on the supplier by passing undefined up
      args.removeDefaultContact = true;
    }

    onUpsert(result, args);
    hideModal();
  };

  return (
    <Modal
      title={modalTitle}
      hideModal={hideModal}
      ctaClass="analytics-add-contact"
    >
      {isNewContact && (
        <div className="mb-5">
          <ToggleTabs
            onSelectTab={() => {
              setIsTeamEmail(!isTeamEmail);
            }}
            defaultTabIndex={isTeamEmail ? 1 : 0}
            className="w-full mb-4"
          >
            <div label="Add individual" />
            <div label="Add team contact" />
          </ToggleTabs>
          {isTeamEmail && (
            <Typography
              className="text-center"
              size="sm"
              color="neutral.default.secondary.enabled"
            >
              Add a team contact if there is a shared email for government sales
              (ex: sales@example.com). Otherwise, add an individual.
            </Typography>
          )}
        </div>
      )}
      <div className="mb-5">
        <FormWrapper
          fields={
            isTeamEmail
              ? SUPPLIER_ROLE_CONTACT_FIELDS
              : SUPPLIER_PERSON_CONTACT_FIELDS
          }
          initialValues={initialContactValues}
          onSubmit={saveContact}
          submitClassName="analytics-supplier-contact-edit-cta"
          submitCta={submitCtaText}
          disabled={false}
        />
        {errorMessage && (
          <Typography
            color="destructive.default.primary.enabled"
            className="whitespace-pre-line"
          >
            {errorMessage}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
