import Cookies from "js-cookie";

import { LOGIN_REDIRECT_COOKIE_KEY } from "../../utils/constants";
import type { SignupOrigin } from "../../utils/enums";

export function goToHomePage() {
  window.open("/", "_parent");
}

export function goToLoginPage() {
  window.open("/accounts/login", "_parent");
}
export function goToSignupPage(signupOrigin?: SignupOrigin) {
  let url = "/accounts/initial-signup";

  if (signupOrigin) {
    url = `${url}?origin=${signupOrigin}`;
  }

  window.open(url, "_parent");
}

export function goToWelcomePage() {
  window.open("/welcome", "_parent");
}

export function redirectOnComplete(redirectUrl = "/welcome") {
  // If present, remove the shared link cookie.
  Cookies.remove(LOGIN_REDIRECT_COOKIE_KEY);
  window.open(redirectUrl, "_parent");
}

export function goToSignupWelcomePage(setIsFreshSignup: (b: boolean) => void) {
  setIsFreshSignup(false);
  window.open("/accounts/signup-welcome", "_parent");
}

export function goToPostAuthPage() {
  window.open("/accounts/social/signup", "_parent");
}
