import pluralize from "pluralize";
import { useState } from "react";

import { useGroupContractFiles } from "../../../hooks/useGroupContractFiles";
import { Button, ButtonSizes, ButtonThemes } from "../../../library";
import type { Bookmark, OCRFile } from "../../../shared/types";
import { isFeatureEnabled } from "../../../utils/split";
import type { FileSection } from "../types";
import FileGroup from "./FileGroup";
import type { HandleClickBookmarkOptions } from "./FileItem";

interface FilesListProps {
  contractNumber?: string;
  currentFileIndex: number;
  files: OCRFile[];
  onClick: (file: OCRFile) => void;
  onClickBookmark: (file: OCRFile, bookmark: Bookmark) => void;
  onCopyLink: (file: OCRFile) => void;
  onDownload: (file: OCRFile) => void;
  supplierName: string;
}

// Renders the file list and allows you to toggle changes
export default function FilesList({
  files,
  currentFileIndex,
  onClick,
  onClickBookmark,
  onCopyLink,
  onDownload,
  contractNumber,
  supplierName,
}: FilesListProps) {
  const hasFeature = isFeatureEnabled("simplifiedContractPage");
  const [showAllFiles, setShowAllFiles] = useState(hasFeature);
  function handleClickBookmark({ file, bookmark }: HandleClickBookmarkOptions) {
    onClickBookmark(file, bookmark);
  }
  const { fileSections, numHiddenFiles } = useGroupContractFiles(
    files,
    showAllFiles
  );

  return (
    <div className="analytics-files-sidebar-select-file grid gap-4">
      {fileSections.map((fileSection: FileSection) => (
        <FileGroup
          key={fileSection.header}
          analyticsClassName={fileSection.className}
          contractNumber={contractNumber}
          currentFileIndex={currentFileIndex}
          files={files}
          header={fileSection.header}
          onClick={onClick}
          handleClickBookmark={handleClickBookmark}
          onCopyLink={onCopyLink}
          onDownload={onDownload}
          sectionFiles={fileSection.files}
          supplierName={supplierName}
        />
      ))}

      {!hasFeature && (showAllFiles || numHiddenFiles > 0) && (
        <Button
          theme={ButtonThemes.TERTIARY_DARK}
          size={ButtonSizes.SMALL}
          onClick={() => setShowAllFiles(!showAllFiles)}
          className="analytics-files-view-more-btn w-fit"
        >
          {showAllFiles
            ? "View fewer documents"
            : `View ${numHiddenFiles} more ${pluralize(
                "document",
                numHiddenFiles
              )}`}
        </Button>
      )}
    </div>
  );
}
