import _debounce from "lodash/debounce";
import pluralize from "pluralize";
import { useState } from "react";

import { AutocompleteInput, Typography } from "../../library";
import type { InputSizes } from "../../library/Input/LabeledInput";
import { fetchSuppliers, handleError } from "../../utils/api";

export interface SupplierSearchResult {
  handle: Maybe<string>;
  displayName: string;
  numActiveContracts?: string;
  city?: string;
  state?: string;
}

export interface SupplierItem {
  id: string | null;
  label: string;
  Component: typeof SupplierOption;
  data: SupplierSearchResult | null;
}

export function SupplierOption(props: SupplierSearchResult) {
  let parsedContractCount = props.numActiveContracts
    ? Number.parseInt(props.numActiveContracts)
    : 0;
  if (Number.isNaN(parsedContractCount)) {
    parsedContractCount = 0;
  }
  return (
    <>
      <Typography>{props.displayName}</Typography>
      <div className="flex">
        {(props?.city || props?.state) && (
          <Typography size="sm" color="subtler">
            {[props.city, props.state].filter(Boolean).join(", ")}・
          </Typography>
        )}
        <Typography size="sm" color="subtler">
          {parsedContractCount} active{" "}
          {pluralize("contract", parsedContractCount)}
        </Typography>
      </div>
    </>
  );
}

const debouncedGetSuppliersList = _debounce(
  async (
    supplierName: string,
    setResultsList: (items: SupplierItem[]) => void
  ) => {
    if (supplierName.length < 3) {
      setResultsList([]);
      return;
    }

    const response = await fetchSuppliers(supplierName);
    if (handleError(response)) {
      return;
    }
    const data = await response.json();
    setResultsList(
      data.suppliers.map((supplier: SupplierSearchResult) => ({
        id: supplier.handle,
        label: supplier.displayName,
        Component: SupplierOption,
        data: supplier,
      }))
    );
  },
  200,
  { leading: false, trailing: true, maxWait: 200 }
);

interface SupplierSearchInputProps {
  value?: SupplierSearchResult;
  onChange: (value: string, supplier: SupplierSearchResult | null) => void;
  modalSource: Maybe<string>;
  hasLabel?: boolean;
  placeholder?: string;
  errorMessage?: string;
  size?: InputSizes;
  dataTestId?: string;
}

export default function SupplierSearchInput({
  value,
  onChange,
  modalSource,
  hasLabel = true,
  placeholder = "ex. Home Depot",
  errorMessage,
  dataTestId,
  size = "md",
}: SupplierSearchInputProps) {
  const [resultsList, setResultsList] = useState<SupplierItem[]>([]);

  return (
    <div className="relative w-full text-left">
      <AutocompleteInput
        name="supplierName"
        analyticsClass={`analytics-${modalSource}-supplierName`}
        initialValue={value?.displayName}
        initialSelectedId={value?.handle || null}
        onChange={(value) => debouncedGetSuppliersList(value, setResultsList)}
        onSelect={onChange}
        options={resultsList}
        label={hasLabel ? "Business name" : ""}
        placeholder={placeholder}
        errorMessage={errorMessage}
        dataTestId={dataTestId}
        freeSoloLabel="Create a new supplier"
        size={size}
      />
    </div>
  );
}
