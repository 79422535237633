import { atom } from "recoil";

export const currentFileIndexState = atom<number>({
  key: "currentFileIndexState",
  default: 0,
});

export const selectedBookmarkIdState = atom<string | null>({
  key: "selectedBookmarkIdState",
  default: "",
});
