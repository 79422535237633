import { LabeledInputLabelStyles } from "../Input/LabeledInput";
import LabeledInputField, {
  type LabeledInputFieldProps,
} from "./LabeledInputField";

export default function TextareaField(
  props: Omit<LabeledInputFieldProps, "component">
) {
  return <LabeledInputField {...props} component="textarea" />;
}

/**
 * For use with InlineFormWrapper, since FormFieldProps does not include labelStyle
 */
export function TextareaFloatingLabelField(
  props: Omit<LabeledInputFieldProps, "component">
) {
  return (
    <LabeledInputField
      labelStyle={LabeledInputLabelStyles.FLOATING}
      {...props}
      component="textarea"
    />
  );
}
