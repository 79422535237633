import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import clsx from "clsx";
import { Badge, Button, Typography } from "../../library";
import Modal from "../../shared/Modal/Modal";
import { iconColorClass } from "../../utils/colors";

export default function PleaseVerifyEmailModal({
  hideModal,
  onCtaClick,
}: {
  hideModal: () => void;
  onCtaClick?: (hideModal: () => void) => void;
}) {
  const ctaSection = (
    <div className="flex flex-col items-center">
      <Button
        onClick={onCtaClick ? () => onCtaClick(hideModal) : hideModal}
        badgeProps={{ Icon: CheckRoundedIcon }}
        type="submit"
      >
        I verified my email
      </Button>
      <Badge
        Icon={VerifiedUserOutlinedIcon}
        size="sm-md"
        iconClass={clsx(iconColorClass.neutral.bold.enabled, "mt-0.5")}
        className="mt-6"
        inline
      >
        <Typography size="sm" color="neutral.bold.enabled">
          We ask buyers and suppliers to verify their email to ensure that
          everyone has a safe experience on Pavilion.
        </Typography>
      </Badge>
    </div>
  );
  return (
    <Modal
      hideModal={hideModal}
      title="Please verify your work email"
      ctaSection={ctaSection}
    >
      <Typography color="neutral.boldest.enabled">
        It looks like you haven't verified your work email address yet. We've
        sent a link to your email—click the verification link, then come back to
        this page to send your message.
      </Typography>
    </Modal>
  );
}
