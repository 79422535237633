import { type CallbackInterface, DefaultValue, atom, selector } from "recoil";
import type { PiggyBackedContract } from "../components/EntityContractsPage/types";
import { ApiService, type RecentlyViewedContract } from "../generated";
import { handleError } from "../utils/generatedApi";
import { getLocalStorageEffect } from "./util";

export const piggybackedContractsState = atom<PiggyBackedContract[]>({
  key: "piggybackedContractsState",
  default: [],
});

export const piggybackedContractsLoadingState = atom<boolean>({
  key: "piggybackedContractsLoadingState",
  default: false,
});

export const recentlyViewedContractsListState = atom<RecentlyViewedContract[]>({
  key: "recentlyViewedContractsListState",
  default: [],
});

export const recentlyViewedContractsInitializedState = atom<boolean>({
  key: "recentlyViewedContractsInitializedState",
  default: false,
});

export const recentlyViewedContractsState = selector({
  key: "recentlyViewedContractsState",
  get: ({ get }) => {
    return {
      isInitialized: get(recentlyViewedContractsInitializedState),
      contracts: get(recentlyViewedContractsListState),
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) return;

    set(recentlyViewedContractsListState, value.contracts);
    set(recentlyViewedContractsInitializedState, value.isInitialized);
  },
});

export const addPiggybackSuccessState = atom<boolean>({
  key: "addPiggybackSuccessState",
  default: false,
  effects: [getLocalStorageEffect("addPiggybackSuccessState")],
});

export const removePiggybackSuccessState = atom<boolean>({
  key: "removePiggybackSuccessState",
  default: false,
  effects: [getLocalStorageEffect("removePiggybackSuccessState")],
});

export const piggybackErrorState = atom<string>({
  key: "piggybackErrorState",
  default: "",
  effects: [getLocalStorageEffect("piggybackErrorState")],
});

export function initializeRecentlyViewedContractsCallback({
  snapshot,
  set,
}: CallbackInterface) {
  return async () => {
    const [recentlyViewedContracts] = await Promise.all([
      snapshot.getPromise(recentlyViewedContractsState),
    ]);
    if (recentlyViewedContracts.isInitialized) return;

    try {
      const response = await ApiService.apiV1RecentlyViewedContractsList();
      set(recentlyViewedContractsState, {
        contracts: response,
        isInitialized: true,
      });
    } catch (error) {
      handleError(error);
      set(recentlyViewedContractsState, { contracts: [], isInitialized: true });
    }
  };
}
