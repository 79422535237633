import type {
  ContractData,
  ContractHit,
  FileTypeEnum,
  GovernmentAgency as GeneratedGovernmentAgency,
  PermissionRoleEnum,
} from "../generated";
import type { SUPPLIER_PROFILE_TYPES } from "../utils/constants";
import type { SupplierContactSourceType } from "../utils/enums";

export interface SelectComponentOption {
  value: string;
  label: string;
}

// TODO: Replace with BuyerRoleEnum from generated code
export enum BuyerProfileTypes {
  BUYER = "BUYER",
  END_USER = "END_USER",
  LEADERSHIP = "LEADERSHIP",
  OTHER = "OTHER",
}

export interface SupplierProfilePasswordDetails {
  needPasswordChange: boolean;
}
export interface BuyerProfileDetails {
  verified: boolean;
}

export interface BuyerProfile extends BuyerProfileDetails {
  user?: User;
  governmentAffiliation?: string;
  governmentAffiliationState?: string;
  governmentAgency: Maybe<GeneratedGovernmentAgency>;
  contractTypePreferences?: string[];
  buyerProfileType: Maybe<BuyerProfileTypes>;
  governmentAffiliationDisplayName: Maybe<string>;
  permissionRole?: PermissionRoleEnum;
}

export interface SupplierProfile {
  needPasswordChange: boolean;
  supplierProfileType: Maybe<keyof typeof SUPPLIER_PROFILE_TYPES>;
}

export interface User {
  firstName?: string;
  lastName?: string;
  email: string;
}

export interface GovernmentAgency {
  id: string;
  name: string;
  entityType: string;
  url?: string;
  numContracts: number;
  isCoop?: boolean;
  isAgency?: boolean;
  stateCode?: string;
  agencyType?: string;
  agencyTypeLabel?: string;
  zipCode?: string;
  approvedSources?: string[];
  approvedStates?: string[];
  appendStateForDisplay?: boolean;
  showOnlyApprovedSources?: boolean;
}

export enum ContentTypes {
  SEARCH_RESULTS = "search_results",
  CONTRACT = "contract",
  SUPPLIER = "supplier",
  SUPPLIER_ENTITY_RECOMMENDATIONS = "supplier_entity_recommendations",
}

export interface Bookmark {
  id: string;
  bookmark_type: string;
  page_number: number;
}
export interface OCRFile {
  id: string;
  bookmarks: Bookmark[];
  has_access?: boolean;
  name: string;
  type: FileTypeEnum;
  url: string;
  buyer_verification_gated?: boolean;
}

export interface SupplierContactPhoneNumber {
  phoneNumber: string;
  extension?: string;
  isVerified: boolean;
}

export interface SupplierContact {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber?: SupplierContactPhoneNumber;
  title?: string;
  source?: SupplierContactSourceType;
}

export type DedupedSupplierContact = SupplierContact & {
  duplicatePhoneIds: number[];
};

export type SupplierContactWithPhoneNumber = DedupedSupplierContact & {
  phoneNumber: SupplierContactPhoneNumber;
};

export type ContractSearchLimitedData = Pick<
  ContractData,
  "numShowingResults" | "numAllResults" | "numStrongResults"
> & { results: ContractHit[] };
