import ArrowBackRounded from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRounded from "@mui/icons-material/ArrowForwardRounded";
import { Button, Typography } from "../../library";

interface ProductNavigationButtonsProp {
  onClick: (next: number) => void;
  index: number;
  count: number;
  isLoading: boolean;
}

function LoadingNumber() {
  return (
    <div className="rounded-md animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 w-10 h-4 mx-2" />
  );
}

// TODO: Merge this and create a generic navigation button
export function ProductNavigationButtons({
  onClick,
  index,
  count,
  isLoading,
}: ProductNavigationButtonsProp) {
  return (
    <div className="flex grow justify-between items-center">
      <Button
        badgeProps={{ Icon: ArrowBackRounded, includeMargin: false }}
        size={Button.sizes.XS}
        theme={Button.themes.TERTIARY_DARK}
        onClick={() => onClick(index - 1)}
        disabled={!index || isLoading}
      />
      {isLoading ? (
        <LoadingNumber />
      ) : (
        <Typography size="sm" color="neutral.bolder.enabled">
          {`${index + 1} of ${count.toLocaleString()}`}
        </Typography>
      )}
      <Button
        badgeProps={{ Icon: ArrowForwardRounded, includeMargin: false }}
        size={Button.sizes.XS}
        theme={Button.themes.TERTIARY_DARK}
        onClick={() => onClick(index + 1)}
        disabled={index === count - 1 || isLoading}
      />
    </div>
  );
}
